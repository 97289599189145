import React, { Component, useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  Paper,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  // Typography,
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Invoice from "./Invoice";
import Packages from "./Packages";
import { getDiscountPercentage } from "../../methods/getDiscountPercentage";

import stripe from "../../images/stripe.png";
import Config from "../../v2/config";
import { withTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

// import "../../v2/pages/pay-for-ad/style.css";
// import { CURRENCY_META } from "../../v2/config/constant";
// import GenericDataAPI from "../../v2/services/api/generic";
// import { isEmpty } from "lodash";

class Payment extends Component {
  state = {
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "",
    postId: localStorage.getItem("postId")
      ? localStorage.getItem("postId")
      : "",
    sessionId: localStorage.getItem("session_creation")
      ? JSON.parse(localStorage.getItem("session_creation")).sessionId
      : "",

    amountBeforeDiscount: 0,
    price: 0,
    dueAmount: 0,
    discountPercentage: 0,
    discount: 0,
    currency: "",
    currencySymbol: "",
    promoCode: false,
    promo: "",
    isRenew: true,
    countryCode: "LK",
    showCurrencyDropdown: false,
    currencyArr: ["LKR"],
    isPromoApplied: false,
    showPromo: false,
    packages: [],
    selectedPackage: "",
    appliedPromoCode: "",
    threeMonthsDiscount: 0,
    twoMOnthsDiscount: 0,
    threeMonthsDiscountLKR: 0,
    twoMOnthsDiscountLKR: 0,

    // errors
    promoError: "",
    chekoutError: false,

    agreeChecked: false,
    isNotAgree: false,

    // loading
    loading: false,
    priceLoading: false,
    packagesLoading: true,
  };

  componentDidMount = () => {
    this.scrollToTop();
    // remove
    localStorage.removeItem("personalInfo");
    localStorage.removeItem("parentInfo");
    localStorage.removeItem("privateInfo");
    localStorage.removeItem("partnerInfo");
    localStorage.removeItem("statesOrDistricts");
    localStorage.removeItem("uploadPhotoFiles");
    localStorage.removeItem("promoCode");

    this.setState({ priceLoading: true });
    sessionStorage.setItem("force_lkr", false);

    // get my post
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          countryCode: res.data[0].personalInfo.residentCountry.code,
        });
        this.getPackages();
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        if (res.data[0].personalInfo.residentCountry.code !== "LK") {
          this.setState({
            currencyArr: [
              ...this.state.currencyArr,
              // res.data[0].personalInfo.residentCountry.currency,
              "USD",
            ],
          });

          // paymentmethods
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
            .then((res) => {
              const isLocalPayment = res.data.filter(
                (el) => el.id === "local_cc"
              );
              if (isLocalPayment.length > 0) {
                this.setState({ showCurrencyDropdown: true });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPackages = () => {
    this.setState({ priceLoading: true, packagesLoading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/pricing_tiers`)
      .then((res) => {
        if (res.data) {
          let oneMOnth = res.data.filter((filter) => filter.id === "1m");
          let threeMonths = res.data.filter((filter) => filter.id === "3m");
          if (oneMOnth.length > 0 && threeMonths.length > 0) {
            this.setState({
              threeMonthsDiscount: Math.floor(
                ((oneMOnth[0].amountUSD - threeMonths[0].amountUSD / 3) /
                  oneMOnth[0].amountUSD) *
                  100
              ),
              threeMonthsDiscountLKR: Math.floor(
                ((oneMOnth[0].amountLKR - threeMonths[0].amountLKR / 3) /
                  oneMOnth[0].amountLKR) *
                  100
              ),
            });
          }
          let twoMonths = res.data.filter((filter) => filter.id === "2m");
          if (oneMOnth.length > 0 && twoMonths.length > 0) {
            this.setState({
              twoMOnthsDiscountLKR: getDiscountPercentage(
                oneMOnth[0].amountLKR,
                twoMonths[0].amountLKR / 2
              ),
              twoMOnthsDiscount: getDiscountPercentage(
                oneMOnth[0].amountUSD,
                twoMonths[0].amountUSD / 2
              ),
            });
          }
        }
        this.setState({ packages: res.data });
        const defaultPackage = res.data.filter((el) => el.preferred === true);
        if (defaultPackage.length > 0) {
          this.setState({ selectedPackage: defaultPackage[0].id });
          const data = qs.stringify({
            isRenew: this.state.isRenew,
            // paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
            forceLKR:
              sessionStorage.getItem("force_lkr") &&
              sessionStorage.getItem("force_lkr") === "true"
                ? true
                : false,
            pricingTierId: this.state.selectedPackage,
            simulate: true,
          });
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
              data,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((res) => {
              localStorage.setItem(
                "session_creation",
                JSON.stringify(res.data)
              );
              localStorage.removeItem("promoCode");
              this.setState({
                sessionId: res.data.sessionId,
                price: res.data.amount,
                promoCode: res.data.promoCode ? true : false,
                amountBeforeDiscount: res.data.amountBeforeDiscount,
                discountPercentage: res.data.discountPercentage,
                dueAmount: res.data.amount,
                isRenew: res.data.isRenew,
                currency: res.data.currency,
                currencySymbol:
                  res.data.currency === "LKR" ? "Rs." : res.data.currency,
                priceLoading: false,
                discount: 0,
                isPromoApplied: false,
                showPromo: false,
                packagesLoading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                chekoutError: true,
              });
              this.setState({ priceLoading: false, packagesLoading: false });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle inputs
  handleChange = (e) => {
    const {name, value} = e.target;
    let newValue = value;
    switch (name) {
      case "promo":
        newValue = typeof newValue === "string" ? newValue.toUpperCase() : newValue;
        break;
      default:
        break;
    }
    this.setState({
      [name]: newValue,
      [`${name}Error`]: "",
    });
  };

  handleShowPromo = () => {
    this.setState({ showPromo: true });
  };

  // validate
  validate = () => {
    let promoError = "";

    if (this.state.promo === "") {
      promoError = this.props.lng
        ? "Promo code is required"
        : "ප්‍රවර්ධනය කේතය අවශ්‍ය වේ";
    }

    if (promoError) {
      this.setState({
        promoError,
      });
      return false;
    }

    return true;
  };

  // checkout
  handleApply = () => {
    if (this.validate()) {
      this.setState({ loading: true });

      const data = qs.stringify({
        promoCode: this.state.promo.trim(),
        forceLKR:
          sessionStorage.getItem("force_lkr") &&
          sessionStorage.getItem("force_lkr") === "true"
            ? true
            : false,
        pricingTierId: this.state.selectedPackage,
        simulate: true,
        // paymentMethodId:
        //   this.state.currency === "LKR" ? "local_cc" : "foreign_cc",
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("session_creation", JSON.stringify(res.data));
          localStorage.setItem("promoCode", this.state.promo);

          this.setState({
            discount: res.data.amountBeforeDiscount - res.data.amount,
            discountPercentage: res.data.discountPercentage,
            dueAmount: res.data.amount,
            price: res.data.amount,
            isPromoApplied: true,
            promoCode: res.data.promoCode ? true : false,
            sessionId: res.data.sessionId,
            loading: false,
            promo: "",
            promoError: "",
            appliedPromoCode: this.state.promo,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });

          if (err.response.data.code === 1400) {
            this.setState({
              promoError: this.props.lng
                ? "Invalid promo code"
                : "ප්‍රවර්ධනය කේතය වලංගු නොවේ",
            });
          } else if (err.response.data.code === 1401) {
            this.setState({
              promoError: this.props.lng
                ? "Promo code expired"
                : "ප්‍රවර්ධනය කේතය කල් ඉකුත් වී ඇත",
            });
          } else if (err.response.data.code === 1402) {
            this.setState({
              promoError: this.props.lng
                ? "Promo code cannot be used for this country"
                : "මෙම රට සඳහා ප්‍රවර්ධන කේතය භාවිතා කළ නොහැක",
            });
          } else if (err.response.data.code === 1403) {
            this.setState({
              promoError: this.props.lng
                ? "This code is only valid for new Ads "
                : "මෙම කේතය නව දැන්වීම් සඳහා පමණක් වලංගු වේ",
            });
          } else if (err.response.data.code === 1404) {
            const isValidPromo = Config.TWO_THREE_MONTH_PACKAGES_PROMOS.includes(this.state.promo);
            const errorMessage = this.props.lng
              ? isValidPromo
                ? "This code is valid only for 2 and 3 months packages."
                : "This code is not valid for the selected package"
              : isValidPromo
                ? "මෙම කේතය වලංගු වන්නේ මාස 2 සහ 3 පැකේජ සඳහා පමණි"
                : "මෙම කේතය තෝරාගත් පැකේජය සඳහා වලංගු නොවේ";
          
            this.setState({ promoError: errorMessage });
          } else if (err.response.data.code === 1512) {
            this.setState({
              chekoutError: true,
            });
          }
        });
    }
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleContinue = () => {
    sessionStorage.setItem("paying_currency", this.state.currency);
    sessionStorage.setItem("selected_package", this.state.selectedPackage);

    const subscriptionPackage = this.state.packages.find((element) => {
      return element.id === this.state.selectedPackage;
    });
    if (subscriptionPackage !== undefined) {
      sessionStorage.setItem(
        "selected-subscription-package",
        JSON.stringify(subscriptionPackage)
      );
    }
    if (this.state.isRenew) {
      this.props.history.push("/checkout");
    } else {
      if (this.state.agreeChecked) {
        this.props.history.push("/checkout");
      } else {
        this.setState({ isNotAgree: true });
      }
    }
  };

  handleCurrency = (e) => {
    this.setState({ currency: e.target.value, priceLoading: true });
    let countryCode = "LK";
    if (e.target.value === "LKR") {
      sessionStorage.setItem("force_lkr", true);
      countryCode = "LK";
    } else {
      sessionStorage.setItem("force_lkr", false);
      countryCode = "US";
    }
    localStorage.removeItem("promoCode");
    const data = qs.stringify({
      isRenew: this.state.isRenew,
      // paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
      forceLKR: e.target.value === "LKR" ? true : false,
      pricingTierId: this.state.selectedPackage,
      simulate: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        localStorage.removeItem("promoCode");

        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          promoCode: res.data.promoCode ? true : false,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
          discount: 0,
          isPromoApplied: false,
          showPromo: false,
          packagesLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ priceLoading: false });
      });
  };

  removePromo = () => {
    this.setState({ priceLoading: true });
    localStorage.removeItem("promoCode");
    const data = qs.stringify({
      isRenew: this.state.isRenew,
      forceLKR:
        sessionStorage.getItem("force_lkr") &&
        sessionStorage.getItem("force_lkr") === "true"
          ? true
          : false,
      pricingTierId: this.state.selectedPackage,
      simulate: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        localStorage.removeItem("promoCode");

        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          promoCode: res.data.promoCode ? true : false,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
          discount: 0,
          isPromoApplied: false,
          showPromo: false,
          packagesLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ priceLoading: false });
      });
  };

  setPackage = (id) => {
    console.log(this.state);
    this.setState({ selectedPackage: id, priceLoading: true });
    let data = {
      isRenew: this.state.isRenew,
      // paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
      forceLKR:
        sessionStorage.getItem("force_lkr") &&
        sessionStorage.getItem("force_lkr") === "true"
          ? true
          : false,
      pricingTierId: id,
      simulate: true,
    };
    if (localStorage.getItem("promoCode")) {
      data.promoCode = localStorage.getItem("promoCode");
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        // localStorage.removeItem("promoCode");

        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          promoCode: res.data.promoCode ? true : false,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
          discount: res.data.amountBeforeDiscount - res.data.amount,
          // discount: 0,
          // isPromoApplied: false,
          // showPromo: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ priceLoading: false });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <div className="remember-notice">
              {this.props.lng ? (
                `Ad ID : ${this.state.userId}`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දැන්වීම් කේතය : {this.state.userId}
                </span>
              )}
            </div>

            {this.state.chekoutError ? (
              <div
                className="invoice-terms pay-method-div"
                style={{ paddingBottom: 30 }}
              >
                <div
                  className="invoice"
                  style={{ padding: "25px 15px", textAlign: "center" }}
                >
                  {this.props.lng
                    ? "Post not within renewal period"
                    : "ඔබගේ දැන්වීම තවම අලුත් කල නොහැක"}
                </div>
              </div>
            ) : (
              <div className="invoice-terms" style={{ paddingBottom: 30 }}>
                {this.state.currencyArr.length > 1 && (
                  <div className="payment-choose-currency-dropdown-div">
                    <select
                      value={this.state.currency}
                      onChange={this.handleCurrency}
                      className="payment-choose-currency-dropdown"
                    >
                      {this.state.currencyArr.map((el, i) => (
                        <option value={el} key={i}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <Packages
                  lng={this.props.lng}
                  packages={this.state.packages}
                  setPackage={this.setPackage}
                  selectedPackage={this.state.selectedPackage}
                  packagesLoading={this.state.packagesLoading}
                  countryCode={this.state.countryCode}
                  threeMonthsDiscount={this.state.threeMonthsDiscount}
                  twoMOnthsDiscount={this.state.twoMOnthsDiscount}
                  threeMonthsDiscountLKR={this.state.threeMonthsDiscountLKR}
                  twoMOnthsDiscountLKR={this.state.twoMOnthsDiscountLKR}
                />
                <Invoice
                  lng={this.props.lng}
                  isRenew={this.state.isRenew}
                  currencySymbol={this.state.currencySymbol}
                  discountPercentage={this.state.discountPercentage}
                  amountBeforeDiscount={this.state.amountBeforeDiscount}
                  price={this.state.price}
                  promo={this.state.promo}
                  promoCode={this.state.promoCode}
                  showPromo={this.state.showPromo}
                  isPromoApplied={this.state.isPromoApplied}
                  discount={this.state.discount}
                  promoError={this.state.promoError}
                  loading={this.state.loading}
                  priceLoading={this.state.priceLoading}
                  handleChange={this.handleChange}
                  handleApply={this.handleApply}
                  handleShowPromo={this.handleShowPromo}
                  appliedPromoCode={this.state.appliedPromoCode}
                  removePromo={this.removePromo}
                />

                {!this.state.isRenew && (
                  <div>
                    {/* <p
                      style={{
                        fontWeight: 500,
                        fontSize: 15,
                        marginTop: 0,
                      }}
                    >
                      {this.props.lng ? (
                        "Terms of use"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          භාවිතා කිරීමේ කොන්දේසි
                        </span>
                      )}
                    </p> */}
                    <div
                      className="user-agreement-div"
                      style={{
                        fontSize: 14,
                        fontWeight: 300,
                        color: "#333333",
                        padding: "15px",
                      }}
                    >
                      {/* <p
                        style={{
                          marginTop: 0,
                          fontSize: 14,
                          lineHeight: "20px",
                        }}
                      >
                        {this.props.lng
                          ? "By continuing, I understand that"
                          : null}
                      </p>

                      <p style={{ marginTop: 5 }}>
                        {this.props.lng ? (
                          "a) My ad will be submitted for approval only upon completion of the payment and it will take up to 12 hours to be reviewed and approved."
                        ) : (
                          <span className="sinhala-size-12">
                            අ) මගේ දැන්වීම අනුමැතිය සඳහා ඉදිරිපත් කරනු ලබන්නේ
                            ගෙවීම අවසන් වූ පසුව පමණක් වන අතර එය සමාලෝචනය කර
                            අනුමත කිරීම පැය 12 ක් ඇතුලත සිදුවනු ඇත.
                          </span>
                        )}
                      </p>
                      <p style={{ marginTop: 0 }}>
                        {this.props.lng ? (
                          "b) My ad will be live on the website for 56 days and I am entitled to pay and renew the same ad upon expiry."
                        ) : (
                          <span className="sinhala-size-12">
                            ආ) මගේ දැන්වීම දින 56 ක් වෙබ් අඩවියේ සජීවීව ඇති අතර
                            කල් ඉකුත් වූ පසු එම දැන්වීම ම මුදල් ගෙවා අලුත්
                            කිරීමට මට අයිතියක් ඇත.
                          </span>
                        )}
                      </p>
                      <p style={{ marginTop: 0 }}>
                        {this.props.lng ? (
                          <span>
                            c) Other general terms and conditions as stated on
                            our <Link to="/terms">Terms & Conditions</Link> and
                            the <Link to="/privacy">Privacy Policy.</Link>.
                          </span>
                        ) : (
                          <span className="sinhala-size-12">
                            ඇ) අපගේ <Link to="/terms">නියමයන් සහ කොන්දේසි</Link>{" "}
                            පිටුවේ සහ{" "}
                            <Link to="/privacy">රහස්‍යතා ප්‍රතිපත්තියේ</Link>{" "}
                            සඳහන් අනෙකුත් පොදු නියමයන් සහ කොන්දේසි අදාල වේ.
                          </span>
                        )}
                      </p> */}

                      <FormControlLabel
                        className="create-without-email"
                        style={
                          this.state.isNotAgree
                            ? { border: "1px solid red" }
                            : { color: "#000000de" }
                        }
                        control={
                          <Checkbox
                            checked={this.state.agreeChecked}
                            onChange={() =>
                              this.setState({
                                agreeChecked: !this.state.agreeChecked,
                                isNotAgree: false,
                              })
                            }
                            name="agreeChecked"
                            size="small"
                            disabled={this.state.email ? true : false}
                          />
                        }
                        label={
                          Config.CURRENT_PLATFORM === Config.PLATFORMS.IN ? (
                            <span>
                              I have read and agree to the{" "}
                              <Link to="/terms">Terms of Use</Link> of
                              MyVivah.app
                            </span>
                          ) : this.props.lng ? (
                            <>
                              I have read and agree to the{" "}
                              <Link to="/terms">Terms of Use</Link> of{" "}
                              {Config.DOMAIN_DISPLAY_NAME}.
                            </>
                          ) : (
                            <span>
                              මම {Config.DOMAIN_DISPLAY_NAME} හි{" "}
                              <Link to="/terms">පරිශීලක ගිවිසුමට</Link> එකඟ
                              වෙමි.
                            </span>
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Paper>
        </Grid>

        <div style={{ paddingBottom: 15, marginTop: 20 }}>
          <Button
            variant="contained"
            className="back-btn"
            onClick={() => this.props.history.push("/?page=1")}
            startIcon={<KeyboardArrowLeftIcon />}
          >
            {this.props.lng ? "Pay Later" : "පසුව ගෙවන්න"}
          </Button>

          {!this.state.chekoutError && (
            <Button
              variant="contained"
              className="save-btn"
              onClick={this.handleContinue}
            >
              {this.state.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : this.props.lng ? (
                "Continue to Payment"
              ) : (
                "ඉදිරියට"
              )}
            </Button>
          )}

          {this.state.errorRequired && (
            <p
              align="right"
              style={{
                fontSize: 12,
                color: "#f44336",
                marginRight: 12,
              }}
            >
              *{" "}
              {this.props.lng
                ? "Fill all required fields"
                : "අවශ්‍ය සියලුම යෙදවුම් පුරවන්න"}
            </p>
          )}
        </div>

        <p
          align="center"
          style={{
            fontSize: 12,
            color: "#737373",
            marginTop: 50,
            marginBottom: 5,
          }}
        >
          Online Payments are securely processed by
        </p>
        <img
          className="payment-methods-icons"
          src={stripe}
          alt="payment methods"
        />
      </React.Fragment>
    );
  }
}

// const PaymentNew = ({ advertisement, lng }) => {
//   console.log(">>>> advertisement ", advertisement);
//   const { t, i18n } = useTranslation();

//   const [checkoutError, setCheckoutError] = useState(false);
//   const [currency, setCurrency] = useState(Config.BASE_CURRENCY);
//   const [pricingPackages, setPricingPackages] = useState([]);

//   const packagesDiscounts = useMemo(() => {
//     const discounts = {};
//     if (isEmpty(pricingPackages)) {
//       return discounts;
//     }
//     for (const princingPackage of pricingPackages) {
//       console.log(">>>> package ", princingPackage);
//       const packageId = princingPackage.id;
//       discounts[packageId] = {};
//     }
//     return discounts;
//   }, [pricingPackages]);

//   const calculateDiscount = (packageId) => {
//     const oneMonths = pricingPackages.find((current) => current.id === "1m");
//     const twoMonths = pricingPackages.find((current) => current.id === "2m");
//     const threeMonths = pricingPackages.find((current) => current.id === "3m");

//     const discount = { LKR: 0, USD: 0 };

//     if (isEmpty(oneMonths)) {
//       return discount;
//     }
//     // calculate two months discount
//     if (!isEmpty(twoMonths) && packageId === twoMonths.id) {
//     }
//     // calcuate three months discount
//     if (!isEmpty(threeMonths) && packageId === threeMonths.id) {
//     }
//     return discount;
//   };

//   useEffect(() => {
//     console.log(">>>>> mounting >>> useEffect ");
//     GenericDataAPI.getPricingTiers()
//       .then((response) => {
//         if (!response.success) {
//           return; // TODO: Error Handling
//         }
//         const packages = response.body;
//         setPricingPackages(packages);
//       })
//       .catch((error) => {
//         // TODO: Error Handling
//         console.log(">>>> error ", error);
//       });
//   }, []);

//   const supportedCurrencies = useMemo(() => {
//     const output = CURRENCY_META.filter((current) =>
//       Config.SUPPORTED_CURRENCIES.includes(current.code)
//     );
//     return output;
//   }, []);

//   return (
//     <Grid container direction="column" className="payment-page-content">
//       <Typography
//         className={`remember-notice remember-notice-${i18n.language}`}
//       >
//         {t("common.computedAdIdLabel", { id: advertisement.memberId ?? "" })}
//       </Typography>
//       <Grid container direction="column">
//         {checkoutError && (
//           <Typography
//             variant="body1"
//             className={`checkout-error checkout-error-${i18n.language}`}
//           >
//             {t("payment.postNotWithinRenewalPeriod")}
//           </Typography>
//         )}
//         {!checkoutError && (
//           <Grid container direction="column" className="packages-inner-panel">
//             <Select
//               labelId="currency-select-label"
//               variant="outlined"
//               value={currency}
//               onChange={(e) => setCurrency(e.target.value)}
//               style={{ width: 240, backgroundColor: "palegoldenrod" }}
//             >
//               {supportedCurrencies.map((currency) => (
//                 <MenuItem key={currency.code} value={currency.code}>
//                   {currency.label}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Grid>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

export default withRouter(Payment);
