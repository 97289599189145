import React, { useEffect } from "react";
import PageHead from "../../components/atoms/page-head";
// import Navbar from "../../../components/common/Navbar";
// import AppContext from "../../context";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import PrivacyInfoSection from "./privacy-info-section";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import Schema from "./schema";
import "./style.css";
import withLanguageMigrator from "../../hoc/language-migrator";

const PrivacyPolicyPageIn = ({ lng }) => {
  // const { lng, handleLng } = useContext(AppContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView();
  }, []);

  return (
    <>
      <PageHead title="Privacy | MyVivah.app" />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        className="cus-container privacy-policy-content"
        style={{ rowGap: 10, padding: 20 }}
      >
        <Grid>
          <Typography variant="h4" align="center" style={{ marginTop: 30 }}>
            {Schema.topic}
          </Typography>
          <Typography variant="body2" align="center">
            {`Last updated date: ${Schema.lastUpdatedOn}`}
          </Typography>
        </Grid>

        <Typography className="primary-text mt-30">
          The website, MyVivah.app <b>[the “Site”/ “MyVivah.app”]</b> is owned
          by Serendib Systems Pty Ltd of Australia which, together with its
          affiliate partner <b>[“We”, “Us”, or “Our”]</b> and third-party
          licensors provide an online platform where you as a user{" "}
          <b>[“You”]</b> may place advertisements containing your own personal
          information and data (or that of consenting third parties) for
          purposes of seeking a suitable matrimonial partner either on your own
          behalf or on behalf of such consenting third party{" "}
          <b>[“Matrimonial Advertisement”]</b>.
        </Typography>

        <Grid style={{ marginTop: 10 }}>
          <Typography className="primary-text">
            Our Privacy Policy statement explains the following:
          </Typography>

          <ol type="a" className="primary-text">
            <li>
              what personal information and data may be provided by You as a
              user of the Site,
            </li>
            <li>
              what information and data do we gather or collect from visitors
              and users (including You) of, the Site by the use of cookies and
              other technologies, and
            </li>
            <li>
              how we store, use, share, disclose, and protect such information
              and data.
            </li>
          </ol>

          <Typography className="primary-text">
            Our Privacy Policy applies to MyVivah.app, and to the apps related
            to the Site.
          </Typography>
        </Grid>
        <Grid style={{ padding: isMobileScreen ? "0 20px 0 20px" : 0 }}>
          <ol className="list-title">
            {Schema.content.bullets.map((content, idx) => (
              <li className="list-item">
                <PrivacyInfoSection
                  key={`content-bullet-${idx}`}
                  title={content.heading}
                  listItems={content.bullets}
                  description={content.description}
                />
              </li>
            ))}
          </ol>
        </Grid>
      </Grid>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default withLanguageMigrator(PrivacyPolicyPageIn);
