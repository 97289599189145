import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

import Info from "../images/info.png";

import "../styles/disable-profile.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class InvalidPaymentLink extends Component {
  state = {};

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="disable-div">
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 65, opacity: 0.7 }} />
            </p>
            <p className="message">
              {this.props.lng ? "Invalid Payment Link" : "වලංගු නොවන ගෙවීම්කි"}
            </p>
            <p
              align="center"
              className="desc"
              style={{ marginBottom: "-30px" }}
            >
              {this.props.lng ? (
                "This payment link is invalid or expired."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  මෙම ගෙවීම් අවලංගු හෝ කල් ඉකුත් වී ඇත.
                </span>
              )}
            </p>
            <Button
              variant="contained"
              className="all-ads-btn"
              onClick={() => this.props.history.push("/")}
              style={{ width: 180 }}
            >
              {this.props.lng ? "Go To ALL Ads" : "සියලුම දැන්වීම් වෙත යන්න"}
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(InvalidPaymentLink);
