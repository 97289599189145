import { FETCH_ARCHIVED } from "./types";
import axios from "axios";

export const getArchived = () => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem(
        "postId"
      )}/interests?includeArchived=true`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      let income = res.data.incomingMatches;
      let allMatches = income.concat(res.data.outgoingMatches);

      // let unSeenMatches = allMatches.filter((el) => el.likeSeen === false);
      // let unSeenReceiveds = res.data.incomingInterests.filter(
      //   (el) => el.likeSeen === false
      // );

      dispatch({
        type: FETCH_ARCHIVED,
        payload: {
          matches: allMatches,
          received: res.data.incomingInterests,
          sent: res.data.outgoingInterests,
          isArchived: true,
          // unSeenMatches: unSeenMatches.length,
          // unSeenReceiveds: unSeenReceiveds.length,
        },
      });
    });
};
