import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import InterestPref from "../components/interest-preferences/InterestPref";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PartnerPref extends Component {
  state = {
    isAccessDenied: false,
  };

  componentDidMount = () => {
    // lng
    this.setState({
      isAccessDenied: localStorage.getItem("auth_token") ? false : true,
    });

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize
        title={`Interest Preferences | ${Config.DOMAIN_DISPLAY_NAME}`}
      />
    ) : (
      <React.Fragment>
        <PageHead
          title={`Interest Preferences | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#dfdfdf", paddingTop: 5 }}>
          <Typography
            variant="h3"
            align="center"
            className="create-new-ad"
            style={{ paddingBottom: 30, fontWeight: 500 }}
          >
            {this.props.lng === true ? (
              "Interest Preferences"
            ) : (
              <span>කැමැත්තන් පිළිගැනීමේ නිර්ණායක</span>
            )}
          </Typography>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className="cus-container complete-ad-container"
          >
            <InterestPref lng={this.props.lng} />
          </Grid>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(PartnerPref);
