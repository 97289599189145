import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import InformationWithoutReview from "./templates/Information-without-review";
import InformationWithReview from "./templates/information-with-review";
import {
  bindEditFormByAuth,
  handleOnChangeForm,
  onChangeLangauge,
} from "./extension";

const EditMyAdForm = ({ contentLoading }) => {
  const dispatch = useDispatch();
  const { resetForm, values, setFieldValue, initialValues } =
    useFormikContext();
  const { advertisement, boostrapped } = useSelector(
    (state) => state.editAdPage
  );
  const { i18n } = useTranslation();
  const { language } = i18n;

  // bind advertisement to form model
  useEffect(() => {
    if (!isEmpty(advertisement)) {
      const model = bindEditFormByAuth({ advertisement });
      resetForm({ values: model });
    }
  }, [advertisement, dispatch, resetForm]);

  // handle form value dependencies on value changes
  useEffect(() => {
    // if (boostrapped) {
      dispatch(handleOnChangeForm({ values, initialValues, setFieldValue }));
    // }
  }, [values, dispatch, setFieldValue, boostrapped, initialValues]);

  // handle language change
  useEffect(() => {
    dispatch(onChangeLangauge({ language }));
  }, [dispatch, language]);

  return (
    <Grid container direction="column" className="row-gap-24">
      <InformationWithoutReview contentLoading={contentLoading} />
      <InformationWithReview contentLoading={contentLoading} />
    </Grid>
  );
};

export default EditMyAdForm;
