import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

function AlertModal(props) {
  return (
    <Dialog
      open={props.alertModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
        {props.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.lng
            ? "Your ad is expired. Please renew your ad to show interest."
            : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න."}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {localStorage.getItem("post_details") &&
        JSON.parse(localStorage.getItem("post_details")).status ===
          "EXPIRED" ? (
          <React.Fragment>
            <Button onClick={props.handleModalClose}>
              {props.lng === true ? (
                "Cancel"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  අවලංගු කරන්න
                </span>
              )}
            </Button>
            <Button
              onClick={() => props.history.push("/pay-for-ad")}
              style={{ background: "#000", color: "#fff" }}
            >
              {props.lng === true ? (
                "Renew"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">
                  අලුත් කරන්න
                </span>
              )}
            </Button>
          </React.Fragment>
        ) : null}
        {/* 
        {localStorage.getItem("post_details") &&
          JSON.parse(localStorage.getItem("post_details")).status !==
            "EXPIRED" && (
            <Button
              onClick={props.handleModalClose}
              style={{ background: "#fccd12" }}
            >
              {props.lng ? "OK" : "හරි"}
            </Button>
          )} */}
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(AlertModal);
