import React from "react";
import { Paper } from "@material-ui/core";

import Info from "../../../images/info.png";

function UnavailableProfile(props) {
  return (
    <Paper
      elevation={0}
      style={{ minHeight: "15vh", position: "relative" }}
      className="complete-ad-div settings-container post-details-con"
    >
      <div className="disable-div" style={{ marginTop: 0 }}>
        <p align="center" style={{ marginBottom: 0 }}>
          <img src={Info} alt="info" style={{ width: 50, opacity: 0.7 }} />
        </p>
        <p className="message">
          {props.lng ? "Ad is unavailable" : "දැන්වීම නොමැත"}
        </p>
        <p align="center" className="desc">
          {props.lng ? (
            "This advertisement has been taken down by its owner."
          ) : (
            <span className="sinhala-w-600 sinhala-size-14">
              මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත.
            </span>
          )}
        </p>
      </div>
    </Paper>
  );
}

export default UnavailableProfile;
