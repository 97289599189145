import React from "react";
import { Link } from "react-router-dom";

import Feature1 from "../../../images/feature1.png";
import Feature2 from "../../../images/feature2.png";
import Feature3 from "../../../images/feature3.png";
import Feature4 from "../../../images/feature4.png";

function Feature(props) {
  return (
    <table className="feature-table">
      <tbody>
        <tr>
          <td>
            <img src={Feature1} alt="privacy feature" />
          </td>
          <td>
            <p className="feature-topic">
              {props.lng ? (
                "Privacy First"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  පෞද්ගලිකත්වයට මුල් තැන
                </span>
              )}
            </p>
            <p className="feature-desc">
              {props.lng ? (
                "Data that reveals your identity is shared only with the profiles you accept. You are in control of your data."
              ) : (
                <span className="sinhala-size-12">
                  ඔබගේ අනන්‍යතාවය හෙළි කරන දත්ත බෙදාගනු ලබන්නේ ඔබ අනුමත කරන
                  දැන්වීම් සමඟ පමණි. ඔබගේ දත්ත පාලනය කිරීම ඔබටම පමණක් කල හැකිය.
                </span>
              )}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <img src={Feature2} alt="privacy feature" />
          </td>
          <td>
            <p className="feature-topic">
              {props.lng ? (
                "Genuine and Verified Accounts"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  අව්‍යාජ සහ සත්‍යාපිත ගිණුම්
                </span>
              )}
            </p>
            <p className="feature-desc">
              {props.lng ? (
                "We manually review all profiles added to the website and verify the accuracy of the necessary information."
              ) : (
                <span className="sinhala-size-12">
                  අපි වෙබ් අඩවියට එකතු කරන ලද සියලුම දැන්වීම් සමාලෝචනය කර අවශ්‍ය
                  තොරතුරු වල නිරවද්‍යතාවය සත්‍යාපනය කරමු.
                </span>
              )}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <img src={Feature3} alt="privacy feature" />
          </td>
          <td>
            <p className="feature-topic">
              {props.lng ? (
                "Paid Accounts Only"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  මුදල් ගෙවූ ගිණුම් පමණි
                </span>
              )}
            </p>
            <p className="feature-desc">
              {props.lng ? (
                <span>
                  We attract only genuine seekers, and hence the selection
                  process is efficient for our advertisers. Please check the
                  pricing details <Link to="/pricing">here</Link>.
                </span>
              ) : (
                <span className="sinhala-size-12">
                  අප ආකර්ෂණය කර ගන්නේ අව්‍යාජ සොයන්නන් පමණක් වන අතර එබැවින් අපගේ
                  දැන්වීම් පලකරන්නන් සඳහා තෝරා ගැනීමේ ක්‍රියාවලිය කාර්යක්ෂම වේ.
                  කරුණාකර මිල විස්තර <Link to="/pricing">මෙතනින්</Link> පරීක්ෂා
                  කරන්න.
                </span>
              )}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <img src={Feature4} alt="privacy feature" />
          </td>
          <td>
            <p className="feature-topic">
              {props.lng ? (
                "Live Support"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  සජීවී සහාය
                </span>
              )}
            </p>
            <p className="feature-desc" style={{ marginBottom: 0 }}>
              {props.lng ? (
                "We are available on Telephone, Email and on Social Media to give you a helping hand when you need us."
              ) : (
                <span className="sinhala-size-12">
                  ඔබට අවශ්‍ය විටෙක උපකාර ලබා ගැනීමට අපව දුරකථනයෙන්, විද්‍යුත්
                  තැපෑලෙන් සහ සමාජ මාධ්‍යවලින් සම්බන්ද කර ගත හැකිය.
                </span>
              )}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Feature;
