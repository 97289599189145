import React from "react";
import { Helmet } from "react-helmet";

const PageMetaDescription = (props) => {
  return (
    <Helmet>
      <meta name={props.name} content={props.content}></meta>
    </Helmet>
  );
};

export default PageMetaDescription;
