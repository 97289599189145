import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";

import { handleIntBtns } from "../../../actions/handleIntBtns";
import { fetchAllInterest } from "../../../actions/fetchAllInterest";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function IntButtonGroup(props) {

  const goToRecievedInterests = () => {
    props.fetchAllInterest();
    props.handleIntBtns("received");
  }
  return (
    <ButtonGroup variant="text" className="interest-group-btns">
      <Button
        className={props.isMatchActive ? "active-btn" : ""}
        onClick={() => props.handleIntBtns("match")}
      >
        {props.lng ? "Messages" : "පණිවිඩ"}{" "}
        {(props.matches &&
          props.matches.filter((el) => el.matchSeen === false && !el.latestChat)
            .length > 0) ||
        (props.matches &&
          props.matches.filter(
            (el) =>
              el.latestChat &&
              el.latestChat.sender === "OTHER_PARTY" &&
              !el.latestChat.seen
          ).length > 0) ? (
          <span className="badge">
            {(props.matches &&
              props.matches.filter(
                (el) => el.matchSeen === false && !el.latestChat
              ).length) +
              (props.matches &&
                props.matches.filter(
                  (el) =>
                    el.latestChat &&
                    el.latestChat.sender === "OTHER_PARTY" &&
                    !el.latestChat.seen
                ).length)}
          </span>
        ) : null}
      </Button>
      <Button
        className={props.isReceivedActive ? "active-btn" : ""}
        onClick={goToRecievedInterests}
      >
        {props.lng ? "Received Interests" : "කැමැත්ත එවූ අය"}{" "}
        {props.received &&
          props.received.filter(
            (el) => el.ignored === false && el.likeSeen === false
          ).length > 0 && (
            <span className="badge">
              {props.received &&
                props.received.filter(
                  (el) => el.ignored === false && el.likeSeen === false
                ).length}
            </span>
          )}
      </Button>
    </ButtonGroup>
  );
}

// const matchDispatchToProps = (dispatch) => {
//   return bindActionCreators({ handleIntBtns: handleIntBtns }, dispatch);
// };

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleIntBtns: handleIntBtns,
      fetchAllInterest
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    matches: state.matches.allInterests.matches
      ? state.matches.allInterests.matches.filter((el) => !el.ignored)
      : [],
    received: state.matches.allInterests.received,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(IntButtonGroup);
