
import { UPDATE_SEEN_POSTS } from "./types";

export const updateSeen = (id) => (dispatch) => {
  if (id) {
    dispatch({
      type: UPDATE_SEEN_POSTS,
      payload: {
        id: id,
      },
    });
  }
};