import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";

const IconBox = ({ icon, iconClass, primaryText, secondaryText, href }) => {
  return !href || !secondaryText ? (
    <Link href={href} target="_blank" style={{ textDecoration: "none" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className="icon-box-container"
      >
        {icon && <img src={icon} alt="support-icon" style={{ width: 44 }} />}
        <Typography style={{ color: "#333" }}>{iconClass}</Typography>
        <Typography className="icon-box-txt">{primaryText}</Typography>
        <Typography
          className="icon-box-txt"
          dangerouslySetInnerHTML={{ __html: secondaryText }}
        />
      </Grid>
    </Link>
  ) : (
    <></>
  );
};

export default IconBox;
