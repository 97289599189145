import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Switch from "react-input-switch";
import Config from "../../v2/config";

import "../../styles/settings.css";

const initialStates = {
  intRecievedOld: "",
  intRecieved: "",
  matchedOld: "",
  matched: "",
  autoRejectOld: "",
  autoReject: "",
  unreadMsgsOld: "",
  unreadMsgs: "",
  enableLastOnlineOld: false,
  enableLastOnline: false,
  enableHytch: false,
  enableHytchOld: false,

  // errors
  intRecievedError: "",
  matchedError: "",

  // disabled
  dissableBtn: false,

  // loadings
  loading: false,
  loadingCancel: false,

  // alerts
  successAlert: false,
  failAlert: false,
};

export default class PwdChange extends Component {
  state = initialStates;

  componentDidMount = () => {
    this.getNotify();
  };

  getNotify = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}/owner`;
    } else {
      url = "account";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          intRecieved: res.data.userPreferences
            ? res.data.userPreferences.likeEmail &&
              res.data.userPreferences.likeSms
              ? "both"
              : res.data.userPreferences.likeEmail
              ? "email"
              : res.data.userPreferences.likeSms
              ? "sms"
              : ""
            : "",
          intRecievedOld: res.data.userPreferences
            ? res.data.userPreferences.likeEmail &&
              res.data.userPreferences.likeSms
              ? "both"
              : res.data.userPreferences.likeEmail
              ? "email"
              : res.data.userPreferences.likeSms
              ? "sms"
              : ""
            : "",
          matchedOld: res.data.userPreferences
            ? res.data.userPreferences.matchEmail &&
              res.data.userPreferences.matchSms
              ? "both"
              : res.data.userPreferences.matchEmail
              ? "email"
              : res.data.userPreferences.matchSms
              ? "sms"
              : ""
            : "",
          matched: res.data.userPreferences
            ? res.data.userPreferences.matchEmail &&
              res.data.userPreferences.matchSms
              ? "both"
              : res.data.userPreferences.matchEmail
              ? "email"
              : res.data.userPreferences.matchSms
              ? "sms"
              : ""
            : "",
          autoRejectOld: res.data.userPreferences
            ? res.data.userPreferences.autorejectEmail
              ? "email"
              : "off"
            : "",
          autoReject: res.data.userPreferences
            ? res.data.userPreferences.autorejectEmail
              ? "email"
              : "off"
            : "",
          unreadMsgsOld: res.data.userPreferences
            ? res.data.userPreferences.unreadReminderEmail &&
              res.data.userPreferences.unreadReminderSms
              ? "both"
              : res.data.userPreferences.unreadReminderEmail
              ? "email"
              : res.data.userPreferences.unreadReminderSms
              ? "sms"
              : "off"
            : "",
          unreadMsgs: res.data.userPreferences
            ? res.data.userPreferences.unreadReminderEmail &&
              res.data.userPreferences.unreadReminderSms
              ? "both"
              : res.data.userPreferences.unreadReminderEmail
              ? "email"
              : res.data.userPreferences.unreadReminderSms
              ? "sms"
              : "off"
            : "",
          enableLastOnlineOld: res.data.userPreferences
            ? res.data.userPreferences.enableLastOnline
            : false,
          enableLastOnline: res.data.userPreferences
            ? res.data.userPreferences.enableLastOnline
            : false,
          enableHytch: res.data?.userPreferences?.enableHytch ?? false,
          enableHytchOld: res.data?.userPreferences?.enableHytch ?? false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        // dissable buttons with changes
        if (
          this.state.intRecievedOld === this.state.intRecieved &&
          this.state.matchedOld === this.state.matched &&
          this.state.autoRejectOld === this.state.autoReject &&
          this.state.unreadMsgsOld === this.state.unreadMsgs &&
          this.state.enableLastOnlineOld === this.state.enableLastOnline &&
          this.state.enableHytchOld === this.state.enableHytch
        ) {
          this.setState({ dissableBtn: false });
        } else {
          this.setState({ dissableBtn: true });
        }
      }
    );
  };

  handleChangeSwitch = (e) => {
    this.setState({enableLastOnline: e},
      () => {
        // dissable buttons with changes
        if (
          this.state.intRecievedOld === this.state.intRecieved &&
          this.state.matchedOld === this.state.matched &&
          this.state.autoRejectOld === this.state.autoReject &&
          this.state.unreadMsgsOld === this.state.unreadMsgs &&
          this.state.enableLastOnlineOld === this.state.enableLastOnline &&
          this.state.enableHytchOld === this.state.enableHytch
        ) {
          this.setState({ dissableBtn: false });
        } else {
          this.setState({ dissableBtn: true });
        }
      }
    );
  };

  handleHytchChangeSwitch = (e) => {
    this.setState({ enableHytch: e }, () => {
      // dissable buttons with changes
      if (
        this.state.intRecievedOld === this.state.intRecieved &&
        this.state.matchedOld === this.state.matched &&
        this.state.autoRejectOld === this.state.autoReject &&
        this.state.unreadMsgsOld === this.state.unreadMsgs &&
        this.state.enableLastOnlineOld === this.state.enableLastOnline &&
        this.state.enableHytchOld === this.state.enableHytch
      ) {
        this.setState({ dissableBtn: false });
      } else {
        this.setState({ dissableBtn: true });
      }
    });
  };
  
  // validate
  validate = () => {
    let intRecievedError = "";
    let matchedError = "";
    let autoRejectError = "";
    let unreadMsgsError = "";

    if (this.state.intRecieved === "") {
      intRecievedError =
        this.props.lng === true ? "Cannot be empty" : "හිස් විය නොහැක";
    }

    if (this.state.matched === "") {
      matchedError =
        this.props.lng === true ? "Cannot be empty" : "හිස් විය නොහැක";
    }
    if (this.state.autoReject === "") {
      autoRejectError =
        this.props.lng === true ? "Cannot be empty" : "හිස් විය නොහැක";
    }
    if (this.state.unreadMsgs === "") {
      unreadMsgsError =
        this.props.lng === true ? "Cannot be empty" : "හිස් විය නොහැක";
    }

    if (
      intRecievedError ||
      matchedError ||
      autoRejectError ||
      unreadMsgsError
    ) {
      this.setState({
        intRecievedError,
        matchedError,
        autoRejectError,
        unreadMsgsError,
      });
      return false;
    }

    return true;
  };

  // submit sign-up form
  handleSave = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        intRecievedError: "",
        matchedError: "",
      });

      const data = {
        likeSms:
          this.state.intRecieved === "both"
            ? true
            : this.state.intRecieved === "email"
            ? false
            : true,
        likeEmail:
          this.state.intRecieved === "both"
            ? true
            : this.state.intRecieved === "email"
            ? true
            : false,
        matchSms:
          this.state.matched === "both"
            ? true
            : this.state.matched === "email"
            ? false
            : true,
        matchEmail:
          this.state.matched === "both"
            ? true
            : this.state.matched === "email"
            ? true
            : false,
        autorejectEmail: this.state.autoReject === "email" ? true : false,
        unreadReminderEmail:
          this.state.unreadMsgs === "both"
            ? true
            : this.state.unreadMsgs === "email"
            ? true
            : this.state.unreadMsgs === "off"
            ? false
            : false,
        unreadReminderSms:
          this.state.unreadMsgs === "both"
            ? true
            : this.state.unreadMsgs === "email"
            ? false
            : this.state.unreadMsgs === "off"
            ? false
            : true,
        enableLastOnline: this.state.enableLastOnline,
        ...(Config.CURRENT_PLATFORM === Config.PLATFORMS.IN
          ? { enableHytch: false }
          : { enableHytch: this.state.enableHytch }),
      };

      let preferencesUrl = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        preferencesUrl = `operator/managed_posts/${localStorage.getItem("postId")}/preferences`;
      } else {
        preferencesUrl = "account/preferences";
      }
      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/${preferencesUrl}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          this.setState({
            successAlert: true,
            dissableBtn: false,
            loading: false,
          });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          this.getNotify();
        })
        .catch((err) => {
          this.setState({ loading: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    }
  };

  handleCancel = () => {
    this.setState({
      dissableBtn: false,
    });
    this.getNotify();
  };

  handleClose = () => {
    this.setState({ successAlert: false, failAlert: false });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div settings-container"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <p className="sec-heading" style={{ marginBottom: 5 }}>
                    {this.props.lng ? (
                      "Preferences"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        කැමැත්තන්
                      </span>
                    )}
                  </p>
                  <p className="sec-heading" style={{ marginBottom: 5, fontSize: 14 }}>
                    {this.props.lng ? (
                      "Notification Preferences"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">දැනුම්දීම්</span>
                    )}
                  </p>
                  <div className="col">
                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Interest Received"
                            : "කැමැත්තන් ලැබීම්"}
                        </p>
                      </div>
                      <div className="field-col">
                        <select
                          className={
                            this.state.intRecievedError
                              ? "red-border"
                              : this.props.lng
                              ? ""
                              : "notifi-select-sinhala"
                          }
                          name="intRecieved"
                          value={this.state.intRecieved}
                          onChange={this.handleChange}
                        >
                          <option selected hidden>
                            {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                          </option>
                          <option value="both">
                            {this.props.lng
                              ? "Email and SMS"
                              : "විද්‍යුත් තැපෑල සහ කෙටි පණිවුඩ"}
                          </option>
                          <option value="email">
                            {this.props.lng
                              ? "Email Only"
                              : "විද්‍යුත් තැපෑල පමණි"}
                          </option>
                          <option value="sms">
                            {this.props.lng ? "SMS Only" : "කෙටි පණිවුඩ පමණි"}
                          </option>
                        </select>
                        <p className="fields-error">
                          {this.state.intRecievedError}
                        </p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Matched" : "ගැලපීම්"}</p>
                      </div>
                      <div className="field-col">
                        <select
                          className={
                            this.state.matchedError
                              ? "red-border"
                              : this.props.lng
                              ? ""
                              : "notifi-select-sinhala"
                          }
                          name="matched"
                          value={this.state.matched}
                          onChange={this.handleChange}
                        >
                          <option selected hidden>
                            {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                          </option>
                          <option value="both">
                            {this.props.lng
                              ? "Email and SMS"
                              : "විද්‍යුත් තැපෑල සහ කෙටි පණිවුඩ"}
                          </option>
                          <option value="email">
                            {this.props.lng
                              ? "Email Only"
                              : "විද්‍යුත් තැපෑල පමණි"}
                          </option>
                          <option value="sms">
                            {this.props.lng ? "SMS Only" : "කෙටි පණිවුඩ පමණි"}
                          </option>
                        </select>
                        <p className="fields-error">
                          {this.state.matchedError}
                        </p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Interest Auto-reject Reminder"
                            : "කැමැත්තන් දින 10න් පසුව ප්‍රතික්ෂේප කිරීම"}
                        </p>
                      </div>
                      <div className="field-col">
                        <select
                          className={
                            this.state.autoRejectError
                              ? "red-border"
                              : this.props.lng
                              ? ""
                              : "notifi-select-sinhala"
                          }
                          name="autoReject"
                          value={this.state.autoReject}
                          onChange={this.handleChange}
                        >
                          <option selected hidden>
                            {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                          </option>
                          <option value="email">
                            {this.props.lng ? "Email" : "විද්‍යුත් තැපෑල"}
                          </option>
                          <option value="off">
                            {this.props.lng ? "Off" : "අක්‍රීය කරන්න"}
                          </option>
                        </select>
                        <p className="fields-error">
                          {this.state.autoRejectError}
                        </p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Unread Messages Reminder"
                            : "නොකියවූ පණිවිඩ මතක් කිරීම"}
                        </p>
                      </div>
                      <div className="field-col">
                        <select
                          className={
                            this.state.unreadMsgsError
                              ? "red-border"
                              : this.props.lng
                              ? ""
                              : "notifi-select-sinhala"
                          }
                          name="unreadMsgs"
                          value={this.state.unreadMsgs}
                          onChange={this.handleChange}
                        >
                          <option selected hidden>
                            {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                          </option>
                          <option value="both">
                            {this.props.lng
                              ? "Email and SMS"
                              : "විද්‍යුත් තැපෑල සහ කෙටි පණිවුඩ"}
                          </option>
                          <option value="email">
                            {this.props.lng
                              ? "Email Only"
                              : "විද්‍යුත් තැපෑල පමණි"}
                          </option>
                          <option value="sms">
                            {this.props.lng ? "SMS Only" : "කෙටි පණිවුඩ පමණි"}
                          </option>
                          <option value="off">
                            {this.props.lng ? "Off" : "අක්‍රීය කරන්න"}
                          </option>
                        </select>
                        <p className="fields-error">
                          {this.state.unreadMsgsError}
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="sec-heading" style={{ marginBottom: 5, fontSize: 14 }}>
                    {this.props.lng ? (
                      "Account Preferences"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">ගිණුමෙහි දැනුම්දීම්</span>
                    )}
                  </p>

                  <div className="col">
                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Enable Last Online Status"
                            : "අවසාන ඔන්ලයින් දිනය පෙන්වන්න"}
                        </p>
                      </div>
                      <div className="field-col" style={{height: '35px', display: 'flex', alignItems: 'center'}}>
                        <p style={{width: '80%', textAlign: 'end'}}>
                          <Switch
                            on={true}
                            off={false}
                            value={this.state.enableLastOnline}
                            onChange={e => this.handleChangeSwitch(e)}
                            style={{
                              float: "right",
                              height: '18px',
                              width: '30px'
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    {Config.ENABLE_HYTCH && <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Enable Hytch" : "Hytch සක්‍රීය කරන්න"}</p>
                      </div>
                      <div
                        className="field-col"
                        style={{ height: "35px", display: "flex", alignItems: "center" }}
                      >
                        <p style={{ width: "80%", textAlign: "end" }}>
                          <Switch
                            on={true}
                            off={false}
                            value={this.state.enableHytch}
                            onChange={this.handleHytchChangeSwitch}
                            style={{
                              float: "right",
                              height: "18px",
                              width: "30px",
                            }}
                          />
                        </p>
                      </div>
                    </div>}
                  </div>

                  <div className="col"></div>

                  <div
                    style={{
                      paddingBottom: 70,
                    }}
                    id="address"
                  >
                    {this.state.dissableBtn ? (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          onClick={this.handleSave}
                          style={{float: "right", marginTop: "20px"}}
                          className="common-save-btn"
                        >
                          {this.state.loading ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Save"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  තහවුරු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="common-cancel-btn"
                          onClick={this.handleCancel}
                          style={{float: "right", marginRight: "12px", marginTop: "20px"}}
                        >
                          {this.state.loadingCancel ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Cancel"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  අවලංගු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="edit-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Save"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              තහවුරු කරන්න
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="cancel-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Cancel"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              අවලංගු කරන්න
                            </span>
                          )}
                        </Button>{" "}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Notification Preferences have been changed successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    දැනුම්දීම් සාර්ථකව වෙනස් කර ඇත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    දැනුම්දීම් සාර්ථකව වෙනස් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
