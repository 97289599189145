import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Divider, ButtonGroup } from "@material-ui/core";

import close from "../../images/close.png";
import CreateAccountMethod from "../common/CreateAccountMethod";
import AppContext from "../../v2/context";
import Constants from "../../v2/config/constant";
import Config from "../../v2/config";

import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";

class MenuDrawer extends Component {
  render() {
    return (
      <div className="user-account">
        <div className="menu-div">
          <img
            src={close}
            alt="close"
            className="close-profile"
            onClick={this.props.menuDrawer(false)}
          />

          <ProductLogo
            variant={LogoVariant.black}
            size={LogoSize.small}
            className={"drawer-logo"}
          />
          {(window.screen.width > 768 && !localStorage.getItem("auth_token")) ||
          window.screen.width < 769 ? (
            <React.Fragment>
              <CreateAccountMethod lng={this.props.lng} />

              {!localStorage.getItem("auth_token") ? (
                <React.Fragment>
                  <Button
                    className="my-ad-btn"
                    onClick={() => {
                      this.props.history.push("/search");
                      this.props.toggleMenuDrawer({ menuDrawer: false });
                    }}
                    style={{ textAlign: "left", marginTop: 30 }}
                  >
                    <i className="fas fa-list-alt drawer-icon"></i>
                    {this.props.lng === true ? (
                      "All Ads"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-12">
                        සියලූම දැන්වීම්
                      </span>
                    )}
                  </Button>

                  <Divider />
                </React.Fragment>
              ) : null}

              {!localStorage.getItem("auth_token") ? (
                <React.Fragment>
                  <Button
                    className="my-ad-btn"
                    onClick={() => {
                      this.props.history.push("/auth");
                      this.props.toggleMenuDrawer({ menuDrawer: false });
                    }}
                    style={{ textAlign: "left" }}
                  >
                    <i className="fas fa-user-circle drawer-icon"></i>
                    {this.props.lng === true ? (
                      "Login"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-12">
                        පිවිසීම
                      </span>
                    )}
                  </Button>

                  <Divider />
                </React.Fragment>
              ) : null}
              <Button
                className="my-ad-btn"
                onClick={() => {
                  this.props.history.push("/help");
                  this.props.toggleMenuDrawer({ menuDrawer: false });
                }}
                style={{ marginTop: 0, textAlign: "left" }}
              >
                {/* <i className="fa fa-question-circle drawer-icon"></i> */}
                <i class="fas fa-hands-helping drawer-icon"></i>
                {this.props.lng === true ? (
                  "Help"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    උපදෙස් සහ සහාය
                  </span>
                )}
              </Button>
              <Button
                className="my-ad-btn"
                onClick={() => {
                  this.props.history.push("/pricing");
                  this.props.toggleMenuDrawer({ menuDrawer: false });
                }}
                style={{ marginTop: 0, textAlign: "left" }}
              >
                <i className="fas fa-dollar-sign drawer-icon"></i>

                {this.props.lng === true ? (
                  "Pricing"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    මිල ගනන්
                  </span>
                )}
              </Button>
              <Button
                className="my-ad-btn"
                onClick={() => {
                  this.props.history.push("/terms");
                  this.props.toggleMenuDrawer({ menuDrawer: false });
                }}
                style={{ marginTop: 0, textAlign: "left" }}
              >
                <i className="fas fa-file-alt drawer-icon"></i>
                {this.props.lng === true ? (
                  "Terms"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    කොන්දේසි
                  </span>
                )}
              </Button>
              <Divider />
              {Config.MAGAZINE_VISIBILITY && (
                <Button
                  className="my-ad-btn"
                  onClick={() => {
                    this.props.history.push("/magazine");
                    this.props.toggleMenuDrawer({ menuDrawer: false });
                  }}
                  style={{ marginTop: 0, textAlign: "left" }}
                >
                  <i className="fas fa-book-open drawer-icon"></i>
                  {this.props.lng === true ? (
                    "Monthly Magazine"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      මාසික සඟරාව
                    </span>
                  )}
                </Button>
              )}
              {Config.BLOG_PAGE_VISIBILITY && (
                <Button
                  className="my-ad-btn"
                  onClick={() => (window.location.href = "/blog")}
                  style={{ marginTop: 0, textAlign: "left" }}
                >
                  <i className="fas fa-newspaper drawer-icon"></i>
                  {this.props.lng === true ? (
                    "Blog"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      බ්ලොග් පිටුව
                    </span>
                  )}
                </Button>
              )}

              {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
                <Button
                  className="my-ad-btn"
                  onClick={() => {
                    this.props.history.push("/reviews");
                    this.props.toggleMenuDrawer({ menuDrawer: false });
                  }}
                  style={{ marginTop: 0, textAlign: "left" }}
                >
                  <i className="fas fa-regular fa-star drawer-icon"></i>
                  {this.props.lng === true ? (
                    "Reviews and Ratings"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      නිර්දේශ කිරීම් සහ ශ්‍රේණිගත කිරීම්
                    </span>
                  )}
                </Button>
              )}
              {/* <Button
                className="my-ad-btn"
                onClick={() => (window.location.href = "/reviews")}
                style={{ marginTop: 0, textAlign: "left" }}
              >
                <i className="fas fa-comments drawer-icon"></i>
                {this.props.lng === true ? (
                  "Reviews"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    නිර්දේශ කිරීම්
                  </span>
                )}
              </Button> */}
            </React.Fragment>
          ) : null}

          {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
            <AppContext.Consumer>
              {({ setAppLanguage }) => (
                <ButtonGroup
                  color="primary"
                  size="small"
                  aria-label="small outlined button group"
                  className="lng-selection-drawer"
                  style={{ paddingTop: 25 }}
                >
                  <Button
                    className={this.props.lng === true ? "active-lng" : ""}
                    onClick={() => {
                      this.props.handleLng(true);
                      setAppLanguage(Constants.APP_LANG.EN);
                    }}
                  >
                    English
                  </Button>
                  <Button
                    className={this.props.lng === false ? "active-lng" : ""}
                    onClick={() => {
                      this.props.handleLng(false);
                      setAppLanguage(Constants.APP_LANG.SI);
                    }}
                  >
                    සිංහල
                  </Button>
                </ButtonGroup>
              )}
            </AppContext.Consumer>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(MenuDrawer);
