import React from "react";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";

import { connect } from "react-redux";

const Personal = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "20vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <Typography variant="h6" className="heading">
                {props.lng ? (
                  "Personal Info"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-16">
                    පෞද්ගලික තොරතුරු
                  </span>
                )}
              </Typography>
              <div className="row">
                <p className="review-sub-sec-heading">
                  {props.lng ? (
                    "Basic"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">මූලික</span>
                  )}
                </p>

                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Ethnicity" : "ජාතිය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.ethnicity.name
                        : props.personalInfo.ethnicity.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Religion" : "ආගම"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.religion.name
                        : props.personalInfo.religion.name_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">{props.lng ? "Age" : "වයස"}</div>
                    <div className="con-col">
                      {props.lng ? null : "අවුරුදු"} {props.personalInfo.age}{" "}
                      {props.lng ? "years" : null}
                    </div>
                  </div>
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Caste" : "කුලය"}
                    </div>
                    <div className="con-col">
                      {props.personalInfo.caste
                        ? props.personalInfo.caste
                        : "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Civil Status" : "සිවිල් තත්වය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.civilStatus.status
                        : props.personalInfo.civilStatus.status_si}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">{props.lng ? "Height" : "උස"}</div>
                    <div className="con-col">
                      {Math.floor(
                        Math.round(props.personalInfo.height / 2.54) / 12
                      )}
                      ' {Math.round(props.personalInfo.height / 2.54) % 12}"
                    </div>
                  </div>
                </div>

                <p className="review-sub-sec-heading">
                  {props.lng ? (
                    "Residency"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      පදිංචිය
                    </span>
                  )}
                </p>

                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Country" : "පදිංචි රට"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.residentCountry.name
                        : props.personalInfo.residentCountry.name_si}
                    </div>
                  </div>
                  {props.personalInfo.residentRegion ? (
                    <div className="con-row">
                      <div className="con-col">
                        {props.lng
                          ? "State / District"
                          : "රාජ්‍යය / දිස්ත්‍රික්කය"}
                      </div>
                      <div className="con-col">
                        {props.personalInfo.residentRegion
                          ? props.lng
                            ? props.personalInfo.residentRegion.regionName
                            : props.personalInfo.residentRegion.regionName_si
                          : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "City" : "පදිංචි නගරය"}
                    </div>
                    <div className="con-col">
                      {props.personalInfo.residentCity}
                    </div>
                  </div>
                  {props.personalInfo.residentCountry.name ===
                  "Sri Lanka" ? null : (
                    <div className="con-row">
                      <div className="con-col">
                        {props.lng ? "Visa Status" : "වීසා තත්වය"}
                      </div>
                      <div className="con-col">
                        {props.lng
                          ? props.personalInfo.visaType.visaType
                          : props.personalInfo.visaType.visaType_si}
                      </div>
                    </div>
                  )}
                </div>

                <p className="review-sub-sec-heading">
                  {props.lng ? (
                    "Education & Profession"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      වෘත්තිය
                    </span>
                  )}
                </p>

                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Education" : "අධ්යාපනය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.educationLevel.level
                        : props.personalInfo.educationLevel.level_si}
                    </div>
                  </div>
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row mob-occu-row">
                    <div className="con-col">
                      {props.lng ? "Profession" : "වෘත්තිය"}
                    </div>
                    <div className="con-col">
                      {props.personalInfo.professionOther
                        ? props.personalInfo.professionOther
                        : props.lng
                        ? props.personalInfo.profession.name
                        : props.personalInfo.profession.name_si}
                    </div>
                  </div>
                </div>

                <p className="review-sub-sec-heading">
                  {props.lng ? (
                    "Habits"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      පුරුදු
                    </span>
                  )}
                </p>

                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Drinking" : "මත් පැන් භාවිතය"}
                    </div>
                    <div className="con-col">
                      {props.personalInfo.drinking === "YES"
                        ? props.lng
                          ? "Yes"
                          : "ඔව්"
                        : props.personalInfo.drinking === "NO"
                        ? props.lng
                          ? "No"
                          : "නැත"
                        : props.lng
                        ? "Occasionally"
                        : "ඇතැම් විට"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Smoking" : "දුම් පානය"}
                    </div>
                    <div className="con-col">
                      {props.personalInfo.smoking === "YES"
                        ? props.lng
                          ? "Yes"
                          : "ඔව්"
                        : props.lng
                        ? "No"
                        : "නැත"}
                    </div>
                  </div>
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Food Preference" : "ආහාර මනාපය"}
                    </div>
                    <div className="con-col">
                      {props.lng
                        ? props.personalInfo.foodPreference.type
                        : props.personalInfo.foodPreference.type_si}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // personalInfo: state.matches.post.post.personalInfo,
  };
};

export default connect(mapStateToProps, null)(Personal);
