import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import PageMetaDescription from "../components/common/PageMetaDescription";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewComponent from "../components/common/ReviewComponent";
import "../styles/reviews.css";
import "../styles/pricing.css";
import "../styles/faq.css";
import { getDiscountPercentage } from "../methods/getDiscountPercentage";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Pricing extends Component {
  state = {
    // lng: true, // default en

    country: sessionStorage.getItem("countryCode")
      ? sessionStorage.getItem("countryCode")
      : "LK",

    pricings: [
      {
        countryCode: "LK",
        countryName: "Sri Lanka",
        countryNameSinhala: "ශ්‍රී ලංකාව",
      },
      {
        countryCode: "other",
        countryName: "Other Countries",
        countryNameSinhala: "වෙනත් රටවල්",
      },
    ],
    packages: [],
    packagesLoading: false,
    threeMonthsDiscount: 0,
    twoMOnthsDiscount: 0,
    threeMonthsDiscountLKR: 0,
    twoMOnthsDiscountLKR: 0,
    reviews: [],
    metaDescription:
      "Poruwa.lk | Sri Lankan Matrimony : Your private matrimony solution in Sri Lanka. Explore with confidence - images shared upon match progress. Genuine Sri Lankan proposals await. Find love, USA-Sri Lanka proposals too. Join today for secure, meaningful partnerships!",
  };

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.changeCountry("LK");
    this.setState({ packagesLoading: true });
    this.getReviews(0, 6);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/pricing_tiers`)
      .then((res) => {
        if (res.data) {
          let oneMOnth = res.data.filter((filter) => filter.id === "1m");
          let threeMonths = res.data.filter((filter) => filter.id === "3m");
          if (oneMOnth.length > 0 && threeMonths.length > 0) {
            this.setState({
              threeMonthsDiscount: Math.floor(
                ((oneMOnth[0].amountUSD - threeMonths[0].amountUSD / 3) /
                  oneMOnth[0].amountUSD) *
                  100
              ),
              threeMonthsDiscountLKR: Math.floor(
                ((oneMOnth[0].amountLKR - threeMonths[0].amountLKR / 3) /
                  oneMOnth[0].amountLKR) *
                  100
              ),
            });
          }
          let twoMonths = res.data.filter((filter) => filter.id === "2m");
          if (oneMOnth.length > 0 && twoMonths.length > 0) {
            this.setState({
              twoMOnthsDiscountLKR: getDiscountPercentage(
                oneMOnth[0].amountLKR,
                twoMonths[0].amountLKR / 2
              ),
              twoMOnthsDiscount: getDiscountPercentage(
                oneMOnth[0].amountUSD,
                twoMonths[0].amountUSD / 2
              ),
            });
          }
          this.setState({ packages: res.data, packagesLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ packagesLoading: false });
        console.log(err);
      });
  };

  changeCountry = (val) => {
    this.setState({ country: val });
  };

  getReviews = (pageNum, pageSize) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/generic/reviews?pageNum=${pageNum}&pageSize=${pageSize}`
      )
      .then((res) => {
        if (res.data) {
          this.setState({ reviews: res.data.results });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      arrows: false,
      speed: 1000,
      infinite: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <React.Fragment>
        <PageHead title={`Pricing | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <PageMetaDescription
          name="pricing"
          content={this.state.metaDescription}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div
          style={{
            background: "#f7f7f7",
            padding: "0 15px",
            paddingTop: "50px",
          }}
        >
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
            style={{ background: "#ffffff" }}
          >
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: "20px" }}
            >
              {this.props.lng ? (
                "Pricing"
              ) : (
                <span className="sinhala-w-600 sinhala-size-28">මිල ගනන්</span>
              )}{" "}
            </Typography>

            <p style={{ textAlign: "center", marginTop: 35, marginBottom: 10 }}>
              {this.props.lng ? (
                "Where do you live ?"
              ) : (
                <span className="sinhala-size-12">ඔබ පදිංචි රට</span>
              )}
            </p>

            <div className="country-dropdown-div" style={{ marginBottom: 40 }}>
              <FormControl variant="outlined" className="country-dropdown">
                <Select
                  value={this.state.country}
                  onChange={(e) => this.changeCountry(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ textAlign: "center" }}
                >
                  {this.state.pricings.map((price, i) => (
                    <MenuItem value={price.countryCode} key={i}>
                      {this.props.lng
                        ? price.countryName
                        : price.countryNameSinhala}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="pricing-packages">
              {this.state.packagesLoading ? (
                <div className="package-loading-div">
                  <CircularProgress size={16} style={{ color: "#000" }} />
                </div>
              ) : (
                this.state.packages.map((el, i) =>
                  el.id !== "managed" ? (
                    <div
                      className={
                        el.preferred === true
                          ? "package-item-preferred"
                          : "package"
                      }
                      style={{ paddingBottom: "10px", cursor: "auto" }}
                      key={i}
                    >
                      {el.preferred === true && (
                        <div className="active-package">MOST POPULAR</div>
                      )}
                      <div className="package-element">
                        <div
                          className="package-month-label"
                          style={{ marginTop: "10px" }}
                        >
                          {this.props.lng !== true && (
                            <div className="package-valid-div">
                              මාස {el.durationMonths}
                            </div>
                          )}
                        </div>
                        <div className="package-valid-div">
                          {this.props.lng === true &&
                            (el.id === "1m"
                              ? `${el.durationMonths} Month`
                              : `${el.durationMonths} Months`)}
                        </div>
                        <div className="package-amount-div">
                          {this.state.country === "LK" ? (
                            <>
                              <div>
                                Rs.{" "}
                                {Math.round(el.amountLKR / el.durationMonths)}
                              </div>
                              <div className="per-month-label">
                                {this.props.lng ? "per month" : "මසකට"}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                USD{" "}
                                {(el.amountUSD / el.durationMonths) % 1 !== 0
                                  ? (el.amountUSD / el.durationMonths)
                                      .toFixed(2)
                                      .toString()
                                  : el.amountUSD / el.durationMonths}{" "}
                                /{" "}
                              </div>
                              <div>
                                Rs.{" "}
                                {Math.round(
                                  el.amountLKRNonResident / el.durationMonths
                                )}
                              </div>
                              <div className="per-month-label">
                                {this.props.lng ? "per month" : "මසකට"}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="package-discount-label">
                          {this.state.country === "LK" ? (
                            <>
                              {el.id === "2m" &&
                                (this.props.lng === true
                                  ? `SAVE ${this.state.twoMOnthsDiscountLKR}%`
                                  : `${this.state.twoMOnthsDiscountLKR}% වට්ටමක්`)}
                              {el.id === "3m" &&
                                (this.props.lng === true
                                  ? `SAVE ${this.state.threeMonthsDiscountLKR}%`
                                  : `${this.state.threeMonthsDiscountLKR}% වට්ටමක්`)}
                            </>
                          ) : (
                            <>
                              {el.id === "2m" &&
                                (this.props.lng === true
                                  ? `SAVE ${this.state.twoMOnthsDiscount}%`
                                  : `${this.state.twoMOnthsDiscount}% වට්ටමක්`)}
                              {el.id === "3m" &&
                                (this.props.lng === true
                                  ? `SAVE ${this.state.threeMonthsDiscount}%`
                                  : `${this.state.threeMonthsDiscount}% වට්ටමක්`)}
                            </>
                          )}
                        </div>
                        <div className="total-package-amount-div">
                          {this.state.country === "LK" ? (
                            `Rs. ${el.amountLKR
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          ) : (
                            <>
                              <div>
                                USD{" "}
                                {el.amountUSD
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                /{" "}
                              </div>
                              <div>
                                Rs.{" "}
                                {el.amountLKRNonResident
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </>
                          )}
                        </div>
                        {el.id === "managed" && (
                          <div className="managed-user-label">
                            {this.props.lng === true
                              ? `Managed user package`
                              : `Managed user පැකේජය`}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="package package-element-managed" key={i}>
                      <div className="package-element">
                        <div className="managed-account-title">
                          {this.props.lng === true
                            ? "Fully Managed Account"
                            : "ගිණුම සම්පූර්ණයෙන්ම කළමනාකරණය කරනු ඇත "}
                        </div>
                        <div className="managed-account-description">
                          {this.props.lng === true
                            ? `Enjoy peace of mind with a dedicated agent who will find the best possible matches for your profile.`
                            : `ඔබේ දැන්වීම සඳහා හැකි හොඳම ගැලපීම් සොයා ගැනීමට අපගේ නියෝජිතයෙකු ලබා දෙනු ඇත.`}
                        </div>
                        <div className="managed-acc-footer">
                          <div className="package-month-label">
                            {this.props.lng === true
                              ? `${el.durationMonths} Months`
                              : `මාස ${el.durationMonths}`}
                          </div>
                          <div>
                            {this.props.country === "LK"
                              ? `Rs. ${el.amountLKR.toFixed(2)}`
                              : `USD ${el.amountUSD} / Rs. ${el.amountLKRNonResident}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
            <p
              style={{
                textAlign: "center",
                fontWeight: 300,
                fontSize: 14,
                lineHeight: "16px",
                marginTop: 30,
                marginBottom: 0,
              }}
            >
              {this.props.lng ? (
                "* Receive a 20% discount when you renew your ad within 7 days of expiry. "
              ) : (
                <span className="sinhala-size-12">
                  * දැන්වීම කල් ඉකුත් වීමෙන් පසු දින 7 ක් යාමට පෙර දැන්වීම අලුත්
                  කළහොත් ඔබට 20% වට්ටමක් ලැබේ.
                </span>
              )}
            </p>
          </Grid>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
            style={{ background: "#ffffff", marginTop: "30px" }}
          >
            <Typography
              variant="h4"
              align="center"
              className="faq-page-heading"
              style={{ marginTop: 80 }}
            >
              {this.props.lng === true ? (
                "Frequently Asked Questions (FAQ)"
              ) : (
                <span className="sinhala-w-600 sinhala-size-28">
                  නිතර අසනු ලබන ප්‍රශ්න
                </span>
              )}
            </Typography>
            <div className="faq-div">
              <div className="faq-col">
                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "How can I pay for my Ad?"
                  ) : (
                    <span>මගේ දැන්වීම සඳහා ගෙවිය හැක්කේ කෙසේද?</span>
                  )}
                </p>
                <p className="faq-ans" style={{ marginBottom: 15 }}>
                  {this.props.lng === true ? (
                    <React.Fragment>
                      You can pay online using your credit/debit card. Payments
                      can also be made using bank transfer/deposit.
                    </React.Fragment>
                  ) : (
                    <span>
                      ඔබේ ක්‍රෙඩිට් / ඩෙබිට් කාඩ්පත භාවිතා කර අන්තර්ජාලය හරහා
                      ගෙවිය හැකිය. ඊට අමතරව ගෙවීම් බැංකු තැන්පතු හරහා ද කළ
                      හැකිය.
                    </span>
                  )}
                </p>

                <table
                  style={{ fontSize: 14, color: "#494949", marginBottom: 30 }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: 108 }}>
                        {this.props.lng ? (
                          "Account Number"
                        ) : (
                          <span className="sinhala-size-12">ගිණුම් අංකය</span>
                        )}
                      </td>
                      <td>: 017510008154</td>
                    </tr>
                    <tr>
                      <td>
                        {this.props.lng ? (
                          "Account Name"
                        ) : (
                          <span className="sinhala-size-12">ගිණුමේ නම</span>
                        )}
                      </td>
                      <td>
                        :{" "}
                        {this.props.lng ? (
                          "Serendib Systems Lanka Pvt Ltd"
                        ) : (
                          <span className="sinhala-size-12">
                            සසෙරන්ඩිබ් සිස්ටම්ස් ලංකා පුද්ගලික සමාගම
                          </span>
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.props.lng ? (
                          "Bank"
                        ) : (
                          <span className="sinhala-size-12">බැංකුව</span>
                        )}
                      </td>
                      <td>
                        :{" "}
                        {this.props.lng ? (
                          "Sampath Bank PLC (Code: 7278)"
                        ) : (
                          <span className="sinhala-size-12">
                            සම්පත් බැංකුව (කේතය: 7278)
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.props.lng ? (
                          "Branch"
                        ) : (
                          <span className="sinhala-size-12">ශාඛාව</span>
                        )}
                      </td>
                      <td>
                        :{" "}
                        {this.props.lng ? (
                          "Colombo Super (Code: 175)"
                        ) : (
                          <span className="sinhala-size-12">
                            කොළඹ සුපිරි ශාඛාව (කේතය: 175)
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "Is my online payment secured?"
                  ) : (
                    <span>මගේ අන්තර්ජාල ගෙවීම සුරක්‍ෂිතද?</span>
                  )}
                </p>
                <p className="faq-ans">
                  {this.props.lng === true ? (
                    "We use Internet Payment Gateways provided by PayHere (a service backed by Sampath Bank) and Stripe Inc. where both companies provide bank-level security (PCI Level 1) for all your transactions. All payments are one-time payments and card information is not saved on our servers."
                  ) : (
                    <span>
                      PayHere (සම්පත් බැංකුව සමග අනුබද්ධිත) සහ Stripe Inc. යන
                      ආයතන මගින් සපයන ලද අන්තර්ජාල ගෙවීම් දොරටු අපි භාවිතා
                      කරන්නෙමු. එහිදී සමාගම් දෙකම ඔබේ සියලු ගනුදෙනු සඳහා බැංකු
                      මට්ටමේ ආරක්ෂාව (PCI Level 1) සපයයි. සියලුම ගෙවීම් එක් වරක්
                      පමනක් කරන ගෙවීම් වන අතර කාඩ්පත් තොරතුරු අප විසින් තබාගනු
                      නොලැබේ.
                    </span>
                  )}
                </p>

                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "How long does it take to get my ad approved after I pay?"
                  ) : (
                    <span>
                      ගෙවීම සිදු කිරීමෙන් පසු මගේ දැන්වීම අනුමත කිරීමට කොපමණ
                      කාලයක් ගතවේද?
                    </span>
                  )}
                </p>
                <p className="faq-ans">
                  {this.props.lng === true ? (
                    "If you pay online it will be approved within 12 hours from your payment. If you transfer/deposit funds to our bank account your ad will be approved within 12 hours from you sending us the receipt."
                  ) : (
                    <span>
                      ඔබ අන්තර්ජාලය හරහා ගෙවන්නේ නම්, එය ඔබගේ ගෙවීමෙන් පැය 12 ක්
                      ඇතුළත අනුමත වේ. ඔබ අපගේ බැංකු ගිණුමට මුදල් මාරු කරන්නේ නම්
                      / තැන්පත් කරන්නේ නම්, ඔබ අපට රිසිට්පත එවීමෙන් පැය 12 ක්
                      ඇතුළත ඔබේ දැන්වීම අනුමත වේ.
                    </span>
                  )}
                </p>
              </div>
              <div className="faq-col">
                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "When can I renew my Ad?"
                  ) : (
                    <span>මගේ දැන්වීම අලුත් කළ හැක්කේ කවදාද?</span>
                  )}
                </p>
                <p className="faq-ans">
                  {this.props.lng === true ? (
                    "You can renew your ad from 7 days prior to the expiry date. New validity period starts from the date of renewal so we recommend you to renew your ad at the expiry to take the maximum benefit."
                  ) : (
                    <span>
                      කල් ඉකුත් වීමේ දිනට දින 7 කට පෙර සිට ඔබේ දැන්වීම අලුත් කළ
                      හැකිය. නව වලංගු කාල සීමාව අලුත් කිරීමේ දිනයේ සිට ආරම්භ වන
                      බැවින් උපරිම ප්‍රතිලාභ ලබා ගැනීම සඳහා කල් ඉකුත් වීමේ
                      දිනයේදී ඔබේ දැන්වීම අලුත් කිරීමට අපි ඔබට නිර්දේශ කරමු.
                    </span>
                  )}
                </p>

                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "Do you provide support?"
                  ) : (
                    <span>ඔබ අවශ්‍ය වූ විට සහාය ලබා දෙනවාද?</span>
                  )}
                </p>
                <p className="faq-ans" style={{ marginBottom: 15 }}>
                  {this.props.lng === true ? (
                    "Our support is available around the clock as Sri Lankans who live in several countries create profiles with us. Please do not hesitate to contact us via one of the channels below."
                  ) : (
                    <span>
                      රටවල් කිහිපයක වෙසෙන ශ්‍රී ලාංකිකයන් අප සමඟ දැන්වීම් පලකරන
                      බැවින් අපගේ සහාය ඕනෑම වේලාවක ඔබට ලබා ගත හැක. පහත සදහන්
                      ඕනෑම නාලිකාවක් හරහා ඔබට අප හා සම්බන්ධ විය හැක.
                    </span>
                  )}
                </p>

                <table
                  style={{ fontSize: 14, color: "#494949", marginBottom: 30 }}
                >
                  <tbody>
                    <tr>
                      {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (
                        <React.Fragment>
                          <td style={{ width: 125, verticalAlign: "top" }}>
                            {this.props.lng ? (
                              "Phone"
                            ) : (
                              <span className="sinhala-size-12">දුරකථන</span>
                            )}
                          </td>
                          <td>
                            :{" "}
                            {this.props.lng ? (
                              <span>
                                {Config.PHONE_NUMBER} /{" "}
                                {Config.PHONE_NUMBER_MOBILE} <br />
                                &nbsp;(from 8.30 am to 8.30 pm)
                              </span>
                            ) : (
                              <span>
                                {Config.PHONE_NUMBER} /{" "}
                                {Config.PHONE_NUMBER_MOBILE} <br />
                                &nbsp;(පෙ.ව 8.30 සිට ප​.ව​ 8.30 දක්වා)
                              </span>
                            )}
                          </td>
                        </React.Fragment>
                      ) : null}
                    </tr>
                    <tr>
                      <td>
                        {this.props.lng ? (
                          "Email"
                        ) : (
                          <span className="sinhala-size-12">
                            විද්‍යුත් තැපෑල
                          </span>
                        )}
                      </td>
                      <td>
                        :{" "}
                        <a href={`mailto:${Config.SUPPORT_EMAIL}`}>
                          {Config.SUPPORT_EMAIL}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>WhatsApp</td>
                      <td>{`: ${Config.PHONE_NUMBER}`}</td>
                    </tr>
                    <tr>
                      <td>Facebook</td>
                      <td>
                        :{" "}
                        <a
                          href={`${Config.MESSENGER_URL}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Config.MESSENGER_URL}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="faq-ques">
                  {this.props.lng === true ? (
                    "I found a partner. How can I delete my ad?"
                  ) : (
                    <span>
                      මට සහකරුවෙකු හමු විය. මගේ දැන්වීම ඉවත් කල හැක්කේ කෙසේද?
                    </span>
                  )}
                </p>
                <p className="faq-ans">
                  {this.props.lng === true ? (
                    "You can unpublish your ad by visiting the “Edit my ad” section. Else, please inform us via any of the support channels above and our team will take your ad down upon confirmation of your details."
                  ) : (
                    <span>
                      “මගේ දැන්වීම වෙනස් කරන්න” කොටසට පිවිසීමෙන් ඔබට ඔබේ දැන්වීම
                      ඉවත් කර ගත හැකිය. නැතහොත්, කරුණාකර ඉහත ඕනෑම ආධාරක
                      නාලිකාවක් හරහා අපට දන්වන්න, ඔබේ තොරතුරු තහවුරු කිරීමෙන්
                      පසු අපගේ කණ්ඩායම ඔබේ දැන්වීම ඉවත් කරනු ඇත.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </Grid>
          <div style={{ marginTop: "30px" }}>
            {Config.REVIEWS_AND_RATINGS_VISIBILITY &&
              this.state.reviews.length > 0 && (
                <div
                  className="cus-container"
                  style={{ background: "transparent" }}
                >
                  <Grid
                    container
                    xs={12}
                    sm={10}
                    md={8}
                    className="all-ads-container reviews-slider-grid"
                  >
                    <Grid
                      item
                      className="reviews-slider-sub-grid"
                      style={{ paddingTop: "10px" }}
                    >
                      <Slider {...settings}>
                        {this.state.reviews.map((review, i) => (
                          <ReviewComponent
                            review={review}
                            lang={this.props.lng}
                          />
                        ))}
                      </Slider>
                      <p
                        className="ignore-int-text-btn"
                        style={{ textAlign: "end" }}
                        onClick={() => this.props.history.push("/reviews")}
                      >
                        <span>
                          {this.props.lng ? (
                            "Load all reviews"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-13">
                              සියලු නිර්දේශ කිරීම් බලන්න
                            </span>
                          )}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}
          </div>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Pricing)