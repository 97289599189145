import { Grid, IconButton, Typography } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Add as AddIcon } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { SIBLING_OPTIONS } from "../../../../../../static-data";
import Config from "../../../../../../config";
import SiblingForm from "./sibling-form";
import { Formik } from "formik";
import { buildModelByFormMeta } from "../../../../../../utils";
import FormMeta from "../../../../../../meta/sibling-form.json";
import { setEditAdShowSibilingForm } from "../../../../../../store/actions/edit-ad-page";
import FormSchema from "./schema";
import {
  deleteSibling,
  saveNewSiblingData,
} from "../../../../../../features/edit-ad";

const SiblingListItem = ({ sibling }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const relationshipLabel = useMemo(() => {
    if (
      isEmpty(sibling) ||
      isEmpty(sibling.relationship) ||
      isEmpty(sibling.relationshipType)
    ) {
      return "";
    }
    const { relationshipType, relationship } = sibling;
    const relationshipId = `${relationshipType}-${relationship}`;
    const siblingOption = SIBLING_OPTIONS.find(
      (current) => current.id === relationshipId
    );
    if (siblingOption === null) return "";
    let extractionKey = "label";
    if (i18n.language !== Config.APP_LANGS.EN) {
      extractionKey = `label_${i18n.language}`;
    }
    return siblingOption[extractionKey];
  }, [i18n.language, sibling]);

  const civilStatus = useMemo(() => {
    if (isEmpty(sibling) || sibling.isMarried === undefined) {
      return "";
    }

    const localeLabel = sibling.isMarried
      ? "common.married"
      : "common.unmarried";
    return t(localeLabel);
  }, [sibling, t]);

  const profession = useMemo(() => {
    if (isEmpty(sibling) || isEmpty(sibling.profession)) {
      return "";
    }

    if(sibling.profession.id === Config.OTHER_PROFESSION_ID){
      return sibling.professionOther;
    } else{
      let extractionKey = "name";
      if (i18n.language !== Config.APP_LANGS.EN) {
        extractionKey = `name_${i18n.language}`;
      }
      return sibling.profession[extractionKey] ?? "";
    }

  }, [i18n.language, sibling]);

  return (
    <Grid container direction="row" className="sibling-list-item">
      <Grid item xs container direction="column">
        <Typography
          variant="body2"
          className={`list-item-heading ${i18n.language}`}
        >
          {relationshipLabel}
        </Typography>
        <Typography
          variant="caption"
          className={`list-item-caption ${i18n.language}`}
        >
          {`${civilStatus} - ${profession}`}
        </Typography>
      </Grid>
      <IconButton
        size="small"
        onClick={() => {
          dispatch(deleteSibling({ sibling, t }));
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
};

const initialModel = buildModelByFormMeta(FormMeta);

const SiblingInformation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { advertisement, showEditAdForm } = useSelector(
    (state) => state.editAdPage
  );
  const siblingInfo = advertisement.siblingInfo ?? [];

  const onSubmit = (values) => {
    dispatch(saveNewSiblingData({ values, t }));
  };

  const validationSchema = useMemo(() => {
    return FormSchema(t);
  }, [t]);

  return (
    <Grid container direction="column" className="row-gap-16">
      {siblingInfo.map((sibling) => (
        <SiblingListItem key={sibling.id} sibling={sibling} />
      ))}
      {!showEditAdForm && (
        <Typography
          variant="body2"
          className={`add-sibling-btn-txt ${i18n.language}`}
          onClick={() => dispatch(setEditAdShowSibilingForm(true))}
        >
          <AddIcon />
          {t("common.addASibling")}
        </Typography>
      )}
      {showEditAdForm && (
        <Grid item className="sibling-form-container">
          <Formik
            initialValues={initialModel}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <SiblingForm />
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};

export default SiblingInformation;
