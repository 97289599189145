import React, { lazy, memo, Suspense, useEffect, useMemo, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Button,
  Drawer,
  Switch,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Formik, useFormikContext } from "formik";
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { isEmpty, isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import DummyPost from "../common/DummyPost";
import FormMeta from "../../v2/meta/post-filter-bar.json";
import { buildModelByFormMeta, scrollToTop } from "../../v2/utils";
import { useViewportWidth } from "../../v2/hooks";
import Config from "../../v2/config";
import {
  handlePrefferredSearch,
  onChangeFilterCriteria,
} from "../../v2/features/home-page";
import BlogPost from "../blogPost/blogPost";
import FilterBarMagazine from "../magazine/FilterBarMagazine";
import FilterBarReviews from "../filterbar-reviews";
import AdvertisementCard from "../../v2/pages/home/advertisement-card";
import FilterBarMobile from "../common/FilterBar";

import "../../styles/post.css";
import "../../styles/all-ads-container.css";
import "../../styles/pagination.css";
import { setHomePageMobileFilterDrawer } from "../../v2/store/actions/home-page";

import "../../styles/post.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  constructFilterCriteriaBySearchParams,
  constructSearchParamsByValues,
  isChangedFilterCriteria,
  sanitizeSearchFilterValues,
} from "../../v2/pages/home/extension";
import withLanguageMigrator from "../../v2/hoc/language-migrator";

const renderLoader = () => <Grid item className="all-ads-filter-bar"></Grid>;
const FilterBar = lazy(() => import("../common/FilterBar"));
// const FilterBarMobile = lazy(() => import("../common/FilterBarMobile"));

// move this v2 migration later
// Make <Content> component to wrap page content and reuse it into both usecases - mobile and tab
const PreferredSearchToggle = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isActivePrefferredSearch } = useSelector((state) => state.homePage);
  const { resetForm } = useFormikContext();

  return (
    <Grid container direction="row" className="prefferred-search-toggle">
      <Typography className="prefferred-toggle-label">
        {t("common.PreferredSearch")}
      </Typography>
      <Switch
        checked={isActivePrefferredSearch}
        onChange={(event) => {
          dispatch(
            handlePrefferredSearch({
              active: event.target.checked,
              translate: t,
              resetForm,
            })
          );
        }}
      />
    </Grid>
  );
});

const Form = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { values, setFieldValue, setValues } = useFormikContext();
  const { shadowedCriteria, loading, initialResultLoaded } = useSelector(
    (state) => state.homePage
  );
  const { authenticated, initialized: initializedAuth } = useSelector(
    (state) => state.application
  );
  // Avoid parrelly triggering USE_EFFECT_01
  const previousSearchParams = useRef({});

  // USE_EFFECT_01
  useEffect(() => {
    const filters = sanitizeSearchFilterValues({ values, authenticated });
    // Blocking scenarios
    if (
      !initializedAuth ||
      !initialResultLoaded ||
      isEqual(filters, shadowedCriteria) ||
      isEqual(filters, previousSearchParams.current) ||
      loading
    ) {
      return;
    }
    // Actual Processing
    const searchParams = constructSearchParamsByValues({ values: filters });
    const isPaginatedQuery = !isChangedFilterCriteria({
      filters,
      shadowedCriteria,
      ignoredKeys: [FormMeta.pageNum.fieldName, FormMeta.pageSize.fieldName],
    });
    if (!isPaginatedQuery) {
      searchParams.set(FormMeta.pageNum.paramExtractor, 1);
    }
    previousSearchParams.current = filters;
    history.push(`?${searchParams.toString()}`);
  }, [
    dispatch,
    setFieldValue,
    history,
    loading,
    values,
    shadowedCriteria,
    authenticated,
    initializedAuth,
    setValues,
    initialResultLoaded,
  ]);

  // USE_EFFECT_02
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const criteria = constructFilterCriteriaBySearchParams({ searchParams });
    dispatch(
      onChangeFilterCriteria({
        criteria,
        setFieldValue,
        setValues,
      })
    );
  }, [dispatch, location, setFieldValue, setValues]);

  return <>{children}</>;
};

const Container = (props) => {
  const { lng } = props;
  const dispatch = useDispatch();
  const windowWidth = useViewportWidth();
  const {
    loading,
    posts,
    featuredPosts,
    totalCount,
    totalPages,
    currentPage,
    pageSize,
    showMobileFilterDrawer,
    filterCount,
  } = useSelector((state) => state.homePage);
  const { t, i18n } = useTranslation();

  // TODO: Introduce a application state such as breakpoint
  const isMobileViewport = useMemo(() => {
    return windowWidth <= Config.MOBILE_VIEWPORT_UPPER_BOUND;
  }, [windowWidth]);

  const initialModel = useMemo(() => {
    // constructing initial model
    const model = buildModelByFormMeta(FormMeta);
    let storedCriteria = sessionStorage.getItem(Config.POST_SEARCH_CRITERIA);
    if (storedCriteria !== null) {
      storedCriteria = JSON.parse(storedCriteria);
      for (const [key, value] of Object.entries(storedCriteria)) {
        model[key] = value;
      }
    }
    // Override model by URL search params
    const searchParams = new URLSearchParams(window.location.search);
    if (Array.from(searchParams).length > 0) {
      const params = constructFilterCriteriaBySearchParams({ searchParams });
      for (const [key, value] of Object.entries(params)) {
        model[key] = value;
      }
    }
    // initial PageSize migration
    model.pageSize =
      window.innerWidth <= Config.MOBILE_VIEWPORT_UPPER_BOUND
        ? Config.HOME_PAGE_POST_LIMIT_MOBILE
        : Config.HOME_PAGE_POST_LIMIT_LAPTOP;
    return model;
  }, []);

  const actualCurrentPage = currentPage;
  const offset = (actualCurrentPage - 1) * pageSize;
  const paginationStart = offset + 1;
  const isLastPage = actualCurrentPage === totalPages;
  const paginationEnd = isLastPage ? totalCount : offset + pageSize;

  return (
    <Grid container direction="row">
      <Formik initialValues={initialModel} onSubmit={() => {}}>
        {({ setFieldValue }) => (
          <Form>
            {isMobileViewport ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="home-mobile-content-header"
                >
                  <Button
                    size="medium"
                    startIcon={<SearchIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setHomePageMobileFilterDrawer(true));
                    }}
                    className="mobile-header-search-btn"
                  >
                    Search
                    {filterCount !== 0 && (
                      <span className="mobile-search-btn-search-results">
                        {filterCount}
                      </span>
                    )}
                  </Button>
                  <Grid item style={{ width: "fit-content" }}>
                    <PreferredSearchToggle />
                  </Grid>
                </Grid>
                <Divider className="filter-divider" style={{ width: "100%" }} />
                <Drawer
                  anchor="left"
                  variant="persistent"
                  open={showMobileFilterDrawer}
                  onClose={() => dispatch(setHomePageMobileFilterDrawer(false))}
                  className="mobile-filter-bar-drawer"
                >
                  <Suspense fallback={renderLoader()}>
                    <FilterBarMobile isMobileViewport={isMobileViewport} />
                  </Suspense>
                </Drawer>
              </>
            ) : (
              <Grid container direction="column" className="all-ads-filter-bar">
                <Suspense fallback={renderLoader()}>
                  <FilterBar isMobileViewport={isMobileViewport} />
                </Suspense>
                <Grid item container direction="column">
                  {Config.MAGAZINE_VISIBILITY && (
                    <FilterBarMagazine lng={lng} />
                  )}
                  {Config.BLOG_PAGE_VISIBILITY && <BlogPost lng={lng} />}
                  {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
                    <Grid item style={{ width: "100%" }}>
                      <FilterBarReviews lng={lng} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid
              xs
              container
              direction="column"
              alignItems="center"
              id="home-page-ad-container"
            >
              {!isMobileViewport && <PreferredSearchToggle />}
              <Divider className="filter-divider" style={{ width: "100%" }} />
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && !loading && (
                <p
                  className={`post-results ${
                    i18n.language === Config.APP_LANGS.SI
                  } ? "sinhala-w-600 sinhala-size-10" : ""`}
                >
                  {isEmpty(posts)
                    ? t("common.noPostPaginatedResults")
                    : t("common.postPaginatedResultSummery", {
                        start: paginationStart,
                        end: paginationEnd,
                        total: totalCount,
                      })}
                </p>
              )}
              <Grid
                item
                container
                direction="column"
                className="root-advertisment-container"
                // TODO: Remove when showing results count for myvivah.app
                style={{
                  marginTop:
                    Config.CURRENT_PLATFORM === Config.PLATFORMS.IN || loading
                      ? 16
                      : 0,
                }}
              >
                {loading ? (
                  <>
                    <DummyPost />
                    <DummyPost />
                    <DummyPost />
                    <DummyPost />
                    <DummyPost />
                    <DummyPost />
                    <DummyPost />
                  </>
                ) : (
                  <>
                    {/* Refactored Advertisement Card */}
                    {featuredPosts.map((post, index) => (
                      <AdvertisementCard
                        advertisment={post}
                        isFeatured={true}
                        key={post.id}
                        position={index + 20}
                      />
                    ))}
                    {/* Refactored Advertisement Card */}
                    {posts.map((post, index) => (
                      <AdvertisementCard
                        advertisment={post}
                        key={post.id}
                        position={index + 1}
                      />
                    ))}

                    <Grid item className="pagination-div">
                      {totalPages > 1 && (
                        <div className="pagination-main">
                          <div className="custom-pagination-buttons">
                            <Button
                              variant="contained"
                              className="pagination-custom-btn"
                              disabled={actualCurrentPage === 1}
                              onClick={(e) => {
                                const previousPage = actualCurrentPage - 1;
                                setFieldValue(
                                  FormMeta.pageNum.fieldName,
                                  previousPage
                                );
                                scrollToTop();
                              }}
                              startIcon={<ArrowBackIcon />}
                            >
                              Previous
                            </Button>
                            <Button
                              variant="contained"
                              className="pagination-custom-btn"
                              disabled={actualCurrentPage === totalPages}
                              onClick={() => {
                                const nextPage = currentPage + 1;
                                setFieldValue(
                                  FormMeta.pageNum.fieldName,
                                  nextPage
                                );
                                scrollToTop();
                              }}
                              endIcon={<ArrowForwardIcon />}
                            >
                              Next
                            </Button>
                          </div>
                          <Pagination
                            showFirstButton
                            showLastButton
                            siblingCount={
                              windowWidth < Config.MOBILE_VIEWPORT_UPPER_BOUND
                                ? 0
                                : 1
                            }
                            count={totalPages}
                            page={actualCurrentPage}
                            onChange={(_, page) => {
                              setFieldValue(FormMeta.pageNum.fieldName, page);
                              scrollToTop();
                            }}
                            className="pagi"
                          />
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default withRouter(withLanguageMigrator(Container));
