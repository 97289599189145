import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import Close from "../../images/close.png";

function PreferenceMismatchModal(props) {
  return (
    <Dialog
      open={props.preferenceMismatchModal}
      onClose={props.handlePreferenceMismatchModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0 }}
      >
        {props.lng ? (
          "Preference Mismatch"
        ) : (
          <span className="sinhala-w-600 sinhala-size-13">
            අපේක්ශිත නිර්ණායක සමග නොගැලපේ
          </span>
        )}
        <img
          src={Close}
          alt="close"
          className="close-modal"
          onClick={props.handlePreferenceMismatchModalClose}
        />
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          {props.lng ? (
            props.preferenceMismatchMessage
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              {props.preferenceMismatchMessage}
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handlePreferenceMismatchModalClose}
        >
          {props.lng ? (
            "Cancel"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">අවලංගු කරන්න</span>
          )}
        </Button>
        <Button
          onClick={props.showInterestOnMismatchedProfile}
          style={{ background: "#000", color: "#fff" }}
        >
          {props.lng ? (
            "Show Interest"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">කැමැත්ත පෙන්වන්න</span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreferenceMismatchModal;
