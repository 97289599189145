import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { isEmpty } from "lodash";
import "./style.css";
import DataSection from "../../../components/organisms/data-section";

const RespondSection = ({ data }) => {
  return (
    <>
      {!isEmpty(data) ? (
        <DataSection heading="Respond">
          <Grid
            container
            direction="column"
            className="respond-elements-container"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ columnGap: 15 }}
            >
              <i className="fas fa-phone fa-rotate-90"></i>
              <Typography className="contact-text">
                {data.phone || ""}
              </Typography>
            </Grid>
            {!isEmpty(data.email) && (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ columnGap: 15 }}
              >
                <i className="fas fa-at"></i>
                <Typography className="contact-text">
                  {data.email || ""}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DataSection>
      ) : (
        <Grid
          container
          justifyContent="center"
          className="respond-loading-container"
        >
          <CircularProgress className="circular-progress" />
        </Grid>
      )}
    </>
  );
};

export default RespondSection;
