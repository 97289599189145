import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { isEmpty } from "lodash";

import groom from "../../../../images/groom.png";
import bride from "../../../../images/bride.png";
import heart from "../../../../images/heart.png";
import topAdBadge from "../../../../images/topad-badge.png";

import PhotoGrid from "../../../components/molecules/PhotoGrid";

import "./style.css";

import {
  generateHeight,
  generateProfession,
  getAccountCreatedType,
  getResidentCountryCode,
} from "../../../features/profile-view";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getAuthPostImages, getMatchImage } from "../../../features/post";
import { ActionDialogBox } from "../../../components/molecules/dialog-box";
import Config from "../../../config";

const MATCHED = "MATCHED";
const LIKED = "LIKED";
const UNMATCHED = "UNMATCHED";
const UNLIKED = "UNLIKED";
const LIKE_REFUSED = "LIKE_REFUSED";

const INTEREST_VIEW = "INTEREST_VIEW";

const HeaderSection = ({ data, interestData, variant, direction }) => {
  const [profileImages, setProfileImages] = useState([]);
  const [nicVerifyModalVisibility, setNicVerifyModalVisibility] =
    useState(false);
  const [topAdModalVisibility, setTopAdModalVisibility] = useState(false);

  const { authProfile } = useSelector((state) => state.application);
  const history = useHistory();
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const personalInfo = data?.personalInfo;

  const haveProfileImagesSelf = !isEmpty(authProfile?.images);
  const haveProfileImagesOther = !isEmpty(data?.images);

  const authProfileIsIdVerified = authProfile?.idVerified || false;
  const authProfileIsFeatured = authProfile?.isFeatured || false;

  const interestType = useMemo(() => {
    let type = { visibility: false, type: "", text: "" };

    if (!isEmpty(interestData?.timeline)) {
      const matched = interestData.timeline.filter(
        (filter) => filter.eventType === MATCHED
      );

      const liked = interestData.timeline.filter(
        (filter) => filter.eventType === LIKED
      );

      const unmatched = interestData.timeline.filter(
        (filter) => filter.eventType === UNMATCHED
      );
      const unliked = interestData.timeline.filter(
        (filter) => filter.eventType === UNLIKED
      );
      const likeRefused = interestData.timeline.filter(
        (filter) => filter.eventType === LIKE_REFUSED
      );

      if (variant === INTEREST_VIEW) {
        if (matched.length > 0) {
          type = { visibility: true, type: "MATCHED", text: "Matched" };
        } else if (liked.length > 0) {
          if (liked[0].actionBy === "SELF") {
            type = { visibility: true, type: "SENT", text: "Interest Sent" };
          } else {
            type = {
              visibility: true,
              type: "RECEIVED",
              text: "Interest Received",
            };
          }
        }
      } else {
        if (matched.length > 0 && !unmatched.length > 0) {
          type = { visibility: true, type: "MATCHED", text: "Matched" };
        } else if (liked.length > 0) {
          if (liked[0].actionBy === "SELF" && !unliked.length > 0) {
            type = { visibility: true, type: "SENT", text: "Interest Sent" };
          } else if (
            liked[0].actionBy === "OTHER_PARTY" &&
            !likeRefused.length > 0
          ) {
            type = {
              visibility: true,
              type: "RECEIVED",
              text: "Interest Received",
            };
          }
        }
      }
    }
    return type;
  }, [interestData, variant]);

  const TagLine = () => {
    return (
      <Grid
        container
        direction="row"
        style={{ columnGap: 3 }}
        className="tag-line"
      >
        {`${personalInfo?.age}`}
        <span style={{ color: "#8c8c8c" }}>&#8226;</span>
        {`${personalInfo?.residentCity}`}
        <span style={{ color: "#8c8c8c" }}>&#8226;</span>
        {generateProfession(personalInfo, appLanguage)}
        <span style={{ color: "#8c8c8c" }}>&#8226;</span>
        {generateHeight(personalInfo?.height)}
      </Grid>
    );
  };

  const getMatchImages = async () => {
    if (!haveProfileImagesSelf) {
      return;
    }
    const images = Array.isArray(data?.images) ? data?.images : [];
    try {
      const profileImages = [];
      for (const image of images) {
        const imageURL = await getMatchImage({
          postId: authProfile?.id,
          matchId: interestData?.id,
          imageId: image,
        });
        profileImages.push(imageURL);
      }
      setProfileImages(profileImages);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileImages = async () => {
    const images = Array.isArray(authProfile?.images) ? data?.images : [];
    try {
      const profileImages = [];
      for (const image of images) {
        const imageURL = await getAuthPostImages({
          postId: authProfile?.id,
          imageId: image,
        });
        profileImages.push(imageURL);
      }
      setProfileImages(profileImages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }
    setProfileImages([]);
    if (variant === "SELF" && direction === "MATCHES") {
      getProfileImages();
    } else {
      getMatchImages();
    }
  }, [data, direction, variant]);
  return (
    <>
      {!isEmpty(data) ? (
        <Grid container>
          {data?.isFeatured &&
          variant !== INTEREST_VIEW &&
          Config.TOP_AD_FEATURE_ENABLED ? (
            <Box
              onClick={() =>
                !authProfileIsFeatured && setTopAdModalVisibility(true)
              }
              title={
                authProfileIsFeatured
                  ? t("post.alreadyTopAd")
                  : t("post.convertAsTopAd")
              }
              className="top-ad-tag"
            >
              <img
                src={topAdBadge}
                alt="Top Ad Badge"
                className="top-ad-tag-img"
              />
              <Typography className="top-ad-tag-txt">Top Ad</Typography>
            </Box>
          ) : (
            <></>
          )}

          <Grid
            container
            direction="column"
            className={`header-section header-section-${
              data?.isFeatured &&
              variant !== INTEREST_VIEW &&
              Config.TOP_AD_FEATURE_ENABLED &&
              "top-ad"
            }`}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item direction="row">
                <Grid
                  container
                  direction="row"
                  style={{ columnGap: 20 }}
                  wrap="nowrap"
                >
                  <img
                    src={personalInfo?.gender === "FEMALE" ? bride : groom}
                    className="profile-img"
                  />
                  <Grid container direction="column" style={{ rowGap: 3 }}>
                    <Typography className="display-name">
                      {interestType.type === "MATCHED"
                        ? `${personalInfo?.fname} ${personalInfo?.lname}`
                        : personalInfo?.displayName || ""}
                    </Typography>
                    <TagLine />
                    <Grid container direction="column" style={{ rowGap: 5 }}>
                      <Chip
                        label={t("common.accountCreatedBy", {
                          type: getAccountCreatedType(
                            data.createdBy,
                            appLanguage
                          ),
                        })}
                        className="acc-created-chip"
                      />
                      {data.idVerified & (variant !== INTEREST_VIEW) ? (
                        <Chip
                          label={
                            <>
                              {t("common.idVerified")}{" "}
                              <i className="fas fa-check-circle"></i>
                            </>
                          }
                          className={`id-verified-chip ${
                            authProfileIsIdVerified && `cursor-default`
                          }`}
                          onClick={() =>
                            !authProfileIsIdVerified &&
                            setNicVerifyModalVisibility(true)
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {data.differentlyAbled && (
                        <Chip
                          label={t("common.differentlyAbled")}
                          className="differently-abled-chip"
                        />
                      )}
                    </Grid>
                    {interestType.visibility && (
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        style={{ columnGap: 5 }}
                      >
                        <img src={heart} style={{ height: 16 }} />
                        <Typography className="interest-type-txt">
                          {interestType.text}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid>
                <ReactCountryFlag
                  countryCode={getResidentCountryCode(personalInfo)}
                  svg
                  className="country-flag"
                  title={getResidentCountryCode(personalInfo)}
                />
              </Grid>
            </Grid>

            <Grid container direction="row">
              <PhotoGrid images={profileImages} />
            </Grid>

            {interestType.type === "MATCHED" &&
            !(haveProfileImagesSelf & haveProfileImagesOther) ? (
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                className="header-notice"
              >
                <i className="fas fa-eye-slash header-notice-icon" />
                <Typography className="header-notice-txt">
                  {!haveProfileImagesSelf
                    ? t("post.uploadPicturesNotice")
                    : !haveProfileImagesOther
                    ? t("post.userHasntUploadPicturesNotice")
                    : ""}
                  {!haveProfileImagesSelf && (
                    <Link
                      href="/edit-my-ad?scroll=toPhotos"
                      className="header-nav-link"
                    >
                      {t("post.uploadYourPhotos")}
                    </Link>
                  )}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}

            <Typography className="additional-info-txt">
              {personalInfo.additionalInfo}
            </Typography>

            <ActionDialogBox
              showModal={nicVerifyModalVisibility}
              onClose={() => setNicVerifyModalVisibility(false)}
              title={t("post.GetVerifiedTitle")}
              secondaryBtnText={t("post.cancel")}
              primaryBtnText={t("post.GetVerified")}
              secondaryBtnAction={() => setNicVerifyModalVisibility(false)}
              primaryBtnAction={() =>
                history.push({ pathname: "/settings", state: "nic-div" })
              }
            >
              <Typography className="header-modal-content-txt">
                {t("post.likeToVerified")}
              </Typography>
            </ActionDialogBox>

            <ActionDialogBox
              showModal={topAdModalVisibility}
              onClose={() => setTopAdModalVisibility(false)}
              title="Top Ad"
              secondaryBtnText={t("post.cancel")}
              primaryBtnText={t("post.continue")}
              secondaryBtnAction={() => setTopAdModalVisibility(false)}
              primaryBtnAction={() => history.push("/payments/top-ad")}
            >
              <Typography className="header-modal-content-txt">
                {t("post.likeToFeatured")}
              </Typography>
            </ActionDialogBox>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" className="header-loading-com">
          <CircularProgress style={{ color: "#c19d0d" }} />
        </Grid>
      )}
    </>
  );
};

export default HeaderSection;
