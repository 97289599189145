import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import BannerClose from "../../images/banner-close.png";

import "../../styles/banner.css";

class Banner extends Component {
  state = {
    banner: {},
    isEnable: false,
  };


  handleBanner = () => {
    this.setState({ isEnable: false });

    localStorage.setItem(
      "notifyTimeOut",
      moment().add(1, "days").local().format()
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isEnable && (
          <div
            className="banner-container"
            style={{
              background: this.state.banner.banner_color
                ? this.state.banner.banner_color
                : "transparent",
              color: this.state.banner.text_color
                ? this.state.banner.text_color
                : "#333333",
            }}
          >
            <table>
              <tr>
                <td className="icon">
                  <i class="fas fa-exclamation-triangle"></i>
                </td>
                <td className="msg">
                  <p>{this.state.banner.title && this.state.banner.title}</p>
                  <p>
                    {this.state.banner.message && this.state.banner.message}
                  </p>
                </td>
                <td className="close-icon">
                  <img
                    class="banner-close-icon"
                    src={BannerClose}
                    alt="banner close"
                    onClick={this.handleBanner}
                  />
                </td>
              </tr>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Banner);
