import common from "./common";
import createflow from "./createflow";
import errors from "./errors";
import filterbar from "./filterbar";
import message from "./messages";
import Constants from "../config/constant";
import post from "./post"
import payment from "./payment"
import supports from "./supports"
import serverError from "./server-errors";

const combineTranslations = () => {
  const generatorObj = {
    common,
    createflow,
    errors,
    filterbar,
    message,
    post,
    payment,
    supports,
    serverError
  };
  const output = {};
  for (const lang of Object.values(Constants.APP_LANG)) {
    output[lang] = {};
    for (const reference of Object.keys(generatorObj)) {
      output[lang][reference] = {};
    }
  }
  for (const [parentReference, translationObj] of Object.entries(
    generatorObj
  )) {
    for (const [translationKey, translations] of Object.entries(
      translationObj
    )) {
      for (const lang of Object.keys(translations)) {
        output[lang][parentReference][translationKey] = translations[lang];
      }
    }
  }
  return output;
};

const mapTranslations = () => {
  const translations = combineTranslations();
  const output = {};
  for (const [lang, values] of Object.entries(translations)) {
    if (output[lang] === undefined) {
      output[lang] = {};
      output[lang]["translation"] = values;
    }
  }
  return output;
};

const translations = mapTranslations();
export default translations;
