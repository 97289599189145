import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import "./style.css";

const StatusSection = ({ data, interestData }) => {
  const { t } = useTranslation();

  const hasOnlineInfo = !!data?.lastOnline;
  const hasPictures = !!data?.hasImages;
  const hasHoroscopeInfo = !!data?.hasHoroscopeData;

  const matchInfo = interestData?.timeline?.filter(
    (filter) => filter.eventType === "MATCHED"
  );

  const isMatchedProfile = !isEmpty(matchInfo);

  const getLastOnlineStatus = (lastOnline) => {
    let timeRange = "";

    switch (lastOnline) {
      case "0_DAY":
        timeRange = t("post.today");
        break;
      case "1_DAY":
        timeRange = t("post.yesterday");
        break;
      case "7_DAY":
        timeRange = t("post.withinSevenDays");
        break;
      case "30_DAY":
        timeRange = t("post.withinThirtyDays");
        break;
      case ">30_DAY":
        timeRange = t("post.beforeThirtyDays");
        break;
    }
    return timeRange;
  };

  return (
    <Grid container direction="column" className="profile-status-section">
      {!isEmpty(data) ? (
        <>
          {hasOnlineInfo && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ columnGap: 8 }}
            >
              <i
                className="fas fa-circle alert-online-icon"
                style={{ color: "#27AE60" }}
              />
              <Typography style={{ fontSize: 14 }}>
                {t("post.lastOnline", {
                  lastOnline: getLastOnlineStatus(data?.lastOnline),
                })}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ columnGap: 8 }}
          >
            {hasPictures ? (
              <i
                className="fas fa-check-circle alert-enable-icon"
                style={{ color: "#2979FF" }}
              />
            ) : (
              <i
                className="fas fa-times-circle alert-disable-icon"
                style={{ color: "#E0E0E0" }}
              />
            )}

            <Typography style={{ fontSize: 14 }}>
              {t("post.pictures")}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ columnGap: 8 }}
          >
            {hasHoroscopeInfo ? (
              <i
                className="fas fa-check-circle alert-enable-icon"
                style={{ color: "#2979FF" }}
              />
            ) : (
              <i
                className="fas fa-times-circle alert-disable-icon"
                style={{ color: "#E0E0E0" }}
              />
            )}
            <Typography style={{ fontSize: 14 }}>
              {t("post.horoscopeInformation")}
            </Typography>
          </Grid>

          {!isMatchedProfile && (
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="center"
              className="status-notice"
            >
              <i
                className="fas fa-eye-slash status-notice-icon"
              />
              <Typography className="status-notice-txt">
                {t("post.privateInfoNotice")}
              </Typography>
            </Grid>
          )}
        </>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress style={{ color: "#c19d0d" }} />
        </Grid>
      )}
    </Grid>
  );
};

export default StatusSection;
