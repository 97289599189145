import { SET_IS_NETWORK } from "../actions/globalNetwork";

const initialState = {
    isNetwork : false
}

const globalNetwork = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_NETWORK:
            return {...state, isNetwork: action.payload};
        default:
            return state;
    }
}

export default globalNetwork;