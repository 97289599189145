import React, { useState , useRef} from 'react'
import SendIcon from "@material-ui/icons/Send";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import { isEmpty} from "lodash";

function SendMessageTextBox({
    handleSentMessage,
    inactivePost,
    lng,
    isDisabledProfile,
    ignored,
    isDisableChat,
}) {
    const [message, setMessage] = useState("");
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const inputRef = useRef()

    const handleInputMessage = (e) => {
        setMessage(e.target.value);
    }

    const sendMessage = () => {
        const trimmedMessage = typeof(message) === "string" && message.trim();
        if(trimmedMessage){
            handleSentMessage(trimmedMessage);
            setMessage("");
        }
    }

    const onSend = (e) => {
        e && e.preventDefault();
        inputRef.current.focus();
        sendMessage()
    }

    const OnkeyPress = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          sendMessage();
        }
      };

    return (
        <>
            <textarea
                className="msg-field"
                type="text"
                ref={inputRef}
                name="message"
                value={message}
                onChange={handleInputMessage}
                onKeyDown={(e) => !isMobileScreen && OnkeyPress(e)}
                autoFocus={true}
                autoComplete='off'
                maxLength="1000"
                rows="4"
                disabled={
                    inactivePost ||
                    ignored ||
                    isDisabledProfile ||
                    ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") &&
                        localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ||
                        isDisableChat
                }
                placeholder={
                    inactivePost
                        ? lng
                            ? "You cannot send messages to this profile"
                            : "ඔබට මෙම ගැලපීම වලට පණිවිඩ යැවිය නොහැක"
                        :
                        isDisabledProfile
                            ? lng
                                ? "You cannot send messages to this profile as it has been taken down by the advertiser."
                                : "ඔබට මෙම ගැලපීම වලට පණිවිඩ යැවිය නොහැක. මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත."
                            : ignored
                                ? lng
                                    ? "You cannot send messages to this profile"
                                    : "ඔබට මෙම ගැලපීම වලට පණිවිඩ යැවිය නොහැක"
                                : lng
                                    ? "Send a message"
                                    : "පණිවිඩයක් යවන්න"
                }
            />
            <Button
                type="button"
                id="send-message-btn"
                className="send-message-btn"
                onClick={onSend}
                disabled={
                    inactivePost ||
                    ignored ||
                    isDisabledProfile ||
                    (localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2" &&
                        localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)
                    || isEmpty(message)
                }

            >
                <SendIcon
                    className="send-icon"
                    id="send-msg-now-icon"
                    style={{
                        color:
                            inactivePost ||
                                ignored ||
                                isDisabledProfile ||
                                ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") &&
                                    localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)
                                ? "#ccc"
                                : "",
                        cursor:
                            inactivePost ||
                                ignored ||
                                isDisabledProfile ||
                                ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") &&
                                    localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)
                                ? "default"
                                : "",
                    }}
                />
            </Button>
        </>
    )
}

SendMessageTextBox.propTypes = {
    handleSentMessage: PropTypes.func.isRequired,
    inactivePost: PropTypes.bool.isRequired,
    lng: PropTypes.bool.isRequired,
    isDisabledProfile: PropTypes.bool.isRequired,
    ignored: PropTypes.bool.isRequired
}

export default SendMessageTextBox;