import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { ageArr } from "../../contant-data/ageArr";
import { heightsArr } from "../../contant-data/heightsArr";

import "../../styles/partner-pref.css";
import Config from "../../v2/config";

const initialStates = {
  minAge: null,
  minAgeOld: 18,
  maxAge: null,
  maxAgeOld: 60,
  heightMin: null,
  minHeightOld: 121.92,
  heightMax: null,
  maxHeightOld: 228.6,
  ages: ageArr,
  heights: heightsArr,
  ethnicities: [],
  religions: [],
  maritalStatuses: [],
  checkedEthnicities: [],
  residentCountries: [],
  ethnicityAny: false,
  checkedReligions: [],
  statusReligion: false,
  checkedStatus: [],
  statusAny: false,
  checkedResidentCountries: [],
  residentCountriesAny: false,
  checkedEthnicitiesOld: [],
  checkedReligionsOld: [],
  checkedStatusOld: [],
  checkedResidentCountriesOld: [],
  isSetInterests : false,
  postId: localStorage.getItem("postId")
      ? localStorage.getItem("postId")
      : "",

  // disabled
  dissableBtn: false,

  // loadings
  loading: false,
  loadingCancel: false,
  resetLoading: false,

  // alerts
  successAlert: false,
  failAlert: false,
};

export default class InterestPref extends Component {
  state = initialStates;

  componentDidMount = () => {
    this.getNotify();
    this.getAllData();
  };

  getAllData = () => {
    // filtes
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const sortedReligions = res.data.religions.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedEthnicities = res.data.ethnicities.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedStatuses = res.data.civilStatuses.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        this.setState({
          religions: sortedReligions.filter((el) => {
            return el.id !== "other" && el.id !== "none";
          }),
          ethnicities: sortedEthnicities.filter((el) => el.id !== "other"),
          maritalStatuses: sortedStatuses.filter((el) => el.id !== "other"),
          residentCountries: res.data.countries.filter(
            (el) => el.code !== "ZZ"
          ),
        });
      })
      .catch((err) => console.log(err));
  };

  getNotify = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        if (res.data[0].interestPreferences) {
          this.setState({ isSetInterests : true });
        } else {
          this.setState({ isSetInterests : false });
        }
        this.setState({
          minAge: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.minAge
            : null,
          minAgeOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.minAge
            : 18,
          maxAge: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.maxAge
            : null,
          maxAgeOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.maxAge
            : 60,
          heightMin: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.heightMin
              ? res.data[0].interestPreferences.heightMin
              : null
            : null,
          minHeightOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.heightMin
              ? res.data[0].interestPreferences.heightMin
              : 121.92
            : 121.92,
          heightMax: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.heightMax
              ? res.data[0].interestPreferences.heightMax
              : null
            : null,
          maxHeightOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.heightMax
              ? res.data[0].interestPreferences.heightMax
              : 228.6
            : 228.6,
          checkedEthnicities: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.ethnicities
              ? res.data[0].interestPreferences.ethnicities
              : []
            : [],
          checkedEthnicitiesOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.ethnicities
              ? res.data[0].interestPreferences.ethnicities
              : []
            : [],
          ethnicityAny: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.ethnicities
              ? res.data[0].interestPreferences.ethnicities.length > 0
                ? false
                : true
              : true
            : true,
          checkedReligions: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.religions
              ? res.data[0].interestPreferences.religions
              : []
            : [],
          checkedReligionsOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.religions
              ? res.data[0].interestPreferences.religions
              : []
            : [],
          religionAny: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.religions
              ? res.data[0].interestPreferences.religions.length > 0
                ? false
                : true
              : true
            : true,
          checkedStatus: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.civilStatuses
              ? res.data[0].interestPreferences.civilStatuses
              : []
            : [],
          checkedStatusOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.civilStatuses
              ? res.data[0].interestPreferences.civilStatuses
              : []
            : [],
          statusAny: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.civilStatuses
              ? res.data[0].interestPreferences.civilStatuses.length > 0
                ? false
                : true
              : true
            : true,
          checkedResidentCountries: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.residentCountries
              ? res.data[0].interestPreferences.residentCountries
              : []
            : [],
          checkedResidentCountriesOld: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.residentCountries
              ? res.data[0].interestPreferences.residentCountries
              : []
            : [],
          residentCountriesAny: res.data[0].interestPreferences
            ? res.data[0].interestPreferences.residentCountries
              ? res.data[0].interestPreferences.residentCountries.length > 0
                ? false
                : true
              : true
            : true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    if (e.target.name === "minAge" && e.target.value > this.state.maxAge) {
      this.setState({
        maxAge: 60,
      });
    }

    if (e.target.name === "maxAge" && (this.state.minAge === null || this.state.minAge === "")) {
      this.setState({
        minAge: 18,
      });
    }

    if ((e.target.name === "maxAge" && e.target.value < this.state.minAge)) {
      this.setState({
        minAge: 18,
      });
    }

    if (
      e.target.name === "heightMin" &&
      e.target.value > this.state.heightMax
    ) {
      this.setState({
        heightMax: 228.6,
      });
    }

    if (e.target.name === "heightMax" && this.state.heightMin === null) {
      this.setState({
        heightMin: 121.92,
      });
    }

    if (((e.target.name === "heightMax" && e.target.value < this.state.heightMin))) {
      this.setState({
        heightMin: 121.92,
      });
    }

    if ((e.target.name === "heightMax" || e.target.name === "heightMin") && (e.target.value === "")) {
      this.setState({
        heightMin: null,
        heightMax: null
      });
    }

    if ((e.target.name === "minAge" || e.target.name === "maxAge") && (e.target.value === "")) {
      this.setState({
        minAge: null,
        maxAge: null
      });
    }

    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        // dissable buttons with changes
        this.checkButtonsActivation();
      }
    );
  };

  // dissable buttons with changes
  checkButtonsActivation = () => {
    if (
      this.state.minAgeOld == this.state.minAge &&
      this.state.maxAgeOld == this.state.maxAge &&
      this.state.minHeightOld == this.state.heightMin &&
      this.state.maxHeightOld == this.state.heightMax &&
      JSON.stringify(this.state.checkedEthnicities) ===
        JSON.stringify(this.state.checkedEthnicitiesOld) &&
      JSON.stringify(this.state.checkedReligions) ===
        JSON.stringify(this.state.checkedReligionsOld) &&
      JSON.stringify(this.state.checkedStatus) ===
        JSON.stringify(this.state.checkedStatusOld) &&
      JSON.stringify(this.state.checkedResidentCountries) ===
        JSON.stringify(this.state.checkedResidentCountriesOld)
    ) {
      this.setState({ dissableBtn: false });
    } else {
      this.setState({ dissableBtn: true });
    }
  };

  handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  handleClick = () => {
    console.info("You clicked the Chip.");
  };

  // ethnicity
  handleEthnicity = (value) => () => {
    const currentIndex = this.state.checkedEthnicities.indexOf(value);
    const newChecked = [...this.state.checkedEthnicities];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
      this.setState({ ethnicityAny: true });
    } else {
      this.setState({ ethnicityAny: false });
    }

    this.setState({ checkedEthnicities: newChecked }, () => {
      // dissable buttons with changes
      this.checkButtonsActivation();
    });
  };

  handleEthnicityAny = (value) => {
    if (!value) {
      this.setState({ ethnicityAny: !value, checkedEthnicities: [] }, () => {
        // dissable buttons with changes
        this.checkButtonsActivation();
      });
    }
  };

  // religion
  handleReligion = (value) => () => {
    const currentIndex = this.state.checkedReligions.indexOf(value);
    const newChecked = [...this.state.checkedReligions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
      this.setState({ religionAny: true });
    } else {
      this.setState({ religionAny: false });
    }

    this.setState({ checkedReligions: newChecked }, () => {
      // dissable buttons with changes
      this.checkButtonsActivation();
    });
  };

  handleReligionAny = (value) => {
    if (!value) {
      this.setState({ religionAny: !value, checkedReligions: [] }, () => {
        // dissable buttons with changes
        this.checkButtonsActivation();
      });
    }
  };

  // civil status
  handleStatus = (value) => () => {
    const currentIndex = this.state.checkedStatus.indexOf(value);
    const newChecked = [...this.state.checkedStatus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
      this.setState({ statusAny: true });
    } else {
      this.setState({ statusAny: false });
    }

    this.setState({ checkedStatus: newChecked }, () => {
      // dissable buttons with changes
      this.checkButtonsActivation();
    });
  };

  handleStatusAny = (value) => {
    if (!value) {
      this.setState({ statusAny: !value, checkedStatus: [] }, () => {
        // dissable buttons with changes
        this.checkButtonsActivation();
      });
    }
  };

  // resident country
  handleResidentCountries = (value) => () => {
    const currentIndex = this.state.checkedResidentCountries.indexOf(value);
    const newChecked = [...this.state.checkedResidentCountries];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
      this.setState({ residentCountriesAny: true });
    } else {
      this.setState({ residentCountriesAny: false });
    }

    this.setState({ checkedResidentCountries: newChecked }, () => {
      // dissable buttons with changes
      this.checkButtonsActivation();
    });
  };

  handleResidentCountriesAny = (value) => {
    if (!value) {
      this.setState(
        { residentCountriesAny: !value, checkedResidentCountries: [] },
        () => {
          // dissable buttons with changes
          this.checkButtonsActivation();
        }
      );
    }
  };

  // submit sign-up form
  handleSave = () => {
    this.setState({
      loading: true,
    });

    const data = {
      // minAge: this.state.minAge ? this.state.minAge : null,
      // maxAge: this.state.maxAge ? this.state.maxAge : null,
      // heightMin: this.state.heightMin ? Number(this.state.heightMin) : "",
      // heightMax: this.state.heightMax ? Number(this.state.heightMax) : "",
      age: (this.state.minAge && this.state.maxAge) ? {min: Number(this.state.minAge), max: Number(this.state.maxAge)} : {},
      height: (this.state.heightMin && this.state.heightMax) ? {min: Number(this.state.heightMin), max: Number(this.state.heightMax)} : {},
      ethnicities: this.state.checkedEthnicities,
      religions: this.state.checkedReligions,
      civilStatuses: this.state.checkedStatus,
      residentCountries: this.state.checkedResidentCountries,
    };

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem(
          "postId"
        )}/interest_preferences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          successAlert: true,
          dissableBtn: false,
          loading: false,
        });

        setTimeout(() => {
          this.setState({
            successAlert: false,
          });
        }, 2000);

        this.getNotify();
      })
      .catch((err) => {
        this.setState({ loading: false, failAlert: true });

        setTimeout(() => {
          this.setState({
            failAlert: false,
          });
        }, 2000);
      });
    // }
  };

  handleCancel = () => {
    this.setState({
      dissableBtn: false,
    });
    this.getNotify();
  };

  handleClose = () => {
    this.setState({ successAlert: false, failAlert: false });
  };

  handleResetPreferences = () => {
    this.setState({
      resetLoading: true,
    });
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${this.state.postId}/interest_preferences`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          resetLoading: false,
          isSetInterests: false,
          successAlert: true
        });
        setTimeout(() => {
          this.setState({
            successAlert: false
          });
        }, 2000);
        this.getNotify();
      })
      .catch((err) => {
        this.setState({
          resetLoading: false
        });
        console.log(err);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ heightMin: "25vh", position: "relative" }}
            className="complete-ad-div settings-container partner-pref-sec"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                {this.state.isSetInterests &&
                  <div id="filter-bar-desktop" style={{ position: "relative" }}>
                    {this.state.resetLoading ?
                      <p
                        className="reset-all-pref"
                        onClick={this.handleResetPreferences}
                      >
                        <CircularProgress
                          size={15}
                          style={{ color: "#927502" }}
                        />
                      </p>
                    :
                      <p
                        className="reset-all-pref"
                        onClick={this.handleResetPreferences}
                      >
                        {" "}
                        Reset All{" "}
                      </p>
                    }
                  </div>
                }
                <div className="row">
                  <p className="sec-heading"></p>
                  <div className="col">
                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Age Range" : "වයස් සීමාව"}</p>
                      </div>
                      <div className="field-col">
                        <select
                          className="age-range-input"
                          name="minAge"
                          value={(this.state.minAge ? this.state.minAge : "")}
                          onChange={this.handleChange}
                          style={{ width: "38%", marginRight: "4%" }}
                        >
                          <option key="0" value="">
                            Any
                          </option>
                          {this.state.ages.map((age, i) => (
                            <option key={i} value={age}>
                              {age}
                            </option>
                          ))}
                        </select>
                        <select
                          className="age-range-input"
                          name="maxAge"
                          value={this.state.maxAge ? this.state.maxAge : ""}
                          onChange={this.handleChange}
                          style={{ width: "38%" }}
                        >
                          <option key="0" value="">
                            Any
                          </option>
                          {this.state.ages.map((age, i) => (
                            <option key={i} value={age}>
                              {age}
                            </option>
                          ))}
                        </select>
                        <p className="fields-error">{this.state.minAgeError}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Height Range" : "උස සීමාව"}</p>
                      </div>
                      <div className="field-col">
                        <select
                          className="age-range-input"
                          name="heightMin"
                          value={this.state.heightMin ? this.state.heightMin : ""}
                          onChange={this.handleChange}
                          style={{ width: "38%", marginRight: "4%" }}
                        >
                          <option key="0" value="">
                            Any
                          </option>
                          {this.state.heights.map((height, i) => (
                            <option key={i} value={height.value}>
                              {height.name}
                            </option>
                          ))}
                        </select>
                        <select
                          className="age-range-input"
                          name="heightMax"
                          value={this.state.heightMax ? this.state.heightMax : ""}
                          onChange={this.handleChange}
                          style={{ width: "38%" }}
                        >
                          <option key="0" value="">
                            Any
                          </option>
                          {this.state.heights.map((height, i) => (
                            <option key={i} value={height.value}>
                              {height.name}
                            </option>
                          ))}
                        </select>
                        <p className="fields-error">
                          {this.state.maxHeightError}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="" style={{ width: "100%" }}>
                    {Config.ETHNICITY_VISIBILITY && (
                      <div className="field-row">
                      <div className="field-col pref-name-col">
                        <p>{this.props.lng ? "Ethnicity" : "ජාතිය"}</p>
                      </div>
                      <div
                        className="pref-content-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.ethnicities.map((ethnicity) => (
                          <button
                            type="button"
                            className={
                              this.state.checkedEthnicities.indexOf(
                                ethnicity.id
                              ) !== -1
                                ? "pref-chips active"
                                : "pref-chips"
                            }
                            onClick={this.handleEthnicity(ethnicity.id)}
                          >
                            {this.props.lng
                              ? ethnicity.name
                              : ethnicity.name_si}{" "}
                            {this.state.checkedEthnicities.indexOf(
                              ethnicity.id
                            ) !== -1 && <i className="fas fa-check"></i>}
                          </button>
                        ))}
                        <button
                          type="button"
                          className={
                            this.state.ethnicityAny
                              ? "pref-chips active"
                              : "pref-chips"
                          }
                          onClick={() =>
                            this.handleEthnicityAny(this.state.ethnicityAny)
                          }
                        >
                          {this.props.lng ? "Any" : "Any"}{" "}
                          {this.state.ethnicityAny && (
                            <i className="fas fa-check"></i>
                          )}
                        </button>

                        <p className="fields-error">{this.state.minAgeError}</p>
                      </div>
                    </div>
                    )}
                    <div className="field-row">
                      <div className="field-col pref-name-col">
                        <p>{this.props.lng ? "Religion" : "ආගම"}</p>
                      </div>
                      <div
                        className="pref-content-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.religions.map((religion) => (
                          <button
                            type="button"
                            className={
                              this.state.checkedReligions.indexOf(
                                religion.id
                              ) !== -1
                                ? "pref-chips active"
                                : "pref-chips"
                            }
                            onClick={this.handleReligion(religion.id)}
                          >
                            {this.props.lng ? religion.name : religion.name_si}{" "}
                            {this.state.checkedReligions.indexOf(
                              religion.id
                            ) !== -1 && <i className="fas fa-check"></i>}
                          </button>
                        ))}

                        <button
                          type="button"
                          className={
                            this.state.religionAny
                              ? "pref-chips active"
                              : "pref-chips"
                          }
                          onClick={() =>
                            this.handleReligionAny(this.state.religionAny)
                          }
                        >
                          {this.props.lng ? "Any" : "Any"}{" "}
                          {this.state.religionAny && (
                            <i className="fas fa-check"></i>
                          )}
                        </button>

                        <p className="fields-error">{this.state.minAgeError}</p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col pref-name-col">
                        <p>
                          {this.props.lng ? "Civil Status" : "සිවිල් තත්වය"}
                        </p>
                      </div>
                      <div
                        className="pref-content-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.maritalStatuses.map((status) => (
                          <button
                            type="button"
                            className={
                              this.state.checkedStatus.indexOf(status.id) !== -1
                                ? "pref-chips active"
                                : "pref-chips"
                            }
                            onClick={this.handleStatus(status.id)}
                          >
                            {this.props.lng ? status.status : status.status_si}{" "}
                            {this.state.checkedStatus.indexOf(status.id) !==
                              -1 && <i className="fas fa-check"></i>}
                          </button>
                        ))}

                        <button
                          type="button"
                          className={
                            this.state.statusAny
                              ? "pref-chips active"
                              : "pref-chips"
                          }
                          onClick={() =>
                            this.handleStatusAny(this.state.statusAny)
                          }
                        >
                          {this.props.lng ? "Any" : "Any"}{" "}
                          {this.state.statusAny && (
                            <i className="fas fa-check"></i>
                          )}
                        </button>

                        <p className="fields-error">{this.state.minAgeError}</p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col pref-name-col">
                        <p>
                          {this.props.lng ? "Resident Country" : "පදිංචි රට"}
                        </p>
                      </div>
                      <div
                        className="pref-content-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.residentCountries.map((country) => (
                          <button
                            type="button"
                            className={
                              this.state.checkedResidentCountries.indexOf(
                                country.code
                              ) !== -1
                                ? "pref-chips active"
                                : "pref-chips"
                            }
                            onClick={this.handleResidentCountries(country.code)}
                          >
                            {this.props.lng ? country.name : country.name_si}{" "}
                            {this.state.checkedResidentCountries.indexOf(
                              country.code
                            ) !== -1 && <i className="fas fa-check"></i>}
                          </button>
                        ))}

                        <button
                          type="button"
                          className={
                            this.state.residentCountriesAny
                              ? "pref-chips active"
                              : "pref-chips"
                          }
                          onClick={() =>
                            this.handleResidentCountriesAny(
                              this.state.residentCountriesAny
                            )
                          }
                        >
                          {this.props.lng ? "Any" : "Any"}{" "}
                          {this.state.residentCountriesAny && (
                            <i className="fas fa-check"></i>
                          )}
                        </button>

                        <p className="fields-error">{this.state.minAgeError}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ paddingBottom: 70, clear: "both" }}
                    id="address"
                  >
                    {this.state.dissableBtn ? (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="common-save-btn"
                          style={{ float: "right", marginTop: "20px" }}
                          onClick={this.handleSave}
                        >
                          {this.state.loading ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Save"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  තහවුරු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="common-cancel-btn"
                          style={{ float: "right", marginTop: "20px", marginRight: "12px" }}
                          onClick={this.handleCancel}
                        >
                          {this.state.loadingCancel ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Cancel"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  අවලංගු කරන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="edit-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Save"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              තහවුරු කරන්න
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className="cancel-btn-dissable"
                          disabled
                        >
                          {this.props.lng === true ? (
                            "Cancel"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              අවලංගු කරන්න
                            </span>
                          )}
                        </Button>{" "}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Interest Preferences have been changed successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    සාර්ථකව වෙනස් කර ඇත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    දැනුම්දීම් සාර්ථකව වෙනස් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
