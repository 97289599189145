import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StepLabel,
  Stepper,
  Step,
  Paper,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

import "./style.css";
import {
  CREATE_FLOW_STEPS,
  CREATE_FLOW_STEP_META,
  calculateNextStep,
} from "./extensions";
import { setAuthPrevLocation } from "../../../../store/actions/application";

const FlowStepper = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    initialized,
    authTempProfile,
    authAccount,
    previousLocation,
    authProfile,
  } = useSelector((state) => state.application);
  const [activeStep, setActiveStep] = useState(0);

  const getStepIconComponent = (step) => {
    return step <= activeStep
      ? RadioButtonCheckedIcon
      : RadioButtonUncheckedIcon;
  };

  const getStepIconClassName = (step) => {
    return step === activeStep ? "active-step-indicator" : "";
  };

  useEffect(() => {
    if (!initialized || !isEmpty(previousLocation)) {
      return;
    }

    if (!isEmpty(authProfile)) {
      setActiveStep(3);
      return;
    }

    if (!isEmpty(authTempProfile?.postData)) {
      const postData = authTempProfile.postData ?? {};
      const nextStep = calculateNextStep({
        tempProfile: postData,
        authAccount,
      });
      dispatch(setAuthPrevLocation(location));
      history.push(nextStep.meta.route);
    }
  }, [
    authAccount,
    authProfile,
    authTempProfile,
    dispatch,
    history,
    initialized,
    location,
    previousLocation,
  ]);

  useEffect(() => {
    for (const [stepName, meta] of Object.entries(CREATE_FLOW_STEP_META)) {
      if (location.pathname.includes(meta.route)) {
        const step = Object.values(CREATE_FLOW_STEPS).indexOf(stepName);
        if (step !== -1) {
          setActiveStep(step);
        }
      }
    }
  }, [location]);

  return (
    <Paper
      className="create-flow-stepper initialized"
      variant="outlined"
      style={{ paddingTop: initialized ? 8 : 0 }}
    >
      {!initialized && <LinearProgress className="linear-progress" />}
      <Typography align="center" className="stepper-heading">
        {t("createflow.create_a_new_ad")}
      </Typography>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="flow-stepper-root"
      >
        {Object.entries(CREATE_FLOW_STEPS).map(([step, pageName]) => {
          const meta = CREATE_FLOW_STEP_META[pageName];
          const itemStep = parseInt(step);

          return (
            <Step key={step} className={getStepIconClassName(itemStep)}>
              <StepLabel StepIconComponent={getStepIconComponent(itemStep)}>
                {t(meta.localeKey)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Paper>
  );
};

export default FlowStepper;
