import React from "react";
import { Helmet } from "react-helmet";

export default function PageHead(props) {
  return (
    <Helmet>
      <title>
        {props.title}
        {props.name ? props.name : ""}
      </title>
    </Helmet>
  );
}
