import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
  Dialog,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ReactCodeInput from "react-verification-code-input";
import ReactTooltip from "react-tooltip";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from "@material-ui/lab/Alert";
import UpdateEmailModal from "../modals/UpdateEmailModal";
import DeleteEmailModal from "../modals/DeleteEmailModal";
import close from "../../images/close.png";
import "../../styles/settings.css";
import moment from "moment";
import { ACCOUNT_CREATED_BY_OPTIONS } from "../../v2/static-data";
import Config from "../../v2/config";

var timer;

export default class LoginInfo extends Component {
  state = {
    postId: "",
    firstPublishedAt: "",
    createdBy: "",
    phoneNumber: "",
    makePhonePublic: false,
    email: "",
    adId: "",
    emailVerified: false,

    phone: "",
    phoneModal: false,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: sessionStorage.getItem("countryDialCode")
      ? sessionStorage.getItem("countryDialCode")
      : "+94",

    // phone verification
    phoneId: "",
    showPhoneVerification: false,
    timeCountDown: 30,
    showSendOptCode: false,
    sendAgainError: "",
    toManyAttempts: false,

    emailModal: false,
    delemailModal: false,
    updateEmail: "",
    loading: false,

    emailError: "",
    phoneError: "",

    // alerts
    successAlert: false,

    sentSuccess: false,
  };

  componentDidMount = () => {
    this.getLoginData();
    this.getPost();

    // country
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
      .then((res) => {
        const sortedCountries = res.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.setState({
          countries: sortedCountries,
        });
      })
      .catch((err) => console.log(err));

    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', e => {
        const ac = new AbortController();
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          if (otp.code) {
            this.setState({ optCode: otp.code, verificationError: false });
            this.verify();
          }
        }).catch(err => {
          console.log(err);
        });
      });
    }
  };

  componentWillUnmount = () => {
    if ('OTPCredential' in window) {
      window.removeEventListener('DOMContentLoaded', e => {
        const ac = new AbortController();
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          if (otp.code) {
            this.setState({ optCode: otp.code, verificationError: false });
            this.verify();
          }
        }).catch(err => {
          console.log(err);
        });
      });
    }
  }

  getLoginData = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}/owner`;
    } else {
      url = "account";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          createdBy: res.data.accountOwner ? res.data.accountOwner : "",
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
          email: res.data.email ? res.data.email : "",
          emailVerified: res.data.emailVerified
            ? res.data.emailVerified
            : false,
          adId: res.data.memberId,
          emailModal: false,
          delemailModal: false,
          phoneModal: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPost = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        this.setState({
          postId: res.data[0].id,
          makePhonePublic: res.data[0].makePhonePublic
            ? res.data[0].makePhonePublic
            : false,
          firstPublishedAt: res.data[0].firstPublishedAt,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle inputs
  handleChange = (e) => {
    if (e.target.name === "updateEmail") {
      this.setState({
        [e.target.name]: e.target.value.trim(),
        [e.target.name + "Error"]: "",
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      });
    }
  };

  // validate
  validate = () => {
    let emailError = "";

    if (this.state.updateEmail === "") {
      emailError =
        this.props.lng === true
          ? "Email is required"
          : "විද්‍යුත් තැපැල් අවශ්‍ය වේ";
    } else if (
      !this.state.updateEmail.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      )
    ) {
      emailError = this.props.lng
        ? "Email is invalid"
        : "විද්‍යුත් තැපෑල වලංගු නොවේ";
    }

    if (emailError) {
      this.setState({
        emailError,
      });
      return false;
    }

    return true;
  };

  handleUpdateEmail = () => {
    if (this.validate()) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/update_email`,
          `email=${this.state.updateEmail}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          localStorage.setItem("user_email", this.state.updateEmail);
          this.getLoginData();

          this.setState({ successAlert: true, email: "" });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);
        })
        .catch((err) => {
          if (err.response.data.code === 1108) {
            this.setState({
              emailError: this.props.lng
                ? "Email used by other account"
                : "විද්‍යුත් තැපෑල වෙනත් ගිණුමක් දැනටමත් භාවිතා කරයි",
            });
          }
        });
    }
  };

  handleDeleteEmail = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/remove_email`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("user_email", "");  
        this.getLoginData();

        this.setState({ successAlert: true, email: "" });
        setTimeout(() => {
            this.setState({
              successAlert: false,
            });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });

  }

  // login when press the enter key
  handleEmailKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("update-email-btn").click();
    }
  };

  // resend verification email
  handleResendEmail = () => {
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/auth/resend_activation`,
        `email=${this.state.email}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          sentSuccess: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCountryCode = (code) => {
    this.setState({ code, showCountryCodes: false });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ phone: parseInt(str, 10), phoneError: "" });
  };

  // validate
  validatePhone = () => {
    let phoneError = "";

    if (this.state.phone === "") {
      phoneError = this.props.lng
        ? "Phone number is required"
        : "දුරකථන අංකය අවශ්‍ය වේ";
    }

    if (phoneError) {
      this.setState({
        phoneError,
      });
      return false;
    }

    return true;
  };

  optTimer = () => {
    if (this.state.timeCountDown === 0) {
      clearInterval(timer);

      this.setState({ showSendOptCode: true });
    } else {
      this.setState({ timeCountDown: this.state.timeCountDown - 1 });
    }
  };

  handleRegister = () => {
    if (this.validatePhone()) {
      this.setState({
        loading: true,
        toManyAttempts: false,
        verificationError: false,
        sendAgainError: "",
      });

      if (this.state.timeCountDown === 0 || this.state.timeCountDown === 30) {
        let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
        let bb = Math.round((new Date()).getTime() / 1000);
        const tso = bb;
        let aa = "";
        while (bb > 0) {
          let tss = (ALPHABET[bb % ALPHABET.length]).toString();
          aa = aa+tss;
          bb= parseInt(bb / ALPHABET.length);
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
            qs.stringify({
              number: this.state.code + this.state.phone,
              channel: "sms",
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                "x-timestamp": tso,
                "x-client-id": aa,
              },
            }
          )
          .then((res) => {
            this.setState({
              phoneId: res.data.id,
              timeCountDown: res.data.retryIn,
              showSendOptCode: false,
              showPhoneVerification: true,
              loading: false,
            });

            timer = setInterval(this.optTimer, 1000);
          })
          .catch((err) => {
            console.log(err);

            if (err.response.data.code === 1201) {
              this.setState({
                toManyAttempts: true,
              });
            } else if (err.response.data.code === 1000) {
              this.setState({
                phoneError: this.props.lng
                  ? "Could not send sms to this number"
                  : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
              });
            } else if (err.response.data.code === 1203) {
              this.setState({
                phoneError: this.props.lng
                  ? "Invalid phone number format"
                  : "දුරකථන අංකය වැරදිය",
              });
            }
            this.setState({
              showPhoneVerification: false,
              loading: false,
            });
          });
      } else {
        this.setState({
          toManyAttempts: true,
          loading: false,
        });
      }
    }
  };

  sendToVerify = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });
    let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round((new Date()).getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      aa = aa+tss;
      bb= parseInt(bb / ALPHABET.length);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "sms",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not send sms to this number"
              : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
          });
        }
      });
  };

  sendToVerifyByCall = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });
    let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round((new Date()).getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      aa = aa+tss;
      bb= parseInt(bb / ALPHABET.length);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "call",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not make voice call to this number"
              : "මෙම අංකයට ඇමතුමක් ලබා ගැනීමට නොහැකිය",
          });
        }
      });
  };

  handleOPT = (code) => {
    this.setState({ optCode: code, verificationError: false });
  };

  verify = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers/${this.state.phoneId}/verify`,
        qs.stringify({ code: this.state.optCode }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`
          },
        }
      )
      .then((res) => {
        const data = `phoneId=${res.data.id}`;

        // update_phonenumber
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/account/update_phonenumber`,
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              loading: false,
              phoneId: "",
              phone: "",
              showPhoneVerification: false,
              timeCountDown: res.data.retryIn,
              showSendOptCode: false,
              sendAgainError: "",
              toManyAttempts: false,
              successAlert: true,
            });

            setTimeout(() => {
              this.setState({
                successAlert: false,
              });
            }, 2000);

            this.getLoginData();
          })
          .catch((err) => {
            if (err.response.data.code === 1107) {
              this.setState({
                phoneError: this.props.lng
                  ? "Phone number is used by another account"
                  : "දුරකථන අංකය වෙනත් ගිණුමක් භාවිතා කරයි",
                showPhoneVerification: false,
              });
            } else {
              this.setState({
                errorAlert: true,
                errorCode: err.response.data.code,
              });
            }

            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        this.setState({
          verificationError: true,
          loading: false,
        });
      });
  };

  // login when press the enter key
  handleRegisterKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-register-btn").click();
    }
  };

  handleCreatedBy = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    const data = qs.stringify({
      owner: e.target.value,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/account/update_owner`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({ loading: false, successAlert: true });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, failAlert: true });
      });
  };

  handleClose = () => {
    this.setState({
      emailModal: false,
      delemailModal: false,
      phoneModal: false,
      successAlert: false,
      updateEmail: "",
      phone: "",
      emailError: "",
      phoneError: "",
      showCountryCodes: false,
      search: "",
      phoneId: "",
      showPhoneVerification: false,
      timeCountDown: 30,
      showSendOptCode: false,
      sendAgainError: "",
      toManyAttempts: false,
    });
  };

  // handlePublicPhoneCheckout
  handlePublicPhoneCheckout = (e) => {
    this.setState({ loading: true });

    const data = {
      makePhonePublic: e.target.checked,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false, successAlert: true });
        this.getPost();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, failAlert: true });
      });
  };

  render() {
    const filteredCountries = this.state.countries.filter((country) => {
      return (
        country.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 || country.dialCode.includes(this.state.search)
      );
    });

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div settings-container"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <p className="sec-heading" style={{ marginBottom: 5 }}>
                    {this.props.lng ? (
                      "Contact/Login Information"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        සම්බන්ධතා / පිවිසුම් තොරතුරු
                      </span>
                    )}
                  </p>
                  <p className="heading-desc">
                    {this.props.lng ? (
                      "Only a verified email/phone number can be used to login to an ad account."
                    ) : (
                      <span className="sinhala-size-11">
                        දැන්වීම් ගිණුමකට ප්‍රවේශ වීම සඳහා භාවිතා කළ හැක්කේ
                        තහවුරු කරන ලද විද්‍යුත් තැපැලක් / දුරකථන අංකයක් පමණි.
                      </span>
                    )}
                  </p>
                  <div className="col">
                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Phone Number" : "දුරකතන අංකය"}</p>
                      </div>
                      <div
                        className="field-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.phoneNumber && (
                          <span className="verified">
                            <i className="fas fa-check-circle"></i> Verified
                          </span>
                        )}
                        <input
                          type="text"
                          name="phone"
                          value={this.state.phoneNumber}
                          disabled
                        />
                        <span
                          className="edit-phone-email"
                          onClick={() => this.setState({ phoneModal: true })}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </span>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="checkedI"
                              value={this.state.makePhonePublic}
                              onChange={this.handlePublicPhoneCheckout}
                              checked={this.state.makePhonePublic}
                            />
                          }
                          label={
                            this.state.loading
                              ? "Updating..."
                              : this.props.lng
                              ? "Share phone number with public"
                              : "දුරකථන අංකය ප්‍රසිද්ධියේ පෙන්වන්න"
                          }
                          className="make-public-phone-checkbox"
                        />
                        {this.state.makePhonePublic && (
                          <p className="field-info" style={{ marginTop: 0 }}>
                            <i className="fas fa-exclamation-triangle"></i>{" "}
                            {this.props.lng
                              ? "Your phone number will be visible to all website visitors."
                              : "ඔබගේ දුරකථන අංකය වෙබ් අඩවියේ සියලූම පාර්ශවයන්ට පෙන්වනු ඇත."}
                          </p>
                        )}
                        <p className="fields-error"></p>
                      </div>
                    </div>
                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Email" : "විද්‍යුත් තැපෑල"}</p>
                      </div>
                      <div
                        className="field-col"
                        style={{ position: "relative" }}
                      >
                        {this.state.email && this.state.emailVerified ? (
                          <span className="verified">
                            <i className="fas fa-check-circle"></i> Verified
                          </span>
                        ) : this.state.email && !this.state.emailVerified ? (
                          <span className="not-verified">
                            <i className="fas fa-exclamation-triangle"></i> Not Verified
                          </span>
                        ) : null}
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          disabled
                          className="email-input"
                        />
                        <span
                          className="edit-phone-email"
                          onClick={() => this.setState({ emailModal: true })}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </span>
                        { this.state.email &&
                          <span
                            className="delete-phone-email"
                            onClick={() => this.setState({ delemailModal: true })}
                          >
                            <i className="fas fa-trash"></i>
                          </span>
                        }
                        {this.state.email && !this.state.emailVerified ? (
                          this.state.sentSuccess ? (
                            <p
                              className="sent-success"
                              onClick={this.handleResendEmail}
                            >
                              <i className="fas fa-check-circle"></i> Sent
                            </p>
                          ) : (
                            <p
                              className="resend-verify-link"
                              onClick={this.handleResendEmail}
                            >
                              Resend verification email
                            </p>
                          )
                        ) : null}

                        <p className="fields-error"></p>
                      </div>
                    </div>

                    <div className="field-row">
                      <div className="field-col">
                        <p>{this.props.lng ? "Ad ID" : "දැන්වීම් කේතය"}</p>
                      </div>
                      <div
                        className="field-col"
                        style={{ position: "relative" }}
                      >
                        <input
                          type="text"
                          name="adId"
                          value={this.state.adId}
                          disabled
                        />{" "}
                        <i
                          className="fas fa-info-circle ad-id-tooltip-icon"
                          data-tip={ Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (this.props.lng === true
                            ? `Ad ID(Login ID) is unique to your ad account and assigned by ${Config.DOMAIN_DISPLAY_NAME}. Refer this when you contact us. `
                            : "දැන්වීම් කේතය (පිවිසුම් කේතය) ඔබගේ ගිණුමටම ආදාල වේ") : this.props.lng === true
                            ? `Ad ID(Login ID) is unique to your ad account and assigned by ${Config.DOMAIN_DISPLAY_NAME}. Refer this when you contact us. `
                            : "දැන්වීම් කේතය (පිවිසුම් කේතය) ඔබගේ ගිණුමටම ආදාල වේ"
                            
                          }
                        ></i>
                        <p className="fields-error"></p>
                      </div>
                    </div>
                    <div className="field-row">
                      <div className="field-col">
                        <p>
                          {this.props.lng
                            ? "Account created by"
                            : "ගිණුම සාදන ලද්දේ"}
                        </p>
                      </div>
                      <div
                        className="field-col"
                        style={{ position: "relative" }}
                      >
                        <select
                          name="createdBy"
                          value={this.state.createdBy}
                          onChange={this.handleCreatedBy}
                          disabled={moment().isAfter(moment(this.state.firstPublishedAt).add(3, "days"))}
                        >
                          <option selected hidden value="-- Select --">
                            {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                          </option>
                          {ACCOUNT_CREATED_BY_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {this.props.lng ? option.label : option.label_si}
                            </option>
                          ))}
                        </select>
                        <p className="fields-error"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </React.Fragment>
            )}
            <ReactTooltip className="tooltip-cus" />

            <UpdateEmailModal
              emailModal={this.state.emailModal}
              email={this.state.email}
              updateEmail={this.state.updateEmail}
              emailError={this.state.emailError}
              handleChange={this.handleChange}
              handleClose={this.handleClose}
              handleUpdateEmail={this.handleUpdateEmail}
              lng={this.props.lng}
              loading={this.state.loading}
              handleEmailKeyUp={this.handleEmailKeyUp}
            />

            <DeleteEmailModal
              delemailModal={this.state.delemailModal}
              handleClose={this.handleClose}
              handleDeleteEmail = {this.handleDeleteEmail}
              lng={this.props.lng}
              loading={this.state.loading}
            />

            <Dialog
              open={this.state.phoneModal}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              className="login-modal"
            >
              <div
                className="create-login-div login-div-container"
                style={{ margin: 0 }}
              >
                <img
                  src={close}
                  alt="close"
                  className="close-icon"
                  onClick={this.handleClose}
                />

                {!this.state.showPhoneVerification ? (
                  <React.Fragment>
                    {this.state.showCountryCodes ? null : (
                      <React.Fragment>
                        <p className="modal-heading">
                          {this.state.phoneNumber ? (
                            this.props.lng === true ? (
                              "Update Phone Number"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-14">
                                දුරකතන අංකය යාවත්කාලීන කරන්න
                              </span>
                            )
                          ) : this.props.lng === true ? (
                            "Add Phone Number"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              දුරකතන අංකය ඇතුලත් කරන්න
                            </span>
                          )}
                        </p>
                      </React.Fragment>
                    )}

                    <React.Fragment>
                      {!this.state.showCountryCodes && (
                        <p className="input-headings">
                          {this.props.lng ? (
                            "Phone Number"
                          ) : (
                            <span className="sinhala-size-12">දුරකතන අංකය</span>
                          )}
                        </p>
                      )}

                      {this.state.showCountryCodes ? (
                        <React.Fragment>
                          <p
                            className="login-signup-back"
                            onClick={() =>
                              this.setState({ showCountryCodes: false })
                            }
                          >
                            <i className="fas fa-chevron-left"></i>{" "}
                            {this.props.lng === true ? (
                              "BACK"
                            ) : (
                              <span className="sinhala-size-12">ආපසු යන්න</span>
                            )}
                          </p>
                          <p className="modal-heading" style={{ border: 0 }}>
                            {this.props.lng ? (
                              "Select Phone Dialing Code"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-17">
                                දුරකථන ඇමතුම් කේතය තෝරන්න
                              </span>
                            )}
                          </p>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              name="search"
                              className="search-county-code-input"
                              placeholder="Search"
                              value={this.state.search}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <i className="fas fa-search code-search-icon"></i>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <input
                            className="phone-input-country-code"
                            onClick={() =>
                              this.setState({ showCountryCodes: true })
                            }
                            value={this.state.code}
                          />
                          <input
                            type="number"
                            name="phone"
                            className={
                              this.state.phoneError
                                ? "phone-input-custom red-border"
                                : "phone-input-custom"
                            }
                            value={this.state.phone}
                            onChange={this.handlePhone}
                            onKeyUp={this.handleRegisterKeyUp}
                            autoComplete="off"
                          />
                          <div className="error-handler">
                            {this.state.phoneError}
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.showCountryCodes && (
                        <div className="country-code-div">
                          {filteredCountries.map((code, i) => (
                            <p
                              key={i}
                              onClick={() =>
                                this.handleCountryCode(code.dialCode)
                              }
                            >
                              <span>{code.name}</span>{" "}
                              <span>{code.dialCode}</span>
                            </p>
                          ))}
                        </div>
                      )}
                    </React.Fragment>

                    {this.state.showCountryCodes ? null : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="update-btn"
                          onClick={this.handleRegister}
                          id="user-register-btn"
                        >
                          {this.state.loading ? (
                            <React.Fragment>
                              <CircularProgress
                                size={16}
                                style={{ color: "#fff" }}
                              />{" "}
                            </React.Fragment>
                          ) : this.props.lng ? (
                            "Continue"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              ඉදිරියට
                            </span>
                          )}
                        </Button>
                        {this.state.toManyAttempts && (
                          <p
                            style={{
                              fontSize: 12,
                              color: "#f44336",
                              marginRight: 12,
                              marginBottom: 0,
                              textAlign: "center",
                            }}
                          >
                            {this.props.lng
                              ? this.state.timeCountDown !== 0
                                ? `* Too many attempts. Please try again in 
                            ${this.state.timeCountDown} seconds.`
                                : ""
                              : this.state.timeCountDown !== 0
                              ? `* කරුණාකර තත්පර ${this.state.timeCountDown} කින් නැවත උත්සාහ කරන්න.`
                              : ``}
                          </p>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p
                      className="modal-heading"
                      style={{
                        textAlign: "center",
                        border: 0,
                        marginBottom: 25,
                      }}
                    >
                      {this.props.lng ? (
                        "Verify Phone Number"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දුරකථන අංකය තහවුරු කරන්න
                        </span>
                      )}
                    </p>

                    <p className="your-phone">
                      {this.props.lng
                        ? "Your Phone Number"
                        : "ඔබගේ දුරකථන අංකය"}
                    </p>
                    <p className="phone">
                      {this.state.code} {this.state.phone}
                    </p>
                    <p
                      className="change"
                      onClick={() =>
                        this.setState({ showPhoneVerification: false })
                      }
                    >
                      {this.props.lng ? "(Change)" : "වෙනස් කරන්න"}
                    </p>
                    <p className="enter-code">
                      {this.props.lng ? "Enter Code" : "කේතය ඇතුලත් කරන්න"}{" "}
                    </p>
                    <ReactCodeInput
                      fields={4}
                      onChange={(val) => this.handleOPT(val)}
                    />
                    <p className="opt-error" style={{ textAlign: "center" }}>
                      {this.state.verificationError
                        ? this.props.lng
                          ? "Verification code is invalid"
                          : "කේතය වලංගු නොවේ"
                        : null}
                    </p>
                    <p className="opt">
                      {this.props.lng
                        ? "Didn't receive OTP?"
                        : "OTP ලැබුණේ නැද්ද?"}
                    </p>
                    <p className="opt" style={{ marginTop: 2 }}>
                      {this.state.showSendOptCode ? (
                        <span
                          onClick={this.sendToVerify}
                          style={{ cursor: "pointer" }}
                        >
                          {this.props.lng ? (
                            "Send again"
                          ) : (
                            <span className="sinhala-w-600">නැවත යවන්න</span>
                          )}
                        </span>
                      ) : (
                        <span style={{ textDecoration: "none" }}>
                          {this.props.lng ? "Retry in" : "තත්පර"}{" "}
                          {this.state.timeCountDown}
                          {this.props.lng ? "s" : "කින් පසුව උත්සහ කරන්න"}
                        </span>
                      )}
                      {/* {this.state.showSendOptCode ? (
                        <React.Fragment>
                          &nbsp; | &nbsp;
                          <span
                            onClick={this.sendToVerifyByCall}
                            style={{ cursor: "pointer" }}
                          >
                            {this.props.lng ? (
                              "Get Code via Phone Call"
                            ) : (
                              <span className="sinhala-w-600">
                                දුරකථන ඇමතුමක් හරහා කේතය ලබා ගන්න
                              </span>
                            )}
                          </span>
                        </React.Fragment>
                      ) : null} */}
                    </p>
                    {this.state.sendAgainError && (
                      <p
                        style={{
                          fontSize: 12,
                          color: "#f44336",
                          marginRight: 12,
                          marginTop: 5,
                        }}
                      >
                        * {this.state.sendAgainError}
                      </p>
                    )}

                    <Button
                      variant="contained"
                      className="update-btn"
                      onClick={this.verify}
                    >
                      {this.state.loading ? (
                        <React.Fragment>
                          <CircularProgress
                            size={16}
                            style={{ color: "#fff" }}
                          />
                        </React.Fragment>
                      ) : this.state.phoneNumber ? (
                        this.props.lng === true ? (
                          "Update"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-10">
                            යාවත්කාලීන කරන්න
                          </span>
                        )
                      ) : this.props.lng === true ? (
                        "Add"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          එකතු කරන්න
                        </span>
                      )}
                    </Button>
                    <p
                      className="back"
                      onClick={() =>
                        this.setState({ showPhoneVerification: false })
                      }
                    >
                      {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
                    </p>
                  </React.Fragment>
                )}
              </div>
            </Dialog>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Change has been applied successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    වෙනස් කිරීම සාර්ථකව යෙදී ඇත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
