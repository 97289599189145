import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

export default class FreeRenewModal extends Component {
  render() {
    return (
      <Dialog
        open={this.props.confirmRenewalModal}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
          {this.props.lng ? (
            "Renew Your Ad!"
          ) : (
            <span className="sinhala-w-600">දැන්වීම අලුත් කරන්න</span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p style={{ marginTop: 0 }}>
              {this.props.lng ? (
                "Your are about to renew your ad."
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  ඔබගේ මංගල දැන්වීම අලුත් කරනු ඇත.
                </span>
              )}
            </p>
            <p style={{ marginTop: 0 }}>
              {this.props.lng ? (
                "Note: You'll lose any remaining days from current ad validity period."
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  වැදගත්: ඔබගේ වර්ථමාන වලංගු කාලයේ ඉතිරි දින තිබේ නම් ඒවා
                  ඉදිරියට ගෙන යනු නොලැබේ.
                </span>
              )}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            {this.props.lng ? "Cancel" : "අවලංගු කරන්න"}
          </Button>
          <Button
            onClick={this.props.freeRenew}
            style={{ background: "#000", color: "#fff" }}
            autoFocus
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <span>
                {this.props.lng ? (
                  "Renew"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    අලුත් කරන්න
                  </span>
                )}
              </span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
