import { isEmpty } from "lodash";
import Config from "../../../../../config";
import { isEmptyOrNullOrUndefined } from "../../../../../utils";

const PERSONAL_INFORMATIONS = "PERSONAL_INFORMATIONS";
const PARENTS_INFORMATIONS = "PATENTS_INFORMATIONS";
const PRIVATE_INFORMATIONS = "PRIVATE_INFORMATIONS";
const REVIEW_AND_PAY = "REVIEW_AND_PAY";

const ADDITIOANL_COMMUNITY_OPTIONS = ["OTHER", "UNDISCLOSED"];

export const CREATE_FLOW_STEPS = Object.freeze({
  0: PERSONAL_INFORMATIONS,
  1: PARENTS_INFORMATIONS,
  2: PRIVATE_INFORMATIONS,
  3: REVIEW_AND_PAY,
});

export const CREATE_FLOW_STEP_META = Object.freeze({
  [PERSONAL_INFORMATIONS]: {
    label: "Personal",
    route: "complete-ad-personal",
    localeKey: "common.personal",
  },
  [PRIVATE_INFORMATIONS]: {
    label: "Private",
    route: "complete-ad-private",
    localeKey: "common.private",
  },
  [PARENTS_INFORMATIONS]: {
    label: "Parents",
    route: "complete-ad-parent",
    localeKey: "common.parents",
  },
  [REVIEW_AND_PAY]: {
    label: "Review & Pay",
    route: "complete-ad-review",
    localeKey: "common.summery",
  },
});

const getRequiredFieldValues = ({ tempProfile, authAccount, section }) => {
  const { personalInfo, parentInfo } = tempProfile;
  const requiredValues = [];
  switch (section) {
    case PERSONAL_INFORMATIONS:
      requiredValues.push(personalInfo?.fname);
      requiredValues.push(personalInfo?.lname);
      requiredValues.push(personalInfo?.displayNameFormat);
      requiredValues.push(personalInfo?.gender);
      requiredValues.push(personalInfo?.dob);
      requiredValues.push(personalInfo?.height);
      requiredValues.push(personalInfo?.religionId);
      requiredValues.push(personalInfo?.civilStatusId);
      requiredValues.push(personalInfo?.educationLevelId);
      requiredValues.push(personalInfo?.professionId);
      requiredValues.push(personalInfo?.drinking);
      requiredValues.push(personalInfo?.smoking);
      requiredValues.push(personalInfo?.foodPreferenceId);

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
        requiredValues.push(personalInfo?.ethnicityId);
      }

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        if (
          !ADDITIOANL_COMMUNITY_OPTIONS.includes(personalInfo.communityOther)
        ) {
          requiredValues.push(personalInfo?.communityId);
        }
        requiredValues.push(personalInfo?.motherTongueId);
      }
      break;
    case PARENTS_INFORMATIONS:
      if (Array.isArray(parentInfo) && !isEmpty(parentInfo)) {
        for (const parent of parentInfo) {
          const instanceFields = [];
          instanceFields.push(parent.religionId);
          instanceFields.push(parent.residentCountryCode);

          if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
            instanceFields.push(parent.ethnicityId);
          }

          if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
            instanceFields.push(parent.communityId);
          }
          requiredValues.push(instanceFields);
        }
      }
      break;
    case PRIVATE_INFORMATIONS:
      requiredValues.push(personalInfo?.originCountryCode);

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
        requiredValues.push(authAccount.userPreferences?.showOfflineId);
        requiredValues.push(authAccount.userPreferences?.subscribeMagazine);
      }
      break;
    default:
      break;
  }

  return requiredValues;
};

export const calculateNextStep = ({ tempProfile, authAccount }) => {
  for (const [step, metaName] of Object.entries(CREATE_FLOW_STEPS)) {
    const meta = CREATE_FLOW_STEP_META[metaName];
    const output = { step, meta };
    const fieldValues = getRequiredFieldValues({
      tempProfile,
      authAccount,
      section: metaName,
    });
    switch (metaName) {
      case PERSONAL_INFORMATIONS: {
        const shouldRedirect = fieldValues.some((value) =>
          isEmptyOrNullOrUndefined(value)
        );
        if (shouldRedirect) {
          return output;
        }
        break;
      }
      case PARENTS_INFORMATIONS: {
        const shouldRedirect =
          isEmpty(fieldValues) ||
          fieldValues.some((value) => isEmptyOrNullOrUndefined(value));
        if (shouldRedirect) {
          return output;
        }
        break;
      }
      case PRIVATE_INFORMATIONS: {
        const shouldRedirect = fieldValues.some((value) =>
          isEmptyOrNullOrUndefined(value)
        );
        if (shouldRedirect) {
          return output;
        }
        break;
      }
      default:
        return output;
    }
  }
};
