import React from "react";
import "./style.css";

const TermsSegment = ({
  title,
  paragraphs = [],
  alphaList = [],
  bulletPoints = [],
  bottomParagraphs = [],
}) => {
  const HtmlRenderer = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  return (
    <>
      <h4 className="terms-segment-title-text">{title}</h4>

      {paragraphs.map((paragraph) => (
        <p
          style={{
            margin: "1rem 0 0 1rem",
            lineHeight: 1.8,
          }}
          className="terms-segment-primary-text"
        >
          <HtmlRenderer htmlString={paragraph} />
        </p>
      ))}
      <ul type="disc" style={{ margin: 0, padding: 0 }}>
        {bulletPoints.map((item, index) => (
          <li
            key={index}
            className="terms-segment-primary-text"
            style={{
              margin: "1rem 0 1rem 2rem",
              lineHeight: 1.4,
            }}
          >
            {item}
          </li>
        ))}
      </ul>

      <ol type="a" style={{ margin: 0, padding: 0 }}>
        {alphaList.map((item, index) => (
          <li
            key={index}
            className="privacy-content-primary-text"
            style={{
              margin: paragraphs.length > 0 ? "1rem 0 1rem 3rem" : "1rem 0 1rem 2rem",
              lineHeight: 1.4,
            }}
          >
            {item}
          </li>
        ))}
      </ol>
      {bottomParagraphs.map((paragraph) => (
        <p
          style={{
            margin: "1rem 0 0 1rem",
            lineHeight: 1.8,
          }}
          className="terms-segment-primary-text"
        >
          <HtmlRenderer htmlString={paragraph} />
        </p>
      ))}
    </>
  );
};

export default TermsSegment;
