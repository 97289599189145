import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";

import groom from "../../images/groom.png";
import bride from "../../images/bride.png";
import topAdBadge from "../../images/topad-badge.png";

import "../../styles/settings.css";

import FreeRenewalModal from "../modals/FreeRenewModal";
import ProfileStats from "../settings/ProfileStats";
import ManageSubscription from "./manage-subscription";


var getAge = require("get-age");

class Status extends Component {
  state = {
    post: localStorage.getItem("post_details") 
      ? JSON.parse(localStorage.getItem("post_details")) 
      : undefined,
    expiresAt: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).expiresAt
      : "",
    freeRenewEligible: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).freeRenewEligible
      : false,
    freeRenewDone: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).freeRenewDone
      : false,
    gender: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.gender
      : "PONTIN",
    fName: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.fname
      : localStorage.getItem("user_name")
      ? localStorage.getItem("user_name")
      : "",
    lName: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.lname
      : "",
    city: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo
          .residentCity
      : "",
    dob: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.dob
      : "",
    height: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.height
      : "",
    occupation: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.profession
        ? JSON.parse(localStorage.getItem("post_details")).personalInfo
            .profession.id
        : ""
      : "",
    typedOccupation: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo
          .professionOther
      : "",
    profession: "",
    profession_si: "",
    postId: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).id
      : "",
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "",
    postStatus: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).status
      : "INCOMPLETE",

    confirmRenewalModal: false,
    loading: false,
    isFeatured: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).isFeatured
      : false,
    featureUntil: localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isFeatured
      ? JSON.parse(localStorage.getItem("post_details")).featureUntil
      : "",
    country: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.residentCountry.code
      : "LK"
  };

  componentDidMount = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        if (res.data.length > 0) {
          this.setState({
            expiresAt: res.data[0].expiresAt,
            freeRenewEligible: res.data[0].freeRenewEligible,
            freeRenewDone: res.data[0].freeRenewDone,
            gender: res.data[0].personalInfo.gender,
            postId: res.data[0].id,
            postStatus: res.data[0].status,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // profession
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/professions`)
      .then((res) => {
        const filterProf = res.data.filter(
          (filter) => filter.id === this.state.occupation
        );

        if (filterProf.length > 0) {
          this.setState({
            profession: filterProf[0].name,
            profession_si: filterProf[0].name_si,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  freeRenew = () => {
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/free_renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let url = "";if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          url = `operator/managed_posts/${localStorage.getItem("postId")}`;
        } else {
          url = "account/posts";
        }
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((res) => {
            if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
              res.data[0] = res.data;
            }
            if (res.data.length > 0) {
              localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            }
            this.setState({
              expiresAt: res.data[0].expiresAt,
              freeRenewDone: res.data[0].freeRenewDone,
              postStatus: res.data[0].status,
              confirmRenewalModal: false,
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleClose = () => {
    this.setState({ confirmRenewalModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <div className="col settings-div" style={{ width: "100%" }}>
                    <p
                      style={{
                        fontSize: 12,
                        lineHeight: "14px",
                        fontWeight: 300,
                        marginTop: 0,
                        marginBottom: 2,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      {this.props.lng ? "Ad ID" : "දැන්වීම් කේතය"}
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        lineHeight: "19px",
                        fontWeight: 500,
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      {this.state.userId}
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        lineHeight: "19px",
                        fontWeight: 500,
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "2px"
                      }}
                    >
                      {this.state.gender === "MALE" ? (
                        <img
                          src={groom}
                          className="applicant-photo"
                          alt="groom"
                          style={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #cca326",
                            borderRadius: "50%"
                          }}
                        />
                      ) : (
                        <img
                          src={bride}
                          className="applicant-photo"
                          alt="bride"
                          style={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #cca326",
                            borderRadius: "50%"
                          }}
                        />
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        lineHeight: "19px",
                        fontWeight: 500,
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0px"
                      }}
                    >
                      <Typography variant="h6" className="name">
                          <span style={{ textTransform: "capitalize" }}>
                            {this.state.fName} {this.state.lName}
                            {this.state.isFeatured &&
                              <span
                                style={{position:"absolute", marginTop: "4px"}}>
                                <img src={topAdBadge} alt="top ad" className="top-ad-badge" style={{height: "23px", width: "26px", border: "none"}} />
                              </span>
                            }
                          </span>
                        </Typography>
                    </p>
                    <p className="location"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#333333",
                        marginBottom: "0px"
                      }}
                    >
                      {this.state.dob && (
                        <React.Fragment>
                          {getAge(this.state.dob)}{" "}
                          <span style={{ color: "#8c8c8c" }}>&nbsp;&#8226;&nbsp;</span>{" "}
                          {this.state.city}{" "}
                          <span style={{ color: "#8c8c8c" }}>&nbsp;&#8226;&nbsp;</span>{" "}
                          {this.state.typedOccupation
                            ? this.state.typedOccupation
                            : this.props.lng
                            ? this.state.profession
                            : this.state.profession_si}{" "}
                          <span style={{ color: "#8c8c8c" }}>&nbsp;&#8226;&nbsp;</span>{" "}
                          {Math.floor(
                            Math.round(this.state.height / 2.54) / 12
                          )}
                          ' {Math.round(this.state.height / 2.54) % 12}"
                        </React.Fragment>
                      )}
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "13px",
                        marginBottom: "0px"
                      }}
                    >
                      {this.state.postStatus === "LIVE" ? (
                        <p className="status-lable live">
                          {this.props.lng === true ? (
                            "Live"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              සජීවි
                            </span>
                          )}{" "}
                        </p>
                      ) : this.state.postStatus === "PAYMENT_PENDING" ? (
                        <p className="status-lable pending-payment">
                          {this.props.lng === true ? (
                            "Payment Pending"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              ගෙවීම් අපේක්ෂිතය
                            </span>
                          )}
                        </p>
                      ) : this.state.postStatus === "IN_REVIEW" ? (
                        <p className="status-lable in-review">
                          {this.props.lng === true ? (
                            "In Review"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              සමාලෝචනය කරමින්
                            </span>
                          )}
                        </p>
                      ) : this.state.postStatus === "DISABLED" ? (
                        <p className="status-lable unpublished">
                          {this.props.lng === true ? (
                            "Unpublished"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              ඉවත් කර ඇත
                            </span>
                          )}
                        </p>
                      ) : this.state.postStatus === "EXPIRED" ? (
                        <p className="status-lable expired">
                          {this.props.lng === true ? (
                            "Expired"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              කල් ඉකුත් වී ඇත
                            </span>
                          )}
                        </p>
                      ) : (
                        <p className="status-lable incomplete">
                          {this.props.lng === true ? (
                            "Incomplete"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-10">
                              අසම්පූර්ණයි
                            </span>
                          )}
                        </p>
                      )}
                    </p>
                    <p style={{marginTop: "0"}}>
                      {this.state.postStatus === "LIVE" && (
                        <p className="status-desc">
                          {this.props.lng
                            ? `Your Ad is active for ${Math.round(moment(this.state.expiresAt).diff(moment.utc(), "days"))} days`
                            : `ඔබේ දැන්වීම දින ${Math.round(moment(this.state.expiresAt).diff(moment.utc(), "days"))}ක් සක්‍රීයයි`
                          }
                        </p>
                      )}
                      {/* {this.state.postStatus === "PAYMENT_PENDING" && (
                        <p className="status-desc">
                          {this.props.lng
                            ? "Select the most convenient payment method for you"
                            : "ඔබ සඳහා වඩාත් පහසු ගෙවීම් ක්‍රමය තෝරන්න"}
                        </p>
                      )} */}
                      {this.state.postStatus === "INCOMPLETE" && (
                        <p className="status-desc">
                          {this.props.lng
                            ? "Complete your ad and start connecting"
                            : "ඔබේ දැන්වීම සම්පූර්ණ කර සම්බන්ධ කිරීම ආරම්භ කරන්න"}
                        </p>
                      )}
                      {this.state.postStatus === "IN_REVIEW" && (
                        <p className="status-desc">
                          {this.props.lng
                            ? "It will take up to 12 hours for your ad to be reviewed and approved."
                            : "ඔබේ දැන්වීම සමාලෝචනය කර අනුමත කිරීමට පැය 12 ක් ගතවනු ඇත"}
                        </p>
                      )}
                    </p>
                    {localStorage.getItem("postId") && <ProfileStats lng={this.props.lng} />}
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginTop: "0px",
                        marginBottom: "0px"
                      }}
                      >
                      {this.state.postStatus === "INCOMPLETE" ? null : (
                        <div>
                          <Link to="/my-ad" className="edit-my-ad-link" style={{marginRight: "20px", fontSize: "14px"}}>
                            {this.props.lng
                              ? "View My Ad"
                              : "මගේ දැන්වීම බලන්න"}
                          </Link>
                          <Link to="/edit-my-ad" className="edit-my-ad-link" style={{fontSize: "14px"}}>
                          {this.props.lng
                            ? "Edit My Ad"
                            : "දැන්වීම වෙනස් කරන්න"}
                          </Link>
                        </div>
                      )}
                    </p>
                    <hr style={{color: "#BDBDBD", borderTop: "none"}} />
                    <ManageSubscription  
                      post={this.state.post} 
                      language={this.props.lng} 
                      history={this.props.history} 
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            <FreeRenewalModal
              confirmRenewalModal={this.state.confirmRenewalModal}
              handleClose={this.handleClose}
              freeRenew={this.freeRenew}
              lng={this.props.lng}
              loading={this.state.loading}
            />
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(Status);
