import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import "./style.css";
import AppContext from "../../../context";

const LabelColumn = ({ value, visibility = true }) => {
  const { appLanguage } = useContext(AppContext);

  return (
    visibility && (
      <Grid container>
        <Typography className={`label-column label-column-${appLanguage}`}>
          {value || "-"}
        </Typography>
      </Grid>
    )
  );
};

export default LabelColumn;
