import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import NewFeatureBanner from "../components/banners/NewFeatureBanner";
import GoogleReviewBanner from "../components/banners/GoogleReviewBanner";
import HelpBar from "../components/help-bar/HelpBar";
import Footer from "../components/common/Footer";
import Container from "../components/home/Container";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import FooterMobile from "../components/common/FooterMobile";
import NICBanner from "../components/banners/NICBanner";
// import HytchAwarenessBanner from "../components/hytch-awareness-banner";
import PageMetaDescription from "../components/common/PageMetaDescription";
import Constants from "../v2/config/constant";
// import AppContext from "../v2/context/index.js";
// import AppLogger from "../v2/utils/logger/index.js";
// import SupportServiceUnavailableBanner from "../components/banners/SupportServiceUnavailable.jsx";

import ProfileCompletionAlert from "../components/banners/profile-completion-alert/index.jsx";
import withLanguageMigrator from "../v2/hoc/language-migrator/index.jsx";
import "../styles/all-ads.css";
import "../v2/pages/home/style.css";

const HomePageNew = ({ lng }) => {
  const { authProfile  } = useSelector(state => state.application);

  return (
    <>
      <PageHead
        title={`${Config.DOMAIN_DISPLAY_NAME} | ${
          Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
            ? "Sri Lankan Matrimony"
            : "Indian Matrimony"
        }`}
      />
      <PageMetaDescription name="Home" content={Constants.META_DESCRIPTION} />
      {/* <SupportServiceUnavailableBanner lng={lng} /> */}
      <ProfileCompletionAlert lng={lng} />
      {!isEmpty(authProfile) && (
        <NICBanner lng={lng} profile={authProfile} />
      )}

      {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
        <GoogleReviewBanner lng={lng} />
      )}
      <NewFeatureBanner lng={lng} />
      <HelpBar />
      {/* {userProfile !== undefined &&
            userProfile.personalInfo?.gender === "FEMALE" &&
            userProfile.createdBy === "SELF" &&
            ENABLE_HYTCH && (
              <div
                style={{
                  backgroundColor: "#dfdfdf",
                  paddingBottom: 10,
                  paddingTop: 10,
                }}
              >
                <HytchAwarenessBanner />
              </div>
            )} */}
      <div
        className="all-ads-main-container"
        style={{ marginTop: 0, paddingTop: 1 }}
      >
        <Grid container xs={12} sm={10} md={8} className="all-ads-container">
          <Container lng={lng} />
        </Grid>
      </div>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

// const FunctionalProxy = ({ children }) => {
//   // const { snackNotification } = useSelector((state) => state.application);

//   return (
//     <>
//       {children}
//       {/* <NotificationBar
//         severity={snackNotification.severity}
//         message={snackNotification.message}
//       /> */}
//     </>
//   );
// };

// @deprecated implementation remove when stable HomeV2 functional component
// class Home extends Component {
//   state = {
//     // showMagazineBanner: false,
//     // showSupportBanner: "0",
//     // localHideBanner: false,
//     // isDev: false,
//     // isMobileDevice: false,
//     // currentUserProfile: undefined,
//     // allowNICbannerFromDate: false,
//     // steps: [
//     //   {
//     //     target: "#logged_msg_icon",
//     //     disableBeacon: true,
//     //     content: "Check your messeges here",
//     //   },
//     //   {
//     //     target: "#logged_menu_btn",
//     //     content: "Main menu",
//     //   },
//     //   {
//     //     target: ".side-panel-filters",
//     //     content: "This is the filter options",
//     //     placement: "right-start",
//     //   },
//     //   {
//     //     target: ".search-results",
//     //     content: "Preferred search option",
//     //   },
//     //   {
//     //     target: "#first-post",
//     //     content: "Search result posts",
//     //     placement: "right-start",
//     //   },
//     // ],
//     // metaContent: Constants.META_DESCRIPTION,
//     post: {},
//   };

//   componentDidMount = () => {
//     // if (this.state.post !== null) {
//     //   const userProfile = this.state.post;
//     //   this.setState({ currentUserProfile: userProfile });
//     // }
//     // if (localStorage.getItem("magazine-banner") === "false") {
//     //   this.setState({ showMagazineBanner: false });
//     // } else {
//     //   this.setState({ showMagazineBanner: true });
//     // }
//     // if (localStorage.getItem("support-banner") === "0") {
//     //   this.setState({ showSupportBanner: "0" });
//     // } else if (localStorage.getItem("support-banner") === "1") {
//     //   this.setState({ showSupportBanner: "1" });
//     // } else if (localStorage.getItem("support-banner") === "2") {
//     //   this.setState({ showSupportBanner: "2" });
//     // } else {
//     //   this.setState({ showSupportBanner: "0" });
//     //   localStorage.setItem("support-banner", "0");
//     // }
//     // this.setState({
//     //   isDev: Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP,
//     // });
//   };

//   // handleCloseMagazineBanner = () => {
//   //   localStorage.setItem("magazine-banner", "false");
//   //   this.setState({ showMagazineBanner: false });
//   // };

//   // handleCloseSupportBanner = () => {
//   //   this.setState({ localHideBanner: true });
//   //   if (localStorage.getItem("support-banner")) {
//   //     if (localStorage.getItem("support-banner") === "1") {
//   //       this.setState({ showSupportBanner: "2" });
//   //       localStorage.setItem("support-banner", "2");
//   //     } else if (localStorage.getItem("support-banner") === "0") {
//   //       this.setState({ showSupportBanner: "1" });
//   //       localStorage.setItem("support-banner", "1");
//   //     }
//   //   } else {
//   //     this.setState({ showSupportBanner: "1" });
//   //     localStorage.setItem("support-banner", "1");
//   //   }
//   // };

//   render() {
//     return (
//       <FunctionalProxy>
//         {/* <OnboardingTour run={isDev} steps={steps}/> */}
//         <PageHead
//           title={`${Config.DOMAIN_DISPLAY_NAME} | ${
//             Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
//               ? "Sri Lankan Matrimony"
//               : "Indian Matrimony"
//           }`}
//         />
//         <PageMetaDescription name="Home" content={Constants.META_DESCRIPTION} />
//         {/* <SupportServiceUnavailableBanner lng={lng} /> */}
//         <ProfileCompletionAlert lng={this.props.lng} />
//         {this.state.post && (
//           <NICBanner lng={this.props.lng} profile={this.state.post} />
//         )}

//         {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
//           <GoogleReviewBanner lng={this.props.lng} />
//         )}
//         <NewFeatureBanner lng={this.props.lng} />
//         <HelpBar />
//         {/* {userProfile !== undefined &&
//               userProfile.personalInfo?.gender === "FEMALE" &&
//               userProfile.createdBy === "SELF" &&
//               ENABLE_HYTCH && (
//                 <div
//                   style={{
//                     backgroundColor: "#dfdfdf",
//                     paddingBottom: 10,
//                     paddingTop: 10,
//                   }}
//                 >
//                   <HytchAwarenessBanner />
//                 </div>
//               )} */}
//         <div
//           className="all-ads-main-container"
//           style={{ marginTop: 0, paddingTop: 1 }}
//         >
//           <Grid container xs={12} sm={10} md={8} className="all-ads-container">
//             <Container lng={this.props.lng} />
//           </Grid>
//         </div>
//         <div className="create-floor-footer-desktop">
//           <Footer lng={this.props.lng} />
//         </div>
//         <div className="create-floor-footer-mobile">
//           <FooterMobile lng={this.props.lng} />
//         </div>
//       </FunctionalProxy>
//     );
//   }
// }

export default withLanguageMigrator(HomePageNew);