import React, { Component } from "react";
// import axios from "axios";
// import qs from "qs";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PaymentStatus extends Component {
  state = {
    paymentStatus: "true",
  };

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("success");
    this.setState({ paymentStatus: status });
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          {this.state.paymentStatus === "true" ? (
            <div className="success-error-div">
              <p align="center">
                <i className="far fa-check-circle fa-4x" align="center"></i>
              </p>
              <p className="message">
                {this.props.lng ? "Payment Successful !" : "ගෙවීම සාර්ථකයි !"}
              </p>
              <p align="center">
                {this.props.lng ? (
                  "Your ad has been converted to a Top Ad."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">
                    ඔබගේ දැන්වීම Top Add දැන්වීමක් බවට පරිවර්තනය කර ඇත.
                  </span>
                )}
              </p>

              <Button
                variant="contained"
                className={"all-ads-btn"}
                onClick={() => this.props.history.push("/?page=1")}
                style={{ width: 200 }}
              >
                {this.props.lng ? (
                  "Go To ALL Ads"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    සියලුම දැන්වීම් වෙත යන්න
                  </span>
                )}
              </Button>
            </div>
          ) : (
            <div className="success-error-div">
              <p align="center">
                <i className="far fa-times-circle fa-4x" align="center"></i>
              </p>
              <p className="message">
                {this.props.lng === true
                  ? "Payment Error !"
                  : "ගෙවීම් දෝෂයකි !"}
              </p>
              <p align="center">
                {this.props.lng === true
                  ? "Your card was declined by the bank."
                  : "ඔබගේ කාඩ්පත බැංකුව විසින් ප්‍රතික්ෂේප කරන ලදි."}
              </p>
              <div className="payment-options">
                <Button
                  variant="contained"
                  className="pay-by-card"
                  onClick={() => this.props.history.push("/payments/top-ad")}
                >
                  {this.props.lng === true ? (
                    "TRY AGAIN"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      නැවත උත්සහා කරන්න
                    </span>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Grid>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(PaymentStatus));
