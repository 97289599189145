import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import { Grid, Box } from "@material-ui/core";

const FilterCriteriaView = ({
  fieldMeta,
  showResetOption,
  onClickReset,
  children,
}) => {
  const [showContent, setShowContent] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Grid
        onClick={() => setShowContent(!showContent)}
        className={`accordion ${showContent ? "active" : ""}`}
        style={{ maxWidth: "100%" }}
      >
        {t(fieldMeta.label)}
        {showResetOption && (
          <span
            className="accor-reset"
            onClick={(e) => {
              e.stopPropagation();
              if (isFunction(onClickReset)) {
                onClickReset(e);
              }
            }}
          >
            Reset
          </span>
        )}
      </Grid>
      {showContent && <Box style={{ maxWidth: "100%" }}>{children}</Box>}
    </>
  );
};

export default FilterCriteriaView;
