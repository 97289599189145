import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import AcceptIntMsgModal from "../interestModals/AcceptIntMsgModal";
import ShowIntMsgModal from "../interestModals/ShowIntMsgModal";
import ShowIntMsgSection from "../interestModals/ShowIntMsgSection";
import IgnoreModal from "../my-matches/detail-page/IgnoreModal";
import PreferenceMismatchModal from "../modals/PreferenceMismatchModal";

import close from "../../images/close.png";

class Actions extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.applyNormalPost ? (
          !this.props.isInterestInfo ? (
            <table className="respond-ways">
              <tbody>
                {!this.props.intMessageModal &&
                  <tr>
                    <td>
                      <i className="fas fa-globe"></i>
                    </td>
                    <td style={{ position: "relative" }}>
                      <p
                        style={{
                          marginTop: 0,
                          fontSize: 14,
                          lineHeight: "16px",
                          color: "#000",
                        }}
                      >
                        {this.props.lng ? (
                          "Respond to this ad via website."
                        ) : (
                          <span className="sinhala-size-14">
                            මෙම දැන්වීමට අන්තර්ජාලය හරහා ප්‍රතිචාර දක්වන්න.
                          </span>
                        )}
                      </p>

                      <Button
                        variant="contained"
                        className={
                          ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ?
                          "" :
                          this.props.showIntTooltip
                          ? "message-btn btn-tooltip"
                          : "message-btn"
                        }
                        onClick={() =>
                          this.props.handleMessageModal(this.props.gender)
                        }
                        disabled={((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ? true : false}
                        >
                        {this.props.btnLoading ? (
                          <CircularProgress size={16} style={{ color: "#fff" }} />
                        ) : (
                          <span>
                            {this.props.lng ? (
                              "Show Interest"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-11">
                                කැමැත්ත පෙන්වන්න
                              </span>
                            )}
                          </span>
                        )}
                      </Button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          ) : null
        ) : null}

        {this.props.applyInterest ? (
          this.props.showAccept && this.props.isIgnored === false ? (
            <table className="respond-ways">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-globe"></i>
                  </td>
                  <td>
                    <p
                      style={{
                        marginTop: 0,
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: "16px",
                        color: "#F54646",
                      }}
                    >
                      {this.props.lng ? (
                        `You have received an online interest request from ${this.props.displayName}`
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          ඔබගේ දැන්වීමට {this.props.displayName} කැමැත්ත පෙන්වා ඇත.
                        </span>
                      )}
                    </p>
                    <Button
                      variant="contained"
                      className={
                        ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ?
                        "" :
                        "int-accept-btn"
                      }
                      disabled={((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ? true : false}
                      onClick={this.props.handleAcceptMessageModal}
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    >
                      {this.props.lng ? (
                        "Accept"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-11">
                          පිලිගන්න
                        </span>
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleIgnoreModal}
                      className={
                        ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ?
                        "" :
                        "received-ignore-btn"
                      }
                      disabled={((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ? true : false}
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <span>
                        {this.props.lng === true ? (
                          "Ignore"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-11">
                            නොසලකා හරින්න
                          </span>
                        )}
                      </span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null
        ) : null}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.props.showAlert}
          onClose={this.handleClose}
          key={this.props.vertical + this.props.horizontal}
        >
          <Alert
            onClose={this.shouldComponentUpdatehandleClose}
            severity="success"
          >
            {this.props.lng ? (
              "Your request has been sent!"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">
                ඔබගේ ඉල්ලීම යවා ඇත!
              </span>
            )}
          </Alert>
        </Snackbar>

        <Dialog
          open={this.props.alertModal}
          onClose={this.props.handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
            {this.props.alertHeading}
          </DialogTitle>
          <img
            src={close}
            alt="close"
            className="close-modal"
            onClick={this.props.handleModalClose}
          />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {localStorage.getItem("post_details") &&
            JSON.parse(localStorage.getItem("post_details")).status ===
              "EXPIRED" ? (
              <React.Fragment>
                <Button
                  onClick={() => this.props.history.push("/pay-for-ad")}
                  style={{ background: "#000", color: "#fff" }}
                >
                  {this.props.lng === true ? (
                    "Renew"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-11">
                      අලුත් කරන්න
                    </span>
                  )}
                </Button>
              </React.Fragment>
            ) : null}

            {localStorage.getItem("post_details") &&
              JSON.parse(localStorage.getItem("post_details")).status ===
                "PAYMENT_PENDING" && (
                <Button
                  onClick={() => this.props.history.push("/pay-for-ad")}
                  style={{ background: "#000", color: "#fff" }}
                >
                  {this.props.lng ? "Pay Now" : "ගෙවන්න"}
                </Button>
              )}

            {/* {localStorage.getItem("post_details") &&
              JSON.parse(localStorage.getItem("post_details")).status ===
                "DISABLED" && (
                <Button
                  onClick={this.props.handleModalClose}
                  style={{ background: "#000", color: "#fff" }}
                >
                  {this.props.lng ? "OK" : "හරි"}
                </Button>
              )} */}

            {!localStorage.getItem("post_details") && (
              <Button
                onClick={() => this.props.history.push("/complete-ad-review")}
                style={{
                  background: "#000",
                  color: "#fff",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {this.props.lng ? "Complete your ad" : "දැන්වීම සම්පූර්ණ කරන්න"}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <IgnoreModal
          lng={this.props.lng}
          commingMsg={this.props.matchMessage}
          fname={this.props.fName}
          lnameMasked={this.props.lnameMasked}
          gender={this.props.gender}
          ignoreModal={this.props.ignoreModal}
          ignoreReason={this.props.ignoreReason}
          handleIgnoreReason={this.props.handleIgnoreReason}
          handleModalClose={this.props.handleModalClose}
          handleIgnoreAllInt={this.props.handleIgnoreAllInt}
          loadingIgnore={this.props.loadingIgnore}
        />

        {/* {this.props.messageModal ? (
          <AcceptIntMsgModal
            messageModal={this.props.messageModal}
            sendingPostId={this.props.sendingPostId}
            commingMsg={this.props.matchMessage}
            sensitiveDataError={this.props.sensitiveDataError}
            fname={this.props.fName}
            lnameMasked={this.props.lnameMasked}
            gender={this.props.gender}
            loading={this.props.loading}
            handleModalClose={this.props.handleModalClose}
            handleShowInterest={this.props.handleShowInterest}
            lng={this.props.lng}
          />
        ) : null} */}

        {this.props.intMessageModal ? (
          // <ShowIntMsgModal
          //   loading={this.props.loading}
          //   fname={this.props.fName}
          //   lnameMasked={this.props.lnameMasked}
          //   gender={this.props.gender}
          //   messageModal={this.props.intMessageModal}
          //   handleModalClose={this.props.handleModalClose}
          //   handleShowInterest={this.props.handleShowInterest}
          //   sendingPostId={this.props.postId}
          //   sensitiveDataError={this.props.sensitiveDataError}
          //   lng={this.props.lng}
          //   displayName={this.props.displayName}
          // />
          <ShowIntMsgSection
            loading={this.props.loading}
            fname={this.props.fName}
            lnameMasked={this.props.lnameMasked}
            gender={this.props.gender}
            messageModal={this.props.intMessageModal}
            handleModalClose={this.props.handleModalClose}
            handleShowInterest={this.props.handleShowInterest}
            sendingPostId={this.props.postId}
            sensitiveDataError={this.props.sensitiveDataError}
            lng={this.props.lng}
            displayName={this.props.displayName}
            preferenceMismatchMessage={this.props.preferenceMismatchMessage}
          />
        ) : null}

        {this.props.preferenceMismatchModal ? (
          <PreferenceMismatchModal
            preferenceMismatchModal={this.props.preferenceMismatchModal}
            preferenceMismatchMessage={this.props.preferenceMismatchMessage}
            handlePreferenceMismatchModalClose={
              this.props.handlePreferenceMismatchModalClose
            }
            lng={this.props.lng}
            showInterestOnMismatchedProfile={this.props.showInterestOnMismatchedProfile}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(Actions);
