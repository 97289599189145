import React from "react";

import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "./v2/templates/branding/product-logo";

const ErrorFallbackView = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 8,
          padding: 16,
          backgroundColor: "black",
        }}
      >
        <ProductLogo variant={LogoVariant.white} size={LogoSize.medium} className={"poruwa-logo"}/>
        <h3 style={{ color: "white" }}>Unknown Error Occurred</h3>
        <button
          onClick={() => {
            window.location = window.location.origin;
          }}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default ErrorFallbackView;
