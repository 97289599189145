import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import LoginCom from "../components/login/LoginCom";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Login extends Component {
  state = {
  };

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container cus-container-register"
            style={{ paddingTop: 40 }}
          >
            <Paper
              elevation={0}
              style={{ minHeight: "55vh", position: "relative" }}
            >
              <LoginCom lng={this.props.lng} />
            </Paper>
          </Grid>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(Login));
