import axios from "axios";
import { FETCH_INT_POST } from "./types";

export const fetchIntPostData = (post, type) => (dispatch) => {
  if (type === "external") {
    dispatch({
      type: FETCH_INT_POST,
      payload: post,
    });
  } else {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account${
          type === "interests" ? "" : `/posts/${localStorage.getItem("postId")}`
        }/${type}/${post.id}/seen
      `,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: FETCH_INT_POST,
          payload: post,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
