const ACTIVE_BTN_TYPE = "ACTIVE_BTN_TYPE";
const FETCH_INT_POST = "FETCH_INT_POST";
const FETCH_INT_POST_WIHOUT_SEEN = "FETCH_INT_POST_WIHOUT_SEEN";
const FETCH_MATCH_POST = "FETCH_MATCH_POST";
const REMOVE_POST = "REMOVE_POST";
const IS_OPEN_ACCORDIAN = "IS_OPEN_ACCORDIAN";
const FETCH_ALL_INTEREST = "FETCH_ALL_INTEREST";
const FETCH_ARCHIVED = "FETCH_ARCHIVED";
const FETCH_RESTRICTED_POST = "FETCH_RESTRICTED_POST";
const SET_PRE_LOADING = "SET_PRE_LOADING";
const FETCH_MATCHED_POSTS = "FETCH_MATCHED_POSTS";
const FETCH_INTEREST_POSTS = "FETCH_INTEREST_POSTS";
const FETCH_SPAM_INTEREST_POSTS = "FETCH_SPAM_INTEREST_POSTS";
const FETCH_ARCHIVED_MATCHED_POSTS = "FETCH_ARCHIVED_MATCHED_POSTS";
const FETCH_NEW_MATCHED_POSTS = "FETCH_NEW_MATCHED_POSTS";
const FETCH_SENT_INTERESTS = "FETCH_SENT_INTERESTS";
const FETCH_ARCHIVED_INTEREST_POSTS = "FETCH_ARCHIVED_INTEREST_POSTS";
const FETCH_ARCHIVED_SENT_INTERESTS = "FETCH_ARCHIVED_SENT_INTERESTS";
const FETCH_NOTIFICATION_COUNT = "FETCH_NOTIFICATION_COUNT";
const UPDATE_MATCHED_POSTS = "UPDATE_MATCHED_POSTS";
const UPDATE_SEEN_POSTS = "UPDATE_SEEN_POSTS";
const SET_INTEREST_SIDE_MENU_LOADING = "SET_INTEREST_SIDE_MENU_LOADING";
const SET_SPAM_INTEREST_SIDE_MENU_LOADING = "SET_SPAM_INTEREST_SIDE_MENU_LOADING";
const MARK_INTEREST_SEEN = "MARK_INTEREST_SEEN";
const FETCH_MESSAGE_POST_DATA = "FETCH_MESSAGE_POST_DATA";
const FETCH_ARCHIVED_SPAM_INTEREST_POSTS = "FETCH_ARCHIVED_SPAM_INTEREST_POSTS";
const FETCH_UNMATCHED_POSTS = "FETCH_UNMATCHED_POSTS";
const PAGE_VIEW_INITIALIZED = "PAGE_VIEW_INITIALIZED";

export {
  ACTIVE_BTN_TYPE,
  FETCH_INT_POST,
  FETCH_INT_POST_WIHOUT_SEEN,
  FETCH_MATCH_POST,
  REMOVE_POST,
  IS_OPEN_ACCORDIAN,
  FETCH_ALL_INTEREST,
  FETCH_ARCHIVED,
  FETCH_RESTRICTED_POST,
  SET_PRE_LOADING,
  FETCH_MATCHED_POSTS,
  FETCH_INTEREST_POSTS,
  FETCH_SPAM_INTEREST_POSTS,
  FETCH_ARCHIVED_MATCHED_POSTS,
  FETCH_NEW_MATCHED_POSTS,
  FETCH_SENT_INTERESTS,
  FETCH_ARCHIVED_INTEREST_POSTS,
  FETCH_ARCHIVED_SENT_INTERESTS,
  FETCH_NOTIFICATION_COUNT,
  UPDATE_MATCHED_POSTS,
  UPDATE_SEEN_POSTS,
  SET_INTEREST_SIDE_MENU_LOADING,
  SET_SPAM_INTEREST_SIDE_MENU_LOADING,
  MARK_INTEREST_SEEN,
  FETCH_MESSAGE_POST_DATA,
  FETCH_ARCHIVED_SPAM_INTEREST_POSTS,
  FETCH_UNMATCHED_POSTS,
  PAGE_VIEW_INITIALIZED
};
