import React, { useContext, useEffect } from "react";
import PageHead from "../../../../components/atoms/page-head";
import Navbar from "../../../../../components/common/Navbar";
import AppContext from "../../../../context";
import DeprecationHandler from "../../../../utils/deprecation-handler";
import Unauthorize from "../../../../../pages/Unauthorize";
import Footer from "../../../../../components/common/Footer";
import FooterMobile from "../../../../../components/common/FooterMobile";
import { Grid } from "@material-ui/core";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { initializeAuth } from "../../../../features/application";
import FlowStepper from "../stepper";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Config from "../../../../../v2/config";
import { scrollToTop } from "../../../../utils";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateFlowPage = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { lng, handleLng } = useContext(AppContext);
  const dispatch = useDispatch();
  const { initialized, authProfile } = useSelector(
    (state) => state.application
  );

  useEffect(() => {
    if (initialized) {
      return;
    }
    dispatch(initializeAuth());
  }, [dispatch, initialized]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  if (!DeprecationHandler.isAuthAuthorized()) {
    return <Unauthorize title={`Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />;
  }

  if (initialized && !isEmpty(authProfile)) {
    history.push("/");
  }

  return (
    <>
      <PageHead title={`Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar lng={lng} handleLng={handleLng} /> */}
      <FlowStepper />
      <Grid container direction="row" className="create-flow-page-content">
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={6}
          className="form-content"
          container
          direction="column"
        >
          {children}
        </Grid>
      </Grid>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default CreateFlowPage;
