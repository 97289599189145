import { isEqual, isEmpty, isFunction } from "lodash";
import Config from "../../config";
import store from "../../../store";
import GenericDataAPI from "../../services/api/generic";
import PostServiceApi from "../../services/api/post-service";
import {
  buildModelByFormMeta,
  fieldVisibility,
  isEmptyOrNullOrUndefined,
} from "../../utils";
import FormMeta from "../../meta/post-filter-bar.json";
import { setAppSnackNotification } from "../../store/actions/application";
import {
  setHomePageCommunities,
  setHomePageCountryRegions,
  setHomePageCurrentPage,
  setHomePageFeaturedPosts,
  setHomePageInitialResultLoaded,
  setHomePageLoading,
  setHomePageMobileSavePrefferredCriteria,
  setHomePagePageSize,
  setHomePagePosts,
  setHomePagePrefferredActive,
  setHomePagePrefferredCriteria,
  setHomePageSavingPrefferredCriteria,
  setHomePageShadowedCriteria,
  setHomePageShowSavePrefferredCriteria,
  setHomePageTotalCount,
  setHomePageTotalPages,
} from "../../store/actions/home-page";
import UserApi from "../../services/api/user";
import { getAuthUserAccount } from "../application";
import { setHomePageFilterCount } from "../../store/actions/home-page";
import { sanitizeSearchFilterValues } from "../../pages/home/extension";

const showLoginPromptDialog = () => {
  document.getElementById("drawer-login-dialog-btn").click();
};

const isDeprecatedLKPrefferredSearchObject = ({ criteria }) => {
  const deprecatedKeys = Config.DEPRECATED_PREFFERRED_SEARCH_KEYS;
  if (criteria.constructor === Object) {
    return Object.keys(criteria).some((key) => deprecatedKeys.includes(key));
  }
  return false;
};

const transformeDeprecatedSearchObject = ({ input }) => {
  const mappedCriteria = {};
  mappedCriteria[FormMeta.gender.fieldName] = input.male
    ? "male"
    : input.female
    ? "female"
    : "";
  mappedCriteria[FormMeta.minAge.fieldName] =
    input.ageRange?.min ?? FormMeta.minAge.defaultValue;
  mappedCriteria[FormMeta.maxAge.fieldName] =
    input.ageRange?.max ?? FormMeta.maxAge.defaultValue;
  mappedCriteria[FormMeta.minHeight.fieldName] =
    input.heightRange.min ?? FormMeta.minHeight.defaultValue;
  mappedCriteria[FormMeta.maxHeight.fieldName] =
    input.heightRange.max ?? FormMeta.maxHeight.defaultValue;
  mappedCriteria[FormMeta.accountCreatedBy.fieldName] =
    input.checkedAccountOwner ?? [];
  mappedCriteria[FormMeta.differentlyAbled.fieldName] =
    input.checkedDissabled ?? [];
  mappedCriteria[FormMeta.drinking.fieldName] = input.checkedDrinking ?? [];
  mappedCriteria[FormMeta.foodPreferences.fieldName] =
    input.checkedFoodPref ?? [];
  mappedCriteria[FormMeta.idVerification.fieldName] =
    input.checkedNicVerified ?? [];
  mappedCriteria[FormMeta.professions.fieldName] = input.checkedProf ?? [];
  mappedCriteria[FormMeta.regionOrDistrict.fieldName] =
    input.checkedReligions ?? [];
  mappedCriteria[FormMeta.residentCountries.fieldName] =
    input.checkedResidentCountries ?? [];
  mappedCriteria[FormMeta.smoking.fieldName] = input.checkedSmoking ?? [];
  mappedCriteria[FormMeta.civilStatuses.fieldName] = input.checkedStatus ?? [];
  mappedCriteria[FormMeta.educationLevels.fieldName] = input.minEdu
    ? [input.minEdu]
    : [];
  mappedCriteria[FormMeta.showInterests.fieldName] =
    input.showHideInt ?? FormMeta.showInterests.defaultValue;

  mappedCriteria[FormMeta.ethnicities.fieldName] =
    input.checkedEthnicities ?? [];

  return mappedCriteria;
};

const mapPreferredSearchToFilterCriteria = (input) => {
  let mappedCriteria = input;
  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
    const isDeprecated = isDeprecatedLKPrefferredSearchObject({
      criteria: input,
    });
    if (isDeprecated) {
      mappedCriteria = transformeDeprecatedSearchObject({ input });
    }
  }
  return mappedCriteria;
};

export const handlePrefferredSearch =
  ({ active, translate, resetForm }) =>
  async (dispatch) => {
    try {
      const { authenticated, authAccount, authProfile } =
        store.getState().application;

      if (!authenticated) {
        showLoginPromptDialog();
        return;
      }

      if (!active) {
        dispatch(setHomePagePrefferredActive(false));
        if (isFunction(resetForm)) {
          const model = buildModelByFormMeta(FormMeta);
          resetForm({ values: model });
        }
        return;
      }

      if (authAccount !== null) {
        const { USER_ROLES } = Config;
        const operatorRoles = [USER_ROLES.OPERATOR, USER_ROLES.OPERATOR_L2];
        let defaultFilters = {};
        // Operator Roles
        if (operatorRoles.includes(authAccount.role)) {
          const response = await UserApi.getPreferredSearchCriteriaByOperator({
            postId: authProfile.id,
          });
          if (!response.success) {
            dispatch(
              setAppSnackNotification({
                severity: "error",
                message: "Unknown Error Occurred",
              })
            );
            return;
          }
          if (response.body.userPreferences.searchFilters) {
            defaultFilters = JSON.parse(
              response.body.userPreferences.searchFilters
            );
          }
        }
        // User
        if (
          authAccount.role === USER_ROLES.USER &&
          authAccount.userPreferences?.searchFilters
        ) {
          defaultFilters = JSON.parse(
            authAccount.userPreferences.searchFilters
          );
        }
        if (isEmpty(defaultFilters)) {
          dispatch(
            setAppSnackNotification({
              severity: "error",
              message: translate("common.noPrefferredSearchFound"),
            })
          );
        } else {
          const mappedFilters =
            mapPreferredSearchToFilterCriteria(defaultFilters);
          resetForm({ values: mappedFilters });
          dispatch(setHomePagePrefferredCriteria(mappedFilters));
          dispatch(setHomePagePrefferredActive(true));
        }
      }
    } catch (e) {
      console.log(">>> Error! [handlePrefferredSearch]", e);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occurred",
        })
      );
    }
  };

export const onChangeFilterCriteria =
  ({ criteria, setFieldValue, setValues }) =>
  async (dispatch) => {
    try {
      criteria[FormMeta.pageNum.fieldName] =
        criteria[FormMeta.pageNum.fieldName] ?? 1;

      const { shadowedCriteria, initialResultLoaded } =
        store.getState().homePage;

      if (initialResultLoaded && isEqual(shadowedCriteria, criteria)) {
        return;
      }

      criteria[FormMeta.pageSize.fieldName] =
        window.innerWidth <= Config.MOBILE_VIEWPORT_UPPER_BOUND
          ? Config.HOME_PAGE_POST_LIMIT_MOBILE
          : Config.HOME_PAGE_POST_LIMIT_LAPTOP;

      dispatch(
        filterPostBySearchCriteria({
          criteria,
          shadowedCriteria,
          setFieldValue,
          setValues,
        })
      );
      const { authenticated } = store.getState().application;
      const { prefferredSearchCriteria } = store.getState().homePage;
      const tempPreferredCriteria = sanitizedCriteriaToAccountPrefferences({
        criteria,
        authenticated,
      });
      const sanitizedPreferedSearchCriteria = sanitizeSearchFilterValues({
        values: { ...prefferredSearchCriteria },
      });

      if (!isEqual(tempPreferredCriteria, sanitizedPreferedSearchCriteria)) {
        dispatch(setHomePagePrefferredActive(false));
        dispatch(setHomePageShowSavePrefferredCriteria(true));
        dispatch(setHomePageMobileSavePrefferredCriteria(false));
      }
    } catch (e) {
      console.log(">>> Error! [onChangeFilterCriteria]:", e);
      // TODO: Error Handling
    }
  };

const sanitizedCriteriaToAccountPrefferences = ({
  criteria,
  authenticated,
}) => {
  const searchFilters = { ...criteria };
  const deletedKeys = [FormMeta.pageNum, FormMeta.pageSize];
  for (const meta of deletedKeys) {
    delete searchFilters[meta.fieldName];
  }

  // if (authenticated) {
  //   const showInterests = criteria[FormMeta.showInterests.fieldName];
  //   searchFilters[FormMeta.showInterests.fieldName] = showInterests ?? true;
  // }
  return searchFilters;
};

export const onSavePrefferredSearch =
  ({ criteria }) =>
  async (dispatch) => {
    try {
      const { authenticated } = store.getState().application;
      if (!authenticated) {
        showLoginPromptDialog();
        return;
      }
      dispatch(setHomePageSavingPrefferredCriteria(true));
      const searchFilters = sanitizedCriteriaToAccountPrefferences({
        criteria,
      });
      const response = await UserApi.postUserPreferences({
        preferences: { searchFilters },
      });
      if (!response.success) {
        return; // TODO: error handling
      }
      dispatch(setHomePageShowSavePrefferredCriteria(false));
      dispatch(getAuthUserAccount());
      dispatch(setHomePagePrefferredCriteria(searchFilters));
      dispatch(setHomePagePrefferredActive(true));
      dispatch(setHomePageMobileSavePrefferredCriteria(true));
    } catch (error) {
      // TODO: Error Handling
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occcured",
        })
      );
    } finally {
      dispatch(setHomePageLoading(false));
      dispatch(setHomePageSavingPrefferredCriteria(false));
    }
  };

/**
 * Filter Post By provided fitler criteria
 */
const sanitizePostSearchCriteria = ({ criteria }) => {
  const ignoredKeys = [
    FormMeta.sortBy.fieldName,
    "context",
    FormMeta.educationLevels.fieldName,
    FormMeta.differentlyAbled.fieldName,
    FormMeta.idVerification.fieldName,
    FormMeta.showInterests.fieldName,
  ];
  const params = {};
  for (const [key, value] of Object.entries(criteria)) {
    if (ignoredKeys.includes(key)) continue;
    const fieldMeta = Object.values(FormMeta).find(
      (field) => field.fieldName === key
    );
    if (isEmpty(fieldMeta) || fieldMeta.constructor !== Object) {
      continue;
    }

    const fieldName = fieldMeta.jsonKey || fieldMeta.fieldName;
    if (!isEmpty(fieldName)) {
      if (Array.isArray(value) && value.length > 0) {
        params[fieldName] = value.join(",");
      }
      if (!Array.isArray(value) && !isEmptyOrNullOrUndefined(value)) {
        params[fieldName] = value;
      }
    }
  }

  if (
    Array.isArray(criteria[FormMeta.educationLevels.fieldName]) &&
    criteria[FormMeta.educationLevels.fieldName].length > 0
  ) {
    params.minEducationLevel = criteria[FormMeta.educationLevels.fieldName][0];
  }

  if (
    Array.isArray(criteria[FormMeta.differentlyAbled.fieldName]) &&
    criteria[FormMeta.differentlyAbled.fieldName].length === 1
  ) {
    params.differentlyAbled =
      criteria[FormMeta.differentlyAbled.fieldName][0] === "true";
  }

  if (Array.isArray(criteria[FormMeta.idVerification.fieldName])) {
    const idFilter = criteria[FormMeta.idVerification.fieldName][0];
    if (idFilter !== undefined) {
      params[FormMeta.idVerification.jsonKey] = idFilter === "YES";
    }
  }

  const postId = localStorage.getItem("postId");
  const authenticated = postId !== null;
  if (authenticated) {
    params.context = postId;
    params.showInterests = criteria[FormMeta.showInterests.fieldName] ?? true;
  }

  if (criteria[FormMeta.sortBy.fieldName]) {
    let optionMeta = FormMeta.sortBy.options.find(
      (option) => option.value === criteria[FormMeta.sortBy.fieldName]
    );
    if (optionMeta !== undefined) {
      params.sortOrder = optionMeta.sortOrder;
      params.sortBy = optionMeta.sortField;
    }
  }

  // Migrate page number: backend starts page number from 1
  params[FormMeta.pageNum.fieldName] =
    params[FormMeta.pageNum.fieldName] > 0
      ? params[FormMeta.pageNum.fieldName] - 1
      : 0;

  return params;
};

const filterPostBySearchCriteria =
  ({ criteria, shadowedCriteria, setFieldValue, setValues }) =>
  async (dispatch) => {
    try {
      dispatch(setHomePageLoading(true));
      const { authenticated, authProfile } = store.getState().application;
      const filters = sanitizePostSearchCriteria({ criteria });

      const response = await PostServiceApi.searchPosts({
        authenticated: authenticated && !isEmpty(authProfile),
        filters,
      });

      if (!response.success) {
        // Error Handling
        return;
      }

      const {
        featuredPosts,
        results: posts,
        total: totalCount,
        pageCount,
        pageNum,
        pageSize,
      } = response.body;

      dispatch(setHomePagePosts(posts));
      dispatch(setHomePageFeaturedPosts(featuredPosts));
      dispatch(setHomePageTotalPages(pageCount));
      dispatch(setHomePagePageSize(pageSize));
      dispatch(setHomePageTotalCount(totalCount));

      // When completion of search where it should be eqaul formik values and shadow
      const model = buildModelByFormMeta(FormMeta);
      for (const [key, value] of Object.entries(criteria)) {
        model[key] = value;
      }
      if (isFunction(setValues)) {
        setValues(model);
      }
      // Update ShadowCriteria
      const nextShadow = { ...criteria };
      delete nextShadow[FormMeta.pageSize.fieldName];
      nextShadow[FormMeta.pageNum.fieldName] =
        nextShadow[FormMeta.pageNum.fieldName] ?? 1;
      dispatch(setHomePageShadowedCriteria(nextShadow));
      // Migration: Backend pagination start from zero
      dispatch(setHomePageCurrentPage(pageNum + 1));
      sessionStorage.setItem(
        Config.POST_SEARCH_CRITERIA,
        JSON.stringify(criteria)
      );

      // calculate applied filters count
      const defaultFilters = buildModelByFormMeta(FormMeta);
      const ignoredKeys = [
        FormMeta.sortBy.fieldName,
        FormMeta.pageNum.fieldName,
        FormMeta.pageSize.fieldName,
      ];
      let changedCount = 0;
      for (const currentKey of Object.keys(criteria)) {
        if (ignoredKeys.includes(currentKey)) {
          continue;
        }
        const defaultValue = defaultFilters[currentKey];
        const currentValue = criteria[currentKey];
        if (!isEqual(defaultValue, currentValue)) {
          changedCount += 1;
        }
      }
      dispatch(setHomePageFilterCount(changedCount));

      // Resident Countries
      if (
        !isEqual(criteria.residentCountries, shadowedCriteria.residentCountries)
      ) {
        if (
          Array.isArray(criteria.residentCountries) &&
          criteria.residentCountries.length === 1
        ) {
          const countryCode = criteria.residentCountries[0];
          if (countryCode !== Config.OTHER_COUNTRY_CODE) {
            dispatch(fetchCountryRegionsByCountryCode({ countryCode }));
          } else {
            dispatch(setHomePageCountryRegions([]));
          }
        } else {
          if (setFieldValue !== undefined) {
            setFieldValue(FormMeta.regionOrDistrict.fieldName, []);
          }
          dispatch(setHomePageCountryRegions([]));
        }
      }

      // Communities
      if (
        fieldVisibility(FormMeta.communities) &&
        !isEqual(criteria.religions, shadowedCriteria.religions)
      ) {
        if (criteria.religions.length === 1) {
          const religionId = criteria.religions[0];
          dispatch(fetchCommunitiesByReligionId({ religionId }));
        } else {
          dispatch(setHomePageCommunities([]));
        }
      }

      // const adContainer = document.getElementById("home-page-ad-container");
      // if (adContainer !== null) {
      //   adContainer.scrollIntoView({ behavior: "auto" });
      //   document.body.scrollTop = 0;
      //   document.documentElement.scrollTop = 0;
      // }
    } catch (e) {
      console.log(">>>> Error! ", e);
    } finally {
      setTimeout(() => {
        dispatch(setHomePageLoading(false));
        dispatch(setHomePageInitialResultLoaded(true));
      }, 100);
    }
  };

/**
 * Fetch Regions by Country Code
 */
export const fetchCountryRegionsByCountryCode =
  ({ countryCode }) =>
  async (dispatch) => {
    try {
      const response = await GenericDataAPI.getRegionsByCountryCode({
        countryCode,
      });
      if (!response.success) {
        return; // TODO: Error Handling
      }
      dispatch(
        setHomePageCountryRegions(
          response.body.sort((a, b) => a.regionName.localeCompare(b.regionName))
        )
      );
    } catch (e) {
      console.log(">>>> Error! [fetchCountyRegionsByCountryCode]:", e);
    }
  };

/**
 * Fetch Communities by Religion Id
 */
export const fetchCommunitiesByReligionId =
  ({ religionId }) =>
  async (dispatch) => {
    try {
      const response = await GenericDataAPI.getCommunitiesByReligionId({
        religionId,
      });
      if (!response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to fetch communities",
          })
        );
        return;
      }
      dispatch(setHomePageCommunities(response.body));
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occurred",
        })
      );
    }
  };

export const onChangePagination =
  ({ page }) =>
  (dispatch) => {
    try {
      let { shadowedCriteria } = store.getState().homePage;

      if (isEmpty(shadowedCriteria)) {
        shadowedCriteria = buildModelByFormMeta(FormMeta);
      }
      const criteria = { ...shadowedCriteria, pageNum: page };
      dispatch(
        filterPostBySearchCriteria({
          criteria,
          shadowedCriteria,
        })
      );
    } catch (e) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occurred",
        })
      );
    }
  };

export const initializeHomePage =
  ({ setFieldValue }) =>
  async (dispatch) => {
    try {
      const criteria = sessionStorage.getItem(Config.POST_SEARCH_CRITERIA)
        ? JSON.parse(sessionStorage.getItem(Config.POST_SEARCH_CRITERIA))
        : buildModelByFormMeta(FormMeta);
      dispatch(onChangeFilterCriteria({ criteria, setFieldValue }));
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occurred",
        })
      );
    }
  };
