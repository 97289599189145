import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import Switch from "react-input-switch";
import Alert from "@material-ui/lab/Alert";

import close from "../../images/close.png";
import groom from "../../images/groom.png";
import bride from "../../images/bride.png";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";

class AcceptIntMsgModal extends Component {
  state = {
    gender: localStorage.getItem("post_details")
      ? JSON.parse(localStorage.getItem("post_details")).personalInfo.gender
      : "",
    image: localStorage.getItem("user_image")
      ? localStorage.getItem("user_image")
      : "",
    message: "",
    showHidePhone: "true",
    imageData: [],
    photosLoading: false
  };

  componentDidMount = () => {
    if (localStorage.getItem("auth_token")) {
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}`;
      } else {
        url = "account/posts";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            res.data[0] = res.data;
          }
          this.setState({
            gender: res.data[0].personalInfo.gender,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (this.props.showPhotos && this.props.receivedInterestImages.length > 0) {
      this.getProfilePhotos(this.props.receivedInterestImages);
    }
  };

  getProfilePhotos = (profileImgs) => {
    if (profileImgs.length > 0) {
      this.setState({
        photosLoading: true,
      });
      for (let i = 0; i < profileImgs.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/interests/${this.props.intId}/images/${
              profileImgs[i]
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            this.setState({
              imageData: [...this.state.imageData, data],
              // preLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    this.setState({
      photosLoading: false,
    });
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handle show hide
  handleShowHide = (value) => {
    this.setState({
      showHidePhone: value,
    });
  };

  render() {
    return (
      <div
        open={this.props.messageModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="message-modal"
      >
        <Paper
          elevation={0}
          style={{ minHeight: "8vh", position: "relative", maxWidth: '450px', margin: 'auto', marginTop: '25px' }}
          className="complete-ad-div settings-container post-details-con"
        >
          <div className="post-details-col">
            {this.props.gender === "MALE" ? (
              <img src={groom} className="applicant-photo" alt="groom" />
            ) : (
              <img src={bride} className="applicant-photo" alt="bride" />
            )}

            <Typography variant="h6" className="name">
              <span style={{ textTransform: "capitalize" }}>
                {this.props.displayName}
              </span>
            </Typography>
            <p className="location">
              {this.props.age && (
                <React.Fragment>
                  {this.props.age}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {this.props.residentCity}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {this.props.lng
                    ? this.props.profession
                    : this.props.profession_si}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {Math.floor(Math.round(this.props.height / 2.54) / 12)}'{" "}
                  {Math.round(this.props.height / 2.54) % 12}"
                </React.Fragment>
              )}
            </p>
          </div>
          <div>
            {(this.props.showPhotos && this.props.receivedInterestImages.length > 0) &&
              this.state.photosLoading ?
                <div><CircularProgress className="loading-circle" /></div>
                :
                <PhotoProvider>
                  {this.state.imageData &&
                    this.state.imageData.map((item, index) => (
                      <PhotoConsumer
                        key={index}
                        src={item}
                        intro={item}
                      >
                        <img src={item} alt="" className="photos" onContextMenu={(e) => { e.preventDefault() }} />
                      </PhotoConsumer>
                    ))}
                </PhotoProvider>
            }
          </div>
          {this.props.likeMessage &&
            <>
              <div
                style={{ marginBottom: 10, marginTop: 40, fontWeight: '500', fontSize: '16px' }}
              >
                {this.props.lng ? (
                  `${this.props.displayName} Says`
                ) : (
                  <span className="sinhala-w-600 sinhala-size-16">
                    {this.props.displayName} පවසයි
                  </span>
                )}
              </div>
              <div className="invisible-photos"  style={{ marginBottom: 20, marginTop: 0, width: 'fit-content' }}>
                  {this.props.likeMessage}
              </div>
            </>
          }
          <div>
            <img
              src={close}
              alt="close"
              className="close-message-modal"
              onClick={this.props.handleModalClose}
            />

            {this.props.commingMsg && (
              <p className="message">{this.props.commingMsg}</p>
            )}
            <Alert severity="info" style={{ padding: "0 10px", marginTop: '40px' }}>
              {this.props.lng ? (
                `This message will be displayed to ${
                  this.props.gender === "MALE" ? "him" : "her"
                } when you accept this request.`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  ඔබ මෙම ඉල්ලීම පිළිගත් විට මෙම පණිවිඩය
                  {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} දර්ශනය වේ.
                </span>
              )}
            </Alert>

            <textarea
              className={
                this.props.sensitiveDataError
                  ? "message-textarea red-border"
                  : "message-textarea"
              }
              rows="5"
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              placeholder={
                this.props.lng === true
                  ? "Message (Optional)"
                  : "පණිවිඩය (අත්‍යවශ්‍ය නොවේ)"
              }
              maxLength="1000"
            />
            <p className="cus-modal-error">
              {this.props.lng ? (
                this.props.sensitiveDataError
              ) : (
                <span className="sinhala-size-11">
                  {this.props.sensitiveDataError}
                </span>
              )}
            </p>
          </div>
          <div>
            <div
              className="edit-acc-settings"
              style={{ marginBottom: 0, paddingBottom: 0, marginTop: '35px' }}
            >
              <Typography variant="h6" className="show-phone-on-off">
                {this.props.lng ? (
                  <span className="on-off-label">
                    Share my phone number when {" "}
                    {this.props.gender === "MALE" ? " he" : " she"} accepts my request.
                  </span>
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                    මගේ දුරකථන අංකය
                    {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} පෙන්වන්න.
                  </span>
                )}
                <Switch
                  on="true"
                  off="false"
                  value={this.state.showHidePhone}
                  onChange={this.handleShowHide}
                  style={{
                    float: "right",
                  }}
                  className="interest-on-off-btn"
                />
              </Typography>
            </div>
          </div>
          <div style={{marginTop: '50px', display: 'flex', flexDirection: 'column'}}>
            <Button
              variant="contained"
              className="accept-btn"
              onClick={() =>
                this.props.handleShowInterest(
                  this.props.sendingPostId,
                  this.state.message,
                  this.state.showHidePhone
                )
              }
              color="primary"
            >
              {this.props.loading ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : (
                <span>
                  {this.props.lng === true ? (
                    "Accept and Match"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      පිළිගන්න
                    </span>
                  )}
                </span>
              )}
            </Button>
            <Button
              variant="text"
              className="int-btn"
              onClick={this.props.handleModalClose}
              color="primary"
              style={{background: 'none', color: '#4F4F4F', marginTop: '20px'}}
            >
              {this.props.loading ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : (
                <span>
                  {this.props.lng === true ? (
                    "Cancel"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      අවලංගු කරන්න
                    </span>
                  )}
                </span>
              )}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(AcceptIntMsgModal);
