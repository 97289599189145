import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  CircularProgress,
  Button,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Switch from "react-input-switch";
import qs from "qs";
import Alert from "@material-ui/lab/Alert";

import Address from "./Address";

import "../../styles/settings.css";

export default class Mailing extends Component {
  state = {
    recipName: "",
    recipNameOld: "",
    line1: "",
    line1Old: "",
    line2: "",
    line2Old: "",
    city: "",
    cityOld: "",
    showOfflineId: "false",
    showOfflineIdOld: "false",
    subscribeMagazine: "false",
    subscribeMagazineOld: "false",

    // errors
    recipNameError: "",
    line1Error: "",
    cityError: "",

    // disabled
    dissableBtn: false,

    // loadings
    loading: false,

    // alerts
    successAlert: false,
    failAlert: false,
  };

  componentDidMount = () => {
    this.getMailAddress();
  };

  getMailAddress = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}/owner`;
    } else {
      url = "account";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          recipName: res.data.address.name ? res.data.address.name : "",
          recipNameOld: res.data.address.name ? res.data.address.name : "",
          line1: res.data.address.line1,
          line1Old: res.data.address.line1,
          line2: res.data.address.line2,
          line2Old: res.data.address.line2,
          city: res.data.address.city,
          cityOld: res.data.address.city,
          showOfflineId: res.data.userPreferences.showOfflineId
            ? "true"
            : "false",
          showOfflineIdOld: res.data.userPreferences.showOfflineId
            ? "true"
            : "false",
          subscribeMagazine: res.data.userPreferences.subscribeMagazine
            ? "true"
            : "false",
          subscribeMagazineOld: res.data.userPreferences.subscribeMagazine
            ? "true"
            : "false",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  runDisableBtn = () => {
    if (
      this.state.recipNameOld === this.state.recipName &&
      this.state.line1Old === this.state.line1 &&
      this.state.line2Old === this.state.line2 &&
      this.state.cityOld === this.state.city &&
      this.state.showOfflineId === this.state.showOfflineIdOld &&
      this.state.subscribeMagazine === this.state.subscribeMagazineOld
    ) {
      this.setState({ dissableBtn: false });
    } else {
      this.setState({ dissableBtn: true });
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        this.runDisableBtn();
      }
    );
  };

  handleOnOffShowOfflineId = (value) => {
    this.setState(
      {
        showOfflineId: value,
      },
      () => {
        this.runDisableBtn();
      }
    );
  };

  handleOnOffSubscribeMagazine = (value) => {
    this.setState(
      {
        subscribeMagazine: value,
      },
      () => {
        this.runDisableBtn();
      }
    );
  };

  validate = () => {
    let recipNameError = "";
    let line1Error = "";
    let cityError = "";

    if (this.state.showOfflineId || this.state.subscribeMagazine) {
      if (!this.state.recipName) {
        recipNameError = this.props.lng
          ? "Name of recipient is required"
          : "ලබන්නාගේ නම අවශ්‍ය වේ";
      } else if (
        this.state.recipName.match(/[`0-9!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/)
      ) {
        recipNameError = this.props.lng
          ? "Only letters, spaces, dots and commas are allowed"
          : "ඇතුලත් කල හැක්කේ අකුරු, හිස් තැන්, තිත් සහ කොමා පමණි";
      } else if (!this.state.recipName.trim()) {
        recipNameError = this.props.lng
          ? "Name of recipient is Invalid"
          : "ලබන්නාගේ නම වලංගු නොවේ";
      }

      if (!this.state.line1) {
        line1Error = this.props.lng
          ? "Address line 1 is required"
          : "වීදි අංකය අවශ්‍ය වේ";
      } else if (!this.state.line1.trim()) {
        line1Error = this.props.lng
          ? "Address line 1 is Invalid"
          : "වීදි අංකය වලංගු නොවේ";
      }

      if (!this.state.city) {
        cityError = this.props.lng ? "City is required" : "නගරය අවශ්‍ය වේ";
      } else if (!this.state.city.trim()) {
        cityError = this.props.lng ? "City is Invalid" : "නගරය වලංගු නොවේ";
      }
    }

    if (recipNameError || line1Error || cityError) {
      this.setState({
        recipNameError,
        line1Error,
        cityError,
      });
      return false;
    }

    return true;
  };

  checkAddressChange = () => {
    if (
      this.state.recipNameOld === this.state.recipName &&
      this.state.line1Old === this.state.line1 &&
      this.state.line2Old === this.state.line2 &&
      this.state.cityOld === this.state.city
    ) {
      return false;
    }
    else{
      return true;
    }
  }

  checkPreferenceChange = () => {
    if (
      this.state.showOfflineId === this.state.showOfflineIdOld &&
      this.state.subscribeMagazine === this.state.subscribeMagazineOld
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  handleSave = async() => {
    if (this.validate()) {
      this.setState({ loading: true });
    }

    if (this.checkPreferenceChange()) {
      await  this.updatePreferences();
    }
    if(this.checkAddressChange()) {
      await this.UpdateAddress();
    }

    this.setState({ loading: false });
  }

  UpdateAddress = async () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        recipNameError:"",
        cityError:"",
        line1Error:"",
      });
  
      const data = qs.stringify({
        name: this.state.recipName.trim(),
        line1: this.state.line1.trim(),
        line2: this.state.line2.trim(),
        city: this.state.city.trim(),
        countryCode: "LK",
      });
  
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/account/update_address`,
          data,
          {
            headers: {
              contentType: "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        );

        this.getMailAddress();
  
        this.setState({
          successAlert: true,
          dissableBtn: false,
          loading: false,
        });
  
        setTimeout(() => {
          this.setState({
            successAlert: false,
          });
        }, 2000);
  
      } catch (err) {
        this.setState({ loading: false, failAlert: true });
  
        setTimeout(() => {
          this.setState({
            failAlert: false,
          });
        }, 2000);
      }
    }
  };
  
  handleCancel = () => {
    this.setState({
      recipName: this.state.recipNameOld,
      line1: this.state.line1Old,
      line2: this.state.line2Old,
      city: this.state.cityOld,
      showOfflineId: this.state.showOfflineIdOld,
      subscribeMagazine: this.state.subscribeMagazineOld,
      line1Error:"",
      recipNameError:"",
      cityError:"",
      dissableBtn: false,
      loading: false,
    })
  };

  updatePreferences = async () => {
    if (this.validate()) {
        const data = {
        showOfflineId: this.state.showOfflineId,
        subscribeMagazine: this.state.subscribeMagazine,
      };
    
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/account/preferences`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        );

        this.getMailAddress();
    
        this.setState({
          successAlert: true,
          dissableBtn: false,
          loading: false,
        });
    
        setTimeout(() => {
          this.setState({
            successAlert: false,
          });
        }, 2000);
    
      } catch (err) {
        this.setState({ loading: false, failAlert: true });
    
        setTimeout(() => {
          this.setState({
            failAlert: false,
          });
        }, 2000);
      }
    }
    
  };
  
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div settings-container"
            id="offline-responses-div"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <p className="sec-heading" style={{ marginBottom: 5 }}>
                  {this.props.lng ? (
                    "Offline Responses"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-16">
                      ලිපි මගින් පිළිතුරු ලබා ගැනීම
                    </span>
                  )}
                </p>
                <p className="heading-desc">
                  {this.props.lng ? (
                    "This service is provided at no additional cost."
                  ) : (
                    <span className="sinhala-size-11">
                      මෙම සේවාව අතිරේක වියදමකින් තොරව සපයනු ලැබේ.
                    </span>
                  )}
                </p>

                <div className="edit-acc-settings">
                  <Typography variant="h6" className="setting-on-off">
                    {this.props.lng ? (
                      <span className="on-off-label">
                        Accept written interests via post
                      </span>
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                        දැන්වීම් වලට පිළිතුරු තැපැල් මගින් ලබා ගන්න
                      </span>
                    )}
                    <Switch
                      on="true"
                      off="false"
                      value={this.state.showOfflineId}
                      onChange={this.handleOnOffShowOfflineId}
                      style={{
                        float: "right",
                      }}
                      className="interest-on-off-btn"
                    />
                  </Typography>

                  <Typography variant="h6" className="setting-on-off">
                    {this.props.lng ? (
                      <span className="on-off-label">
                        Send me the monthly magazine with all advertisements
                      </span>
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                        සියලුම දැන්වීම් ඇතුලත් මාසික සඟරාව මට එවන්න.
                      </span>
                    )}

                    <Switch
                      on="true"
                      off="false"
                      value={this.state.subscribeMagazine}
                      onChange={this.handleOnOffSubscribeMagazine}
                      style={{
                        float: "right",
                      }}
                      className="interest-on-off-btn"
                    />
                  </Typography>
                </div>

                {this.state.showOfflineId === "true" || this.state.subscribeMagazine === "true" ? (
                  <Address
                    lng={this.props.lng}
                    recipName={this.state.recipName}
                    line1={this.state.line1}
                    line2={this.state.line2}
                    city={this.state.city}
                    recipNameError={this.state.recipNameError}
                    line1Error={this.state.line1Error}
                    cityError={this.state.cityError}
                    handleChange={this.handleChange}
                  />
                ) : null}

                <div style={{ paddingBottom: 70, clear: "both" }}>
                  {this.state.dissableBtn ? (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        className="edit-btn"
                        onClick={this.handleSave}
                      >
                        {this.state.loading ? (
                          <CircularProgress
                            size={25}
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          <span>
                            {this.props.lng ? (
                              "Save"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-10">
                                තහවුරු කරන්න
                              </span>
                            )}
                          </span>
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className="cancel-btn"
                        onClick={this.handleCancel}
                      >
                        {this.state.loadingCancel ? (
                          <CircularProgress
                            size={25}
                            style={{ color: "#000" }}
                          />
                        ) : (
                          <span>
                            {this.props.lng ? (
                              "Cancel"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-10">
                                අවලංගු කරන්න
                              </span>
                            )}
                          </span>
                        )}
                      </Button>
                    </React.Fragment>
                  ) : null}
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng ? (
                  "Maling address have been changed successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    ලිපිනය සාර්ථකව වෙනස් කර ඇත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    ලිපිනය සාර්ථකව වෙනස් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
