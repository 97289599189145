import React, { Component } from "react";
import axios from "axios";

import IncorrectToken from "../pages/IncorrectToken";
import Config from "../v2/config";

export default class RedirectIntPost extends Component {
  state = {
    isAccessDenied: false,
  };

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const intId = urlParams.get("id");
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${intId}
            `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push(`/post?id=${res.data.post.id}`);
      })
      .catch((err) => {
        console.log(err.response)
        // if (err.response.data.code === 1006) {
        //   this.props.history.push("/unavailable-profile");
        // } else if (err.response.data.code === 1004) {
        //   this.setState({ isAccessDenied: true });
        //   // this.props.history.push("/unauthorized-user");
        // }
        if (err.response.status === 404) {
          this.props.history.push("/my-matches");
        } else {
          this.props.history.push("/unavailable-profile");
        }
      });
  };
  render() {
    return (
      this.state.isAccessDenied && (
        <IncorrectToken title={`Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
      )
    );
  }
}
