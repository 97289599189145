import {
  FETCH_ALL_INTEREST,
  ACTIVE_BTN_TYPE,
  FETCH_INT_POST,
  FETCH_INT_POST_WIHOUT_SEEN,
  FETCH_MATCH_POST,
  REMOVE_POST,
  IS_OPEN_ACCORDIAN,
  FETCH_ARCHIVED,
  FETCH_RESTRICTED_POST,
  SET_PRE_LOADING,
  FETCH_MATCHED_POSTS,
  FETCH_INTEREST_POSTS,
  FETCH_SPAM_INTEREST_POSTS,
  FETCH_ARCHIVED_MATCHED_POSTS,
  FETCH_NEW_MATCHED_POSTS,
  FETCH_SENT_INTERESTS,
  FETCH_ARCHIVED_INTEREST_POSTS,
  FETCH_ARCHIVED_SPAM_INTEREST_POSTS,
  FETCH_ARCHIVED_SENT_INTERESTS,
  FETCH_NOTIFICATION_COUNT,
  UPDATE_MATCHED_POSTS,
  UPDATE_SEEN_POSTS,
  SET_INTEREST_SIDE_MENU_LOADING,
  SET_SPAM_INTEREST_SIDE_MENU_LOADING,
  MARK_INTEREST_SEEN,
  FETCH_MESSAGE_POST_DATA,
  FETCH_UNMATCHED_POSTS,
  PAGE_VIEW_INITIALIZED
} from "../actions/types";

const initialState = {
  allInterests: {},
  activeBtnType: {
    sent: false,
    received: false,
    match: true,
  },
  post: {},
  messagePostData: {},
  inactiveProfile: false,
  isOpenAccodian: false,
  preLoading: false,
  sideMenuPosts: {
    matched: [],
    page: 0,
  },
  sideMenuUnmatched:{
    unmatched:[],
    page:0
  },
  updatedId: "no update",
  sideMenuInterests: {
    interests: [],
    page: 0
  },
  sideMenuSpamInterests: {
    interests: [],
    page: 0
  },
  sideMenuArchivedMatched: {
    archivedMatched: []
  },
  sideMenuNewMatches: {
    newMatches: []
  },
  sideMenuSentInterests: {
    sent: [],
    page: 0
  },
  sideMenuArchivedInterests: {
    archived: []
  },
  sideMenuSpamArchivedInterests: {
    archived: []
  },
  sideMenuArchivedSentInterests: {
    archived: []
  },
  notifications: {
    messages: 0,
    matches: 0,
    interests: 0,
    all: 0
  },
  sideMenuNewMatchesLoading: true,
  sideMenuMatchesAllFetched: false,
  sideMenuReceivedAllFetched: false,
  sideMenuUnmatchesAllFetched: true,
  sideMenuSpamReceivedAllFetched: false,
  sideMenuSentAllFetched: false,
  sideMenuInterestsLoading: false,
  sideMenuSpamInterestsLoading: false,
  sideMenuArchivedMatchesAllFetched: false,
  sideMenuArchivedReceivedAllFetched: false,
  sideMenuArchivedSentAllFetched: false,
  sideMenuSpamArchivedReceivedAllFetched: false,
  initializedView: false
};

const intBtnReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_INTEREST:
      return {
        ...state,
        allInterests: action.payload,
      };
    case ACTIVE_BTN_TYPE:
      return {
        ...state,
        activeBtnType: action.payload,
        post: {},
        isOpenAccodian: false,
      };
    case FETCH_INT_POST:
      return {
        ...state,
        post: action.payload,
      };
    case FETCH_INT_POST_WIHOUT_SEEN:
      return {
        ...state,
        post: action.payload,
      };
    case FETCH_MATCH_POST:
      return {
        ...state,
        post: action.payload,
        preLoading: false,
      };
    case REMOVE_POST:
      return {
        ...state,
        post: action.payload,
      };
    case IS_OPEN_ACCORDIAN:
      return {
        ...state,
        isOpenAccodian: action.payload,
      };
    case FETCH_ARCHIVED:
      return {
        ...state,
        allInterests: action.payload,
      };
    case FETCH_RESTRICTED_POST:
      return {
        ...state,
        post: action.payload,
      };
    case SET_PRE_LOADING:
      return {
        ...state,
        preLoading: action.payload,
      };
    case FETCH_MATCHED_POSTS:
      const filteredList = action.payload.matched.filter(interest => {
        return interest.post !== undefined && interest.post !== null;
      });
      return {
        ...state,
        sideMenuPosts: action.payload.page == 0 ? {'matched': filteredList, "page": action.payload.page} : {'matched': state.sideMenuPosts.matched.concat(filteredList), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuMatchesAllFetched: action.payload.pageSize > action.payload.matched.length,
        initializedView: true,
      } 
      case FETCH_UNMATCHED_POSTS:

        return {
          ...state,
          sideMenuUnmatched: action.payload.page == 0 ? {'unmatched': action.payload.unmatched, "page": action.payload.page} : {'unmatched': state.sideMenuUnmatched.unmatched.concat(action.payload.unmatched), "page": action.payload.page},
          sideMenuInterestsLoading: false,
          sideMenuUnmatchesAllFetched: action.payload.pageSize > action.payload.unmatched.length,
        }
    case FETCH_INTEREST_POSTS:
      return {
        ...state,
        sideMenuInterests: action.payload.page == 0 ? {'interests': action.payload.interests, "page": action.payload.page} : {'interests': state.sideMenuInterests.interests.concat(action.payload.interests), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuReceivedAllFetched: action.payload.pageSize > action.payload.interests.length ? true: false,
        initializedView: true,
      }
    case FETCH_SPAM_INTEREST_POSTS:
      return {
        ...state,
        sideMenuSpamInterests: action.payload.page == 0 ? {'interests': action.payload.interests, "page": action.payload.page} : {'interests': state.sideMenuSpamInterests.interests.concat(action.payload.interests), "page": action.payload.page},
        sideMenuSpamInterestsLoading: false,
        sideMenuSpamReceivedAllFetched: action.payload.pageSize > action.payload.interests.length ? true: false
      }
    case FETCH_ARCHIVED_MATCHED_POSTS:
      return {
        ...state,
        sideMenuArchivedMatched: action.payload.page == 0 ? {'archivedMatched': action.payload.archivedMatched, "page": action.payload.page} : {'archivedMatched': state.sideMenuArchivedMatched.archivedMatched.concat(action.payload.archivedMatched), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuArchivedMatchesAllFetched: action.payload.pageSize > action.payload.archivedMatched.length ? true: false
      }
    case FETCH_NEW_MATCHED_POSTS: {
      return {
        ...state,
        sideMenuNewMatches: action.payload,
        sideMenuNewMatchesLoading: false
      }
    }
    case FETCH_SENT_INTERESTS: {
      return {
        ...state,
        sideMenuSentInterests: action.payload.page == 0 ? {'sent': action.payload.sent, "page": action.payload.page} : {'sent': state.sideMenuSentInterests.sent.concat(action.payload.sent), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuSentAllFetched: action.payload.pageSize > action.payload.sent.length ? true: false,
        initializedView: true,
      }
    }
    case FETCH_ARCHIVED_INTEREST_POSTS: {
      return {
        ...state,
        sideMenuArchivedInterests: action.payload.page == 0 ? {'archived': action.payload.archived, "page": action.payload.page} : {'archived': state.sideMenuArchivedInterests.archived.concat(action.payload.archived), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuArchivedReceivedAllFetched: action.payload.pageSize > action.payload.archived.length ? true: false
      }
    }
    case FETCH_ARCHIVED_SPAM_INTEREST_POSTS: {
      return {
        ...state,
        sideMenuSpamArchivedInterests: action.payload.page == 0 ? {'archived': action.payload.archived, "page": action.payload.page} : {'archived': state.sideMenuSpamArchivedInterests.archived.concat(action.payload.archived), "page": action.payload.page},
        sideMenuSpamInterestsLoading: false,
        sideMenuSpamArchivedReceivedAllFetched: action.payload.pageSize > action.payload.archived.length ? true: false
      }
    }
    case FETCH_ARCHIVED_SENT_INTERESTS: {
      return {
        ...state,
        sideMenuArchivedSentInterests: action.payload.page == 0 ? {'archived': action.payload.archived, "page": action.payload.page} : {'archived': state.sideMenuArchivedSentInterests.archived.concat(action.payload.archived), "page": action.payload.page},
        sideMenuInterestsLoading: false,
        sideMenuArchivedSentAllFetched: action.payload.pageSize > action.payload.archived.length ? true: false
      }
    }
    case FETCH_NOTIFICATION_COUNT: {
      return {
        ...state,
        notifications: {messages: action.payload.messages, matches: action.payload.matches, interests: action.payload.interests, all: action.payload.messages+action.payload.matches+action.payload.interests}
      }
    }
    case UPDATE_MATCHED_POSTS: {
      let updatingItem = state.sideMenuPosts.matched.filter(item => item.id == action.payload.post.data.interestId)[0];
      updatingItem['latestChat'] = action.payload.post.data;      
      return {
        ...state,
        sideMenuPosts: {
          'matched': [updatingItem].concat(state.sideMenuPosts.matched.filter(item => item.id !== action.payload.post.data.interestId)),
          page: state.sideMenuPosts.page
        },
        updatedId: action.payload.post.data.id,
      }
    }
    case UPDATE_SEEN_POSTS: {
      let newArray = state.sideMenuPosts.matched;
      let objIndex = newArray.findIndex((obj => obj.id == action.payload.id));
      if (newArray[objIndex] && newArray[objIndex]['latestChat']) {
        if(newArray[objIndex]['latestChat'].sender !== 'SELF'){
          newArray[objIndex]['latestChat']['seen'] = true;
        }
        return {
          ...state,
          sideMenuPosts: {
            'matched': newArray,
            page: state.sideMenuPosts.page
          },
          updatedId: action.payload.id,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case SET_INTEREST_SIDE_MENU_LOADING: {
      return {
        ...state,
        sideMenuInterestsLoading: true
      }
    }
    case SET_SPAM_INTEREST_SIDE_MENU_LOADING: {
      return {
        ...state,
        sideMenuSpamInterestsLoading: true
      }
    }
    case MARK_INTEREST_SEEN: {
      let newArray = state.sideMenuInterests.interests;
      let objIndex = newArray.findIndex((obj => obj.id == action.payload.id));
      if (newArray[objIndex] && !newArray[objIndex]['likeSeen']) {
        newArray[objIndex]['likeSeen'] = true;
        return {
          ...state,
          sideMenuInterests: {
            'interests': newArray,
            page: state.sideMenuInterests.page
          }
        }
      } else {
        return {
          ...state
        }
      }
    }
    case FETCH_MESSAGE_POST_DATA: {
      return {
        ...state,
        messagePostData: action.payload.match,
        inactiveProfile: action.payload.status
      }
    }
    case PAGE_VIEW_INITIALIZED: {
      return {
        ...state,
        initializedView: action.payload
      }
    }
    default:
      return state;
  }
};

export default intBtnReducer;
