import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import close from "../../../images/close.png";
import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";

function IgnoreModal(props) {
  return (
    <Dialog
      open={props.ignoreModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="message-modal"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        {props.gender === "MALE" ? (
          <img src={groom} alt="groom" className="messager" />
        ) : (
          <img src={bride} alt="bride" className="messager" />
        )}
        <span className="receivers-name">
          {/* {props.fname} {props.lnameMasked} */}
          {props.displayName}
        </span>
      </DialogTitle>
      <DialogContent dividers></DialogContent>
      <DialogContent>
        <img
          src={close}
          alt="close"
          className="close-message-modal"
          onClick={props.handleModalClose}
        />

        {props.commingMsg && <p className="message">{props.commingMsg}</p>}

        <Alert severity="info" style={{ padding: "0 10px" }}>
          {props.lng ? (
            `You will not be able to respond to this advertisement after you ignore this request. This message will be displayed to ${
              props.gender === "MALE" ? " him" : " her"
            }.`
          ) : (
            <span className="sinhala-w-600 sinhala-size-12">
              ඔබ මෙම ඉල්ලීම නොසලකා හැරීමෙන් පසුව නැවත ඔබට මෙම දැන්වීමට ප්‍රතිචාර
              දැක්වීමට නොහැකි වනු ඇත. මෙම පණිවිඩය{" "}
              {props.gender === "MALE" ? " ඔහුට" : " ඇයට"} දර්ශනය වේ.
            </span>
          )}
        </Alert>

        <textarea
          className="message-textarea"
          rows="5"
          name="message"
          value={props.ignoreReason}
          onChange={props.handleIgnoreReason}
          placeholder={
            props.lng === true
              ? "Message (Optional)"
              : "පණිවිඩය (අත්‍යවශ්‍ය නොවේ)"
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="accept-btn"
          onClick={props.handleIgnoreAllInt}
          color="primary"
        >
          {props.loadingIgnore ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            <span>
              {props.lng === true ? (
                "Ignore Request"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  නොසලකා හරින්න
                </span>
              )}
            </span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IgnoreModal;
