import * as Yup from "yup";
import {
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
} from "../../../../utils";
import FormMeta from "../../../../meta/edit-self-profile.json";

const schema = ({ t }) => {
  return Yup.object().shape({
    fnameReview: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.firstname") })
      )
      .min(
        FormMeta.firstNameReview.min,
        t("errors.formFieldTooShort", { fieldName: t("common.firstname") })
      )
      .max(
        FormMeta.firstNameReview.max,
        t("errors.formFieldTooLong", { fieldName: t("common.firstname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.firstname"),
        }),
      }),
    lnameReview: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.lastname") })
      )
      .max(
        FormMeta.lastNameReview.max,
        t("errors.formFieldTooLong", { fieldName: t("common.lastname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.lastname"),
        }),
      }),
    caste: Yup.string().max(
      FormMeta.casteReview.max,
      t("errors.formFieldTooLong", { fieldName: t("common.caste") })
    ),
  });
};

export default schema;
