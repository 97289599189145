import React, { useContext } from "react";
import PageHead from "../../components/atoms/page-head";
// import Navbar from "../../../components/common/Navbar";
import { Box, Grid, Typography } from "@material-ui/core";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import IconBox from "./icon-box";
import "./style.css";

import messengerIcon from "../../../images/messenger.png";
import whatsappIcon from "../../../images/whatsapp.png";
import Config from "../../config";
import { useTranslation } from "react-i18next";
import withLanguageMigrator from "../../hoc/language-migrator";

const HELP_OPTIONS = [
  { id: "account_creation", label: "Account Creation" },
  { id: "matching_with_other", label: "Matching with other ads" },
  { id: "settings", label: "Settings" },
];

const HelpPageIn = ({ lng }) => {
  // const { lng, handleLng } = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <PageHead title={`Help | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Box className="help-page-container">
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container content-container"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            xs={10}
            sm={8}
            md={6}
            style={{ rowGap: 25, marginTop: "-25px" }}
          >
            <Typography align="center" className="help-page-heading">
              {t("supports.helpCenter")}
            </Typography>

            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ rowGap: 15 }}
            >
              {HELP_OPTIONS.map((option, index) => (
                <Grid
                  key={index}
                  container
                  direction="row"
                  className="help-option-selector"
                  onClick={() =>
                    history.push({
                      pathname: "/faq",
                      state: { optionType: option.id },
                    })
                  }
                >
                  <Typography className="help-option-selector-txt">
                    {option.label}
                  </Typography>
                  <i className="fas fa-angle-right help-option-selector-icon" />
                </Grid>
              ))}
            </Grid>

            <Typography
              align="center"
              className="help-page-heading"
            >
              {t("supports.support")}
            </Typography>

            <Grid container direction="column" style={{ rowGap: 15 }}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid style={{ width: "50%" }}>
                  <IconBox
                    icon={whatsappIcon}
                    primaryText="Whatsapp Live Chat"
                    href={Config.WHATSAPP_URL}
                  />
                </Grid>
                <Grid style={{ width: "45%" }}>
                  <IconBox
                    icon={messengerIcon}
                    primaryText="Messenger Live Chat"
                    href={Config.MESSENGER_URL}
                  />
                </Grid>
              </Grid>

              <IconBox
                iconClass={<i className="fas fa-headset fa-2x" />}
                primaryText="Hotline"
                href={`tel:${Config.PHONE_NUMBER}`}
                secondaryText={`${Config.PHONE_NUMBER}</br>${Config.PHONE_NUMBER_MOBILE}</br>`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default withLanguageMigrator(HelpPageIn);
