import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Close from "../../images/close.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function NeedToCreateAnAccount(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <Dialog
      open={props.needToCreateAnAccount}
      onClose={props.needToCreateAnAccountClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0, paddingRight: 15 }}
      >
        <img
          src={Close}
          alt="close"
          className="close-modal"
          onClick={props.needToCreateAnAccountClose}
        />
      </DialogTitle>
      <DialogContent>
        <DialogTitle 
          id="alert-dialog-title"
          style={{ color: "#000", paddingLeft:0 , paddingTop: 2 }}>
            {props.lng ? "You're not logged in!" : <span className="sinhala-w-600 sinhala-size-13">ඔබ වෙබ් අඩවියට පිවිසී නොමැත!</span>}
        </DialogTitle>
        <DialogContentText id="alert-dialog-description">
          {props.lng ? (
            "Please login to your existing account or create a new account to send responses. Your message and profile details will be directly delivered to this advertiser for a faster response."
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              ප්‍රතිචාර දැක්වීම සදහා ඔබගේ පවතින ගිණුමට පිවිසෙන්න 
              හෝ නව ගිණුමක් සාදන්න. ඔබේ පණිවිඩය සහ විස්තර 
              මෙම දැන්වීමෙහි හිමිකරු වෙත සෘජුවම ලබා දෙනු ඇත.
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.setItem("redirect-after-login", JSON.stringify(location));
            history.push("/auth")
          }}
          style={{ background: "#000", color: "#fff" }}
        >
          {props.lng ? "Create/Login" : "සාදන්න/පිවිසෙන්න"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NeedToCreateAnAccount;
