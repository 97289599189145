import { Grid } from "@material-ui/core";
import React from "react";
import { SectionSubHeading } from "../../custom-typography";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown from "../../../../../../components/molecules/form-dropdown";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";

const HabbitsInformation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    values,
    errors,
    initialValues,
    submitCount,
    handleChange,
    setFieldError,
    setFieldValue,
  } = useFormikContext();
  const showFieldErrors = submitCount > 0;
  const { drinkingPreferences, smokingPrefernces, foodPreferences } =
    useSelector((state) => state.genericData);

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionSubHeading labelKey="common.habits" />
      <Grid container direction="row" className="row-gap-8">
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.drinking,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(FormMeta.drinking, values, t, setFieldError)
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.drinking,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.drinking.fieldName}
            value={values.drinking}
            onChange={handleChange}
            options={drinkingPreferences}
            keyExtractor={FormMeta.drinking.keyExtractor}
            labelExtractor={FormMeta.drinking.labelExtractor}
            lang={i18n.language}
            label={t(FormMeta.drinking.label)}
            errorText={showFieldErrors && errors.drinking}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.smoking,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(FormMeta.smoking, values, t, setFieldError)
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.smoking,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.smoking.fieldName}
            value={values.smoking}
            onChange={handleChange}
            options={smokingPrefernces}
            keyExtractor={FormMeta.smoking.keyExtractor}
            labelExtractor={FormMeta.smoking.labelExtractor}
            lang={i18n.language}
            label={t(FormMeta.smoking.label)}
            errorText={showFieldErrors && errors.smoking}
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.foodPreference,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(FormMeta.foodPreference, values, t, setFieldError)
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.foodPreference,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.foodPreference.fieldName}
            value={values.foodPreferenceId}
            onChange={handleChange}
            options={foodPreferences}
            keyExtractor={FormMeta.foodPreference.keyExtractor}
            labelExtractor={FormMeta.foodPreference.labelExtractor}
            lang={i18n.language}
            label={t(FormMeta.foodPreference.label)}
            errorText={showFieldErrors && errors.foodPreferenceId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HabbitsInformation;
