import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { setInitializedView } from "../../../actions/getMatchedPosts";

function IntButtonGroup(props) {
  const { sideMenuInterestsLoading } = useSelector((state) => state.matches);

  const [initialized, setInitialized] = useState(false);

  const contentLoading = useMemo(() => {
    return !initialized && sideMenuInterestsLoading;
  }, [initialized, sideMenuInterestsLoading]);

  useEffect(() => {
    return () => {
      props.setInitializedView(false);
    };
  }, []);

  let history = useHistory();
  return (
    <ButtonGroup
      variant="text"
      className="interest-group-btns"
      disabled={contentLoading}
    >
      <Button
        className={props.pageViewType === "message" ? "active-btn" : ""}
        onClick={() => history.push("/messages")}
      >
        {props.lng ? (
          "Messages"
        ) : (
          <span className="sinhala-w-600 sinhala-size-10">පණිවිඩ</span>
        )}{" "}
        {(props.matches &&
          props.matches.filter((el) => el.matchSeen === false && !el.latestChat)
            .length > 0) ||
        props.messagesNotificationCount + props.matchesNotificationCount > 0 ? (
          <span className="badge">
            {props.messagesNotificationCount + props.matchesNotificationCount}
          </span>
        ) : null}
      </Button>
      <Button
        className={props.pageViewType === "received" ? "active-btn" : ""}
        onClick={() => history.push("/interests")}
      >
        {props.lng ? (
          "Received"
        ) : (
          <span className="sinhala-w-600 sinhala-size-10">කැමැත්ත එවූ අය</span>
        )}{" "}
        {props.interestsNotificationCount > 0 && (
          <span className="badge">{props.interestsNotificationCount}</span>
        )}
      </Button>
      <Button
        className={props.pageViewType === "sent" ? "active-btn" : ""}
        onClick={() => history.push("/sent-interests")}
      >
        {props.lng ? (
          "Sent"
        ) : (
          <span className="sinhala-w-600 sinhala-size-10">යැවූ කැමැත්තන්</span>
        )}
      </Button>
    </ButtonGroup>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setInitializedView,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    matches: state.matches.sideMenuPosts.matched
      ? state.matches.sideMenuPosts.matched.filter((el) => !el.ignored)
      : [],
    sideMenuInterests: state.matches.sideMenuInterests.interests,
    allNotificationCount: state.matches.notifications.all,
    messagesNotificationCount: state.matches.notifications.messages,
    matchesNotificationCount: state.matches.notifications.matches,
    interestsNotificationCount: state.matches.notifications.interests,
    sideMenuSentInterests: state.matches.sideMenuSentInterests.sent,
    initializedView: state.matches.initializedView,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(IntButtonGroup);
