import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { Timeline, TimelineEvent } from "react-event-timeline";

import { removePost } from "../../../actions/removePost";
import { accordianSubTab } from "../../../actions/accordianSubTab";
import { fetchAllInterest } from "../../../actions/fetchAllInterest";
import { fetchInterestPost } from "../../../actions/fetchInterestPost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertModal from "./AlertModal";
import CancelRequestModeal from "./CancelRequestModeal";
import Alert from "@material-ui/lab/Alert";

const TimeLine = (props) => {
  const [alertModal, setAlertModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);

  const [likedTime, setLikedTime] = useState({});
  const [cancelTime, setCancelTime] = useState({});
  const [reqAcceptTime, setReqAcceptTime] = useState({});
  const [ignoreTime, setIgnoreTime] = useState({});
  const [unmatched, setUnmatched] = useState({});
  const [undoAutoRejectTime, setUndoAutoRejectTime] = useState({});

  useEffect(() => {
    if (props.post && props.post.timeline) {
      setLikedTime(props.post.timeline.filter((filter) => filter.eventType === "LIKED"));
      setCancelTime(props.post.timeline.filter((filter) => filter.eventType === "UNLIKED"));
      setReqAcceptTime(props.post.timeline.filter((filter) => filter.eventType === "MATCHED"));
      setIgnoreTime(props.post.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
      setUnmatched(props.post.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
      setUndoAutoRejectTime(props.post.timeline.filter((filter) => filter.eventType === "UNDO_AUTO_REJECT"));
    }
  }, [props]);

  const getLikedTime = () => {
    if (likedTime && likedTime.length > 0) {
      return likedTime[0].eventTime;
    } else {
      return props.post.likedAt ? props.post.likedAt : "";
    }
  };

  const getlikeBy = () => {
    if (likedTime && likedTime.length > 0) {
      return likedTime[0].actionBy;
    } else {
      return false;
    }
  };

  const getCancelSentTime = () => {
    if (cancelTime && cancelTime.length > 0) {
      return cancelTime[0].eventTime;
    } else {
      return false;
    }
  };

  const getAcceptTime = () => {
    if (reqAcceptTime && reqAcceptTime.length > 0) {
      return reqAcceptTime[0].eventTime;
    } else {
      return props.post.matchedAt ? props.post.matchedAt : "";
    }
  };

  const getAcceptBy = () => {
    if (reqAcceptTime && reqAcceptTime.length > 0) {
      return reqAcceptTime[0].actionBy;
    } else {
      return false;
    }
  };

  const getAcceptCancelTime = () => {
    if (ignoreTime && ignoreTime.length > 0) {
      return ignoreTime[0].eventTime;
    } else {
      return false;
    }
  };

  const getIgnoredBy = () => {
    if (ignoreTime && ignoreTime.length > 0) {
      return ignoreTime[0].actionBy;
    } else {
      return false;
    }
  };

  const getUnmatchedTime = () => {
    if (unmatched && unmatched.length > 0) {
      return unmatched[0].eventTime;
    } else {
      return false;
    }
  };

  const getUndoAutoRejectTime = () => {
    if (undoAutoRejectTime && undoAutoRejectTime.length > 0) {
      return undoAutoRejectTime[0].eventTime;
    } else {
      return false;
    }
  };

  const getUndoAutoRejectBy = () => {
    if (undoAutoRejectTime && undoAutoRejectTime.length > 0) {
      return undoAutoRejectTime[0].actionBy;
    } else {
      return false;
    }
  };

  const getUnmatchedBy = () => {
    if (unmatched && unmatched.length > 0) {
      return unmatched[0].actionBy;
    } else {
      return false;
    }
  };

  const handleIgnoreModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      setAlertModal(true);
    } else {
      setWithdrawModal(true);
    }
  };

  const handleModalClose = () => {
    setAlertModal(false);
    setWithdrawModal(false);
  };

  const handleIgnoreAllInt = () => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }?rejectReason=`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setWithdrawModal(false);
        setLoadingIgnore(false);
        props.fetchAllInterest();
        props.fetchInterestPost(props.post.id, "interests");
        props.accordianSubTab(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoadingIgnore(false);
      });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "20vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <Typography
                variant="h6"
                className="heading"
                style={{ marginBottom: 15, marginTop: 0 }}
              >
                {props.lng ? (
                  "Timeline"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-16">
                    කාල රේඛාව
                  </span>
                )}
              </Typography>
              <Timeline className="time-line-div" lineColor="#c19d0d">
                {getlikeBy() === "SELF" ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={
                      getLikedTime()
                        ? moment
                            .utc(getLikedTime())
                            .local()
                            .format("DD MMM YYYY hh:mm a")
                        : "Before 22 July 2020"
                    }
                    title={
                      props.lng ? "Interest Request Sent" : "කැමැත්ත යවන ලදි"
                    }
                    icon={<i className="fas fa-paper-plane"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  >
                    {props.post.likeMessage && props.post.likeMessage}

                    {!props.post.ignored &&
                      props.post.timeline.filter(
                        (el) => el.eventType === "MATCHED"
                      ).length < 1 && (
                        <p
                          className="ignore-int-text-btn"
                          onClick={handleIgnoreModal}
                        >
                          <span style={{ fontSize: 13 }}>
                            {props.lng ? (
                              "Withdraw Request"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-13">
                                ඉල්ලීම අස්කර ගන්න
                              </span>
                            )}
                          </span>
                        </p>
                      )}
                  </TimelineEvent>
                ) : (
                  <TimelineEvent
                    className="time-line"
                    createdAt={
                      getLikedTime()
                        ? moment
                            .utc(getLikedTime())
                            .local()
                            .format("DD MMM YYYY hh:mm a")
                        : "Before 22 July 2020"
                    }
                    title={
                      props.lng
                        ? "Interest Request Received"
                        : "කැමැත්ත ලද දිනය "
                    }
                    icon={<i className="fas fa-paper-plane fa-rotate-180"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  >
                    {props.post.likeMessage && props.post.likeMessage}
                  </TimelineEvent>
                )}

                {getCancelSentTime() ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={moment
                      .utc(getCancelSentTime())
                      .local()
                      .format("DD MMM YYYY hh:mm a")}
                    title={
                      props.lng ? "Request Withdrawn" : "ඉල්ලා අස්කර ගන්නා ලදි"
                    }
                    icon={<i className="fas fa-ban"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  ></TimelineEvent>
                ) : (
                  <p style={{ margin: 0 }}></p>
                )}

                {getAcceptCancelTime() ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={moment
                      .utc(getAcceptCancelTime())
                      .local()
                      .format("DD MMM YYYY hh:mm a")}
                    title={
                      getIgnoredBy() === "SELF"
                        ? props.lng
                          ? "Ignored by You"
                          : "ඔබ විසින් නොසලකා හරින ලදි"
                        : getIgnoredBy() === "OTHER_PARTY"
                        ? props.lng
                          ? `Ignored by ${props.post.post.personalInfo.displayName}`
                          : `${props.post.post.personalInfo.displayName} විසින් නොසලකා හරින ලදි`
                        : props.lng
                        ? "Ignored"
                        : "නොසලකා හරින ලදි"
                    }
                    icon={<i className="fas fa-times"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  >
                    {/* {props.post.autoIgnored || getUndoAutoRejectTime()
                      ? getIgnoredBy() === "SELF"
                        ? props.lng
                          ? "Ignored by the System after 10 days from the request."
                          : "කැමැත්ත එවා දින 10 කට පසු ස්වයංක්‍රීයව නොසලකා හරින ලදී."
                        : null
                      : null} */}
                    {props.post.autoIgnored || getUndoAutoRejectTime()
                      ? props.lng
                        ? "Ignored by the System after 10 days from the request."
                        : "කැමැත්ත එවා දින 10 කට පසු ස්වයංක්‍රීයව නොසලකා හරින ලදී."
                      : null
                    }
                    {props.post.refuseReason && props.post.refuseReason}
                    {(!props.post.archived && props.post.autoIgnored) &&
                      <p
                        className="ignore-int-text-btn"
                        onClick={props.handleAcceptMessageModal}
                      >
                        <span>
                          {props.lng ? (
                            "Accept and Match"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-13">
                              පිළිගන්න
                            </span>
                          )}
                        </span>
                      </p>
                    }
                    {(props.post.archived && props.post.autoIgnored) &&
                      <Alert
                        severity="info"
                        style={{ marginBottom: 35, padding: "0 10px" }}
                      >
                        {props.lng ? (
                          "Can not be rewined after 14 days."
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-12">
                            දින 14 කට පසු නැවත පිළිගත නොහැක.
                          </span>
                        )}
                      </Alert>
                    }
                  </TimelineEvent>
                ) : (
                  <p style={{ margin: 0 }}></p>
                )}

                {getUndoAutoRejectTime() ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={moment
                      .utc(getUndoAutoRejectTime())
                      .local()
                      .format("DD MMM YYYY hh:mm a")}
                    title={
                      getUndoAutoRejectBy() === "SELF"
                        ? props.lng
                          ? "Undo Auto Reject by You"
                          : "ඔබ විසින් ස්වයංක්‍රීය ප්‍රතික්ෂේප කිරීම අහෝසි කරන ලදි"
                        : getUndoAutoRejectBy() === "OTHER_PARTY"
                        ? props.lng
                          ? `Undo Auto Reject by ${props.post.post.personalInfo.displayName}`
                          : `${props.post.post.personalInfo.displayName} විසින් ස්වයංක්‍රීය ප්‍රතික්ෂේප කිරීම අහෝසි කරන ලදි`
                        : props.lng
                        ? "Ignored"
                        : "නොසලකා හරින ලදි"
                    }
                    icon={<i className="fas fa-undo"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  >
                  </TimelineEvent>
                ) : (
                  <p style={{ margin: 0 }}></p>
                )}

                {getAcceptTime() ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={moment
                      .utc(getAcceptTime())
                      .local()
                      .format("DD MMM YYYY hh:mm a")}
                    title={
                      getAcceptBy() === "SELF" ||
                      getAcceptBy() === "OTHER_PARTY" ||
                      getlikeBy() === "OTHER_PARTY"
                        ? props.lng
                          ? `You Matched with ${props.post.post.personalInfo.displayName}`
                          : `ඔබ ${props.post.post.personalInfo.displayName} සමඟ ගැලපේ`
                        : props.lng
                        ? `Matched ${getAcceptBy() ? getAcceptBy() : ""}`
                        : "ගැලපුනා"
                    }
                    icon={<i className="fas fa-heart"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  >
                    {props.post.matchMessage && props.post.matchMessage}
                    {(props.post.ignored || ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)) ? null : (
                      <p
                        className="ignore-int-text-btn"
                        onClick={props.handleUnmatchModal}
                      >
                        <span>
                          {props.lng ? (
                            "Unmatch"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-13">
                              නොගලපන්න
                            </span>
                          )}
                        </span>
                      </p>
                    )}
                  </TimelineEvent>
                ) : (
                  <p style={{ margin: 0 }}></p>
                )}

                {getUnmatchedTime() ? (
                  <TimelineEvent
                    className="time-line"
                    createdAt={moment
                      .utc(getUnmatchedTime())
                      .local()
                      .format("DD MMM YYYY hh:mm a")}
                    title={
                      getUnmatchedBy() === "SELF"
                        ? props.lng
                          ? "Unmatched by You"
                          : "ඔබ විසින් ගැලපීම ඉවත් කරන ලදී"
                        : props.lng
                        ? `Unmatched by ${props.post.post.personalInfo.displayName}`
                        : `${props.post.post.personalInfo.displayName} විසින් ගැලපීම ඉවත් කරන ලදී`
                    }
                    icon={<i className="fas fa-heart-broken"></i>}
                    bubbleStyle={{
                      color: "#000",
                      border: "1.5px solid #c19d0d",
                      fontSize: 15,
                    }}
                    iconStyle={{
                      cursor: "default",
                    }}
                  ></TimelineEvent>
                ) : (
                  <p style={{ margin: 0 }}></p>
                )}
              </Timeline>
            </React.Fragment>
          )}

          <AlertModal
            lng={props.lng}
            alertModal={alertModal}
            handleModalClose={handleModalClose}
            freeRenew={props.freeRenew}
            loading={props.loading}
          />

          <CancelRequestModeal
            lng={props.lng}
            withdrawModal={withdrawModal}
            handleModalClose={handleModalClose}
            handleIgnoreAllInt={handleIgnoreAllInt}
            loadingIgnore={loadingIgnore}
          />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllInterest: fetchAllInterest,
      fetchInterestPost,
      removePost,
      accordianSubTab,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(TimeLine);
