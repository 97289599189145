import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const DataColumn = ({ label, value, visibility = true, className = "" }) => {
  const { i18n } = useTranslation();

  const displayValue = useMemo(() => {
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((segement) => (
            <span>
              {segement}
              <br></br>
            </span>
          ))}
        </>
      );
    }

    return <span>{value || "-"}</span>;
  }, [value]);

  if (!visibility) {
    return false;
  }

  return (
    <Grid
      direction="row"
      container
      wrap="nowrap"
      className={`data-column ${className}`}
    >
      <Grid className="data-column-left">
        <Typography
          className={`typo-body1-${i18n.language} font-weight-500 data-column-left-label`}
        >
          {label}
        </Typography>
      </Grid>
      <Grid direction="row" container className="data-column-right">
        <Typography
          className={`typo-body1-${i18n.language} data-column-right-label`}
        >
          {displayValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DataColumn;
