import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import Footer from "../components/common/Footer";
import Config from "../v2/config";

import Info from "../images/access-denied.png";

import "../styles/disable-profile.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class IncorrectToken extends Component {
  state = {};

  handleBack = () => {
    this.props.history.goBack();
  };

  handleLogin = (e) => {
    e.preventDefault();
    document.getElementById("drawer-login-dialog-btn").click();
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="disable-div">
            <p className="message">
              {this.props.lng ? "Access Denied" : "භාවිතයට අවසර නැත"}
            </p>
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 100, opacity: 0.7 }} />
            </p>
            <p align="center" className="desc">
              {this.props.lng ? (
                "Content cannot be accessed by the logged-in user. Please log in to the correct ad account."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  පිවිසී ඇති පරිශීලකයාට අන්තර්ගතය නැරඹීමට නොහැක. කරුණාකර නිවැරදි
                  දැන්වීම් ගිණුමට පිවිසෙන්න.
                </span>
              )}
            </p>

            <Button
              variant="contained"
              onClick={this.handleLogin}
              className="login-btn"
              style={{ width: 120 }}
            >
              {this.props.lng ? (
                "Login"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  ප්‍රවේශ වන්න
                </span>
              )}
            </Button>
          </div>
        </Grid>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(IncorrectToken));
