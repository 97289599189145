const CommonParentCreateModel = {
  religionId: "",
  ethnicityId: "",
  professionId: "",
  otherProfession: "",
  caste: "",
  residentCountryCode: "",
  additionalInfo: "",
  type: "",
};

const CommonDisplayModel = {
  type: "",
  additionalInfo: "",
  caste: "",
  religion: "",
  ethnicity: "",
  profession: "",
  residentCountry: "",
};

export const ParentCreateModel = (parentType) => {
  const clone = { ...CommonParentCreateModel };
  clone.type = parentType;
  return clone;
};

export const ParentDisplayModel = (parentType) => {
  return { ...CommonDisplayModel, type: parentType };
};

export const PrivateInformationDisplayModel = {
  horoscopeMatching: "",
  birthCountry: "",
  birthTime: "",
  birthdate: "",
  birthCity: "",
  additionalInfo: "",
  email: "",
  phoneNumber: "",
  images: [],
  horoscopeImages: [],
};

export const OfflineResponseDisplayModel = {
  showOfflineId: "",
  subscribeMagazine: "",
  nameOfRecipient: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
};
