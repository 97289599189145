import React from "react";
import {
  Button,
  Table,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";

function TopAdInvoice(props) {
  return (
    <div className="invoice">
      <p className="inv-heading">
        {props.lng ? (
          "Invoice"
        ) : (
          <span className="sinhala-w-600 sinhala-size-14">බිල්පත</span>
        )}
      </p>
      <Table className="checkout-table" aria-label="simple table">
        <tbody>
          <TableRow>
            <TableCell component="td" scope="row">
              {props.lng ? "Top Ad Fee" : "Top Ad ගාස්තුව"}
            </TableCell>
            <TableCell align="right">
              {props.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : (
                `${props.currencySymbol} ${props.price.toFixed(2)}`
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              {props.lng ? "Total Due" : "ගෙවිය යුතු මුළු මුදල"}
            </TableCell>
            <TableCell align="right">
              {props.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : (
                `${props.currencySymbol}
                    ${props.price.toFixed(2)}`
              )}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </div>
  );
}

export default TopAdInvoice;
