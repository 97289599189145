import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

const FieldBox = ({ children, desktopViewOrder, mobileViewOrder }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const componentsMap = React.Children.toArray(children).reduce(
    (acc, child) => {
      acc[child.props.fieldName] = child;
      return acc;
    },
    {}
  );

  return (
    <>
      {!isMobileViewport
        ? desktopViewOrder.map((key) => componentsMap[key])
        : mobileViewOrder.map((key) => componentsMap[key])}
    </>
  );
};

export default FieldBox;
