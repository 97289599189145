import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import ReviewSection from "../../../../../components/molecules/review-section";
import DataColumn from "../../../../../components/molecules/data-column";
import LabelColumn from "../../../../../components/molecules/label-column";
import AppContext from "../../../../../context";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../../features/createflow";
import Constants from "../../../../../config/constant";
import { fieldVisibility } from "../../../../../utils";
import personalFormMeta from "../../../personal-create-account/schema/formMeta.json";
import reviewAndPayMeta from "../../schema/formMeta.json";
import { Grid, Typography } from "@material-ui/core";

const formMeta = { ...personalFormMeta, ...reviewAndPayMeta };

const ReviewPersonalInfo = () => {
  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { authTempProfile } = useSelector((state) => state.application);
  const { initialized } = useSelector((state) => state.genericData);
  const [displayValues, setDisplayValues] = useState({});

  useEffect(() => {
    if (initialized && !isEmpty(authTempProfile)) {
      generateDisplayValues({
        section: DataDisplaySection.personalInformation,
        lang: appLanguage,
      }).then((values) => setDisplayValues(values));
    }
  }, [appLanguage, initialized, authTempProfile]);

  return (
    <ReviewSection
      heading={t("common.personalInfo")}
      navigate={"complete-ad-personal"}
    >
      <Typography
        className={`review-section-title typo-h4-${i18n.language} capitalized-text margin-top-8`}
      >
        {t("post.basic")}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        <DataColumn
          label={t(formMeta.firstName.label)}
          value={displayValues.firstName}
          visibility={fieldVisibility(formMeta.firstName)}
        />
        <DataColumn
          label={t(formMeta.lastName.label)}
          value={displayValues.lastName}
          isPrivate={true}
          visibility={fieldVisibility(formMeta.lastName)}
        />
        <DataColumn
          label={t(formMeta.createdBy.label)}
          value={displayValues.accountOwner}
          visibility={fieldVisibility(formMeta.createdBy)}
        />
        <DataColumn
          label={t(formMeta.gender.label)}
          value={displayValues.gender}
          visibility={fieldVisibility(formMeta.gender)}
        />
        <DataColumn
          label={t(formMeta.ethnicity.label)}
          value={displayValues.ethnicity}
          visibility={fieldVisibility(formMeta.ethnicity)}
        />
        <DataColumn
          label={t(formMeta.religion.label)}
          value={displayValues.religion}
          visibility={fieldVisibility(formMeta.religion)}
        />
        <DataColumn
          label={t(formMeta.caste.label)}
          value={displayValues.caste}
          visibility={fieldVisibility(formMeta.caste)}
        />
        <DataColumn
          label={t(formMeta.motherTongueId.label)}
          value={displayValues.motherTongue}
          visibility={fieldVisibility(formMeta.motherTongueId)}
        />
        <DataColumn
          label={t(formMeta.communityId.label)}
          value={displayValues.community}
          visibility={fieldVisibility(formMeta.communityId)}
        />
        <DataColumn
          label={t(formMeta.subCommunity.label)}
          value={displayValues.subCommunity}
          visibility={fieldVisibility(formMeta.subCommunity)}
        />
        {!isEmpty(displayValues.gothra) && (
          <DataColumn
            label={t(formMeta.gothraId.label)}
            value={displayValues.gothra}
            visibility={fieldVisibility(formMeta.gothraId)}
          />
        )}
        <DataColumn
          label={t(formMeta.height.label)}
          value={displayValues.height}
          visibility={fieldVisibility(formMeta.height)}
        />
        <DataColumn
          label={t(formMeta.age.label)}
          value={t("createflow.ageDisplayFormat", {
            age: displayValues.age,
          })}
          visibility={fieldVisibility(formMeta.age)}
        />
        <DataColumn
          label={t(formMeta.civilStatus.label)}
          value={displayValues.civilStatus}
          visibility={fieldVisibility(formMeta.civilStatus)}
        />
        {!isEmpty(displayValues.hasChildren) && (
          <DataColumn
            label={t(formMeta.hasChildren.label)}
            value={displayValues.hasChildren}
            visibility={fieldVisibility(formMeta.hasChildren)}
          />
        )}
      </Grid>

      <Typography
        className={`review-section-title typo-h4-${i18n.language} capitalized-text margin-top-16`}
      >
        {t("post.residency")}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        {authTempProfile.postData?.personalInfo.residentCountryCode ===
        Constants.OTHER_COUNTRY_CODE ? (
          <DataColumn
            label={t(formMeta.country.label)}
            value={displayValues.residentCountry}
            visibility={fieldVisibility(formMeta.country)}
          />
        ) : (
          <>
            <DataColumn
              label={t(formMeta.country.label)}
              value={displayValues.residentCountry}
              visibility={fieldVisibility(formMeta.country)}
            />
            <DataColumn
              label={t(formMeta.residentRegion.label)}
              value={displayValues.residentRegion}
              visibility={fieldVisibility(formMeta.residentRegion)}
            />
          </>
        )}
        <DataColumn
          label={t(formMeta.residentCity.label)}
          value={displayValues.residentCity}
          visibility={fieldVisibility(formMeta.residentCity)}
        />
        {!isEmpty(displayValues.visaType) && (
          <DataColumn
            label={t(formMeta.visaType.label)}
            value={displayValues.visaType}
            visibility={fieldVisibility(formMeta.visaType)}
          />
        )}
      </Grid>

      <Typography
        className={`review-section-title typo-h4-${i18n.language} capitalized-text margin-top-16`}
      >
        {t("post.educationAndProfession")}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        <DataColumn
          label={t(formMeta.educationLevel.label)}
          value={displayValues.educationLevel}
          visibility={fieldVisibility(formMeta.educationLevel)}
        />
        <DataColumn
          label={t(formMeta.profession.label)}
          value={displayValues.profession}
          visibility={fieldVisibility(formMeta.profession)}
        />
      </Grid>

      <Typography
        className={`review-section-title typo-h4-${i18n.language} capitalized-text margin-top-16`}
      >
        {t("post.habits")}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        <DataColumn
          label={t(formMeta.drinking.label)}
          value={displayValues.drinking}
          visibility={fieldVisibility(formMeta.drinking)}
        />
        <DataColumn
          label={t(formMeta.smoking.label)}
          value={displayValues.smoking}
          visibility={fieldVisibility(formMeta.smoking)}
        />
        <DataColumn
          label={t(formMeta.foodPreference.label)}
          value={displayValues.foodPreference}
          visibility={fieldVisibility(formMeta.foodPreference)}
        />
      </Grid>

      <Typography
        className={`review-section-title typo-h4-${i18n.language} capitalized-text margin-top-16`}
      >
        {t("common.adDescription")}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        <LabelColumn
          value={displayValues.additionalInfo}
          visibility={fieldVisibility(formMeta.additionalInfo)}
        />
      </Grid>
    </ReviewSection>
  );
};

export default ReviewPersonalInfo;
