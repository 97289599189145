import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { removePost } from "../../actions/removePost";
import { accordianSubTab } from "../../actions/accordianSubTab";
import { getArchived } from "../../actions/getArchived";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getInnerHeight } from "./methods/getInnerHeight";
import Search from "./interest-com/Search";
import SentCard from "./interest-com/SentCard";

function SentSidebar(props) {
  const [search, setSearch] = useState("");
  const [sentArchivedCount, setSentArchivedCount] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem(
          "postId"
        )}/interests/archived_count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setSentArchivedCount(res.data.outgoingInterests);
      })
      .catch((err) => {
        console.log(err);
      });

    getInnerHeight("sent-bar-unique", 70);
  }, []);

  const removePost = () => {
    props.removePost();
    props.history.goBack();
  };

  return (
    <div
      className={
        props.isEmptyPost ? "interest-view-div" : "interest-view-div view-hide"
      }
      id="sent-bar-unique"
    >
      <span
        className="dis-back-btn back-btn-msg"
        onClick={removePost}
        style={{ display: "block", margin: 0, marginBottom: 15 }}
      >
        <i className="fas fa-angle-left"></i> {props.lng ? "Back" : "ආපසු යන්න"}
      </span>

      <Search lng={props.lng} search={props.search} setSearch={setSearch} />

      {props.posts.filter((el) => el.ignored === false).length > 0 && (
        <p className="heading">
          {props.lng ? (
            "Interests Sent"
          ) : (
            <span className="sinhala-w-600">යවන ලද කැමැත්තන්</span>
          )}
        </p>
      )}

      <SentCard
        posts={props.posts
          .filter((el) => el.ignored === false)
          .sort((a, b) => b.likedAt.localeCompare(a.likedAt))
          .filter(
            (el) =>
              el.post.personalInfo.displayName
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
          )}
        lng={props.lng}
      />

      {props.posts.filter((el) => el.ignored === true).length > 0 ||
      sentArchivedCount > 0 ? (
        <div
          className={props.isOpenAccodian ? "accordion active" : "accordion"}
          onClick={() => props.accordianSubTab(props.isOpenAccodian)}
        >
          {props.lng ? (
            "Withdrawn / Ignored"
          ) : (
            <span className="sinhala-w-600">
              කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම
            </span>
          )}
        </div>
      ) : null}
      {props.isOpenAccodian ? (
        <div className="panel">
          <SentCard
            posts={props.posts
              .filter((el) => el.ignored)
              .sort((a, b) => {
                const typeA =
                  a.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                    .length > 0
                    ? a.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                    : a.timeline.filter((el) => el.eventType === "UNLIKED");
                const typeB =
                  b.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                    .length > 0
                    ? b.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                    : b.timeline.filter((el) => el.eventType === "UNLIKED");

                return typeB[0].eventTime.localeCompare(typeA[0].eventTime);
              })}
            lng={props.lng}
          />
          {props.isArchived
            ? null
            : sentArchivedCount > 0 && (
                <p className="see-more-btn" onClick={() => props.getArchived()}>
                  See more...
                </p>
              )}
        </div>
      ) : null}
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      accordianSubTab: accordianSubTab,
      getArchived: getArchived,
      removePost: removePost,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    isOpenAccodian: state.matches.isOpenAccodian,
    isArchived: state.matches.allInterests.isArchived,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(SentSidebar));
