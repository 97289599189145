import { createReduxAction } from "../../../utils";

export const SET_APP_MONTHLY_MAGAZINE_VISIBILITY =
  "SET_APP_MONTHLY_MAGAZINE_VISIBILITY";
export const SET_APP_LANGUAGES = "SET_APP_LANGUAGES";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const SET_APP_LNG = "SET_APP_LNG";
export const SET_APP_AUTHENTICATED = "SET_APP_AUTHENTICATED";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";
export const SET_APP_AUTH_ACCOUNT = "SET_APP_AUTH_ACCOUNT";
export const SET_APP_AUTH_PROFILE = "SET_APP_AUTH_PROFILE";
export const SET_APP_AUTH_TEMP_PROFILE = "SET_APP_AUTH_TEMP_PROFILE";
export const SET_APP_PREV_LOCATION = "SET_APP_PREV_LOCATION";
export const SET_APP_CONFIG = "SET_APP_CONFIG";
export const SET_APP_SNACK_NOTIFICATION = "SET_APP_SNACK_NOTIFICATION";

export const setMonthlyMagazineVisibility = (payload) =>
  createReduxAction(SET_APP_MONTHLY_MAGAZINE_VISIBILITY, payload);

export const setLanguages = (payload) =>
  createReduxAction(SET_APP_LANGUAGES, payload);

export const setAppLanguage = (payload) =>
  createReduxAction(SET_APP_LANGUAGE, payload);

export const setAppLng = (payload) => createReduxAction(SET_APP_LNG, payload);

export const setAuthenticated = (payload) =>
  createReduxAction(SET_APP_AUTHENTICATED, payload);

export const setAppInitialized = (payload) =>
  createReduxAction(SET_APP_INITIALIZED, payload);

export const setAuthAccount = (payload) =>
  createReduxAction(SET_APP_AUTH_ACCOUNT, payload);

export const setAuthProfile = (payload) =>
  createReduxAction(SET_APP_AUTH_PROFILE, payload);

export const setAuthTempProfile = (payload) =>
  createReduxAction(SET_APP_AUTH_TEMP_PROFILE, payload);

export const setAuthPrevLocation = (payload) =>
  createReduxAction(SET_APP_PREV_LOCATION, payload);

export const setAppConfig = (payload) =>
  createReduxAction(SET_APP_CONFIG, payload);

export const setAppSnackNotification = (payload) => {
  const processedPayload = {
    severity: payload.severity ?? "success",
    message: payload.message ?? "",
  };
  return createReduxAction(SET_APP_SNACK_NOTIFICATION, processedPayload);
};
