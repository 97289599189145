import React, { useState, useEffect } from "react";

// import Navbar from "../components/common/Navbar";
import InterestView from "../components/my-matches/InterestView";
import MatchedInterest from "../components/my-matches/MatchedInterest";
import UnautorizedProfile from "../components/my-matches/interest-com/UnautorizedProfile";
import IncorrectToken from "../components/my-matches/interest-com/IncorrectToken";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

import { fetchAllInterest } from "../actions/fetchAllInterest";
import { handleIntBtns } from "../actions/handleIntBtns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withLanguageMigrator from "../v2/hoc/language-migrator";

const InterestsMatched = (props) => {
  const lng = props.lng;
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    props.handleIntBtns("match");
  }, []);

  useEffect(() => {
    props.fetchAllInterest();
  }, []);

  const isEmptyObj =
    Object.keys(props.post).length === 0 && props.post.constructor === Object;

  return isAccessDenied ? (
    <Unauthorize title={`Matches | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Matches | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div">
          <InterestView posts={props.matchedPosts} lng={lng} />

          {props.isMatchActive ? (
            isEmptyObj ? null : props.post.code ? (
              props.post.code === 1006 ? (
                <IncorrectToken lng={lng} />
              ) : props.post.code === 1004 ? (
                <UnautorizedProfile lng={lng} />
              ) : (
                <MatchedInterest lng={lng} />
              )
            ) : (
              <MatchedInterest lng={lng} />
            )
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAllInterest, handleIntBtns }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    isMatchActive: state.matches.activeBtnType.match,
    post: state.matches.post,
    matchedPosts: state.matches.allInterests.matches
      ? state.matches.allInterests.matches
      : [],
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withLanguageMigrator(InterestsMatched));
