import React, { Component } from "react";
import { 
    Button, 
    CircularProgress, 
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,} from "@material-ui/core";

// import close from "../../images/close.png";

export default class DeleteEmailModal extends Component {
  render() {
    return (
      <Dialog
        open={this.props.delemailModal}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        className="login-modal"
      >
         <DialogTitle
          id="alert-dialog-title"
          style={{ color: "#000", paddingBottom: 0 }}
        >
          {this.props.lng === true ? (
            "Are you sure?"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">ඔබට විශ්වාසද?</span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.lng === true ? (
              "Are you sure you want to delete this email?"
            ) : (
              <span className="sinhala-w-600 sinhala-size-13">
                ඔබට මෙම විද්‍යුත් තැපෑල ඉවත් කිරීමට අවශ්‍ය යැයි විශ්වාසද? 
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>
            {this.props.lng === true ? (
              "No"
            ) : (
              <span className="sinhala-w-600 sinhala-size-11">නැත</span>
            )}
          </Button>
          <Button
            onClick={this.props.handleDeleteEmail}
            style={{ background: "#000", color: "#fff" }}
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <span>
                {this.props.lng === true ? (
                  "Yes"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-11">ඔව්</span>
                )}
              </span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
