import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import RegisterNewCom from "../components/register/RegisterNewCom";
import PageHead from "../components/common/PageHead";
import Navbar from "../components/common/Navbar";
import Header from "../components/register/new/Header";
import ButtonNav from "../components/register/new/ButtonNav";
import Feature from "../components/register/new/Feature";
import Config from "../v2/config";

import "../styles/register-new.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class RegisterNew extends Component {
  state = {
    value: 0,
  };

  updateDimensions = () => {
    if (window.innerWidth > 768) {
      this.setState({ value: 0 });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }


  handleButtonNavs = (btn) => {
    this.setState({ value: btn });
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div
          className="register-new"
          style={{ background: "#f7f7f7", minHeight: "100vh" }}
        >
          <Header />

          <ButtonNav
            handleButtonNavs={this.handleButtonNavs}
            value={this.state.value}
          />

          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container cus-container-register cus-new-register-container"
          >
            <div className="reg-container">
              {this.state.value === 0 && (
                <div className="reg-col">
                  <RegisterNewCom lng={this.props.lng} />
                </div>
              )}
              {this.state.value !== 0 && (
                <div className="reg-col">
                  <Feature lng={this.props.lng} />
                </div>
              )}

              <div className="reg-col feature-col-cus">
                <Feature lng={this.props.lng} />
              </div>
            </div>
          </Grid>

          <p className="terms-n-policy">
            {this.props.lng
              ? "By signing up you are agreeing to our "
              : "ඉදිරියට යෑමෙන් ඔබ අපගේ "}
            <Link to="/privacy">
              {this.props.lng ? "Privacy Policy" : "රහස්‍යතා ප්‍රතිපත්තිය"}
            </Link>{" "}
            {this.props.lng ? "and" : "සහ"}{" "}
            <Link to="/terms">
              {this.props.lng ? "Terms of Use" : "සේවා කොන්දේසි "}
            </Link>
            {this.props.lng ? "" : "වලට එකග වේ"}.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(RegisterNew));
