import { createReduxAction } from "../../../utils";

export const SET_HOME_PAGE_POSTS = "SET_HOME_PAGE_POSTS";
export const SET_HOME_PAGE_FEATURED_POSTS = "SET_HOME_PAGE_FEATURED_POSTS";
export const SET_HOME_PAGE_PAGE_SIZE = "SET_HOME_PAGE_PAGE_SIZE";
export const SET_HOME_PAGE_CURRENT_PAGE = "SET_HOME_PAGE_CURRENT_PAGE";
export const SET_HOME_PAGE_TOTAL_PAGES = "SET_HOME_PAGE_TOTAL_PAGES";
export const SET_HOME_PAGE_TOTAL_COUNT = "SET_HOME_PAGE_TOTAL_COUNT";
export const SET_HOME_PAGE_COUNTRY_REGIONS = "SET_HOME_PAGE_COUNTRY_REGIONS";
export const SET_HOME_PAGE_COMMUNITIES = "SET_HOME_PAGE_COMMUNITIES";
export const SET_HOME_PAGE_SHADOWED_CRTIERIA =
  "SET_HOME_PAGE_SHADOWED_CRTIERIA";
export const SET_HOME_PAGE_LOADING = "SET_HOME_PAGE_LOADING";
export const SET_HOME_PAGE_PREFFERRED_CRITERIA =
  "SET_HOME_PAGE_PREFFERRED_CRITERIA";
export const SET_HOME_PAGE_PREFFERRED_ACTIVE =
  "SET_HOME_PAGE_PREFFERRED_ACTIVE";

export const SET_HOME_PAGE_MOBILE_FILTER_DRAWER =
  "SET_HOME_PAGE_MOBILE_FILTER_DRAWER";
export const SET_HOME_PAGE_SHOW_SAVE_PREFFERRED_CRITERIA_BTN =
  "SET_HOME_PAGE_SHOW_SAVE_PREFFERRED_CRITERIA_BTN";
export const SET_HOME_PAGE_SAVING_PREFFERRED_CRITERIA =
  "SET_HOME_PAGE_SAVING_PREFFERRED_CRITERIA";
export const SET_HOME_PAGE_MOBILE_SAVE_PREFFERRED_CRITERIA =
  "SET_HOME_PAGE_MOBILE_SAVE_PREFFERRED_CRITERIA";
export const SET_HOME_PAGE_FILTER_COUNT = "SET_HOME_PAGE_FILTER_COUNT";
export const SET_HOME_PAGE_INITIAL_RESULT_LOADED =
  "SET_HOME_PAGE_INITIAL_RESULT_LOADED";

export const setHomePageLoading = (payload) =>
  createReduxAction(SET_HOME_PAGE_LOADING, payload);

export const setHomePagePosts = (payload) =>
  createReduxAction(SET_HOME_PAGE_POSTS, payload);

export const setHomePageFeaturedPosts = (payload) =>
  createReduxAction(SET_HOME_PAGE_FEATURED_POSTS, payload);

export const setHomePagePageSize = (payload) =>
  createReduxAction(SET_HOME_PAGE_PAGE_SIZE, payload);

export const setHomePageCurrentPage = (payload) =>
  createReduxAction(SET_HOME_PAGE_CURRENT_PAGE, payload);

export const setHomePageTotalPages = (payload) =>
  createReduxAction(SET_HOME_PAGE_TOTAL_PAGES, payload);

export const setHomePageTotalCount = (payload) =>
  createReduxAction(SET_HOME_PAGE_TOTAL_COUNT, payload);

export const setHomePageCountryRegions = (payload) =>
  createReduxAction(SET_HOME_PAGE_COUNTRY_REGIONS, payload);

export const setHomePageCommunities = (payload) =>
  createReduxAction(SET_HOME_PAGE_COMMUNITIES, payload);

export const setHomePageShadowedCriteria = (payload) =>
  createReduxAction(SET_HOME_PAGE_SHADOWED_CRTIERIA, payload);

export const setHomePagePrefferredCriteria = (payload) =>
  createReduxAction(SET_HOME_PAGE_PREFFERRED_CRITERIA, payload);

export const setHomePagePrefferredActive = (payload) =>
  createReduxAction(SET_HOME_PAGE_PREFFERRED_ACTIVE, payload);

export const setHomePageMobileFilterDrawer = (payload) =>
  createReduxAction(SET_HOME_PAGE_MOBILE_FILTER_DRAWER, payload);

export const setHomePageShowSavePrefferredCriteria = (payload) =>
  createReduxAction(SET_HOME_PAGE_SHOW_SAVE_PREFFERRED_CRITERIA_BTN, payload);

export const setHomePageSavingPrefferredCriteria = (payload) =>
  createReduxAction(SET_HOME_PAGE_SAVING_PREFFERRED_CRITERIA, payload);

export const setHomePageMobileSavePrefferredCriteria = (payload) =>
  createReduxAction(SET_HOME_PAGE_MOBILE_SAVE_PREFFERRED_CRITERIA, payload);

export const setHomePageFilterCount = (payload) =>
  createReduxAction(SET_HOME_PAGE_FILTER_COUNT, payload);

export const setHomePageInitialResultLoaded = (payload) =>
  createReduxAction(SET_HOME_PAGE_INITIAL_RESULT_LOADED, payload);
