import React from "react";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

function ButtonNav(props) {
  return (
    <BottomNavigation
      value={props.value}
      onChange={(event, newValue) => props.handleButtonNavs(newValue)}
      showLabels
      className="button-navs"
    >
      <BottomNavigationAction label="Sign Up / Login" />
      <BottomNavigationAction label="Features" />
    </BottomNavigation>
  );
}

export default ButtonNav;
