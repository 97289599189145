
import { UPDATE_MATCHED_POSTS } from "./types";
import axios from "axios";

export const updateMatch = (match) => (dispatch) => {
  if (match) {
    dispatch({
      type: UPDATE_MATCHED_POSTS,
      payload: {
        post: match,
      },
    });
  }
};