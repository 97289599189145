import React from "react";
import Config from "../../v2/config";
// import ThumbNail from "static.poruwa.lk/blog/images/Article01/Cover-Image.jpg";
const { CURRENT_PLATFORM, PLATFORMS } = Config;

function BlogPost(props) {
  return (
    <a
      href="/blog"
      className="maganize-card"
      style={{ width: "100%", margin: '0' }}
      id="magasize-heading"
    >
      <div className="filter-maga-div" id="filter-maga-div">
        <h1 className="magasize-heading" style={{ lineHeight: "15px" }}>
          {props.lng ? (
            "Blog"
          ) : (
            <span style={{ fontSize: 18 }}>බ්ලොග් පිටුව</span>
          )}
        </h1>
        <img
          src={
            CURRENT_PLATFORM === PLATFORMS.IN
              ? "https://static.poruwa.lk/blog/images/myvivah/covers/myvivah-blog-cover.jpg"
              : "https://static.poruwa.lk/blog/images/Article04/Cover-Image.jpg"
          }
          alt="blog"
          className="maganize-img"
        />
        {/* <span>
          {props.lng ? (
            "April 2022"
          ) : (
            <span style={{ fontSize: 12, lineHeight: "12px" }}>
              අප්‍රේල් 2022
            </span>
          )}
        </span> */}
      </div>
    </a>
  );
}

export default BlogPost;
