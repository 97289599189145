export const heightsArr = [
  { name: "4ft (122cm)", value: 121.92 },
  { name: "4ft 1in (124cm)", value: 124.46 },
  { name: "4ft 2in (127cm)", value: 127 },
  { name: "4ft 3in (130cm)", value: 129.54 },
  { name: "4ft 4in (132cm)", value: 132.08 },
  { name: "4ft 5in (135cm)", value: 134.62 },
  { name: "4ft 6in (137cm)", value: 137.16 },
  { name: "4ft 7in (140cm)", value: 139.7 },
  { name: "4ft 8in (142cm)", value: 142.24 },
  { name: "4ft 9in (145cm)", value: 144.78 },
  { name: "4ft 10in (147cm)", value: 147.32 },
  { name: "4ft 11in (150cm)", value: 149.86 },
  { name: "5ft (152cm)", value: 152.4 },
  { name: "5ft 1in (155cm)", value: 154.94 },
  { name: "5ft 2in (157cm)", value: 157.48 },
  { name: "5ft 3in (160cm)", value: 160.02 },
  { name: "5ft 4in (163cm)", value: 162.56 },
  { name: "5ft 5in (165cm)", value: 165.1 },
  { name: "5ft 6in (168cm)", value: 167.64 },
  { name: "5ft 7in (170cm)", value: 170.18 },
  { name: "5ft 8in (173cm)", value: 172.72 },
  { name: "5ft 9in (175cm)", value: 175.26 },
  { name: "5ft 10in (178cm)", value: 177.8 },
  { name: "5ft 11in (180cm)", value: 180.34 },
  { name: "6ft (183cm)", value: 182.88 },
  { name: "6ft 1in (185cm)", value: 185.42 },
  { name: "6ft 2in (188cm)", value: 187.96 },
  { name: "6ft 3in (191cm)", value: 190.5 },
  { name: "6ft 4in (193cm)", value: 193.04 },
  { name: "6ft 5in (196cm)", value: 195.58 },
  { name: "6ft 6in (198cm)", value: 198.12 },
  { name: "6ft 7in (201cm)", value: 200.66 },
  { name: "6ft 8in (203cm)", value: 203.2 },
  { name: "6ft 9in (206cm)", value: 205.74 },
  { name: "6ft 10in (208cm)", value: 208.28 },
  { name: "6ft 11in (211cm)", value: 210.82 },
  { name: "7ft (213cm)", value: 213.36 },
  { name: "7ft 1in (216cm)", value: 215.9 },
  { name: "7ft 2in (218cm)", value: 218.44 },
  { name: "7ft 3in (221cm)", value: 220.98 },
  { name: "7ft 4in (224cm)", value: 223.52 },
  { name: "7ft 5in (226cm)", value: 226.06 },
  { name: "7ft 6in (229cm)", value: 228.6 },
];

export const heightsWithShortLabels = [
  { name: "4ft", value: 121.92 },
  { name: "4ft 1in", value: 124.46 },
  { name: "4ft 2in", value: 127 },
  { name: "4ft 3in", value: 129.54 },
  { name: "4ft 4in", value: 132.08 },
  { name: "4ft 5in", value: 134.62 },
  { name: "4ft 6in", value: 137.16 },
  { name: "4ft 7in", value: 139.7 },
  { name: "4ft 8in", value: 142.24 },
  { name: "4ft 9in", value: 144.78 },
  { name: "4ft 10in", value: 147.32 },
  { name: "4ft 11in", value: 149.86 },
  { name: "5ft", value: 152.4 },
  { name: "5ft 1in", value: 154.94 },
  { name: "5ft 2in", value: 157.48 },
  { name: "5ft 3in", value: 160.02 },
  { name: "5ft 4in", value: 162.56 },
  { name: "5ft 5in", value: 165.1 },
  { name: "5ft 6in", value: 167.64 },
  { name: "5ft 7in", value: 170.18 },
  { name: "5ft 8in", value: 172.72 },
  { name: "5ft 9in", value: 175.26 },
  { name: "5ft 10in", value: 177.8 },
  { name: "5ft 11in", value: 180.34 },
  { name: "6ft", value: 182.88 },
  { name: "6ft 1in", value: 185.42 },
  { name: "6ft 2in", value: 187.96 },
  { name: "6ft 3in", value: 190.5 },
  { name: "6ft 4in", value: 193.04 },
  { name: "6ft 5in", value: 195.58 },
  { name: "6ft 6in", value: 198.12 },
  { name: "6ft 7in", value: 200.66 },
  { name: "6ft 8in", value: 203.2 },
  { name: "6ft 9in", value: 205.74 },
  { name: "6ft 10in", value: 208.28 },
  { name: "6ft 11in", value: 210.82 },
  { name: "7ft", value: 213.36 },
  { name: "7ft 1in", value: 215.9 },
  { name: "7ft 2in", value: 218.44 },
  { name: "7ft 3in", value: 220.98 },
  { name: "7ft 4in", value: 223.52 },
  { name: "7ft 5in", value: 226.06 },
  { name: "7ft 6in", value: 228.6 },
];
