import { ACTIVE_BTN_TYPE } from "../actions/types";

export const handleIntBtns = (type) => {
  let data = {};
  if (type === "sent") {
    data = {
      sent: true,
      received: false,
      match: false,
    };
  } else if (type === "received") {
    data = {
      sent: false,
      received: true,
      match: false,
    };
  } else if (type === "match") {
    data = {
      sent: false,
      received: false,
      match: true,
    };
  }

  return {
    type: ACTIVE_BTN_TYPE,
    payload: data,
  };
};
