import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Button } from "@material-ui/core";

import Lock from "../../images/lock.png";

import "../../styles/not-yet-logged.css";

class NotLoggedBanner extends Component {
  state = {
    isEnable: true,
  };

  componentDidMount = () => {
    if (localStorage.getItem("auth_token")) {
      this.setState({ isEnable: false });
    }
  };

  handleLoginBtn = () => {
    document.getElementById("drawer-login-dialog-btn").click();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isEnable && (
          <div className="not-logged-in-banner">
            <img src={Lock} alt="lock pic" />
            <p className="heading">
              {this.props.lng
                ? "You’re not Logged in"
                : "ඔබ වෙබ් අඩවියට පිවිසී නොමැත"}
            </p>
            <p className="sub-heading">
              {this.props.lng
                ? "Please log in to show interest or respond to this advertisement online."
                : "මෙම දැන්වීමට අන්තර්ජාලය හරහා ප්‍රතිචාර දැක්වීමට කරුණාකර පිවිසෙන්න."}
            </p>

            <div className="action-con">
              <Button className="action-btn" onClick={this.handleLoginBtn}>
                {this.props.lng ? "Log in" : "පිවිසෙන්න"}
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(NotLoggedBanner);
