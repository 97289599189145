import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Grid, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import MessageMoreInfoButton from "./MessageMoreInfoButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
const TopicsHeader = ({
  language,
  parent,
  menuItems,
  heading,
  isSecondryItems,
  onClickBack,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: "10px" }}
    >
      <Grid
        item
        xs={6}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        {isSecondryItems ? (
          <Button
            startIcon={<ArrowBackIcon />}
            style={{
              color: "#cca326",
              backgroundColor: "transparent",
              paddingLeft: 0,
              textTransform: "capitalize",
            }}
            onClick={onClickBack}
            size="small"
          >
            <Typography className="heading">
              {language ? (
                <span>{heading}</span>
              ) : (
                <span className="sinhala-w-600">{heading}</span>
              )}
            </Typography>
          </Button>
        ) : (
          <Typography className="heading">
            {language ? (
              <span>{heading}</span>
            ) : (
              <span className="sinhala-w-600">{heading}</span>
            )}
          </Typography>
        )}
      </Grid>
      <MessageMoreInfoButton
        menuItems={menuItems}
        isSecondryItems={isSecondryItems}
      />
    </Grid>
    // <div className={props.isMatchedPosts ? "headerpanel" : ""}>
    //   {props.isMatchedPosts && (
    //     <p className="heading">
    //       {props.lng ? (
    //         "Messages"
    //       ) : (
    //         <span className="sinhala-w-600">පණිවිඩ</span>
    //       )}
    //     </p>
    //   )}
    //   <p style={{ textAlign: "right" }}>
    //     <OverlayTrigger
    //       trigger="click"
    //       placement={"left"}
    //       overlay={
    //         <Popover
    //           id={`popover-positioned-top`}
    //           onClick={() => props.markAllSeen(props)}
    //           className="allseenpopup"
    //           style={{
    //             backgroundColor: "#eaeaea",
    //             padding: "8px",
    //             borderRadius: "3px",
    //           }}
    //         >
    //           <Popover.Body style={{ fontSize: "12px", cursor: "pointer" }}>
    //             Mark all as seen
    //           </Popover.Body>
    //         </Popover>
    //       }
    //       rootClose={true}
    //       onClick={document.body.click()}
    //     >
    //       <MoreHorizIcon className="allseenicon" />
    //     </OverlayTrigger>
    //   </p>
    // </div>
  );
};

TopicsHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  language: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  isSecondryItems: PropTypes.bool.isRequired,
};

export default TopicsHeader;
