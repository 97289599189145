import { CircularProgress, Grid, Paper } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import { SectionHeading } from "../custom-typography";
import BasicInformation from "./basic-info-form";
import ResidencyInformation from "./residency-information";
import EducationAndProfession from "./education-and-profession";
import HabbitsInformation from "./habits-information";
import ParentInformation, { ParentType } from "./parent-information";
import HoroscopeInformation from "./horoscope-information";
import DifferentlyAbledInformation from "./differently-abled";
import SiblingInformation from "./sibling-information";

const InformationWithoutReview = ({ contentLoading }) => {
  const { i18n, t } = useTranslation();

  if (contentLoading) {
    return (
      <Paper variant="outlined" className="form-section-paper">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="full-height-container"
        >
          <CircularProgress className="branded-circullar-progress" />
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" className="form-section-paper">
      <Grid container direction="column" style={{ rowGap: 32 }}>
        <Grid item container direction="column" style={{ rowGap: 16 }}>
          <SectionHeading labelKey="common.personalInfo" />
          <Notice
            varaint={Varaint.primary}
            label={t("common.editadPersonalInfoNotice")}
            lang={i18n.language}
          />
          <BasicInformation />
          <ResidencyInformation />
          <EducationAndProfession />
          <HabbitsInformation />
        </Grid>
        <Grid item container direction="column" style={{ rowGap: 16 }}>
          <SectionHeading labelKey="common.parentsInfo" />
          <ParentInformation type={ParentType.FATHER} />
          <ParentInformation type={ParentType.MOTHER} />
        </Grid>
        <Grid item container direction="column" style={{ rowGap: 16 }}>
          <SectionHeading labelKey="common.siblingsInfo" />
          <SiblingInformation />
        </Grid>
        <Grid item container direction="column" style={{ rowGap: 16 }}>
          <SectionHeading labelKey="common.horoscopeDetails" />
          <Notice
            varaint={Varaint.primary}
            label={t("common.editAdHoroscopeInfoNotice")}
            lang={i18n.language}
          />
          <HoroscopeInformation />
        </Grid>

        <DifferentlyAbledInformation />
      </Grid>
    </Paper>
  );
};

export default InformationWithoutReview;
