import Constants from "../../../config/constant";

const generatePersonalInfo = ({ oldValues, newValues }) => {
  const PersonalInfo = {
    fname: newValues.fname ?? oldValues.fname,
    lname: newValues.lname ?? oldValues.lname,
    dob: newValues.dob ?? oldValues.dob,
    gender: newValues.gender ?? oldValues.gender,
    height: newValues.height ?? oldValues.height,
    religionId: newValues.religionId ?? oldValues.religionId,
    ethnicityId: newValues.ethnicityId ?? oldValues.ethnicityId,
    originCountryCode:
      newValues.originCountryCode ?? oldValues.originCountryCode,
    residentCountryCode:
      newValues.residentCountryCode ?? oldValues.residentCountryCode,
    residentRegionId: newValues.residentRegionId ?? oldValues.residentRegionId,
    residentCity: newValues.residentCity ?? oldValues.residentCity,
    visaTypeId: newValues.visaTypeId ?? oldValues.visaTypeId,
    educationLevelId: newValues.educationLevelId ?? oldValues.educationLevelId,
    professionId: newValues.professionId ?? oldValues.professionId,
    otherProfession: newValues.otherProfession ?? oldValues.otherProfession,
    civilStatusId: newValues.civilStatusId ?? oldValues.civilStatusId,
    drinking: newValues.drinking ?? oldValues.drinking,
    smoking: newValues.smoking ?? oldValues.smoking,
    foodPreferenceId: newValues.foodPreferenceId ?? oldValues.foodPreferenceId,
    caste: newValues.caste ?? oldValues.caste,
    additionalInfo: newValues.additionalInfo ?? oldValues.additionalInfo,
    displayNameFormat:
      newValues.displayNameFormat ?? oldValues.displayNameFormat,
    children: newValues.children ?? oldValues.children,
    residentCountryCodeOther: null,
  };

  if (PersonalInfo.residentCountryCode === Constants.OTHER_COUNTRY_CODE) {
    PersonalInfo.residentCountryCodeOther = newValues.residentCountryCodeOther;
  }
  return PersonalInfo;
};

const Extensions = {
  generatePersonalInfo,
};

export default Extensions;
