import {
  FETCH_ARCHIVED_MATCHED_POSTS,
  SET_INTEREST_SIDE_MENU_LOADING,
} from "./types";
import axios from "axios";

export const getArchivedMatchedPosts =
  (page = 0) =>
  (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
          "postId"
        )}/interests/matches?archived=true&pageNum=${page}&pageSize=25&type=unmatched`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let allMatches = res.data.results;
        let page = res.data.pageNum;
        dispatch({
          type: FETCH_ARCHIVED_MATCHED_POSTS,
          payload: {
            archivedMatched: allMatches,
            page: page,
            pageSize: 25,
          },
        });
      })
      .catch((error) => {
        console.log(">>>>> error >>>> ", error);
        dispatch({
          type: FETCH_ARCHIVED_MATCHED_POSTS,
          payload: {
            archivedMatched: [],
            page: 0,
            pageSize: 25,
          },
        });
      });
  };
