import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";

import { fetchIntPostWithoutSeen } from "../../../actions/fetchIntPostWithoutSeen";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function SentCard(props) {
  const history = useHistory();
  return props.posts.map((el, i) => (
    <div
      className={
        el.id === props.intId 
          ? "interest-post-container active-interest"
          : "interest-post-container"
      }
      key={i}
      onClick={() =>
        // el.id === props.intId || props.preLoading ? null : props.fetchIntPostWithoutSeen(el)
        history.push(`/sent-interests?id=${el.id}`)
      }
    >
      <img
        src={el.post.personalInfo.gender === "MALE" ? Male : Female}
        className="profile-pic"
        alt="profile pic"
      />
      <p className="name">
        {/* {el.post.personalInfo.fname} {el.post.personalInfo.lnameMasked} */}
        {el.post.personalInfo.displayName}
      </p>

      <div style={{display: 'flex'}}>
        <p className="message">
          {el.post.personalInfo.age}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {el.post.personalInfo.residentCity}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {el.post.personalInfo.profession.name === "Other"
            ? el.post.personalInfo.professionOther
            : props.lng
            ? el.post.personalInfo.profession.name
            : el.post.personalInfo.profession.name_si}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {Math.floor(Math.round(el.post.personalInfo.height / 2.54) / 12)}'{" "}
          {Math.round(el.post.personalInfo.height / 2.54) % 12}"
        </p>
        {el.ignorePreferences && <label className="hytch-label">{props.lng ? 'Mismatched' : 'නොගැලපේ'}</label>}
      </div>
      <p className="msg-time">
        {moment
          .utc(
            !el.ignored
              ? el.likedAt
              : el.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                  .length > 0
              ? el.timeline.filter((el) => el.eventType === "LIKE_REFUSED")[0]
                  .eventTime
              : el.timeline.filter((el) => el.eventType === "UNLIKED").length >
                0
              ? el.timeline.filter((el) => el.eventType === "UNLIKED")[0]
                  .eventTime
              : el.likedAt
          )
          .local()
          .fromNow()}
      </p>

      {!el.ignored && (
        <div className="action-div">
          <span
            className="view-btn"
            onClick={() =>
              el.id === props.intId ? null : props.fetchIntPostWithoutSeen(el)
            }
          >
            View
          </span>
          {/* <span className="view-btn">withdraw Request</span> */}
        </div>
      )}
    </div>
  ));
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostWithoutSeen,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    // intId: state.matches.post.id,
    preLoading: state.matches.preLoading,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(SentCard);
