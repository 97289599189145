import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Rating from "react-rating";
import "../../styles/all-ads-container.css";
import "../../styles/pagination.css";
import facebookIcon from "../../images/icons8-facebook-circled-48.png";
import googleIcon from "../../images/icons8-google-48.png";
import gmailIcon from "../../images/icons8-gmail-logo-48.png";
import instagramIcon from "../../images/icons8-instagram-48.png";
import websiteIcon from "../../images/website-icon.png";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function Reviews(props) {
  let history = useHistory();

  const getSourceImage = (src) => {
    if (src === "FACEBOOK") {
      return facebookIcon;
    } else if (src === "GOOGLE") {
      return googleIcon;
    } else if (src === "EMAIL") {
      return gmailIcon;
    } else if (src === "INSTAGRAM") {
      return instagramIcon;
    } else if (src === "WEBSITE") {
      return websiteIcon;
    }
  };

  return (
    <Grid className="review-component">
      <Grid container direction="column" className="review-content-wrapper">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography style={{ fontSize: 18, fontWeight: 500 }}>
              {props.review.name}
            </Typography>
            <Typography
              style={{ fontSize: 12, fontWeight: 300, color: "#A7A1A1" }}
            >
              {moment(props.review.createdAt).format("MM/DD/YYYY")}
            </Typography>
          </Grid>
          <img
            style={{ height: "37px" }}
            src={getSourceImage(props.review.source)}
            alt="logo"
          />
        </Grid>
        <Rating
          initialRating={props.review.rating}
          style={{ color: "#f8b805" }}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly={true}
        />
        <Typography style={{ fontSize: 12, fontWeight: 300, color: "#A7A1A1" }}>
          {moment.utc(props.review.createdAt, "YYYYMMDD").local().fromNow()}
        </Typography>
        <ShowMoreText
          lines={3}
          more={props.lang ? "Show more" : "සියල්ල බලන්න"}
          less={props.lang ? "Show less" : "අඩුවෙන් බලන්න"}
          className="content-css"
          anchorClass="show-more-less-clickable"
          expanded={false}
          truncatedEndingComponent={"... "}
          onClick={() =>
            props.isRedirect ? history.push("/reviews") : undefined
          }
        >
          {props.review.content}
        </ShowMoreText>
      </Grid>
    </Grid>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(Reviews));
