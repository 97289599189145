import * as Sentry from "@sentry/react";
import Config from "../../config";

class Logger {
  constructor() {
    Sentry.init({
      dsn: Config.SENTRY_ID,
      debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
      environment: Config.NODE_ENV,
      enabled: Config.NODE_ENV === "production",
      tracesSampleRate: 1.0,
    });
  }

  _doAdditionalConfigs() {
    let count = 0;
    while (!Sentry.isInitialized() && count < 10000) {
      count++;
    }
    Sentry.getCurrentScope().setLevel("log");
  }

  log(message) {
    try {
      console.log(message);
      Sentry.captureMessage(message, "error");
    } catch (error) {
      console.error("logger crashed Error! ", error);
    }
  }

  debug(message, ...args) {
    try {
      console.debug(message, ...args);
    } catch (error) {
      console.error("Error! [AppLogger#debug] ", error);
    }
  }
 
  // error(message, otherParams) {
  //   Sentry.is;
  //   try {
  //     console.error(message, otherParams);
  //     Sentry.captureMessage(message, "error");
  //   } catch (error) {
  //     Sentry.captureException(error);
  //     console.error("Error", error);
  //   }
  // }

  exception(exception) {
    try {
      console.error(exception);
      Sentry.captureException(exception);
    } catch (error) {
      console.error("Error", error);
    }
  }

  static getInstance() {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }
}

const AppLogger = new Logger();
AppLogger._doAdditionalConfigs();
export default AppLogger;
