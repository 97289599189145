import { Grid } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";

import { SectionSubHeading } from "../../custom-typography";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import FormDropdown from "../../../../../../components/molecules/form-dropdown";
import { fieldVisibility } from "../../../../../../utils";
import Config from "../../../../../../config";
import FormAutoComplete from "../../../../../../components/molecules/form-auto-complete";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";

const { OTHER_COUNTRY_CODE, PLATFORMS, BASE_COUNTRY_CODE } = Config;

const ResidencyInformation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { values, initialValues, errors, handleChange, setFieldValue } =
    useFormikContext();
  const { countries, secondaryCountries, visaStatusOptions } = useSelector(
    (state) => state.genericData
  );
  const { countryRegions } = useSelector((state) => state.editAdPage);
  const [residentCities, setResidentCities] = useState([]);
  const showFieldErrors = false;

  const otherCountries = useMemo(() => {
    const primaryCountryCodes = countries.map((item) => item.code);
    return secondaryCountries.filter(
      (item) => !primaryCountryCodes.includes(item.code)
    );
  }, [countries, secondaryCountries]);

  const onSearchChange = async (event, setOptions) => {
    if (event === null) {
      return;
    }
    const searchText = event.target?.value;
    try {
      if (searchText === undefined || searchText.length === 0) {
        return;
      }
      const Google = window.google;
      const service = new Google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: searchText,
          types: ["geocode"],
          componentRestrictions: {
            country:
              values.residentCountryCode !== OTHER_COUNTRY_CODE &&
              values.residentCountryCode !== ""
                ? values.residentCountryCode
                : values.residentCountryCodeOther || "",
          },
        },
        (predictions, status) => {
          if (status === "OK") {
            const locations = predictions.map(
              (item) => item.structured_formatting.main_text
            );
            setOptions(locations);
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionSubHeading labelKey="common.residency" />
      <Grid container direction="row" className="row-gap-8">
        <Grid item className="form-field-container">
          <FormDropdown
            fieldName={FormMeta.residentCountry.fieldName}
            value={values.residentCountryCode}
            onChange={handleChange}
            options={countries}
            keyExtractor={FormMeta.residentCountry.keyExtractor}
            labelExtractor={FormMeta.residentCountry.labelExtractor}
            label={t(FormMeta.residentCountry.label)}
            lang={i18n.language}
            errorText={
              showFieldErrors && errors[FormMeta.residentCountry.fieldName]
            }
            visibility={fieldVisibility(FormMeta.residentCountry)}
            disabled={true}
          />
        </Grid>
        {fieldVisibility(FormMeta.residentCountry) &&
          values[FormMeta.residentCountry.fieldName] === OTHER_COUNTRY_CODE && (
            <Grid item className="form-field-container">
              <FormDropdown
                mode="interactive"
                showInteractiveActions={hasChangedFormValue({
                  meta: FormMeta.residentCountryOther,
                  initialValues,
                  values,
                })}
                onClickInteractiveSave={() => {
                  dispatch(
                    updateAdField(FormMeta.residentCountryOther, values, t)
                  );
                }}
                onClickInteractiveClear={() => {
                  dispatch(
                    resetChangedField(
                      FormMeta.residentRegion,
                      initialValues,
                      setFieldValue
                    )
                  );
                }}
                fieldName={FormMeta.residentCountryOther.fieldName}
                value={values.residentCountryCodeOther}
                onChange={handleChange}
                options={otherCountries}
                keyExtractor={FormMeta.residentCountryOther.keyExtractor}
                labelExtractor={FormMeta.residentCountryOther.labelExtractor}
                label={t(FormMeta.residentCountryOther.label)}
                lang={i18n.language}
                visibility={
                  fieldVisibility(FormMeta.residentCountryOther) &&
                  values.residentCountryCode === OTHER_COUNTRY_CODE
                }
                igonoreLocaleLabels
                errorText={showFieldErrors && errors.residentCountryCodeOther}
                disabled={true}
              />
            </Grid>
          )}
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.residentRegion,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.residentRegion, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.residentRegion,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.residentRegion.fieldName}
            value={values[FormMeta.residentRegion.fieldName]}
            onChange={handleChange}
            options={countryRegions}
            keyExtractor={FormMeta.residentRegion.keyExtractor}
            labelExtractor={FormMeta.residentRegion.labelExtractor}
            label={t(FormMeta.residentRegion.label)}
            lang={i18n.language}
            visibility={
              fieldVisibility(FormMeta.residentRegion) &&
              values.residentCountryCode !== OTHER_COUNTRY_CODE
            }
            disabled={
              !Boolean(values[FormMeta.residentCountry.fieldName]) ||
              isEmpty(countryRegions)
            }
            igonoreLocaleLabels={values.residentCountryCode !== PLATFORMS.LK}
            errorText={
              showFieldErrors && errors[FormMeta.residentRegion.fieldName]
            }
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormAutoComplete
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.residentCity,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.residentCity, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.residentCity,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.residentCity.fieldName}
            onChange={setFieldValue}
            value={values[FormMeta.residentCity.fieldName]}
            onSearchChange={(event) => onSearchChange(event, setResidentCities)}
            label={t(FormMeta.residentCity.label)}
            options={residentCities}
            freeSolo={true}
            disableClearable={true}
            disabled={
              !(
                values.residentCountryCode &&
                (values.residentCountryCode !== OTHER_COUNTRY_CODE
                  ? values.residentRegionId
                  : values.residentCountryCodeOther)
              )
            }
            lang={i18n.language}
            errorText={
              showFieldErrors && errors[FormMeta.residentCity.fieldName]
            }
          />
        </Grid>
        <Grid item className="form-field-container">
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.visaType,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(updateAdField(FormMeta.visaType, values, t));
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.visaType,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.visaType.fieldName}
            value={values[FormMeta.visaType.fieldName]}
            onChange={handleChange}
            options={visaStatusOptions}
            keyExtractor={FormMeta.visaType.keyExtractor}
            labelExtractor={FormMeta.visaType.labelExtractor}
            label={t(FormMeta.visaType.label)}
            lang={i18n.language}
            visibility={
              fieldVisibility(FormMeta.visaType) &&
              Boolean(values.residentCountryCode) &&
              values.residentCountryCode !== BASE_COUNTRY_CODE
            }
            errorText={showFieldErrors && errors[FormMeta.visaType.fieldName]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResidencyInformation;
