import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";
import _12FromTo24Hours from "12fromto24hours";

import { connect } from "react-redux";

function Horoscope(props) {
  const [amIaddedHoro, setAmIaddedHoro] = useState(false);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    setImageData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/account/posts
    `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setAmIaddedHoro(
          res.data[0].horoscopeInfo.birthCity &&
            res.data[0].horoscopeInfo.birthTime
            ? true
            : false
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getHoroPhotos();
  }, [props.horoscopeInfo && props.horoscopeInfo.images]);

  const getHoroPhotos = () => {
    let joinData = [];

    if (
      props.horoscopeInfo &&
      props.horoscopeInfo.images &&
      props.horoscopeInfo.images.length
    ) {
      for (let i = 0; i < props.horoscopeInfo.images.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
              props.intId
            }/images/${props.horoscopeInfo.images[i]}?thumbnail=false`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            joinData.push(data);

            setImageData(joinData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative", marginBottom: 0 }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <Typography
                variant="h6"
                className="heading"
                style={{ marginBottom: 30 }}
              >
                {props.lng ? (
                  "Horoscope Info"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">
                    කේන්දර විස්තර
                  </span>
                )}
              </Typography>

              <div className="row">
                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Birthday" : "උපන් දිනය"}
                    </div>
                    <div className="con-col">{props.dob}</div>
                  </div>
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">
                      {props.lng ? "Matching" : "ගැලපීම"}
                    </div>
                    <div className="con-col">
                      {props.horoscopeMatching
                        ? props.lng
                          ? "Essential"
                          : "අත්‍යවශ්‍යයි"
                        : props.lng
                        ? "Not essential"
                        : "අත්‍යවශ්‍ය නොවේ"}
                    </div>
                  </div>
                </div>
              </div>

              {amIaddedHoro ? (
                props.horoscopeInfo.birthTime &&
                props.horoscopeInfo.birthCity ? (
                  <div className="row">
                    <HoroDetails
                      lng={props.lng}
                      horosDetails={props.horoscopeInfo}
                    />

                    <div>
                      {props.horoscopeInfo &&
                      props.horoscopeInfo.images &&
                      props.horoscopeInfo.images.length > 0 ? (
                        <PhotoProvider>
                          {imageData &&
                            imageData.map((item, index) => (
                              <PhotoConsumer
                                key={index}
                                src={item}
                                intro={item}
                              >
                                <img
                                  src={item}
                                  alt="horescope images"
                                  className="photos"
                                />
                              </PhotoConsumer>
                            ))}
                        </PhotoProvider>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <p className="invisible-photos">
                    <i className="fas fa-eye-slash"></i>{" "}
                    {props.lng
                      ? "User has not added more horoscope information to their Ad. Your horoscope details will not be shared with them."
                      : "මෙම පරිශීලකයා ඔවුන්ගේ දැන්වීමට කේන්දර තොරතුරු ඇතුලත් කර නොමැත. එම නිසා ඔබේ කේන්දර තොරතුරු ඔවුන්ට පෙන්වන්නේ ද නොමැත."}
                  </p>
                )
              ) : (
                <p className="invisible-photos" style={{ marginBottom: 0 }}>
                  <i className="fas fa-eye-slash"></i>{" "}
                  {props.lng
                    ? "Please add your birth city and the time to view horoscope details of your matches."
                    : "ඔබගේ ගැලපීම් වල කේන්දර විස්තර බැලීමට ඔබගේ උපන් නගරය සහ උපන් වෙලාව එක් කරන්න."}{" "}
                  <Link to="/edit-my-ad" style={{ color: "#2196F3" }}>
                    {props.lng
                      ? "Add horoscope info"
                      : "කේන්දර තොරතුරු එක් කරන්න"}
                  </Link>
                </p>
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

function HoroDetails(props) {
  return (
    <React.Fragment>
      <div className="review-col rev-col-left">
        <div className="con-row" style={{ background: "#fff" }}>
          <div className="con-col">
            {props.lng ? "Birth City" : "උපන් ස්ථානය"}
          </div>
          <div className="con-col">
            {props.horosDetails.birthCity ? props.horosDetails.birthCity : "-"}
          </div>
        </div>
        <div className="con-row" style={{ background: "#f2f2f2" }}>
          <div className="con-col">
            {props.lng ? "Additional Info" : "අමතර තොරතුරු"}
          </div>
          <div className="con-col">
            {props.horosDetails.additionalData
              ? props.horosDetails.additionalData
              : "-"}
          </div>
        </div>
      </div>

      <div className="review-col rev-col-right">
        <div className="con-row" style={{ background: "#fff" }}>
          <div className="con-col">
            {props.lng ? "Birth Time" : "උපන් වේලාව"}
          </div>
          <div className="con-col">
            {props.horosDetails.birthTime
              ? _12FromTo24Hours(props.horosDetails.birthTime)
              : "-"}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    intId: state.matches.post.id,
    horoscopeInfo: state.matches.post.post.horoscopeInfo,
    horoscopeMatching: state.matches.post.post.horoscopeMatching,
    dob: state.matches.post.post.personalInfo.dob,
  };
};

export default connect(mapStateToProps, null)(Horoscope);
