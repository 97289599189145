import React, { Component } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  RadioGroup,
  Radio,
  List,
} from "@material-ui/core";
import moment from "moment";
import { Timeline, TimelineEvent } from "react-event-timeline";

import UnmatchModal from "../modals/UnmatchModal";

export default class TimeLine extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ minHeight: "20vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {this.props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <Typography
                  variant="h6"
                  className="heading"
                  style={{ marginBottom: 15 }}
                >
                  {this.props.lng === true ? (
                    "Timeline"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-16">
                      කාල රේඛාව
                    </span>
                  )}
                </Typography>
                <Timeline className="time-line-div" lineColor="#c19d0d">
                  {this.props.showInt ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(
                          this.props.sentTime
                            ? this.props.sentTime
                            : this.props.likedAt
                        )
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.lng
                          ? "Interest Request Sent"
                          : "කැමැත්ත යවන ලදි"
                      }
                      icon={<i className="fas fa-paper-plane"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    >
                      {this.props.likeMessage}

                      {this.props.applyInterest ? (
                        this.props.showInt ? (
                          this.props.isIgnored === false ? (
                            <p
                              className="ignore-int-text-btn"
                              onClick={this.props.handleCancelReqModal}
                            >
                              <span style={{ fontSize: 13 }}>
                                {this.props.lng === true ? (
                                  "Withdraw Request"
                                ) : (
                                  <span className="sinhala-w-600 sinhala-size-13">
                                    ඉල්ලීම අස්කර ගන්න
                                  </span>
                                )}
                              </span>
                            </p>
                          ) : null
                        ) : null
                      ) : null}
                    </TimelineEvent>
                  ) : (
                    <TimelineEvent
                      className="time-line"
                      createdAt={
                        this.props.receivedTime
                          ? moment
                              .utc(this.props.receivedTime)
                              .local()
                              .format("DD MMM YYYY hh:mm a")
                          : "Before 22 July 2020"
                      }
                      title={
                        this.props.lng
                          ? "Interest Request Received"
                          : "කැමැත්ත ලද දිනය "
                      }
                      icon={
                        <i className="fas fa-paper-plane fa-rotate-180"></i>
                      }
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    >
                      {this.props.likeMessage}
                    </TimelineEvent>
                  )}

                  {this.props.cancelSentTime ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(this.props.cancelSentTime)
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.lng
                          ? "Request Withdrawn"
                          : "ඉල්ලා අස්කර ගන්නා ලදි"
                      }
                      icon={<i className="fas fa-ban"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    ></TimelineEvent>
                  ) : (
                    <p style={{ margin: 0 }}></p>
                  )}

                  {this.props.cancelAcceptTime ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(this.props.cancelAcceptTime)
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.ignoredBy === "SELF"
                          ? this.props.lng
                            ? "Ignored by You"
                            : "ඔබ විසින් නොසලකා හරින ලදි"
                          : this.props.ignoredBy === "OTHER_PARTY"
                          ? this.props.lng
                            ? `Ignored by ${this.props.fName ? this.props.fName : this.props.displayName}`
                            : `${this.props.fName ? this.props.fName : this.props.displayName} විසින් නොසලකා හරින ලදි`
                          : this.props.lng
                          ? "Ignored"
                          : "නොසලකා හරින ලදි"
                      }
                      icon={<i className="fas fa-times"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    >
                      {/* {this.props.autoIgnored || this.props.undoAutoRejectTime
                        ? this.props.ignoredBy === "SELF"
                          ? this.props.lng
                            ? "Ignored by the System after 10 days from the request."
                            : "කැමැත්ත එවා දින 10 කට පසු ස්වයංක්‍රීයව නොසලකා හරින ලදී."
                          : null
                        : null} */}
                      {this.props.autoIgnored || this.props.undoAutoRejectTime
                          ? this.props.lng
                            ? "Ignored by the System after 10 days from the request."
                            : "කැමැත්ත එවා දින 10 කට පසු ස්වයංක්‍රීයව නොසලකා හරින ලදී."
                        : null
                      }
                      {this.props.refuseReason && this.props.refuseReason}
                    </TimelineEvent>
                  ) : (
                    <p style={{ margin: 0 }}></p>
                  )}

                  {this.props.undoAutoRejectTime ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(this.props.undoAutoRejectTime)
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.undoAutoRejectedBy === "SELF"
                          ? this.props.lng
                          ? "Undo Auto Reject by You"
                          : "ඔබ විසින් ස්වයංක්‍රීය ප්‍රතික්ෂේප කිරීම අහෝසි කරන ලදි"
                          : this.props.undoAutoRejectedBy === "OTHER_PARTY"
                          ? this.props.lng
                            ? `Undo Auto Reject by ${this.props.fName}`
                            : `${this.props.fName} විසින් ස්වයංක්‍රීය ප්‍රතික්ෂේප කිරීම අහෝසි කරන ලදි`
                          : this.props.lng
                          ? "Ignored"
                          : "නොසලකා හරින ලදි"
                      }
                      icon={<i className="fas fa-undo"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    >
                      {this.props.autoIgnored
                        ? this.props.undoAutoRejectedBy === "SELF"
                          ? this.props.lng
                            ? "Ignored by the System after 10 days from the request."
                            : "කැමැත්ත එවා දින 10 කට පසු ස්වයංක්‍රීයව නොසලකා හරින ලදී."
                          : null
                        : null}
                      {this.props.refuseReason && this.props.refuseReason}
                    </TimelineEvent>
                  ) : (
                    <p style={{ margin: 0 }}></p>
                  )}

                  {this.props.acceptTime ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(
                          this.props.acceptTime
                            ? this.props.acceptTime
                            : this.props.matchedAt
                        )
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.acceptBy === "SELF" ||
                        this.props.acceptBy === "OTHER_PARTY" ||
                        this.props.receivedTo === "OTHER_PARTY"
                          ? this.props.lng
                            ? `You Matched with ${this.props.fName}`
                            : `ඔබ ${this.props.fName} සමඟ ගැලපේ`
                          : this.props.lng
                          ? "Matched"
                          : "ගැලපුනා"
                      }
                      icon={<i className="fas fa-heart"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    >
                      {this.props.matchMessage}

                      {(this.props.applyMatch && ((localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") || (localStorage.getItem("post_details") && !JSON.parse(localStorage.getItem("post_details")).isManaged))) ? (
                        this.props.isIgnored ? null : (
                          <p
                            className="ignore-int-text-btn"
                            onClick={this.props.handleUnmatchModal}
                          >
                            <span>
                              {this.props.lng ? (
                                "Unmatch"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-13">
                                  නොගලපන්න
                                </span>
                              )}
                            </span>
                          </p>
                        )
                      ) : null}
                    </TimelineEvent>
                  ) : (
                    <p style={{ margin: 0 }}></p>
                  )}

                  {this.props.unmatchedTime ? (
                    <TimelineEvent
                      className="time-line"
                      createdAt={moment
                        .utc(this.props.unmatchedTime)
                        .local()
                        .format("DD MMM YYYY hh:mm a")}
                      title={
                        this.props.unmatchedBy === "SELF"
                          ? this.props.lng
                            ? "Unmatched by You"
                            : "ඔබ විසින් ගැලපීම ඉවත් කරන ලදී"
                          : this.props.lng
                          ? `Unmatched by ${this.props.fName}`
                          : `${this.props.fName} විසින් ගැලපීම ඉවත් කරන ලදී`
                      }
                      icon={<i className="fas fa-heart-broken"></i>}
                      bubbleStyle={{
                        color: "#000",
                        border: "1.5px solid #c19d0d",
                        fontSize: 15,
                      }}
                      iconStyle={{
                        cursor: "default",
                      }}
                    ></TimelineEvent>
                  ) : (
                    <p style={{ margin: 0 }}></p>
                  )}
                </Timeline>

                {!this.props.isMyProfile && this.props.applyMatch ? (
                  this.props.reportedPosts.filter(
                    (el) => el === this.props.matchPostId
                  ).length > 0 ? (
                    <p className="report-profile" style={{ cursor: "default" }}>
                      {this.props.lng === true ? (
                        "Ad Reported"
                      ) : (
                        <span className="sinhala-size-13">
                          දැන්වීම වාර්තා කර ඇත
                        </span>
                      )}
                    </p>
                  ) : (
                    <span
                      className="report-profile"
                      onClick={this.props.handleReport}
                    >
                      {this.props.lng === true ? (
                        "Report this profile"
                      ) : (
                        <span className="sinhala-size-13">
                          මෙම දැන්වීම වාර්තා කරන්න
                        </span>
                      )}
                    </span>
                  )
                ) : null}
              </React.Fragment>
            )}

            <UnmatchModal
              lng={this.props.lng}
              unmatchModal={this.props.unmatchModal}
              handleModalClose={this.props.handleModalClose}
              handleIgnoreAllInt={this.props.handleIgnoreAllInt}
              loadingIgnore={this.props.loadingIgnore}
            />

            {!this.props.isMyProfile && (
              <Dialog
                open={this.props.reportModal}
                onClose={this.props.handleReportClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
              >
                <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
                  {this.props.lng === true ? (
                    "Report this Ad"
                  ) : (
                    <span className="sinhala-size-14 sinhala-w-600">
                      මෙම දැන්වීම වාර්තා කරන්න
                    </span>
                  )}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: 15 }}
                  >
                    {this.props.lng === true ? (
                      <span>
                        Your report is private and will not be shared with any
                        other party. We will try our best to resolve this as
                        soon as we can.
                      </span>
                    ) : (
                      <span className="sinhala-size-12">
                        ඔබගේ වාර්තා කිරීම පුද්ගලික වන අතර වෙනත් පාර්ශවයන්ට
                        දන්වනු නොලැබේ. අපට හැකි ඉක්මනින් මෙය විසඳීමට උපරිම
                        උත්සාහයක් දරන්නෙමු.
                      </span>
                    )}
                  </DialogContentText>

                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: 15 }}
                  >
                    {this.props.lng === true ? (
                      <span>
                        Please select a reason for reporting this profile.
                      </span>
                    ) : (
                      <span className="sinhala-size-12">
                        කරුණාකර මෙම දැන්වීම වාර්තා කිරීමට හේතුවක් තෝරන්න.
                      </span>
                    )}
                  </DialogContentText>

                  <List component="nav" aria-label="secondary mailbox folders">
                    <RadioGroup
                      className="filter-radio why-dissable-radio-group"
                      style={{ marginLeft: 0 }}
                      aria-label="reasons"
                      value={this.props.whyReport}
                      onChange={this.props.handleReportOptions}
                    >
                      {this.props.reasons.map((reason, i) => (
                        <FormControlLabel
                          key={reason.id}
                          value={reason.reason}
                          control={<Radio size="small" />}
                          label={
                            this.props.lng === true
                              ? reason.reason
                              : reason.reason_si
                          }
                          className={
                            this.props.lng !== true
                              ? "sinhala-w-600  sinhala-size-12"
                              : ""
                          }
                          style={{ paddingLeft: 0 }}
                        />
                      ))}
                    </RadioGroup>
                    {this.props.error ? (
                      <p
                        style={{
                          color: "#cc2a2a",
                          fontSize: 14,
                          marginTop: 5,
                          marginLeft: 13,
                          marginBottom: 0,
                        }}
                      >
                        {this.props.lng === true ? (
                          "Please select one of above."
                        ) : (
                          <span className="sinhala-size-14">
                            කරුණාකර ඉහත එකක් තෝරන්න.
                          </span>
                        )}
                      </p>
                    ) : null}
                  </List>

                  <lable HTMLFor="reasonDesc" className="add-explanation">
                    {this.props.lng === true ? (
                      "Additional Explanation"
                    ) : (
                      <span className="sinhala-size-13">
                        අතිරේක පැහැදිලි කිරීම
                      </span>
                    )}
                  </lable>
                  <textarea
                    className="report-add-desc-input"
                    name="reasonDesc"
                    id="reasonDesc"
                    cols="30"
                    rows="5"
                    value={this.props.reasonDesc}
                    onChange={this.props.handleChange}
                  ></textarea>
                </DialogContent>
                <DialogActions style={{ padding: 15 }}>
                  <Button
                    onClick={this.props.handleReportClose}
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.props.lng === true ? "Cancel" : "අවලංගු කරන්න"}
                  </Button>
                  {this.props.isDissable ? (
                    <Button
                      style={{
                        background: "#e4e4e4",
                        color: "#5f5f5f",
                        textTransform: "capitalize",
                        minWidth: 130,
                        height: 35,
                      }}
                      disabled
                    >
                      {this.props.lng === true ? (
                        "Report this Ad"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          දැන්වීම වාර්තා කරන්න
                        </span>
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={this.props.handleReportAd}
                      style={{
                        background: "#bd2727",
                        color: "#fff",
                        textTransform: "capitalize",
                        minWidth: 130,
                        height: 35,
                      }}
                      autoFocus
                    >
                      {this.props.loading ? (
                        <CircularProgress size={16} style={{ color: "#fff" }} />
                      ) : this.props.lng === true ? (
                        "Report this Ad"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          දැන්වීම වාර්තා කරන්න
                        </span>
                      )}
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
