import axios from "axios";
import { FETCH_MESSAGE_POST_DATA } from "./types";

export const fetchMessagePostData = (id, history, setLoading = undefined) => async (dispatch) => {
  const isSetLoadingCallable = setLoading !== undefined 
    && setLoading.constructor === Function;
  if (isSetLoadingCallable) {
    setLoading(true);
    await dispatch({ type: FETCH_MESSAGE_POST_DATA, payload: {
      match: {},
      status: false
    }})
  }
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
        "postId"
      )}/interests/${id}
    `,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.interest) {
        history.push("/interests/?id=" + id);
      } else if (res.data.match) {
        dispatch({
          type: FETCH_MESSAGE_POST_DATA,
          payload: {
            match: res.data.match,
            status: false
          },
        });
      } else {
        history.push("/messages");
      }
    })
    .catch((err) => {
      if (
        err.response.data.code === 1006 ||
        err.response.data.code === 1514
        ) {
          dispatch({
            type: FETCH_MESSAGE_POST_DATA,
            payload: {
              match: {},
              status: true
            },
          });
        } else {
          console.log(err);
          history.push("/messages");
        }
    }).finally(() => {
      if (isSetLoadingCallable) {
        setLoading(false);
      }
    });
};