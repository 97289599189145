import { createContext } from "react";
import Constants from "../config/constant";

const appContext = {
  languages: Constants.APP_LANG,
  appLanguage: Constants.APP_LANG.EN,
  setAppLanguage: () => {},

  // deprecated state values
  lng: true,
  handleLng: () => {},
};
const AppContext = createContext(appContext);

export default AppContext;
