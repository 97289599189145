import React, { useMemo } from "react";
import Config from "../../../config";
import { Box } from "@material-ui/core";

import "./style.css";

export const LogoSize = Object.freeze({
  xsmall: "xsmall",
  small: "small",
  medium: "medium",
  large: "large",
});

export const LogoVariant = Object.freeze({
  white: "white",
  black: "black",
});

const ProductLogo = ({
  variant = LogoVariant.white,
  size = LogoSize.medium,
  className = "",
  onClick
}) => {
  const source = useMemo(() => {
    switch (variant) {
      case LogoVariant.black:
        return Config.PRODUCT_LOGO_BLACK;
      default:
        return Config.PRODUCT_LOGO_WHITE;
    }
  }, [variant]);

  return (
    <Box className="product-logo-box">
      <img
        src={source}
        alt="product-logo"
        className={`product-logo product-logo-${size} ${className}`}
        onClick={onClick}
      />
    </Box>
  );
};

export default ProductLogo;
