import React from "react";
import "../../styles/manage-account-banner.css";

function ManageAccountBanner(props) {
  return (
    <div className="manage-account-banner">
      <p>
        {props.lng ? (
          <span>This is an operator managed post. Some actions will be disabled.</span>
        ) : (
          <span className="sinhala-size-12">මෙය ක්‍රියාකරු විසින් කළමනාකරණය කරන දැන්වීමකි. සමහර ක්‍රියා අක්‍රීය කර ඇත.</span>
        )}
      </p>
    </div>
  );
}

export default ManageAccountBanner;
