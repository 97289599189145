import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "../../i18n";
import Constants from "../../config/constant";

export const { APP_LANG } = Constants;

export const getLanguageFromStorage = () => {
  if (localStorage.getItem("lng") !== null) {
    const isEnglish = JSON.parse(localStorage.getItem("lng"));
    return isEnglish ? APP_LANG.EN : APP_LANG.SI;
  }
  return APP_LANG.EN;
};

class LocaleHandler {
  static initialize() {
    i18next.use(initReactI18next).init({
      resources: translations,
      lng: getLanguageFromStorage(),
      interpolation: {
        escapeValue: false,
      },
    });
  }

  static changeLanguage(appLanguage) {
    i18next.changeLanguage(appLanguage);
  }

  static getLanguageFromStorage() {
    return getLanguageFromStorage();
  }
}

export default LocaleHandler;
