import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PaymentError extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="success-error-div">
            <p align="center">
              <i className="far fa-times-circle fa-4x" align="center"></i>
            </p>
            <p className="message">
              {this.props.lng === true ? "Payment Error!" : "ගෙවීම් දෝෂයකි!"}
            </p>
            <p align="center">
              {this.props.lng === true
                ? "Your card was declined by the bank."
                : "ඔබගේ කාඩ්පත බැංකුව විසින් ප්‍රතික්ෂේප කරන ලදි."}
            </p>
            <div className="payment-options">
              <Button
                variant="contained"
                className="pay-by-card"
                onClick={() => this.props.history.push("/pay-for-ad")}
              >
                {this.props.lng === true ? (
                  "TRY AGAIN"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    නැවත උත්සහා කරන්න
                  </span>
                )}
              </Button>
              {this.state.currency === "LKR" ? (
                <Button
                  variant="contained"
                  className="pay-by-card"
                  onClick={() =>
                    this.props.history.push("/checkout/bank-transfer")
                  }
                >
                  {this.props.lng === true ? (
                    "Bank Transfer"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      බැංකු ගිණුමට බැර කරන්න
                    </span>
                  )}
                </Button>
              ) : null}
            </div>
          </div>
        </Grid>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(PaymentError));
