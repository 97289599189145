import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";

const FormItemLabel = ({ text, labelPosition, lang, styleOverrides }) => {
  const computedClassName = useMemo(() => {
    return `form-item-label form-item-label-${lang} form-item-label-${labelPosition}`;
  }, [labelPosition, lang]);

  return (
    <Typography className={computedClassName} style={styleOverrides}>
      {text}
    </Typography>
  );
};

export default FormItemLabel;
