import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useRef } from "react";
import ReactCountryFlag from "react-country-flag";

import bride from "../../../../images/bride.png";
import groom from "../../../../images/groom.png";
import topAdBadge from "../../../../images/topad-badge.png";
import locationIcon from "../../../../images/location.png";
import ageIcon from "../../../../images/age.png";
import ethnicityIcon from "../../../../images/ethnicity.png";
import casteIcon from "../../../../images/caste.png";
import heightIcon from "../../../../images/height.png";
import religionIcon from "../../../../images/religion.png";
import jobIcon from "../../../../images/job.png";
import heart from "../../../../images/heart.png";
import civilStatusIcon from "../../../../images/civil-status.png";

import "./style.css";
import IconizedText from "./iconized-text";
import { buildModelByFormMeta, fieldVisibility } from "../../../utils";
import ModelMeta from "../../../meta/home-post-card.json";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Config from "../../../config";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { OTHER_COUNTRY_CODE } from "../../../config/constant";
import FieldBox from "./field-box";
import {
  centimertsIntoFeetInches,
  getLocaleLabelByFieldMeta,
  getResidentCountry,
  getResidentCountryCode,
} from "../../../utils/data-generator";
import { BootstrapTooltip } from "../../../components/molecules/tooltip";

const LAST_SEEN_PROFILE_ID = "lastSeenProfileId";

const AdvertisementCard = ({
  advertisment = {},
  isFeatured = false,
  position,
}) => {
  const history = useHistory();
  const cardHeadingRef = useRef(null);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const model = useMemo(() => {
    const model = buildModelByFormMeta(ModelMeta);
    if (!isEmpty(advertisment)) {
      if (fieldVisibility(ModelMeta.age)) {
        model[ModelMeta.age.fieldName] =
          t("createflow.ageDisplayFormat", {
            age: advertisment.personalInfo?.age,
          }) ?? "";
      }

      if (fieldVisibility(ModelMeta.ethnicity)) {
        model[ModelMeta.ethnicity.fieldName] = getLocaleLabelByFieldMeta({
          fieldMeta: ModelMeta.ethnicity,
          input: advertisment.personalInfo?.ethnicity,
          language: i18n.language,
        });
      }

      if (fieldVisibility(ModelMeta.caste)) {
        model[ModelMeta.caste.fieldName] =
          advertisment.personalInfo?.caste ?? "";
      }

      if (fieldVisibility(ModelMeta.height)) {
        model[ModelMeta.height.fieldName] = centimertsIntoFeetInches(
          advertisment.personalInfo?.height ?? ""
        );
      }

      if (fieldVisibility(ModelMeta.religion)) {
        model[ModelMeta.religion.fieldName] = getLocaleLabelByFieldMeta({
          fieldMeta: ModelMeta.religion,
          input: advertisment.personalInfo?.religion,
          language: i18n.language,
        });
      }

      if (fieldVisibility(ModelMeta.profession)) {
        if (advertisment.personalInfo?.profession?.name === "Other") {
          model[ModelMeta.profession.fieldName] =
            advertisment.personalInfo?.professionOther ?? "";
        } else {
          model[ModelMeta.profession.fieldName] = getLocaleLabelByFieldMeta({
            fieldMeta: ModelMeta.profession,
            input: advertisment.personalInfo?.profession,
            language: i18n.language,
          });
        }
      }

      if (fieldVisibility(ModelMeta.civilStatus)) {
        if (advertisment.personalInfo?.civilStatus) {
          model[ModelMeta.civilStatus.fieldName] =
            advertisment.personalInfo?.civilStatus?.status || "";
        }
      }

      if (fieldVisibility(ModelMeta.community)) {
        if (advertisment.personalInfo?.community) {
          model[ModelMeta.community.fieldName] = getLocaleLabelByFieldMeta({
            fieldMeta: ModelMeta.community,
            input: advertisment.personalInfo?.community,
            language: i18n.language,
          });
        }
      }
    }
    return model;
  }, [advertisment, i18n.language, t]);

  const interestType = useMemo(() => {
    let interest = { visibility: false, text: "" };

    if (advertisment.interestInfo) {
      if (advertisment.interestInfo?.liked) {
        if (advertisment.interestInfo?.liked === "OUTGOING") {
          interest = { visibility: true, text: "Interest Sent" };
        } else if (advertisment.interestInfo?.liked === "INCOMING") {
          interest = { visibility: true, text: "Interest Received" };
        }
      }
      if (advertisment.interestInfo?.matched) {
        interest = { visibility: true, text: "Matched" };
      }
    }

    return interest;
  }, [advertisment]);

  const handleOnAdvertisementClick = () => {
    if (!isFeatured) {
      sessionStorage.setItem(LAST_SEEN_PROFILE_ID, advertisment.id);
    }
    history.push(`/post?id=${advertisment.id}`);
  };

  const createSubHeading = () => {
    const personalInfo = advertisment.personalInfo ?? {};
    const countryCode = personalInfo.residentCountry?.code ?? "";
    const city = personalInfo.residentCity ?? "";
    let country = "";
    if (countryCode === OTHER_COUNTRY_CODE) {
      country = personalInfo.residentCountryOther?.name || "";
    } else {
      const extractKey =
        i18n.language === Config.APP_LANGS.EN
          ? "name"
          : `name_${i18n.language}`;
      country = (personalInfo.residentCountry ?? {})[extractKey] ?? "";
    }
    const output = `${city}, ${country}`;
    return output;
  };

  useEffect(() => {
    const lastSeenProfileId = sessionStorage.getItem(LAST_SEEN_PROFILE_ID);
    if (!isEmpty(lastSeenProfileId) && lastSeenProfileId === advertisment.id) {
      const element = document.getElementById(lastSeenProfileId);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      sessionStorage.removeItem(LAST_SEEN_PROFILE_ID);
    }
  }, [advertisment.id]);

  return (
    <Grid container className="home-advertisement-wrapper" id={advertisment.id}>
      {isFeatured && (
        <Box className="top-ad-wrapper">
          <Typography className="top-ad-txt" title="Top Ad">
            Top ad
          </Typography>
        </Box>
      )}
      <a
        href={`/post?id=${advertisment.id}`}
        style={{ textDecoration: "none" }}
        onClick={(event) => {
          event.preventDefault();
          handleOnAdvertisementClick();
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          className={`home-advertisement-container ${
            isFeatured && `home-advertisement-container-featured`
          }`}
        >
          {advertisment.isSuggested && (
            <Box className="suggested-ad-wrapper">
              <Typography className="suggested-ad-txt">
                Suggested for you
              </Typography>
            </Box>
          )}
          <Grid container direction="row" style={{ marginTop: 0 }}>
            {/* Card Header */}
            <Grid item container direction="row" justifyContent="space-between">
              <Grid
                item
                xs
                container
                direction="row"
                className="row-gap-8 column-gap-8"
              >
                <img
                  src={
                    advertisment.personalInfo?.gender === "MALE" ? groom : bride
                  }
                  className="gender-img"
                  alt="gender"
                />
                <Grid item xs container direction="column" ref={cardHeadingRef}>
                  <Grid container xs alignItems="center">
                    <Typography className="display-name">
                      {advertisment.personalInfo?.displayName ?? ""}
                    </Typography>
                    {advertisment.idVerified && (
                      <BootstrapTooltip title={t("common.idVerified")}>
                        <i className="fas fa-check-circle nic-verified-icon" />
                      </BootstrapTooltip>
                    )}
                  </Grid>
                  <IconizedText
                    icon={locationIcon}
                    text={createSubHeading()}
                    containerProps={{
                      className: "column-gap-8 card-sub-heading",
                    }}
                    iconProps={{ className: "data-icon" }}
                    labelProps={{ className: "card-sub-heading-label" }}
                  />

                  {interestType.visibility && (
                    <IconizedText
                      icon={heart}
                      text={interestType.text}
                      containerProps={{ className: "column-gap-8" }}
                      iconProps={{ className: "data-icon" }}
                      labelProps={{ className: "interest-type" }}
                    />
                  )}
                </Grid>
              </Grid>

              {!isEmpty(getResidentCountryCode(advertisment.personalInfo)) ? (
                <ReactCountryFlag
                  countryCode={
                    getResidentCountryCode(advertisment.personalInfo) || ""
                  }
                  svg
                  style={{ fontSize: "1.25em" }}
                  title={
                    getResidentCountry(
                      advertisment.personalInfo,
                      i18n.language
                    ) || ""
                  }
                />
              ) : (
                <i className="fas fa-globe-americas" title="Other Country"></i>
              )}
            </Grid>

            {/* Card Content */}
            <Grid
              container
              direction="row"
              className={`content-container ${
                isFeatured && `content-container-featured`
              } row-gap-16`}
            >
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (
                <FieldBox
                  desktopViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.ethnicity.fieldName,
                    ModelMeta.caste.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                  mobileViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.ethnicity.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.profession.fieldName,
                    ModelMeta.caste.fieldName,
                  ]}
                >
                  <IconizedText
                    icon={ageIcon}
                    fieldName={ModelMeta.age.fieldName}
                    text={model[ModelMeta.age.fieldName]}
                    tooltip={t(ModelMeta.age.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.age) &&
                      (model[ModelMeta.age.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={ethnicityIcon}
                    fieldName={ModelMeta.ethnicity.fieldName}
                    text={model[ModelMeta.ethnicity.fieldName]}
                    tooltip={t(ModelMeta.ethnicity.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.ethnicity) &&
                      (model[ModelMeta.ethnicity.fieldName] ||
                        !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={casteIcon}
                    fieldName={ModelMeta.caste.fieldName}
                    text={model[ModelMeta.caste.fieldName]}
                    tooltip={t(ModelMeta.caste.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.caste) &&
                      (model[ModelMeta.caste.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={heightIcon}
                    fieldName={ModelMeta.height.fieldName}
                    text={model[ModelMeta.height.fieldName]}
                    tooltip={t(ModelMeta.height.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.height) &&
                      (model[ModelMeta.height.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={religionIcon}
                    fieldName={ModelMeta.religion.fieldName}
                    text={model[ModelMeta.religion.fieldName]}
                    tooltip={t(ModelMeta.religion.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.religion) &&
                      (model[ModelMeta.religion.fieldName] || isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={jobIcon}
                    fieldName={ModelMeta.profession.fieldName}
                    text={model[ModelMeta.profession.fieldName]}
                    tooltip={t(ModelMeta.profession.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.profession) &&
                      (model[ModelMeta.profession.fieldName] ||
                        isMobileViewport)
                    }
                  />
                </FieldBox>
              ) : (
                <FieldBox
                  desktopViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.civilStatus.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.community.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                  mobileViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.civilStatus.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.community.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                >
                  <IconizedText
                    icon={ageIcon}
                    fieldName={ModelMeta.age.fieldName}
                    text={model[ModelMeta.age.fieldName]}
                    tooltip={t(ModelMeta.age.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.age) &&
                      (model[ModelMeta.age.fieldName] || !isMobileViewport)
                    }
                  />

                  <IconizedText
                    icon={religionIcon}
                    fieldName={ModelMeta.religion.fieldName}
                    text={model[ModelMeta.religion.fieldName]}
                    tooltip={t(ModelMeta.religion.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.religion) &&
                      (model[ModelMeta.religion.fieldName] || isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={civilStatusIcon}
                    fieldName={ModelMeta.civilStatus.fieldName}
                    text={model[ModelMeta.civilStatus.fieldName]}
                    tooltip={t(ModelMeta.civilStatus.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.civilStatus) &&
                      (model[ModelMeta.civilStatus.fieldName] ||
                        !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={heightIcon}
                    fieldName={ModelMeta.height.fieldName}
                    text={model[ModelMeta.height.fieldName]}
                    tooltip={t(ModelMeta.height.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.height) &&
                      (model[ModelMeta.height.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={ethnicityIcon}
                    fieldName={ModelMeta.community.fieldName}
                    text={model[ModelMeta.community.fieldName]}
                    tooltip={t(ModelMeta.community.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.community) &&
                      (model[ModelMeta.community.fieldName] ||
                        !isMobileViewport)
                    }
                  />

                  <IconizedText
                    icon={jobIcon}
                    fieldName={ModelMeta.profession.fieldName}
                    text={model[ModelMeta.profession.fieldName]}
                    tooltip={t(ModelMeta.profession.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{ className: "contet-text" }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.profession) &&
                      (model[ModelMeta.profession.fieldName] ||
                        isMobileViewport)
                    }
                  />
                </FieldBox>
              )}
            </Grid>

            <Grid container direction="row" justifyContent="space-between">
              <Grid container direction="row" xs alignItems="center">
                {isFeatured && (
                  <img
                    src={topAdBadge}
                    alt="top-ad-badge"
                    className="top-ad-badge"
                    title="Top Ad"
                  />
                )}
                <BootstrapTooltip title={t("common.publishedDate")}>
                  <Typography className="published-time-txt">
                    {`Published : 
              ${moment.utc(advertisment.publishedAt).local().fromNow()}`}
                  </Typography>
                </BootstrapTooltip>
              </Grid>
              <Typography
                className="see-more-txt"
                id={position === 1 ? "more-one" : null}
              >{`${t("common.moreDetails")} >`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default AdvertisementCard;
