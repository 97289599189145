import store from "../../../store";
import Constants, { APP_LANG, OTHER_COUNTRY_CODE } from "../../config/constant";
import { isEmpty } from "lodash";
import { getRegionsByCountryCode } from "../generic-data";
import { ParentDisplayModel } from "../../models/profile";
import {
  ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS,
  ACCOUNT_CREATED_BY_OPTIONS,
  ADDITIONAL_COMMUNITY_OPTIONS,
  HAS_CHILDREN_OPTIONS,
  HOROSCOPE_MATCHING_LABEL_OPTIONS,
  MARITAL_OPTIONS,
  PARENT_OPTIONS,
  RELATIONSHIP_OPTIONS,
  RELATIONSHIP_TYPE_OPTIONS,
} from "../../static-data";
import Config from "../../config";
import { variantDirectionOptions } from "../../templates/profile-view";

export const DataDisplaySection = Object.freeze({
  personalInformation: "personalInformation",
  parentInformation: "parentInformation",
  horoscopeInformation: "horoscopeInformation",
});

export const generateHeight = (heightInCm) => {
  let height = "";
  if (heightInCm !== undefined && heightInCm !== "") {
    const totalInches = Math.round(heightInCm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;

    height = `${feet}' ${inches}"`;
  }
  return height;
};

const generateExtractionKeyByLang = (lang, extractionKey) => {
  const isEnglish = lang === APP_LANG.EN;
  return isEnglish ? extractionKey : `${extractionKey}_${lang}`;
};

const generateDisplayValueByMeta = (meta, extractionKey, lang) => {
  return isEmpty(meta)
    ? ""
    : meta[generateExtractionKeyByLang(lang, extractionKey)];
};

export const getAccountCreatedType = (type, lang) => {
  var accountType = "";
  const accountTypeMeta = ACCOUNT_CREATED_BY_OPTIONS.find(
    (element) => element.value === type
  );
  if (!isEmpty(accountTypeMeta)) {
    accountType = generateDisplayValueByMeta(accountTypeMeta, "label", lang);
  }
  return accountType;
};

export const generateProfession = (personalInfo, lang) => {
  const { genericData } = store.getState();
  const { professions } = genericData;
  let profession = "";
  if (personalInfo.profession?.name === Constants.OTHER_PROFESSION_NAME) {
    profession = personalInfo.professionOther;
  } else {
    const professionMeta = professions.find(
      (element) => element.id === personalInfo.profession?.id
    );
    profession = generateDisplayValueByMeta(professionMeta, "name", lang);
  }
  return profession;
};

export const getResidentCountryCode = (personalInfo) => {
  return personalInfo?.residentCountry?.code === OTHER_COUNTRY_CODE
    ? personalInfo?.residentCountryOther?.code
    : personalInfo?.residentCountry?.code;
};

const generatePersonalInformation = async (data, lang) => {
  const { genericData } = store.getState();
  const {
    ethnicities,
    religions,
    civilStatusOptions,
    countries,
    secondaryCountries,
    visaStatusOptions,
    educationLevels,
    professions,
    drinkingPreferences,
    foodPreferences,
    smokingPrefernces,
  } = genericData;

  const output = {};
  const personalInfo = data.personalInfo || {};

  const ethnicityMeta = ethnicities.find(
    (element) => element.id === personalInfo.ethnicity?.id
  );
  output.ethnicity = generateDisplayValueByMeta(ethnicityMeta, "name", lang);

  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    if (personalInfo?.community) {
      output.community = personalInfo?.community?.name || "";
    } else {
      const communityMeta = ADDITIONAL_COMMUNITY_OPTIONS.find(
        (element) => element.id === personalInfo?.communityOther
      );
      output.community =
        generateDisplayValueByMeta(communityMeta, "name", lang) || "";
    }

    output.motherTongue = personalInfo?.motherTongue?.name || "";
    output.subCommunity = personalInfo?.subCommunity || "";
    output.gothra = personalInfo?.gothra?.name || "";
  }

  const religionMeta = religions.find(
    (element) => element.id === personalInfo.religion.id
  );
  output.religion = generateDisplayValueByMeta(religionMeta, "name", lang);

  output.age = personalInfo.age || "";

  output.caste = personalInfo.caste || "";

  const civilStatusMeta = civilStatusOptions.find(
    (element) => element.id === personalInfo.civilStatus.id
  );

  output.civilStatus = generateDisplayValueByMeta(
    civilStatusMeta,
    "status",
    lang
  );

  if (!isEmpty(personalInfo.children)) {
    const hasChildrenMeta = HAS_CHILDREN_OPTIONS.find(
      (element) => element.value === personalInfo.children
    );
    output.hasChildren = generateDisplayValueByMeta(
      hasChildrenMeta,
      "label",
      lang
    );
  }

  output.height = generateHeight(personalInfo.height);

  let residentCountry = "";
  let residentRegion = "";
  let visaType = "";
  if (personalInfo.residentCountry?.code !== Constants.OTHER_COUNTRY_CODE) {
    const countryMeta = countries.find(
      (element) => element.code === personalInfo.residentCountry?.code
    );
    residentCountry = generateDisplayValueByMeta(countryMeta, "name", lang);
    const countryRegions = await getRegionsByCountryCode({
      countryCode: personalInfo.residentCountry?.code,
      appLanguage: lang,
    });
    if (!isEmpty(personalInfo.residentRegion)) {
      const regionMeta = countryRegions.find(
        (element) => element.id === personalInfo.residentRegion.id
      );
      residentRegion = generateDisplayValueByMeta(
        regionMeta,
        "regionName",
        lang
      );
    }
  } else {
    const countryMeta = secondaryCountries.find(
      (element) => element.code === personalInfo.residentCountryOther?.code
    );
    residentCountry = isEmpty(countryMeta) ? "" : countryMeta.name;
  }

  if (personalInfo.residentCountry?.code !== Config.CURRENT_PLATFORM) {
    const visaStatusMeta = visaStatusOptions.find(
      (element) => element.id === personalInfo.visaType.id
    );
    visaType = generateDisplayValueByMeta(visaStatusMeta, "visaType", lang);
  }

  output.residentCountry = residentCountry;
  output.residentRegion = residentRegion;
  output.visaType = visaType;

  output.residentCity = personalInfo.residentCity || "";

  const educationMeta = educationLevels.find(
    (element) => element.id === personalInfo.educationLevel?.id
  );
  output.educationLevel = generateDisplayValueByMeta(
    educationMeta,
    "level",
    lang
  );

  let profession = "";
  if (personalInfo.profession?.name === Constants.OTHER_PROFESSION_NAME) {
    profession = personalInfo.professionOther;
  } else {
    const professionMeta = professions.find(
      (element) => element.id === personalInfo.profession?.id
    );
    profession = generateDisplayValueByMeta(professionMeta, "name", lang);
  }
  output.profession = profession;

  const drinkingMeta = drinkingPreferences.find(
    (element) => element.value === personalInfo.drinking
  );
  output.drinking = generateDisplayValueByMeta(drinkingMeta, "label", lang);
  const foodPreferenceMeta = foodPreferences.find(
    (element) => element.id === personalInfo.foodPreference.id
  );
  output.foodPreference = generateDisplayValueByMeta(
    foodPreferenceMeta,
    "type",
    lang
  );
  const smokingMeta = smokingPrefernces.find(
    (element) => element.value === personalInfo.smoking
  );
  output.smoking = generateDisplayValueByMeta(smokingMeta, "label", lang);

  return output;
};

const generateParentInformation = (data, lang) => {
  const { PARENT_TYPE } = Constants;
  const output = {
    [PARENT_TYPE.FATHER]: ParentDisplayModel(PARENT_TYPE.FATHER),
    [PARENT_TYPE.MOTHER]: ParentDisplayModel(PARENT_TYPE.MOTHER),
  };

  const { genericData } = store.getState();
  const { ethnicities, religions, professions, countries } = genericData;
  const parentInfo = data.parentInfo;

  if (Array.isArray(parentInfo)) {
    for (const current of parentInfo) {
      const currentOutput = output[current.type];
      currentOutput.additionalInfo = current.additionalInfo ?? "";
      currentOutput.caste = current.caste ?? "";
      const parentTypeMeta = PARENT_OPTIONS.find(
        (element) => element.value === current.type
      );
      currentOutput.displayName = generateDisplayValueByMeta(
        parentTypeMeta,
        "label",
        lang
      );
      const ethnicityMeta = ethnicities.find(
        (element) => element.id === current.ethnicity.id
      );
      currentOutput.ethnicity = generateDisplayValueByMeta(
        ethnicityMeta,
        "name",
        lang
      );

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        if (current?.community) {
          currentOutput.community = current?.community?.name || "";
        } else {
          const communityMeta = ADDITIONAL_COMMUNITY_OPTIONS.find(
            (element) => element.id === current?.communityOther
          );
          currentOutput.community =
            generateDisplayValueByMeta(communityMeta, "name", lang) || "";
        }

        currentOutput.subCommunity = current?.subCommunity || "";
      }

      const religionMeta = religions.find(
        (element) => element.id === current.religion.id
      );
      currentOutput.religion = generateDisplayValueByMeta(
        religionMeta,
        "name",
        lang
      );

      if (!isEmpty(current.profession)) {
        let profession = "";
        if (current.profession?.name === Constants.OTHER_PROFESSION_NAME) {
          profession = current.professionOther;
        } else {
          const professionMeta = professions.find(
            (element) => element.id === current.profession.id
          );
          profession = generateDisplayValueByMeta(professionMeta, "name", lang);
        }
        currentOutput.profession = profession;
      }

      const countryMeta = countries.find(
        (element) => element.code === current.residentCountry.code
      );

      currentOutput.residentCountry = generateDisplayValueByMeta(
        countryMeta,
        "name",
        lang
      );
    }
  }

  const siblingInfo = data.siblingInfo;

  if (!isEmpty(siblingInfo) & Array.isArray(siblingInfo)) {
    let siblingsOutput = [];

    for (let sibling of siblingInfo) {
      let tempOutput = {};

      const maritalStatus = sibling.isMarried
        ? generateDisplayValueByMeta(MARITAL_OPTIONS[0], "label", lang)
        : generateDisplayValueByMeta(MARITAL_OPTIONS[1], "label", lang);

      const relationshipMeta = RELATIONSHIP_OPTIONS.find(
        (element) => element.id === sibling.relationship
      );

      const relationship = generateDisplayValueByMeta(
        relationshipMeta,
        "label",
        lang
      );

      const relationshipTypeMeta = RELATIONSHIP_TYPE_OPTIONS.find(
        (element) => element.id === sibling.relationshipType
      );

      const relationshipType = generateDisplayValueByMeta(
        relationshipTypeMeta,
        "label",
        lang
      );
      let profession = "";
      if (sibling.profession?.name === Constants.OTHER_PROFESSION_NAME) {
        profession = sibling.professionOther ?? "";
      } else {
        const professionMeta = professions.find(
          (element) => element.id === sibling.profession?.id
        );
        profession =
          generateDisplayValueByMeta(professionMeta, "name", lang) ?? "";
      }
      tempOutput.relationship = `${relationshipType} ${relationship}`;
      tempOutput.status = `${maritalStatus} - ${profession}`;

      siblingsOutput.push(tempOutput);
    }
    output.siblingInfo = siblingsOutput;
  }

  return output;
};

const generateHoroscopeInformation = (
  data,
  interestData,
  lang,
  variant,
  direction
) => {
  const { genericData } = store.getState();
  const { countries, starOptions, haveDoshOptions, starSignOptions } =
    genericData;
  const output = {};

  const horoscopeMatchingMeta = HOROSCOPE_MATCHING_LABEL_OPTIONS.find(
    (element) => element.value === data.horoscopeMatching?.toString()
  );

  output.horoscopeMatching = generateDisplayValueByMeta(
    horoscopeMatchingMeta,
    "label",
    lang
  );

  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    const acceptOtherCasteMeta = ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS.find(
      (element) => element.value === data.acceptOtherCaste
    );
    output.acceptOtherCaste =
      generateDisplayValueByMeta(acceptOtherCasteMeta, "label", lang) ?? "";
  }

  const birthCountryMeta = countries.find(
    (element) => element.code === data?.personalInfo?.originCountry?.code
  );

  output.birthCountry = generateDisplayValueByMeta(
    birthCountryMeta,
    "name",
    lang
  );

  let postInfo = {};

  if (
    variant === variantDirectionOptions.SELF &&
    direction === variantDirectionOptions.MATCHES
  ) {
    postInfo = data;
  } else {
    postInfo = interestData?.post;
  }

  output.birthday = postInfo?.personalInfo?.dob ?? "";
  output.additionalInfo = postInfo?.horoscopeInfo?.additionalData ?? "";
  output.birthCity = postInfo?.horoscopeInfo?.birthCity ?? "";
  output.birthTime = postInfo?.horoscopeInfo?.birthTime ?? "";
  if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
    const starMeta = starOptions.find(
      (element) => element.id === postInfo?.personalInfo?.star?.id
    );
    output.star = generateDisplayValueByMeta(starMeta, "name", lang) || "";

    const starSignMeta = starSignOptions.find(
      (element) => element.id === postInfo?.personalInfo?.starSign?.id
    );
    output.starSign =
      generateDisplayValueByMeta(starSignMeta, "name", lang) || "";

    const haveDoshMeta = haveDoshOptions.find(
      (element) => element.value === postInfo?.hasDosh
    );
    output.haveDosh =
      generateDisplayValueByMeta(haveDoshMeta, "label", lang) || "";
  }

  return output;
};

export const generateDisplayValues = async ({
  section,
  data,
  interestData,
  lang,
  variant,
  direction,
}) => {
  switch (section) {
    case DataDisplaySection.personalInformation:
      return generatePersonalInformation(data, lang);
    case DataDisplaySection.parentInformation:
      return generateParentInformation(data, lang);
    case DataDisplaySection.horoscopeInformation:
      return generateHoroscopeInformation(
        data,
        interestData,
        lang,
        variant,
        direction
      );
    default:
      return {};
  }
};
