import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import FormSection from "../../../../../components/molecules/form-section";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import { useSelector } from "react-redux";
import AppContext from "../../../../../context";
import { getRegionsByCountryCode } from "../../../../../features/generic-data";
import Constants from "../../../../../config/constant";
import FormAutoComplete from "../../../../../components/molecules/form-auto-complete";
import { useFormikContext } from "formik";
import {
  fieldVisibility,
  isEmptyOrNullOrUndefined,
} from "../../../../../utils";
import Config from "../../../../../config";

const { LANKA_COUNTRY_CODE } = Constants;
const { OTHER_COUNTRY_CODE, BASE_COUNTRY_CODE } = Config;

const ResidencyInfo = ({ formMeta }) => {
  const { values, setFieldValue, handleChange, errors, submitCount } =
    useFormikContext();
  const showFieldErrors = submitCount > 0;
  const { t } = useTranslation();
  const { countries, secondaryCountries, visaStatusOptions } = useSelector(
    (state) => state.genericData
  );
  const { appLanguage } = useContext(AppContext);
  const [countryRegions, setCountryRegions] = useState([]);
  const [cityOptions, setCityOption] = useState([]);

  const getCountryRegions = useCallback(async () => {
    let output = [];
    const countryCode = values.residentCountryCode;
    if (Boolean(countryCode) && countryCode !== OTHER_COUNTRY_CODE) {
      try {
        output = await getRegionsByCountryCode({
          countryCode,
          appLanguage,
        });
        setFieldValue("residentCountryCodeOther", "");
      } catch (e) {
        console.error("error countryRegions", e);
      }
    }
    setCountryRegions(output);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.residentCountryCode, appLanguage, setFieldValue]);

  const onSearchChange = async (_event, searchText) => {
    // if (event === null) {
    //   return;
    // }
    // const searchText = event.target?.value;
    // if(searchText === ""){
    //   setCityOption([])
    // }
    try {
      if (isEmptyOrNullOrUndefined(searchText)) {
        setCityOption([]);
        return;
      }
      const Google = window.google;
      const service = new Google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: searchText,
          types: ["geocode"],
          componentRestrictions: {
            country:
              values.residentCountryCode !== OTHER_COUNTRY_CODE &&
              values.residentCountryCode !== ""
                ? values.residentCountryCode
                : values.residentCountryCodeOther || "",
          },
        },
        (predictions, status) => {
          if (status === "OK") {
            const locations = predictions.map(
              (item) => item.structured_formatting.main_text
            );
            setCityOption(locations);
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const otherCountries = useMemo(() => {
    const primaryCountryCodes = countries.map((item) => item.code);
    return secondaryCountries.filter(
      (item) => !primaryCountryCodes.includes(item.code)
    );
  }, [countries, secondaryCountries]);

  const showCountryRegionField = useMemo(() => {
    return values[formMeta.residentCountry.fieldName] !== OTHER_COUNTRY_CODE;
  }, [formMeta.residentCountry.fieldName, values]);

  useEffect(() => {
    getCountryRegions();
  }, [getCountryRegions]);

  useEffect(() => {
    setCityOption([]);
  }, [values.residentCountryCode]);

  return (
    <FormSection heading={t("common.residency")}>
      <Grid container direction="row" className="row-gap-16">
        {fieldVisibility(formMeta.residentCountry) && (
          <Grid item className="form-element-container">
            <FormDropdown
              fieldName={formMeta.residentCountry.fieldName}
              value={values.residentCountryCode}
              onChange={handleChange}
              options={countries}
              keyExtractor={formMeta.residentCountry.keyExtractor}
              labelExtractor={formMeta.residentCountry.labelExtractor}
              label={t(formMeta.residentCountry.label)}
              lang={appLanguage}
              errorText={showFieldErrors && errors.residentCountryCode}
            />
          </Grid>
        )}
        {fieldVisibility(formMeta.residentRegion) && showCountryRegionField && (
          <Grid item className="form-element-container">
            <FormDropdown
              fieldName={formMeta.residentRegion.fieldName}
              value={values.residentRegionId}
              onChange={handleChange}
              options={countryRegions}
              keyExtractor={formMeta.residentRegion.keyExtractor}
              labelExtractor={formMeta.residentRegion.labelExtractor}
              label={t(formMeta.residentRegion.label)}
              lang={appLanguage}
              visibility={values.residentCountryCode !== OTHER_COUNTRY_CODE}
              disabled={
                !Boolean(values.residentCountryCode) ||
                !Boolean(countryRegions.length)
              }
              igonoreLocaleLabels={
                values.residentCountryCode !== LANKA_COUNTRY_CODE
              }
              errorText={showFieldErrors && errors.residentRegionId}
            />
          </Grid>
        )}
        {fieldVisibility(formMeta.residentCountryOther) &&
          !showCountryRegionField && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.residentCountryOther.fieldName}
                value={values.residentCountryCodeOther}
                onChange={handleChange}
                options={otherCountries}
                keyExtractor={formMeta.residentCountryOther.keyExtractor}
                labelExtractor={formMeta.residentCountryOther.labelExtractor}
                label={t(formMeta.residentCountryOther.label)}
                lang={appLanguage}
                igonoreLocaleLabels
                errorText={showFieldErrors && errors.residentCountryCodeOther}
              />
            </Grid>
          )}
        {fieldVisibility(formMeta.residentCity) && (
          <Grid item className="form-element-container">
            <FormAutoComplete
              fieldName={formMeta.residentCity.fieldName}
              onChange={setFieldValue}
              value={values.residentCity}
              onSearchChange={onSearchChange}
              label={t(formMeta.residentCity.label)}
              disabled={
                !(
                  values.residentCountryCode &&
                  (values.residentCountryCode !== OTHER_COUNTRY_CODE
                    ? values.residentRegionId
                    : values.residentCountryCodeOther)
                )
              }
              lang={appLanguage}
              errorText={showFieldErrors && errors.residentCity}
              options={cityOptions}
              autoSelect={true}
              freeSolo={true}
              disableClearable={true}
            />
          </Grid>
        )}
        {fieldVisibility(formMeta.visaType) &&
          values.residentCountryCode !== BASE_COUNTRY_CODE && (
            <Grid item className="form-element-container">
              <FormDropdown
                fieldName={formMeta.visaType.fieldName}
                value={values.visaTypeId}
                onChange={handleChange}
                options={visaStatusOptions}
                keyExtractor={formMeta.visaType.keyExtractor}
                labelExtractor={formMeta.visaType.labelExtractor}
                label={t(formMeta.visaType.label)}
                lang={appLanguage}
                errorText={showFieldErrors && errors.visaTypeId}
              />
            </Grid>
          )}
      </Grid>
    </FormSection>
  );
};

export default ResidencyInfo;
