import React, { Component } from "react";
import { Button, CircularProgress, Dialog } from "@material-ui/core";

import close from "../../images/close.png";

export default class UpdateEmailModal extends Component {
  render() {
    return (
      <Dialog
        open={this.props.emailModal}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        className="login-modal"
      >
        <div
          className="create-login-div login-div-container"
          style={{ margin: 0 }}
        >
          <img
            src={close}
            alt="close"
            className="close-icon"
            onClick={this.props.handleClose}
          />

          <p className="modal-heading">
            {this.props.email ? (
              this.props.lng === true ? (
                "Update Email"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  විද්‍යුත් තැපෑල යාවත්කාලීන කරන්න
                </span>
              )
            ) : this.props.lng === true ? (
              "Add Email"
            ) : (
              <span className="sinhala-w-600 sinhala-size-14">
                විද්‍යුත් තැපෑල ඇතුලත් කරන්න
              </span>
            )}
          </p>

          <p className="input-headings">
            {this.props.lng ? (
              "Email"
            ) : (
              <span className="sinhala-size-12">විද්‍යුත් තැපෑල</span>
            )}
          </p>

          <input
            type="email"
            name="updateEmail"
            className={
              this.props.emailError ? "login-input red-border" : "login-input"
            }
            value={this.props.updateEmail}
            onChange={this.props.handleChange}
            onKeyUp={this.props.handleEmailKeyUp}
          />
          <div className="error-handler">{this.props.emailError}</div>

          <Button
            variant="contained"
            className="update-btn"
            onClick={this.props.handleUpdateEmail}
            id="update-email-btn"
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : this.props.email ? (
              this.props.lng === true ? (
                "Update"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">
                  යාවත්කාලීන කරන්න
                </span>
              )
            ) : this.props.lng === true ? (
              "Add"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">එකතු කරන්න</span>
            )}
          </Button>
        </div>
      </Dialog>
    );
  }
}
