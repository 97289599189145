import React, { useMemo } from "react";
import InformationSection from "./information-section";
import HeaderSection from "./header-section";
import { Grid } from "@material-ui/core";
import RespondSection from "./respond-section";

export const variantDirectionOptions = Object.freeze({
  SELF: "SELF",
  MATCHES: "MATCHES"
});

const ProfileSection = Object.freeze({
  personalInfo: "personalInfo",
  respond: "respond",
  matchTimeline: "matchTimeline",
});

const ProfileView = ({ variant, direction, data }) => {

  const sectionVisibility = useMemo(() => {
    const visibilities = {};
    visibilities[ProfileSection.personalInfo] = true;
    visibilities[ProfileSection.respond] = direction === variantDirectionOptions.MATCHES;
    return visibilities;
  }, [direction]);

  return (
    <Grid container direction="column" style={{ rowGap: 20 }}>
      <HeaderSection data={data} variant={variant} direction={direction}/>
      {sectionVisibility[ProfileSection.respond] && (
        <RespondSection data={data} />
      )}
      <InformationSection data={data} variant={variant} direction={direction}/>
    </Grid>
  );
};

export default ProfileView;
