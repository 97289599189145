import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import Rating from "react-rating";
import "./comment-section.css";
import "../../styles/google-review-banner.css";

//  field enum
export const FIELDS = Object.freeze({
  startRating: "startRating",
  description: "description",
});

const CommentSection = ({ headerText, values, onChange, language }) => {
  return (
    <Grid className="comment-main-container" container direction="column">
      <Typography className="container-header-text">{headerText}</Typography>
      <Rating
        initialRating={values[FIELDS.startRating]}
        className="rate-starts-input"
        emptySymbol="far fa-star"
        fullSymbol="fas fa-star"
        onChange={(value) => {
          onChange(FIELDS.startRating, value);
        }}
      />
      <TextField
        placeholder={language ? "Add your comment" : "ඔබේ අදහස එක් කරන්න"}
        multiline
        fullWidth
        maxRows={4}
        variant="outlined"
        style={{
          backgroundColor: "white",
          borderColor: "white",
        }}
        inputProps={{
          style: {
            fontSize: "small",
            "::placeholder": {
              fontSize: "small",
              fontFamily: '"Noto Sans Sinhala Variable", "sans-serif',
            },
          },
        }}
        onChange={(e) => onChange(FIELDS.description, e.target.value)}
      />
    </Grid>
  );
};

export default CommentSection;
