import React, { useState, useEffect } from "react";
import {
  CircularProgress,
} from "@material-ui/core";
import "../../styles/packages.css";
import Config from "../../v2/config";

function Packages(props) {
  const [selectedPackage, setSelectedPackage] = useState("");

  useEffect(() => {
    if (props.selectedPackage) {
      setSelectedPackage(props.selectedPackage);
    }
  });

  return (
    <div className="payment-invoice" style={{ marginTop: '7px'}}>
      {props.packagesLoading ? (
        <div className="package-loading-div"><CircularProgress size={16} style={{ color: "#000" }} /></div>
      ) : (
        <div className="packages-list">
          {props.packages.map((el, i) => (
            (el.id !== "managed" ?
              <div
                className={
                  el.preferred === true ?
                    el.id === selectedPackage
                      ? "package-preferred package-active"
                      : "package-preferred"
                    :
                    el.id === selectedPackage
                      ? "package package-active"
                      : "package"
                }
                key={i}
                onClick={() => props.setPackage(el.id)}
                style={{paddingBottom: '10px'}}
              >
                {el.preferred === true &&
                  <div
                    className={
                      el.id === selectedPackage
                        ? "selected-best"
                        : "best"
                    }
                  >
                    BEST VALUE
                  </div>
                }
                <div className="package-element">
                  <div className="package-month-label" style={{marginTop: '10px'}}>
                    {props.lng !== true && <div className="package-valid-div">මාස {el.durationMonths}</div>}
                  </div>
                  <div className="package-valid-div">
                    {props.lng === true && (el.id==="1m" ? `${el.durationMonths} Month` : `${el.durationMonths} Months`)}
                  </div>
                  <div className="package-amount-div">
                    {props.countryCode === "LK" && Config.CURRENT_PLATFORM !== Config.PLATFORMS.IN ?
                      <>
                        <div>Rs. {Math.round(el.amountLKR/el.durationMonths)}</div>
                        <div className="per-month-label">{props.lng ? 'per month' : 'මසකට'}</div>
                      </>
                      :
                    sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true" ?
                      <>
                        <div style={{marginTop: '10px'}}>Rs. {Math.round(el.amountLKRNonResident/el.durationMonths)}</div>
                        <div className="per-month-label">{props.lng ? 'per month' : 'මසකට'}</div>
                      </>
                      :
                      <>
                        <div>USD {(el.amountUSD/el.durationMonths) % 1 !== 0 ? (el.amountUSD/el.durationMonths).toFixed(2).toString() : (el.amountUSD/el.durationMonths)}</div>
                        <div className="per-month-label">{props.lng ? 'per month' : 'මසකට'}</div>
                      </>
                    }
                  </div>
                  <div className="package-discount-label">
                    {props.countryCode === "LK" ?
                        <>
                          {(el.id==="2m") && (props.lng === true ? `SAVE ${props.twoMOnthsDiscountLKR}%` : `${props.twoMOnthsDiscountLKR}% වට්ටමක්`)}
                          {(el.id==="3m") && (props.lng === true ? `SAVE ${props.threeMonthsDiscountLKR}%` : `${props.threeMonthsDiscountLKR}% වට්ටමක්`)}
                        </>
                        :
                        <>
                          {(el.id==="2m") && (props.lng === true ? `SAVE ${props.twoMOnthsDiscount}%` : `${props.twoMOnthsDiscount}% වට්ටමක්`)}
                          {(el.id==="3m") && (props.lng === true ? `SAVE ${props.threeMonthsDiscount}%` : `${props.threeMonthsDiscount}% වට්ටමක්`)}
                        </>
                      }
                  </div>
                  <div className="total-package-amount-div">
                    {props.countryCode === "LK" && Config.CURRENT_PLATFORM !== Config.PLATFORMS.IN ?
                      `Rs. ${el.amountLKR.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      :
                      sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true" ?
                        <div style={{marginTop: '10px'}}>Rs. {el.amountLKRNonResident.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        :
                        <div>USD {el.amountUSD.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    }
                  </div>                  
                  {el.id === "managed" &&
                    <div className="managed-user-label">
                      {props.lng === true ? (
                      `Managed user package`
                    ) : (
                      `Managed user පැකේජය`
                    )}
                    </div>
                  }
                </div>
              </div>
              :
              <div
                className={
                  el.id === selectedPackage
                    ? "package package-active package-element-managed"
                    : "package package-element-managed"
                }
                key={i}
                onClick={() => props.setPackage(el.id)}
              >
                <div className="package-element">
                  <div className="managed-account-title">{props.lng === true ? "Fully Managed Account" : "ගිණුම සම්පූර්ණයෙන්ම කළමනාකරණය කරනු ඇත "}</div>
                  <div className="managed-account-description">
                    {props.lng === true ? `Enjoy peace of mind with a dedicated agent who will find the best possible matches for your profile.` : `ඔබේ දැන්වීම සඳහා හැකි හොඳම ගැලපීම් සොයා ගැනීමට අපගේ නියෝජිතයෙකු ලබා දෙනු ඇත.`}
                  </div>
                  <div className="managed-acc-footer">
                    <div className="package-month-label">
                      {props.lng === true ? `${el.durationMonths} Months` : `මාස ${el.durationMonths}`}
                    </div>
                    <div>
                      {props.countryCode === "LK" ?
                        `LKR ${el.amountLKR.toFixed(2)}`
                        :
                      sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true" ?
                        `LKR ${el.amountLKRNonResident.toFixed(2)}`
                        :
                        `USD ${el.amountUSD.toFixed(2)}`
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
}

export default Packages;
