import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import "./style.css";
import PageHead from "../../components/atoms/page-head";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import AppContext from "../../context";
import Config from "../../config";
import { useTranslation } from "react-i18next";
import { Home } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PaymentView = ({ advertisementId, advertisementStatus }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { lng, handleLng } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Fragment>
      <PageHead title={`Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Navbar handleLng={handleLng} lng={lng} />
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          minHeight: "70vh",
          backgroundColor: "#f7f7f7",
        }}
      >
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="invoice-container"
        >
          <Typography className="payment-info-header">
            {t("common.computedAdIdLabel", {
              id: advertisementId,
            })}
          </Typography>

          {advertisementStatus === "IN_REVIEW" && (
            <Typography variant="body1" className="checkout-error">
              {t("payment.postUnderReview")}
            </Typography>
          )}

          {advertisementStatus === "DISABLED" && (
            <Typography variant="body1" className="checkout-error">
              {t("payment.postIsDisabled")}
            </Typography>
          )}

          {(advertisementStatus === "IN_REVIEW" ||
            advertisementStatus === "DISABLED") && (
            <Button
              variant="contained"
              className="secondary-button margin-top-16 margin-bottom-16"
              startIcon={<Home />}
              onClick={() => history.push("/")}
            >
              {t("payment.goToHome")}
            </Button>
          )}
        </Grid>
      </Grid>
      {isMobileScreen ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </Fragment>
  );
};

export default PaymentView;
