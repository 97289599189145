import React from "react";
import { Button } from "@material-ui/core";

import { removePost } from "../../../actions/removePost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Info from "../../../images/info.png";

const UnautorizedProfile = (props) => {
  const clickToLogin = () => {
    let login = document.getElementById("drawer-login-btn");
    login.click();
  };
  return (
    <div
      className={
        props.isEmptyObj
          ? "received-profile-detail-div"
          : "received-profile-detail-div show-view"
      }
    >
      <span
        className="dis-back-btn back-btn-msg"
        onClick={() => props.removePost()}
      >
        <i className="fas fa-angle-left"></i> {props.lng ? "Back" : "ආපසු යන්න"}
      </span>
      <div className="inner-div" style={{ background: "#fff" }}>
        <div className="disable-div">
          <p align="center" style={{ marginBottom: 0 }}>
            <img src={Info} alt="info" style={{ width: 65, opacity: 0.7 }} />
          </p>
          <p className="message">
            {props.lng ? "Login Required" : "ප්‍රවේශ  වීම අවශ්‍යයි"}
          </p>
          <p align="center" className="desc">
            {props.lng ? (
              "Please log in to your ad account to access this content."
            ) : (
              <span className="sinhala-w-600 sinhala-size-14">
                මෙම අන්තර්ගතය නැරඹීමට කරුණාකර ඔබේ දැන්වීම් ගිණුමට පිවිසෙන්න.
              </span>
            )}
          </p>

          <Button
            variant="contained"
            onClick={clickToLogin}
            className="login-btn"
            style={{ width: 120 }}
          >
            {props.lng ? (
              "Login"
            ) : (
              <span className="sinhala-w-600 sinhala-size-14">
                ප්‍රවේශ වන්න
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ removePost: removePost }, dispatch);
};

export default connect(null, matchDispatchToProps)(UnautorizedProfile);
