import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { CURRENCY_META } from "../../../../config/constant";

const PaymentPackageCard = ({
  durationMonths,
  isPreferred,
  preferredText = "",
  currency = "",
  packagesCount = 0,
  payAmount,
  payAmountUsd,
}) => {
  const { t } = useTranslation();

  const calPayAmount = () => {
    const currencyMeta = CURRENCY_META.find(
      (element) => element.code === currency
    );
    const currencySymbol = currencyMeta.symbol;
    const amount = currency === "USD" ? payAmountUsd : payAmount ?? "";

    return `${currencySymbol} ${amount.toFixed(2)}`;
  };

  return (
    <Grid
      style={{
        width: 160,
      }}
    >
      {isPreferred && preferredText && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          className="preferrd-package"
        >
          <Typography className="preferrd-package-txt">
            {preferredText}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        className={`payment-package-card ${
          isPreferred && preferredText && `payment-package-card-preferred`
        } ${packagesCount < 2 && `payment-package-card-single-package`}`}
      >
        <Typography variant="h5" className="pay-amount-txt">
          {calPayAmount()}
        </Typography>

        <Typography className="package-duration-txt">
          {t("common.months", { months: durationMonths })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentPackageCard;
