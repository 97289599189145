import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  DialogTitle,
  Paper
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Switch from "react-input-switch";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import close from "../../images/close.png";
import "../../styles/message-modal.css"

class ShowIntMsgModal extends Component {
  state = {
    message: "",
    showHidePhone: "true",
    imageData: [],
    showHidePhotos: "false"
  };

  componentDidMount = () => {  
    window.scrollTo(0, 0);
    if (localStorage.getItem("auth_token")) {
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}`;
      } else {
        url = "account/posts";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            res.data[0] = res.data;
          }
          this.setState({
            gender: res.data[0].personalInfo.gender,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).images && JSON.parse(localStorage.getItem("post_details")).images.length > 0) {
      this.getProfilePhotos(JSON.parse(localStorage.getItem("post_details")).images);
    }
  };

  getProfilePhotos = (profileImgs) => {
    if (profileImgs.length > 0) {
      for (let i = 0; i < profileImgs.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/images/${
              profileImgs[i]
            }?thumbnail=false`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            this.setState({
              imageData: [...this.state.imageData, data],
              // preLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handle show hide
  handleShowHide = (value) => {
    this.setState({
      showHidePhone: value,
    });
  };

  handleShowHidePhotos = (value) => {
    this.setState({
      showHidePhotos: value,
    });
  };

  render() {
    return (
      <div
        open={this.props.messageModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="message-modal"
      >
        <Paper
          elevation={0}
          style={{ minHeight: "8vh", position: "relative", paddingTop: '60px', maxWidth: '450px', margin: 'auto' }}
          className="complete-ad-div settings-container post-details-con"
        >
          <div
            variant="h6"
            className="heading"
            style={{ marginBottom: '35px', textAlign: 'center', fontSize: '24px' }}
          >
            {this.props.lng ? (
              `Show your interest to ${this.props.displayName}`
            ) : (
              <span className="sinhala-w-600 sinhala-size-16">
                {this.props.displayName} ට කැමැත්ත පෙන්වන්න
              </span>
            )}
          </div>
          {this.props.preferenceMismatchMessage &&
            <div>
              <Alert severity="error" style={{ padding: "0 10px", marginBottom: '10px' }}>
                {this.props.lng ? this.props.preferenceMismatchMessage : this.props.preferenceMismatchMessageSinhala}
              </Alert>
            </div>
          }
          <div>
            <img
              src={close}
              alt="close"
              className="close-message-modal"
              onClick={this.props.handleModalClose}
            />

            <textarea
              className={
                this.props.sensitiveDataError
                  ? "message-textarea red-border"
                  : "message-textarea"
              }
              rows="5"
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              placeholder={
                this.props.lng === true
                  ? "Message (Optional)"
                  : "පණිවිඩය (අත්‍යවශ්‍ය නොවේ)"
              }
              maxLength="1000"
            />
            <p className="cus-modal-error">
              {this.props.lng ? (
                this.props.sensitiveDataError
              ) : (
                <span className="sinhala-size-11">
                  {this.props.sensitiveDataError}
                </span>
              )}
            </p>
            <Alert severity="info" style={{ padding: "0 10px" }}>
              {this.props.lng ? (
                `This message will be displayed to ${
                  this.props.gender === "MALE" ? "him" : "her"
                } along with your interest request.`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  ඔබගේ ඉල්ලීම සමඟ මෙම පණිවිඩය
                  {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} දර්ශනය වේ.
                </span>
              )}
            </Alert>
          </div>
          <div>
            <div
              className="share-phone-number-div"
              style={{ marginBottom: '30px', paddingBottom: 0, position: "relative", marginTop: '60px' }}
            >
              <Typography variant="h6" className="show-phone-on-off">
                {this.props.lng ? (
                  <span className="on-off-label">
                    Share my phone number when
                    {this.props.gender === "MALE" ? " he" : " she"} accepts my
                    request.
                  </span>
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                    මගේ ඉල්ලීම අනුමත කල විට මගේ දුරකථන අංකය
                    {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} පෙන්වන්න.
                  </span>
                )}
                <Switch
                  on="true"
                  off="false"
                  value={this.state.showHidePhone}
                  onChange={this.handleShowHide}
                  style={{
                    float: "right",
                  }}
                  className="interest-on-off-btn"
                />
              </Typography>
            </div>
            <div
              className="share-phone-number-div"
              style={{ marginBottom: '10px', paddingBottom: 0, position: "relative" }}
            >
              <Typography variant="h6" className="show-phone-on-off">
                {this.props.lng ? (
                  <span className="on-off-label">
                    Share my photos with
                    {this.props.gender === "MALE" ? " him" : " her"} before matching.
                  </span>
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                    මගේ ඉල්ලීම අනුමත කිරීමට පෙර මගේ ඡායාරූප
                    {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} පෙන්වන්න.
                  </span>
                )}
                <Switch
                  disabled = {(this.state.imageData.length > 0)? "" : "disabled"}
                  on="true"
                  off="false"
                  value={this.state.showHidePhotos}
                  onChange={this.handleShowHidePhotos}
                  style={{
                    float: "right",
                  }}
                  className="interest-on-off-btn"
                />
              </Typography>
            </div>
            <Alert severity="info" style={{ padding: "0 10px" }}>
              {this.props.lng ? (
                `You may choose to make your photos visible to ${this.props.displayName} along with your request. ${this.props.gender === "MALE" ? " He" : " She"} will be able to see them before matching with you even if ${this.props.gender === "MALE" ? " he" : " she"} has not uploaded photos of ${this.props.gender === "MALE" ? " him" : " her"} own.`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  ඔබගේ ඉල්ලීම සමඟ ඔබේ ඡායාරූප {this.props.displayName} ට දර්ශනය වේ. {this.props.gender === "MALE" ? " ඔහු" : " ඇය"} තමාගේ ඡායාරූප උඩුගත කර නොමැති වුවද ඔබ සමඟ ගැලපීමට පෙර {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} ඒවා දැකීමට හැකි වනු ඇත.
                </span>
              )}
            </Alert>
            <p className="cus-modal-error">
              {this.props.lng ? (
                this.props.sensitiveDataError
              ) : (
                <span className="sinhala-size-11">
                  {this.props.sensitiveDataError}
                </span>
              )}
            </p>
            <div>
              {(this.state.showHidePhotos === "true" && this.state.imageData.length > 0) ? (
                <PhotoProvider>
                  {this.state.imageData &&
                    this.state.imageData.map((item, index) => (
                      <PhotoConsumer
                        key={index}
                        src={item}
                        intro={item}
                      >
                        <img
                          src={item}
                          alt="profile pics"
                          className="photos"
                          onContextMenu={(e) => { e.preventDefault() }}
                        />
                      </PhotoConsumer>
                    ))}
                </PhotoProvider>
              ) : null}
            </div>
          </div>
          <div style={{marginTop: '50px', display: 'flex', flexDirection: 'column'}}>
            <Button
              variant="contained"
              className="int-btn"
              onClick={() =>
                this.props.handleShowInterest(
                  this.props.sendingPostId,
                  this.state.message,
                  this.state.showHidePhone,
                  this.state.showHidePhotos,
                )
              }
              color="primary"
              style={{marginBottom: '20px'}}
            >
              {this.props.loading ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : (
                <span>
                  {this.props.lng === true ? (
                    "Show Interest"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      කැමැත්ත පෙන්වන්න
                    </span>
                  )}
                </span>
              )}
            </Button>
            <Button
              variant="text"
              className="int-btn"
              onClick={this.props.handleModalClose}
              color="primary"
              style={{background: 'none', color: '#4F4F4F'}}
            >
              {this.props.loading ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : (
                <span>
                  {this.props.lng === true ? (
                    "Cancel"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      අවලංගු කරන්න
                    </span>
                  )}
                </span>
              )}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(ShowIntMsgModal);
