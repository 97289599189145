import React, { Component } from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "../../styles/dummy-post.css";

class Post extends Component {
  render() {
    return (
      <div className="post dummy-post">
        <Skeleton animation="wave" variant="circle" width={45} height={45} />
        <Skeleton animation="wave" className="dummy-name" />
        <Skeleton animation="wave" className="dummy-address" />
        <Skeleton animation="wave" variant="rect" className="dummy-content" />
      </div>
    );
  }
}

export default Post;
