import React from "react";
import PropTypes from 'prop-types';
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";

const PhotoGrid = ({ images }) => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <PhotoProvider onVisibleChange={(visible) => {
      if (visible) {
        document.addEventListener("contextmenu", handleContextMenu);
      } else {
        document.removeEventListener("contextmenu", handleContextMenu);
      }
    }}>
      {images.map((item, index) => (
          <PhotoConsumer key={index} src={item} intro={item}>
            <img
              src={item}
              alt=""
              className="photos"
              onContextMenu={handleContextMenu}
            />
          </PhotoConsumer>
        ))}
    </PhotoProvider>
  );
};

PhotoGrid.propTypes = {
  images: PropTypes.array.isRequired
};

export default PhotoGrid;
