import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";
import heart from "../../../images/heart.png";
import googlePlay from "../../../images/google-play.png";
import appStore from "../../../images/app-store.svg";
import moment from "moment";

const Header = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              {props.post && (
                <div className="post-details-col ">
                  <img src={props.post.gender === "MALE" ? groom : bride} className="applicant-photo" alt="groom" />
                  <Typography variant="h6" className="name">
                    <span style={{ textTransform: "capitalize" }}>
                      {props.post.extName}
                    </span>
                  </Typography>
                  <p className="location">
                    {props.post.extBirthYear}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.extCity}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.extProfession}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  </p>
                </div>
              )}
              {props.post && (
                <div className="post-date-col">
                  <p align="right" className="date-of-post">
                    {props.post.countryCode ===
                    "" ? (
                      <i
                        className="fas fa-globe-americas"
                        data-tip="Other Country"
                      ></i>
                    ) : (
                      <ReactCountryFlag
                        countryCode={
                          props.post.countryCode
                        }
                        svg
                        style={{
                          fontSize: "1.25em",
                        }}
                      />
                    )}
                  </p>
                </div>
              )}
              <p className="likes-you">
                <img className="heart-icon" src={heart} alt="heart" />
                &nbsp; 
                {props.lng
                  ? " Liked you on "
                  : " කැමැත්ත ප්‍රකශ කරන ලද්දේ "}
                  {moment
                  .utc(
                    props.post.createdAt
                  )
                  .local()
                  .format("MMM D")}
              </p>
            </React.Fragment>
          )}
        </Paper>


        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <div style={{color: '#333333', fontSize: '14px'}}>
                {props.lng
                  ? <p>This profile is from an App called <strong>Hytch</strong> which is developed and operated by the Poruwa.lk team. Hytch is a privacy focused dating app designed for Sri Lankans and all Poruwa users are eligible to join it free of charge for 3 months.</p>
                  : <p>මෙය Poruwa.lk කණ්ඩායම විසින් මෙහෙයවනු ලබන <strong>Hytch</strong> නම් App එකයි. Hytch යනු ශ්‍රී ලාංකිකයන් සඳහා නිර්මාණය කර ඇති රහස්‍යතා කේන්ද්‍ර කරගත් App එකක් වන අතර සියලුම Poruwa භාවිතා කරන්නන් හට මාස 3ක් සඳහා නොමිලේ එයට සම්බන්ධ විය හැක.</p>}
              </div>
              <div className="app-store-div">
                <a
                  href="https://www.facebook.com/poruwaSL"
                  target="_blank"
                  style={{marginTop: '-5px', marginRight: '10px', marginLeft: '10px'}}
                >
                  <img
                    src={googlePlay}
                    alt="google play"
                    style={{width: '225px', height: '96px'}}
                  />
                </a>
                <a
                  href="https://www.facebook.com/poruwaSL"
                  target="_blank"
                  style={{marginLeft: '10px', marginRight: '10px'}}
                >
                  <img
                    src={appStore}
                    alt="app store"
                    style={{width: '200px', height: '85px'}}
                  />
                </a>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};
                
const mapStateToProps = (state) => {
  return {
    id: state.matches.post.id,
    post: state.matches.post,
    images: state.matches.post
      ? state.matches.post.post
        ? state.matches.post.post.images
          ? state.matches.post.post.images
          : []
        : []
      : [],
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    isSentActive: state.matches.activeBtnType.sent,
  };
};

export default connect(mapStateToProps, null)(Header);
