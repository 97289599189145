const PersonalInfoModel = {
  fname: "",
  lname: "",
  dob: "",
  gender: "",
  height: "",
  religionId: "",
  ethnicityId: "",
  originCountryCode: "",
  residentCountryCode: "",
  residentCountryCodeOther: "",
  residentRegionId: "",
  residentCity: "",
  visaTypeId: "",
  educationLevelId: "",
  professionId: "",
  otherProfession: "",
  civilStatusId: "",
  drinking: "",
  smoking: "",
  foodPreferenceId: "",
  caste: "",
  additionalInfo: "",
  displayNameFormat: "",
  children: "",
};

const PersonalCreateDataForm = Object.freeze({
  fname: "",
  lname: "",
  displayNameFormat: "",
  email: "",
  dob: "",
  gender: "",
  religionId: "",
  ethnicityId: "",
  height: "",
  caste: "",
  civilStatusId: "",
  residentCountryCode: "",
  residentCountryCodeOther: "",
  originCountryCode: "",
  residentRegionId: "",
  residentCity: "",
  visaTypeId: "",
  educationLevelId: "",
  professionId: "",
  otherProfession: "",
  drinking: "",
  smoking: "",
  foodPreferenceId: "",
  additionalInfo: "",
  children: "",
  differentlyAbled: "",
  description: "",
});

const TempProfile = {
  PersonalInfoModel: { ...PersonalInfoModel },
  PersonalCreateDataForm: { ...PersonalCreateDataForm },
};

export default TempProfile;
