import React, { Component } from "react";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import "../../styles/complete-your-ad.css";

export default class CompleteItem extends Component {
  
  state = {
    topAdModal: false,
  };
  
  redirectFunc = () => {
    if (this.props.scrollTo === "make-top-ad-div") {
      this.handleTopAdModalOpen();
    } else {
      this.props.url(this.props.path, this.props.scrollTo);
    }
  }

  handleTopAdModalClose = () => {
    this.setState({ topAdModal: false });
  }

  handleTopAdModalOpen = () => {
    this.setState({ topAdModal: true });
  }

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}            
            className="complete-ad-div main-paper"
          >
            <React.Fragment>
                <div className="row">
                  <p style={{textAlign:"center"}}>
                    <img className="heading-img" src={this.props.img} />
                  </p>
                  <p className="title">
                    {this.props.title}
                  </p>
                  <p className="main-desc">
                    {this.props.description} <br></br> {(this.props.description2) ? this.props.description2 : ""}
                  </p>
                  <div style={{ paddingBottom: 70 }}>
                    <Button
                      variant="contained"
                      className={this.props.isDisable ? "disabled-redirect-btn" : "common-save-btn"}
                      onClick={this.redirectFunc}
                      disabled={this.props.isDisable}
                      style={{float: "right", width: "190px", marginTop: "20px"}}
                      >
                      {this.props.isDisable ? this.props.lng ? "Completed" : "සම්පූර්ණ කර ඇත" : this.props.btnText}
                    </Button>
                  </div>
                </div>
              </React.Fragment>
          </Paper>
          <Dialog
            open={this.state.topAdModal}
            onClose={this.handleTopAdModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "#000", paddingBottom: 0 }}
            >
              Top Ad
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.lng ? (
                  "Would you like to convert your ad as a Top Ad"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">
                    ඔබගේ දැන්වීම Top Ad දැන්වීමක් ලෙස පරිවර්තනය කිරීමට කැමතිද?
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleTopAdModalClose}>
                {this.props.lng ? (
                  "Cancel"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">අවලංගු කරන්න</span>
                )}
              </Button>
              <Button
                onClick={this.props.openModalAction}
                style={{ background: "#000", color: "#fff" }}
              >
                {this.state.loadingDelete ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  <span>
                    {this.props.lng ? (
                      "Continue"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-13">ඉදිරියට යන්න</span>
                    )}
                  </span>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}
