import React from "react";
import { Grid } from "@material-ui/core";
import PersonalInformation from "./personal-information";
import HoroscopeInformation from "./horoscope-information";
import FamilyInformation from "./family-information";
import "./style.css";

const InformationSection = ({ data, variant, direction }) => {
  return (
    <>
      <Grid
        container
        direction="column"
        style={{ rowGap: 20, borderRadius: 10 }}
      >
        <PersonalInformation data={data} />
        <FamilyInformation data={data} />
        <HoroscopeInformation data={data} variant={variant} direction={direction}/>
      </Grid>
    </>
  );
};

export default InformationSection;
