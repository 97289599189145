import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export const SectionHeading = ({ labelKey }) => {
  const { t, i18n } = useTranslation();
  return (
    <h6 className={`section-heading section-heading-${i18n.language}`}>
      {t(labelKey)}
    </h6>
  );
};

export const SectionSubHeading = ({ labelKey }) => {
  const { t, i18n } = useTranslation();

  return (
    <p className={`section-sub-heading section-sub-heading-${i18n.language}`}>
      {t(labelKey)}
    </p>
  );
};
