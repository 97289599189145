import * as Yup from "yup";
import FormMeta from "../../../../../../../meta/sibling-form.json";
import {
  buildFormErrorMessage,
  FormErrorMessage,
  isOtherProfession,
} from "../../../../../../../utils";

const CommonSchema = (t) => {
  return Yup.object().shape({
    [FormMeta.relationship.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.relationship.fieldName
      )
    ),
    [FormMeta.isMarried.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.isMarried.fieldName
      )
    ),
    [FormMeta.profession.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.profession.fieldName
      )
    ),
    [FormMeta.otherProfession.fieldName]: Yup.string().when(
      FormMeta.profession.fieldName,
      ([professionId], scheam) => {
        if (isOtherProfession(professionId)) {
          return scheam.required(
            buildFormErrorMessage(
              t,
              FormErrorMessage.REQUIRED_FIELD,
              FormMeta.otherProfession.label
            )
          );
        }
      }
    ),
  });
};
const FormSchema = (t) => {
  const schema = CommonSchema(t);
  return schema;
};

export default FormSchema;
