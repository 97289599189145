import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import withLanguageMigrator from "../../v2/hoc/language-migrator";

const SettingsFAQ = ({ lng }) => {
  const [selected, setSelected] = useState(1);
  const [expandArr, setExpandArr] = useState([]);
  const QNA = [
    {
      id: 1,
      quee: "Can I hide my advertisement from the public advertisements list?",
      ques: "මාගේ දැන්වීම අනෙක් පාර්ශවයන්ට නොපෙනෙන පරිදි සගවා තැබිය හැකිද?",
      answe:
        "Yes. You can hide your advertisement by going to ‘settings’ and clicking the ‘Hide Ad’ button. You can make your Ad visible to the public whenever you want.",
      answs:
        "ඔව්. ඔබට ‘මෙවලම්’ වෙත ගොස් ‘දැන්වීම සගවන්න’ යන බොත්තම ඔබා ඔබගේ දැන්වීම සගවා තැබීමට හැකියි. එය ඔබට අවශ්‍ය විටෙක නැවත සැමටම පෙනෙන පරිදි වෙනස් කල හැකිය.",
    },
    {
      id: 2,
      quee: "How do I remove my advertisement?",
      ques: "මාගේ  දැන්වීම ස්ථිර ලෙසම ඉවත් කර ගන්නේ කෙසේද?",
      answe:
        "You can permanently remove your advertisement by clicking ‘settings’ and clicking the ‘Remove Ad’ button, and it cannot be undone. You can register with a new Ad when you want.",
      answs:
        "‘මෙවලම්’ වෙත ගොස් ‘දැන්වීම ඉවත් කරන්න’ යන්න හරහා ඔබට ඔබගේ දැන්වීම ඉවත් කර ගත හැකියි. එසේ ඉවත් කල දැන්වීමක් නැවත ලබා ගත නොහැකි අතර ඔබට අවශ්‍ය වූ විටෙක නව දැන්වීමක් මගින් ලියාපදිංචි විය හැක.",
    },
    {
      id: 3,
      quee: "Can the email address or phone number used during registration be changed whenever necessary?",
      ques: "ලියාපදිංචි වීමේදී යොදාගත් ඊමේල් ලිපිනය හෝ දුරකථන අංකය අවශ්‍ය විටෙක වෙනස් කල හැකිද?",
      answe:
        "Yes. The email address or phone number can be changed whenever necessary. In the ‘settings’ section, After entering the email address or phone number you want to change in the 'Contact / Login Information' section shown, we will provide a verification code at which point you can verify the phone number or email address.",
      answs:
        "ඔව්. ඊමේල් ලිපිනය හෝ දුරකථන අංකය අවශ්‍ය විටෙක වෙනස් කිරීමේ හැකියාව ඇත. ‘මෙවලම්’ හි පෙන්වා ඇති ‘සම්බන්ධතා / පිවිසුම් තොරතුරු’  කොටසෙහි ඔබට වෙනස් කිරීමට අවශ්‍ය ඊමේල් ලිපිනය හෝ දුරකථන අංකය ඇතුලත් කිරීමෙන් පසු එම අවස්ථාවේදී අප විසින් සත්‍යාපන කේතයක් ලබා දෙන අතර එමගින් දුරකථන අංකය හෝ ඊමේල් ලිපිනය තහවුරු කල හැකියි.",
    },
    {
      id: 4,
      quee: "Can I disable ‘Accept written interests via post’ ?",
      ques: "මා හට අවශ්‍ය විටෙක ‘ලිපි මගින් පිළිතුරු ලබා ගැනීම’ අක්‍රීය කල හැකිද?",
      answe:
        "If you do not wish to receive responses through letters, you can go to the ‘settings’ and disable the ‘Accept written interests via post’ option. Then others will not be able to contact you by offline responses, and you can enable it whenever you want.",
      answs:
        "ඔව්. ‘මෙවලම්’ තුල ඇති ‘දැන්වීම් වලට පිළිතුරු තැපැල් මගින් ලබා ගන්න’ යන යෙදවුම තුලින් එය අක්‍රීය කල හැකි අතර නැවත ඔබට අවශ්‍ය වූ විටෙක සක්‍රීය කිරීමේ හැකියාව ඇත.",
    },
    {
      id: 5,
      quee: "How do I renew my Ad when it expires?",
      ques: "දැන්වීමක් කල් ඉකුත් වූ විට නැවත එය අලුත් කරගන්නේ කෙසේද?",
      answe:
        "If your Ad is expired, click on the ‘AD ID’ button in the top right corner. You can then renew your Ad by clicking the 'Renew Ad' button, choosing your preferred payment option, and making the relevant payment to renew the Ad.",
      answs:
        "ඔබගේ දැන්වීම කල් ඉකුත් වී ඇත්නම් දකුණු පස උඩ කෙලවරේ ඇති ගිණුම් අයිකනය පෙන්වන මෙනුවෙහි ඇති ‘අලුත් කරන්න’ යන බොත්තම ඔබා  මුදල් ගෙවීම මගින් නැවත දැන්වීම අලුත් කර ගත හැක.",
    },
    {
      id: 6,
      quee: " How can I contact customer support if I want to get any other information apart from the given information?",
      ques: "ලබා දී ඇති තොරතුරු වලට අමතරව වෙනත් අවශ්‍ය තොරතුරක් ලබාගැනීමට අවශ්‍ය නම් මා සම්බන්ධ විය යුත්තේ කෙසේද ?",
      answe: (
        <React.Fragment>
          If you need any further assistance, you can contact us through our
          customer service number or Facebook, or WhatsApp. You can also let us
          know by email address. Our details are shown below.
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">Address</h4>
            <p className="new-faq-sub-data">No.356</p>
            <p className="new-faq-sub-data">Denzil Kobbakatuwa Road,</p>
            <p className="new-faq-sub-data">Battaramulla,</p>
            <p className="new-faq-sub-data">Sri Lanka.</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">Email Address </h4>
            <p className="new-faq-sub-data">
              <a
                href="mailto: support@poruwa.lk"
                style={{ textDecoration: "none", color: "black" }}
              >
                support@poruwa.lk
              </a>
            </p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">Contact Number </h4>
            <p className="new-faq-sub-data">0112824814</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">WhatsApp </h4>
            <p className="new-faq-sub-data">0112824814</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">Facebook </h4>
            <p className="new-faq-sub-data">
              <a
                href="https://www.facebook.com/poruwaSL"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "black" }}
              >
                https://www.facebook.com/poruwaSL
              </a>
            </p>
          </div>
        </React.Fragment>
      ),
      answs: (
        <span>
          ඔබට කිසියම් වැඩිදුර විස්තරයක් අවශ්‍ය නම් අපගේ පාරිබෝගික සේවා අංකය හෝ
          Facebook ,WhatsApp හරහා සම්බන්ද විය හැක. තව ද අපගේ ඊමේල් ලිපිනය මගින්
          ද අප හා සම්බන්ද විය හැකි අතර එම විස්තර පහතින් දැක් වේ.
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">ලිපිනය </h4>
            <p className="new-faq-sub-data">අංක 356</p>
            <p className="new-faq-sub-data">ඩෙන්සිල් කොබ්බෑකඩුව පාර,</p>
            <p className="new-faq-sub-data">බත්තරමුල්ල,</p>
            <p className="new-faq-sub-data">ශ්‍රී ලංකාව.</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">ඊමේල් ලිපිනය</h4>
            <p className="new-faq-sub-data">
              <a
                href="mailto: support@poruwa.lk"
                style={{ textDecoration: "none", color: "black" }}
              >
                support@poruwa.lk
              </a>
            </p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">දුරකථන අංකය</h4>
            <p className="new-faq-sub-data">0112824814</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">WhatsApp </h4>
            <p className="new-faq-sub-data">0112824814</p>
          </div>
          <div className="new-faq-sub-div">
            <h4 className="new-faq-sub-topic">Facebook </h4>
            <p className="new-faq-sub-data">
              <a
                href="https://www.facebook.com/poruwaSL"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "black" }}
              >
                https://www.facebook.com/poruwaSL
              </a>
            </p>
          </div>
        </span>
      ),
    },
    {
      id: 7,
      quee: "What are the statistics mentioned at the top of the ‘settings’ page?",
      ques: "සැකසුම් පිටුවෙහි ඉහලින්ම පෙන්වන සංක්යාලේඛන මගින් ප්‍රකාෂ වන්නේ කුමක්ද?",
      answe:
        "It displays how your Ad appeared on our website to other parties, interest requests that you have received and sent and other statistics to get a better understanding of your Ad performance conveniently. If you want, you can change its timeline and check for your reference.",
      answs:
        "එමගින් ඔබගේ දැන්වීම අපගේ වෙබ් අඩවිය තුල අනෙක් පාර්ශවයන්ට දිස්වූ ආකාරය, ඔබට ලැබුණු සහ ඔබ විසින් යැවූ ඉල්ලීම් සහ අනෙකුත් දත්ත ඔබට දැනගැනීම සදහා අප විසින් එම සංක්යාලේඛන මගින් පෙන්වනු ලබයි.ඔබට අවශ්‍ය ලෙස එහි කාලරේඛාව වෙනස් කල හැක.",
    },
    {
      id: 8,
      quee: "How can I verify the identity of my Ad? What details does it require?",
      ques: "මාගේ දැන්වීමෙහි අනන්‍යතාවය තහවුරු කරගන්නේ කෙසේද? එයට අවශ්‍ය වන විස්තර මොනවාද?",
      answe:
        "Go to the ‘settings’ section in the main menu and there you will find the ‘ID Verification’ section. By uploading pictures of your NIC, passport, driving license, or any other related documents you can verify your identity. Once you upload pictures, our team will review and verify your Ad. This will make your Ad appear as a verified Ad to other parties viewing your Ad, thus increasing the appeal compared to other Ads.",
      answs:
        "ප්‍රධාන මෙනුවෙහි ඇති ‘සැකසුම්’ පිටුවට ගොස් එහි පෙන්වන ‘අනන්‍යතාවය තහවුරු කිරීම’ යන කොටසෙහි ඔබගේ ජාතික හැදුනුම්පතෙහි,රියදුරු බලපත්රයෙහි, ගමන් බලපත්රයෙහි හෝ ඔබගේ අනයතාවය තහවුරු කල හැකි වෙනත් ඕනෑම ලේඛනයක පින්තුර ඇතුලත් කිරීම මගින් අප විසින් එය පරික්ෂා කිරීමෙන් අනතුරුව ඔබගේ දැන්වීම තහවුරු කිරීම සිදු කරනු ලබයි. එමගින් ඔබගේ දැන්වීම බලන අනෙක් පාර්ශවයන් හට ඔබගේ දැන්වීම තහවුරු කරන ලද දැන්වීමක් ලෙස දිස්වන අතර එමගින් අනෙක් ද්න්වීම් වලට සාපේක්ෂව ලැබෙන ආකර්ෂණය වැඩි වේ.",
    },
    {
      id: 9,
      quee: "How to log in to the Ad with a new password if there is no current password or if the password is forgotten?",
      ques: "වත්මන් මුරපදයක් නොමැති විට හෝ මුරපදය අමතක වූ විට අලුත් මුරපදයක් තුලින් දැන්වීමට ඇතුල් වන්නේ කෙසේද?",
      answe:
        "You can go to “Log in using password” and press “Forgot password” and, add the password reset code received to your phone number and create a new password.",
      answs:
        "‘මුරපදය භාවිතයෙන් පිවිසෙන්න’ වෙත ගොස් ‘මුරපදය අමතක උනාද’ ඔස්සේ ඔබගේ දුරකථන අංකයට ලැබෙන කේතය එකතු කර අලුත් මුරපදයක් දැන්වීමට එකතු කල හැක.",
    },
    {
      id: 10,
      quee: "Why am I not allowed to log in to the ad through my email address?",
      ques: "මාගේ  ඊමේල් ලිපිනය හරහා දැන්වීමට ඇතුලත් වීමට ඉඩ ලබා නොදෙන්නේ ඇයි ?",
      answe:
        "If you have not verified your email address, you will not be able to log in through it, and you can always log in to your account through the verified phone number. You can also send a verification link to your email address which you added previously and not verified, by clicking the ‘Resend verification email’  button next to the email address and by confirming the email address through it, you will be able to verify the email address and login to your Ad.",
      answs:
        "ඔබගේ ඊමේල් ලිපිනය සත්‍යාපනය කර නොමැති නම් හෝ ඊමේල් ලිපිනයක් ඇතුලත් කර නොමැති විට එය හරහා ඇතුලත් විය නොහැකි අතර ඔබ ඊමේල් ලිපිනයක් කලින් ඇතුලත් කර එය සත්‍යාපනය කර නොමැති විට දිස්වන “නැවත යවන්න” බොත්තම ඔබා ඔබගේ ඊමේල් ලිපිනයට එන ඊමේල් පණිවිඩය හරහා සත්‍යාපනය කිරීමෙන් පසු ගිණුමට ඇතුළු විය හැක. ඊට අමතරව සත්‍යාපනය කරන ලද දුරකථන අංකය හරහා ඔබට සැමවිටම දැන්වීමට ඇතුලත් විය හැක.",
    },
    {
      id: 11,
      quee: "What does ‘User does not exist’ mean?",
      ques: "පරිශීලකයා නොපවතී යනුවෙන් අදහස් වන්නේ කුමක්ද?",
      answe:
        "This means that an Ad has not been created with the phone number or email address you entered, or that the phone number or email address is entered incorrectly.",
      answs:
        "එමගින් අදහස් වන්නේ ඔබ ඇතුලත් කරන ලද දුරකථන අංකයෙන් හෝ ඊමේල් ලිපිනයෙන් ගිණුමක් සෑදී නොමැති බව හෝ එම දුරකථන අංකය හෝ ඊමේල් ලිපිනය වැරදි ලෙස ඇතුලත් කල විටය.",
    },
    {
      id: 12,
      quee: "Can I change my information in the Ad whenever I want?",
      ques: "අවශ්‍ය විටෙක දැන්වීමෙහි ඇතුලත් තොරතුරු වෙනස් කල හැකිද?",
      answe:
        "Your gender and country of residence cannot be changed again after registration, and the date of birth, ethnicity, religion, civil status, and height can be changed only within 3 days after registration. All other information can be changed whenever you want. Your name also cannot be changed after your ad got verified.",
      answs:
        "ඔබගේ (ස්ත්‍රී/පුරුෂ භාවය,පදිංචි රට) ලියාපදිංචි වීමෙන් පසු නැවත වෙනස් කල නොහැකි අතර (උපන් දිනය,ජාතිය,ආගම,සිවිල් තත්ත්වය, උස) යන තොරතුරු වෙනස් කල හැක්කේ ලියාපදිංචි වී දවස් 3ක් ඇතුලත පමණයි. අනෙකුත් සියලුම තොරතුරු ඔබට අවශ්‍ය විටෙක වෙනස් කල හැක.දැන්වීම තහවුරු කරන අවස්ථාවෙන් පසු ඔබගේ නම ද වෙනස් කල නොහැක.",
    },
    {
      id: 13,
      quee: "What information is added to the Ad after a review?",
      ques: "සංස්කරණය කල විට සමාලෝචනයකින් පසුව දැන්වීමට එක්වන තොරතුරු මොනවාද?",
      answe:
        "When you add or change more information about your description, the name mentioned in your Ad, additional details of parents, photos, horoscope photos, and horoscope information, we will update them after a review and then update that information in the Ad.",
      answs:
        "ඔබ පිලිබදව වැඩි විස්තර,ඔබගේ දැන්වීමෙහි පල කරන ලද නම, දෙමව්පියන්ගේ අමතර විස්තර,ජායාරූප,කේන්දර සටහන් ජායාරූප,කේන්දර සටහන් වැඩි විස්තර යන තොරතුරු ඔබ ඇතුලත් කල විට හෝ වෙනස් කළහොත් අප විසින් සිදු කරන සමාලෝචනයකින් අනතුරුව යාවත්කාලීන කරනු ලබන අතර ඉන්පසු එම තොරතුරු දැන්වීමට ඇතුලත් වේ.",
    },
    {
      id: 14,
      quee: "Is there an additional charge for adding more details or editing the Ad?",
      ques: "යම් විස්තරයක් එකතු කිරීමේදී හෝ වෙනස් කිරීමේදී අමතර ගාස්තුවක් අයවේද?",
      answe:
        "No. There is no additional charge when you add or edit the information on your Ad. You can change the Ad details as per your requirement.",
      answs:
        "නැත. වෙනත් කිසිම අමතර ගාස්තුවක් අය නොවන අතර ඔබට අවශ්‍ය පරිදි දැන්වීම් විස්තර වෙනස් කල හැක.",
    },
    {
      id: 15,
      quee: "How long will it take for the information to be reviewed and updated after editing my Ad?",
      ques: "මගේ දැන්වීම සංස්කරණය කිරීමෙන් පසු එම තොරතුරු සමාලෝචනය වී යාවත්කාලින වීමට කොපමණ කාලයක් ගතවේ ද?",
      answe:
        "The information will be updated within 12 hours after you edit the Ad and if any information is not validated, we will notify you by email, and you can log in to the Ad again and change the information.",
      answs:
        "ඔබ දැන්වීම සංස්කරණය කර පැය 12ක් ඇතුලත තොරතුරු යාවත්කාලින වන අතර කිසියම් තොරතුරක වලංගු භාවය තහවුරු නොවුනහොත් අප විසින් එය ඊමේල් පණිවිඩයක් මගින් දැනුම් දෙන අතර නැවත දැන්වීමට පිවිස එම තොරතුරු වෙනස් කල හැක.",
    },
    {
      id: 16,
      quee: "How can I add sibling details to the Ad?",
      ques: "සහෝදර සහෝදරියන් පිළිබද විස්තර දැන්වීමට එකතු කල හැක්කේ කෙසේද?",
      answe:
        "You can enter the details of your siblings by going to ‘Edit my Ad’ in the main menu and clicking on the “Add Siblings” button in the “Sibling Details” section.",
      answs:
        "ප්‍රධාන මෙණුවෙහි ඇති දැන්වීම වෙනස් කරන්න වෙත ගොස් එහි ඇති “සහෝදර සහෝදරියන්ගේ තොරතුරු” කොටසෙහි “සහෝදර සහෝදරියන් එකතු කරන්න” යන බොත්තම ඔබා ඔබගේ සහෝදර සහෝදරියන් පිළිබද විස්තර ඇතුලත් කල හැක.",
    },
  ];
  return (
    <div>
      <div className="new-faq-mobile">
        {QNA.map((qna) => (
          <div className="new-faq-div">
            <div className="new-faq-col">
              <div
                onClick={() => {
                  if (!expandArr.includes(qna.id)) {
                    setExpandArr((current) => [...current, qna.id]);
                  } else {
                    setExpandArr(
                      expandArr.filter((current) => current !== qna.id)
                    );
                  }
                }}
                className="new-faq-ques"
              >
                <span className="new-faq-ques-wrapper">
                  {lng === true ? <>{qna.quee}</> : <span>{qna.ques}</span>}
                </span>
                {expandArr.includes(qna.id) ? (
                  <i class="fas fa-chevron-down faq-drawer-icon"></i>
                ) : (
                  <i class="fas fa-chevron-right faq-drawer-icon"></i>
                )}
              </div>
              <p
                className={
                  expandArr.includes(qna.id)
                    ? "new-faq-ans-expand"
                    : "new-faq-ans"
                }
              >
                {lng === true ? (
                  <React.Fragment>{qna.answe}</React.Fragment>
                ) : (
                  <span>{qna.answs}</span>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withLanguageMigrator(SettingsFAQ);
