import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import Header from "./detail-page/Header";
import TimeLine from "./detail-page/TimeLine";
import Connect from "./detail-page/Connect";
import Personal from "./detail-page/Personal";
import Parent from "./detail-page/Parent";
import Horoscope from "./detail-page/Horoscope";
import UnavailableProfile from "./interest-com/UnavailableProfile";

import UnmatchModal from "../modals/UnmatchModal";
import AlertModal from "./detail-page/AlertModal";

import { setPreLoading } from "../../actions/setPreLoading";
import { removePost } from "../../actions/removePost";
import { fetchInterestPost } from "../../actions/fetchInterestPost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getInnerHeight } from "./methods/getInnerHeight";

function MatchedInterest(props) {
  const [preLoading, setPreLoading] = useState(false);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);

  useEffect(() => {
    setPreLoading(true);
    props.setPreLoading(true);
    setTimeout(() => setPreLoading(false), 1000);

    if (props.post.restricted || props.post.isDisabledProfile) {
      props.setPreLoading(false);
    } else {
      props.fetchInterestPost(props.post.id, "matches");
    }

    getInnerHeight("received-profile-detail-div-unique", 70);
  }, [props.post.id]);

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingIgnore(false);
        setUnmatchModal(false);

        window.location.reload();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  return (
    <React.Fragment>
      <div
        className={
          props.isEmptyObj
            ? "received-profile-detail-div"
            : "received-profile-detail-div show-view"
        }
        id="received-profile-detail-div-unique"
      >
        <div className="inner-div">
          <span
            className="dis-back-btn back-btn-msg"
            onClick={() => props.removePost()}
            style={{ margin: "15px 15px 15px 0" }}
          >
            <i className="fas fa-angle-left"></i>{" "}
            {props.lng ? "Back" : "ආපසු යන්න"}
          </span>

          <Header lng={props.lng} preLoading={preLoading} />
          {props.post.isDisabledProfile ||
          (props.post.timeline.length > 0 &&
            props.post.timeline.filter((el) => el.eventType === "UNMATCHED")
              .length > 0) ? null : (
            <Connect lng={props.lng} preLoading={preLoading} />
          )}
          <TimeLine
            lng={props.lng}
            preLoading={preLoading}
            handleUnmatchModal={handleUnmatchModal}
          />

          {props.post.post.personalInfo.civilStatus ? (
            <Personal lng={props.lng} preLoading={preLoading} />
          ) : null}
          {props.post.post.parentInfo ? (
            <Parent lng={props.lng} preLoading={preLoading} />
          ) : null}

          {props.post.post.horoscopeInfo ? (
            <Horoscope lng={props.lng} preLoading={preLoading} />
          ) : null}

          {props.post.isDisabledProfile ? (
            <UnavailableProfile lng={props.lng} />
          ) : null}
        </div>
      </div>

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        loading={props.loading}
      />

      <UnmatchModal
        lng={props.lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
      />
    </React.Fragment>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setPreLoading, removePost, fetchInterestPost },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    post: state.matches.post,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(MatchedInterest));
