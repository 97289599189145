import React from "react";
import { Helmet } from "react-helmet";

const PageHead = ({ title, name }) => {
  return (
    <Helmet>
      <title>
        {title} {name ?? ""}
      </title>
    </Helmet>
  );
};

export default PageHead;
