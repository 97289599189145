import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";

// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class EmailAlreadyActivated extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Email Activation | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="success-error-div">
            <p align="center" style={{ marginBottom: "-15px" }}>
              <i className="far fa-check-circle fa-4x" align="center"></i>
            </p>
            <p className="message" style={{ marginBottom: "-30px" }}>
              {this.props.lng
                ? "Email already activated"
                : "විද්‍යුත් තැපෑල දැනටමත් සක්‍රිය කර ඇත"}
            </p>
            <Button
              variant="contained"
              className="all-ads-btn"
              onClick={() => this.props.history.push("/")}
            >
              {this.props.lng ? "Go To ALL Ads" : "සියලුම දැන්වීම් වෙත යන්න"}
            </Button>
          </div>
        </Grid>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(EmailAlreadyActivated));
