import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";

import Actions from "./InterestActions";
import PhoneVisibilityModal from "./PhoneVisibilityModal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Envelop from "../../../images/envelop.jpg";
import Envelop_si from "../../../images/envelop_si.jpg";
import AcceptIntMsgSection from "../../interestModals/AcceptIntMsgSection";
import Config from "../../../v2/config";

const Connect = (props) => {
  const [phoneVisibilityModal, setPhoneVisibilityModal] = useState(false);
  useEffect(() => {}, [props.post.showPhone]);

  const handlePhoneVisibilityModal = () => {
    setPhoneVisibilityModal(true);
  };

  const handleGivePhoneVisibility = (e) => {
    e.preventDefault();

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }/phone_visibility`,
        "showPhone=true",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setPhoneVisibilityModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModalClose = () => {
    setPhoneVisibilityModal(false);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        {props.messageModal ?
          <AcceptIntMsgSection
            messageModal={props.messageModal}
            sendingPostId={props.sendingPostId}
            commingMsg={props.matchMessage}
            sensitiveDataError={props.sensitiveDataError}
            fname={props.fName}
            lnameMasked={props.lnameMasked}
            gender={props.gender}
            loading={props.loading}
            handleModalClose={props.handleModalClose}
            handleShowInterest={props.handleShowInterest}
            lng={props.lng}
            displayName={props.displayName}
            likeMessage={props.likeMessage}
            showPhotos={props.showPhotos}
            receivedInterestImages = {props.receivedInterestImages}
            intId={props.intId}
            height={props.height}
            age={props.age}
            profession={props.profession}
            profession_si={props.profession_si}
            residentCity={props.residentCity}
          />
          :
          <Paper
            elevation={0}
            style={{ minHeight: "9vh", position: "relative" }}
            className="complete-ad-div settings-container post-details-con"
          >
            {props.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <Typography
                  variant="h6"
                  className="heading"
                  style={{ marginBottom: 20 }}
                >
                  {props.lng ? (
                    "Respond"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      ප්‍රතිචාර දක්වන්න
                    </span>
                  )}
                </Typography>

                {props.history.location.pathname !== "/messages" &&
                props.isMatchActive ? (
                  <table className="respond-ways">
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <i className="fas fa-paper-plane"></i>
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            className="message-btn"
                            onClick={() =>
                              props.history.push(
                                `/my-matches?id=${props.post.post.id}`
                              )
                            }
                          >
                            {props.lng ? (
                              "Message"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-12">
                                පණිවිඩ
                              </span>
                            )}
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}

                {props.post.post && <Actions post={props.post} lng={props.lng} handleAcceptMessageModal={props.handleAcceptMessageModal}/>}

                {props.post.post &&
                  props.history.location.pathname !== "/my-matches" && (
                    <table style={{ marginTop: 4 }}>
                      <tbody>
                        <tr>
                          <td width="50px"></td>
                          <td>
                            {props.post.post.isExpired && (
                              <p
                                style={{
                                  color: "#f44336",
                                  fontSize: 14,
                                  marginTop: 4,
                                  marginBottom: 0,
                                }}
                              >
                                *{" "}
                                {props.lng ? (
                                  "This ad has expired. You may not receive a response."
                                ) : (
                                  <span className="sinhala-size-11">
                                    මෙම දැන්වීම කල් ඉකුත් වී ඇත. ඇතැම්විට ඔබට
                                    ප්‍රතිචාරයක් නොලැබෙනු ඇත.
                                  </span>
                                )}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                {props.post.post &&
                  (props.isMatchActive ? (
                    props.post.showPhone ? (
                      props.post.post.phone ? (
                        <Show
                          lng={props.lng}
                          icon="fas fa-phone fa-rotate-90"
                          message={props.post.post.phone}
                        />
                      ) : (
                        <>
                          <Hidden
                            lng={props.lng}
                            icon="fas fa-phone fa-rotate-90"
                            message={
                              props.lng
                                ? "This user has not shared their phone number with you yet. Your phone number will not be shared with them until they share theirs."
                                : "මෙම පරිශීලකයා තවමත් ඔවුන්ගේ දුරකථන අංකය ඔබ සමඟ බෙදාගෙන නොමැත. එම නිසා ඔබගේ දුරකථන අංකයද ඔවුන්ට නොපෙනේ."
                            }
                          />
                        </>
                      )
                    ) : (
                      <Hidden
                        lng={props.lng}
                        icon="fas fa-phone fa-rotate-90"
                        message={
                          props.lng
                            ? "You have not shared your phone number with this user yet. You will not be able to see their number until you share yours."
                            : "ඔබ තවමත් ඔබගේ දුරකථන අංකය මෙම පරිශීලකයාගෙන් සඟවා ඇත. ඔබ ඔබේ දුරකථන අංකය පෙන්වන තුරු ඔබට ඔවුන්ගේ දුරකථන අංකය නොපෙනේ."
                        }
                        share={
                          props.lng
                            ? "Share my phone number."
                            : "දුරකථන අංකය පෙන්වන්න."
                        }
                        handlePhoneVisibilityModal={handlePhoneVisibilityModal}
                      />
                    )
                  ) : props.post.post.phone ? (
                    <Show
                      lng={props.lng}
                      icon="fas fa-phone fa-rotate-90"
                      message={props.post.post.phone}
                    />
                  ) : null)
                }

                {props.post.post &&
                  (props.isMatchActive ? (
                    props.post.post.email ? (
                      <Show
                        lng={props.lng}
                        icon="fas fa-at"
                        message={props.post.post.email}
                      />
                    ) : null
                  ) : null)
                }

                {props.post.post &&
                  (localStorage.getItem("auth_token") ? null : props.post.post
                      .offlineId ? (
                    <Show
                      lng={props.lng}
                      icon="fas fa-envelope"
                      message={` Ref Code : ${props.post.post.offlineId}`}
                      offlineId={props.post.post.offlineId}
                    />
                  ) : (
                    <Hidden
                      lng={props.lng}
                      icon="fas fa-envelope"
                      message={
                        props.lng
                          ? "This advertisement does not accept responses via post."
                          : "මෙම දැන්වීම තැපැල් මගින් ප්‍රතිචාර බාර ගන්නේ නැත."
                      }
                    />
                  ))
                }
              </React.Fragment>
            )}

            <PhoneVisibilityModal
              lng={props.lng}
              phoneVisibilityModal={phoneVisibilityModal}
              handleGivePhoneVisibility={handleGivePhoneVisibility}
              handleModalClose={handleModalClose}
            />
          </Paper>
        }
      </Grid>
    </React.Fragment>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // fetchInterestPost,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    // post: state.matches.post,
    // isMatchActive: state.matches.activeBtnType.match,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(Connect));

function Hidden(props) {
  return (
    <table className="respond-ways-hidden">
      <tbody>
        <tr>
          <td>
            <i className={props.icon}></i>
          </td>
          <td>
            {props.message}{" "}
            {props.share && (
              <span
                style={{ color: "#2196F3", cursor: "pointer" }}
                onClick={props.handlePhoneVisibilityModal}
              >
                {props.share}
              </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Show(props) {
  return (
    <table className="respond-ways">
      <tbody>
        <tr>
          <td>
            <i className={props.icon}></i>
          </td>
          <td>
            {props.offlineId && (
              <p style={{ marginTop: 0 }}>
                {props.lng ? (
                  "Respond to this ad via Post."
                ) : (
                  <span className="sinhala-size-14">
                    මෙම දැන්වීමට තැපැල් මගින් ප්‍රතිචාර දක්වන්න.
                  </span>
                )}
              </p>
            )}

            <span style={{ fontWeight: 500 }}>{props.message}</span>

            {props.offlineId && (
              <Alert
                severity="info"
                style={{ marginTop: 15, padding: "0 10px" }}
              >
                {props.lng ? (
                  `You can respond to this advertisement via post by sending a letter to the following address with the Ref Code “${props.offlineId}” clearly written on the top left corner of the envelope.`
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    මෙම දැන්වීම සඳහා තැපැල් මගින් ප්‍රතිචාර යවන්නේනම් ඔබගේ ලිපිය
                    පහත ලිපිනයට යොමු කරන්න. ලියුම් කවරයේ උඩ වම් කෙලවරේ{" "}
                    {props.offlineId} ලෙස සඳහන් කරන්න.
                  </span>
                )}

                <p className="address" style={{ marginTop: 15 }}>
                  {props.lng ? (
                    Config.DOMAIN_DISPLAY_NAME
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      Config.DOMAIN_DISPLAY_NAME
                    </span>
                  )}
                </p>
                <p className="address">
                  {props.lng ? (
                    "P.O. Box 5,"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      තැ. පෙට්ටිය 5,
                    </span>
                  )}
                </p>
                <p className="address" style={{ marginBottom: 15 }}>
                  {props.lng ? (
                    "Nugegoda."
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      නුගේගොඩ.
                    </span>
                  )}
                </p>

                <PhotoProvider>
                  <PhotoConsumer src={Envelop} intro={Envelop}>
                    {props.lng ? (
                      <span className="sample-envelop">Sample Envelope</span>
                    ) : null}
                  </PhotoConsumer>

                  <PhotoConsumer src={Envelop_si} intro={Envelop_si}>
                    {props.lng ? null : (
                      <span className="sample-envelop sinhala-size-12">
                        ආදර්ශ ලියුම් කවරයක්
                      </span>
                    )}
                  </PhotoConsumer>
                </PhotoProvider>
              </Alert>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
