import { FETCH_ALL_INTEREST } from "./types";
import axios from "axios";

export const fetchAllInterest = () => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem(
        "postId"
      )}/interests`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      let income = res.data.incomingMatches;
      let allMatches = income.concat(res.data.outgoingMatches);

      dispatch({
        type: FETCH_ALL_INTEREST,
        payload: {
          matches: allMatches,
          received: res.data.incomingInterests,
          sent: res.data.outgoingInterests,
          isArchived: false,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
