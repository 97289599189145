import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ListItem from "../list-item";
import { isEmpty } from "lodash";

const FaqSegment = ({ question, answer, listItems = [] }) => {
  return (
    <>
      <Grid container direction="column" style={{ rowGap: 10 }}>
        <Typography style={{ color: "#494949", fontSize: 17, fontWeight: 600 }}>
          {question}
        </Typography>
        <Typography style={{ color: "#494949", fontSize: 15 }}>
          {answer}
        </Typography>
      </Grid>
      {!isEmpty(listItems) && (
        <Grid container>
          {listItems.map((item) => (
            <ListItem
              listItem={item.listItem}
              value={item.value}
              subValue={item.subValue}
              link={item.link}
              linkType={item.linkType}
            />
          ))}
        </Grid>
      )}
    </>
  );
};

export default FaqSegment;
