import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";

import Header from "./detail-page/Header";
import Connect from "./detail-page/Connect";
import TimeLine from "./detail-page/TimeLine";

import UnmatchModal from "../modals/UnmatchModal";
import AlertModal from "./detail-page/AlertModal";

import { fetchInterestPost } from "../../actions/fetchInterestPost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Info from "../../images/info.png";

function PersonalProfile(props) {
  const [preLoading, setPreLoading] = useState(false);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);

  useEffect(() => {
    setPreLoading(true);
    setTimeout(() => setPreLoading(false), 1000);

    if (props.post.restricted || props.post.isDisabledProfile) {
    } else {
      props.fetchInterestPost(props.post.id, "matches");
    }
  }, [props.post.id]);

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingIgnore(false);
        setUnmatchModal(false);

        window.location.reload();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  return (
    <React.Fragment>
      <div className="profile-view-div">
        {preLoading ? (
          <div className="loading-div">
            <CircularProgress className="loading-circle" />
          </div>
        ) : (
          <>
            <Header lng={props.lng} />
            {props.post.isDisabledProfile ||
            (props.post.timeline.length > 0 &&
              props.post.timeline.filter((el) => el.eventType === "UNMATCHED")
                .length > 0) ? null : (
              <Connect lng={props.lng} />
            )}
            <TimeLine lng={props.lng} handleUnmatchModal={handleUnmatchModal} />
            {props.post.isDisabledProfile || props.post.restricted ? null : (
              <Link
                to={`/post?id=${props.post.post.id}`}
                className="view-profile"
              >
                {props.lng ? "View Full Profile >" : "සම්පූර්ණ දැන්වීම බලන්න"}
              </Link>
            )}

            {props.post.isDisabledProfile ? (
              <div className="disable-div" style={{ marginTop: 0 }}>
                <p align="center" style={{ marginBottom: 0 }}>
                  <img
                    src={Info}
                    alt="info"
                    style={{ width: 50, opacity: 0.7 }}
                  />
                </p>
                <p className="message">
                  {props.lng ? "Ad is unavailable" : "දැන්වීම නොමැත"}
                </p>
                <p align="center" className="desc">
                  {props.lng ? (
                    "This advertisement has been taken down by its owner."
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත.
                    </span>
                  )}
                </p>
              </div>
            ) : null}
          </>
        )}
      </div>

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        loading={props.loading}
      />

      <UnmatchModal
        lng={props.lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
      />
    </React.Fragment>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInterestPost,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    post: state.matches.post,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(PersonalProfile));
