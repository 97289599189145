import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../styles/help-bar.css";
import Config from "../../v2/config";

function HelpBar(props) {
  const [hideHelp, setHideHelp] = useState(true);
  const showHideHelpContent = (status) => {
    setHideHelp(!status);
  };

  const hideHelpContent = () => {
    setHideHelp(true);
  };

  const openMessenger = () => {
    window.open(`${Config.MESSENGER_URL}`, "_blank");
  };

  const openWhatsapp = () => {
    window.open(`${Config.WHATSAPP_URL}`, "_blank");
  };

  const callLandline = () => {
    window.location.href = `tel:${Config.PHONE_NUMBER}`;
  };

  const callMobile = () => {
    window.location.href = `tel:${Config.PHONE_NUMBER_MOBILE}`;
  };

  return (
      <div
        className={`help-bar ${hideHelp ? "arrange-help-bar" : ""}`}
        tabIndex="0"
        onBlur={hideHelpContent}
      >
        <div className="drawer-col" onClick={() => showHideHelpContent(hideHelp)}>
          <span>
            <i
              className="fas fa-comments fa-rotate-90"
              style={{ fontSize: 18 }}
            ></i>{" "}
            &nbsp; Contact Us
          </span>
        </div>
        <div className={`help-content ${hideHelp ? "hide-help-content" : null}`}>
          <p className="non-link" onClick={openMessenger}>
            <i
              className="fab fa-facebook-messenger"
              style={{ fontSize: 20, color: "#00BCD4" }}
            ></i>
            &nbsp; <span>Messenger</span>
          </p>
          <p className="non-link" onClick={openWhatsapp}>
            <i
              className="fab fa-whatsapp"
              style={{ fontSize: 19, color: "#18a31e" }}
            ></i>
            &nbsp; <span>Whatsapp</span>
          </p>
          {
            (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) ?
            <div>
              <p className="non-link" onClick={callLandline}>
                  <i
                    className="fas fa-phone fa-rotate-90"
                    style={{ fontSize: 15, color: "#333333" }}
                  ></i>
                  &nbsp; <span>{Config.PHONE_NUMBER}</span>
                </p>
                <p className="non-link" onClick={callMobile}>
                  <i
                    className="fas fa-phone fa-rotate-90"
                    style={{ fontSize: 15, color: "#333333" }}
                  ></i>
                  &nbsp; <span>{Config.PHONE_NUMBER_MOBILE}</span>
              </p>
            </div> : null
      }
      </div>
    </div>
  );
}

export default withRouter(HelpBar);
