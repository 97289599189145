import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";

import { Button, CircularProgress } from "@material-ui/core";
import ReactCodeInput from "react-verification-code-input";
import ComErrorAlert from "../create-flow/ComErrorAlert";

import Close from "../../images/close.png";
import CallUsToCreateAccount from "../common/CallUsToCreateAccount";

var timer;

var refCode = "";

class RegisterAcc extends Component {
  state = {
    name: "",
    regEmail: "",
    phone: "",
    password: "",
    conPassword: "",
    isShowPwd: false,
    isShowConPwd: false,
    createdBy: "",
    showPassword: false,
    showFirstView: true,

    isUsingPhone: false,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: sessionStorage.getItem("countryDialCode")
      ? sessionStorage.getItem("countryDialCode")
      : "+94",

    // phone verification
    phoneId: "",
    showPhoneVerification: false,
    timeCountDown: 30,
    showSendOptCode: false,
    sendAgainError: "",
    toManyAttempts: false,
    getAuotOtp: false,

    // error
    nameError: "",
    regEmailError: "",
    phoneError: "",
    passwordError: "",
    conPasswordError: "",
    createdByError: "",
    authError: "",

    // loading
    loading: false,
    errorAlert: false,
    errorCode: "",
  };

  componentDidMount = () => {    
    // country
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
      .then((res) => {
        const sortedCountries = res.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.setState({
          countries: sortedCountries,
        });
      })
      .catch((err) => console.log(err));

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // ref code
    const findRef = document.cookie.match(
      new RegExp("(^| )" + "ref" + "=([^;]+)")
    );

    if (findRef !== null) {
      refCode = findRef[2];
    }

    //event to auto complete OTP
    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', e => {
        const ac = new AbortController();
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          if (otp.code) {
            this.setState({ getAuotOtp: true});
            this.setState({ optCode: otp.code, verificationError: false });
            this.verify();
          }
        }).catch(err => {
          console.log(err);
        });
      });
    }
  };

  componentWillUnmount = () => {
    if ('OTPCredential' in window) {
      window.removeEventListener('DOMContentLoaded', e => {
        const ac = new AbortController();
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          if (otp.code) {
            this.setState({ getAuotOtp: true});
            this.setState({ optCode: otp.code, verificationError: false });
            this.verify();
          }
        }).catch(err => {
          console.log(err);
        });
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });   
  };

  handleCountryCode = (code) => {
    this.setState({ code, showCountryCodes: false });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ phone: parseInt(str, 10), phoneError: "" });
  };

  handleEmailMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: false,
      showCountryCodes: false,
      regEmailError: "",
      passwordError: "",
      phoneError: "",
    });
  };

  handlePhoneMethod = () => {
    this.setState({
      isUsingPhone: true,
      showFirstView: false,
      showCountryCodes: false,
      regEmailError: "",
      passwordError: "",
      phoneError: "",
    });
  };

  // validate
  validate = () => {
    let nameError = "";
    let regEmailError = "";
    let phoneError = "";
    let passwordError = "";
    let conPasswordError = "";
    let createdByError = "";

    if (this.state.name === "") {
      nameError = this.props.lng ? "Name is required" : "නම අවශ්‍ය වේ";
    }

    if (this.state.isUsingPhone) {
      if (this.state.phone === "") {
        phoneError = this.props.lng
          ? "Phone number is required"
          : "දුරකථන අංකය අවශ්‍ය වේ";
      }
    } else {
      if (this.state.regEmail === "") {
        regEmailError = this.props.lng
          ? "Email / Ad ID is required"
          : "විද්‍යුත් තැපැල් / දැන්වීම් කේතය අවශ්‍ය වේ";
      } else if (
        !this.state.regEmail.match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )
      ) {
        regEmailError = this.props.lng
          ? "Email is invalid"
          : "විද්‍යුත් තැපෑල වලංගු නොවේ";
      }
    }

    if (this.state.password === "") {
      passwordError = this.props.lng
        ? "Password is required"
        : "මුරපදය අවශ්‍ය වේ";
    } else if (this.state.password.length < 8) {
      passwordError = this.props.lng
        ? "Password should be at least 8 characters long"
        : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය";
    }

    if (this.state.conPassword === "") {
      conPasswordError = this.props.lng
        ? "Confirm password is required"
        : "තහවුරු මුරපදය අවශ්‍ය වේ";
    } else if (this.state.conPassword !== this.state.password) {
      conPasswordError = this.props.lng
        ? "Passwords should match"
        : "මුරපද ගැලපිය යුතුය";
    }

    if (this.state.createdBy === "") {
      createdByError = this.props.lng
        ? "Created by is required"
        : "සාදනු ලබන්නා අවශ්‍ය වේ";
    }

    if (
      nameError ||
      regEmailError ||
      passwordError ||
      conPasswordError ||
      phoneError ||
      createdByError
    ) {
      this.setState({
        nameError,
        regEmailError,
        phoneError,
        passwordError,
        conPasswordError,
        createdByError,
      });
      return false;
    }

    return true;
  };

  optTimer = () => {
    if (this.state.timeCountDown === 0) {
      clearInterval(timer);

      this.setState({ showSendOptCode: true });
    } else {
      this.setState({ timeCountDown: this.state.timeCountDown - 1 });
    }
  };

  handleRegister = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        toManyAttempts: false,
        verificationError: false,
        sendAgainError: "",
        timeCountDown: 0,
      });

      if (this.state.isUsingPhone) {
        const data = qs.stringify({
          userId: this.state.code + this.state.phone,
          idType: "PHONE",
        });

        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/auth/user_exists?${data}`)
          .then((res) => {
            if (res.status === 204) {
              if (
                this.state.timeCountDown === 0 ||
                this.state.timeCountDown === 30
              ) {
                let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
                let bb = Math.round((new Date()).getTime() / 1000);
                const tso = bb;
                let aa = "";
                while (bb > 0) {
                  let tss = (ALPHABET[bb % ALPHABET.length]).toString();
                  aa = aa+tss;
                  bb= parseInt(bb / ALPHABET.length);
                }
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
                    qs.stringify({
                      number: this.state.code + this.state.phone,
                      channel: "sms",
                      webOTP: true
                    }),
                    {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                        "x-timestamp": tso,
                        "x-client-id": aa,
                      },
                    }
                  )
                  .then((res) => {
                    this.setState({
                      phoneId: res.data.id,
                      timeCountDown: res.data.retryIn,
                      showSendOptCode: false,
                      showPhoneVerification: true,
                      loading: false,
                    });

                    timer = setInterval(this.optTimer, 1000);

                    // scroll to top
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  })
                  .catch((err) => {
                    console.log(err);

                    if (err.response.data.code === 1201) {
                      this.setState({
                        toManyAttempts: true,
                      });
                    } else if (err.response.data.code === 1000) {
                      this.setState({
                        phoneError: this.props.lng
                          ? "Could not send sms to this number"
                          : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
                      });
                    } else if (err.response.data.code === 1203) {
                      this.setState({
                        phoneError: this.props.lng
                          ? "Invalid phone number format"
                          : "දුරකථන අංකය වැරදිය",
                      });
                    }
                    this.setState({
                      showPhoneVerification: false,
                      loading: false,
                    });
                  });
              } else {
                this.setState({
                  toManyAttempts: true,
                  loading: false,
                });
              }
            } else if (res.status === 200) {
              this.setState({
                phoneError: this.props.lng
                  ? "Phone number is used by another account"
                  : "දුරකථන අංකය වෙනත් ගිණුමක් භාවිතා කරයි",
                loading: false,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        const data = qs.stringify({
          userId: this.state.regEmail,
          name: this.state.name,
          password: this.state.password,
          idType: "EMAIL",
          refCode: refCode ? refCode : null,
          ab: "new_signup",
          owner: this.state.createdBy,
        });

        // register
        axios
          .post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, data, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
            localStorage.setItem("auth_token", res.data.sessionToken);
            localStorage.setItem("userId", res.data.memberId);

            this.setState({
              loading: false,
            });

            axios
              .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
                headers: {
                  Authorization: `Bearer ${res.data.sessionToken}`,
                },
              })
              .then((res) => {
                localStorage.setItem("user_name", res.data.name);
                localStorage.setItem("user_email", res.data.email);
                localStorage.setItem("user_role", res.data.role);
                this.props.history.push("/complete-ad-personal");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });

            if (err.response.data.code === 1100) {
              this.setState({
                regEmailError: this.props.lng
                  ? "Email already exists"
                  : "විද්‍යුත් තැපෑල දැනටමත් පවතී",
              });
            } else if (err.response.data.code === 1106) {
              this.setState({
                passwordError: this.props.lng
                  ? "Password should be at least 8 characters long"
                  : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය",
              });
            } else {
              this.setState({
                errorAlert: true,
                errorCode: err.response.data.code,
              });
            }
          });
      }
    }
  };

  sendToVerify = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });
    let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round((new Date()).getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      aa = aa+tss;
      bb= parseInt(bb / ALPHABET.length);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "sms",
          webOTP: true
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
          timeCountDown: res.data.retryIn
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not send sms to this number"
              : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
          });
        }
      });
  };

  sendToVerifyByCall = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });
    let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round((new Date()).getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      aa = aa+tss;
      bb= parseInt(bb / ALPHABET.length);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "call",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
          timeCountDown: res.data.retryIn
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not make voice call to this number"
              : "මෙම අංකයට ඇමතුමක් ලබා ගැනීමට නොහැකිය",
          });
        }
      });
  };

  handleOPT = (code) => {
    this.setState({ optCode: code, verificationError: false });
  };

  verify = () => {
    this.setState({ loading: true });
    let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round((new Date()).getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      aa = aa+tss;
      bb= parseInt(bb / ALPHABET.length);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers/${this.state.phoneId}/verify`,
        qs.stringify({ code: this.state.optCode }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        }
      )
      .then((res) => {
        const data = qs.stringify({
          userId: res.data.id,
          name: this.state.name,
          password: this.state.password,
          idType: "PHONE",
          refCode: refCode,
          ab: "new_signup",
          owner: this.state.createdBy,
        });

        // register
        axios
          .post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, data, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
            localStorage.setItem("auth_token", res.data.sessionToken);
            localStorage.setItem("userId", res.data.memberId);

            this.setState({
              loading: false,
            });

            axios
              .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
                headers: {
                  Authorization: `Bearer ${res.data.sessionToken}`,
                },
              })
              .then((res) => {
                localStorage.setItem("user_name", res.data.name);
                localStorage.setItem("user_role", res.data.role);

                this.props.history.push("/complete-ad-personal");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            if (err.response.data.code === 1106) {
              this.setState({
                passwordError: this.props.lng
                  ? "Password should be at least 8 characters long"
                  : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය",
              });
            } else if (err.response.data.code === 1100) {
              this.setState({
                phoneError: this.props.lng
                  ? "Phone number is used by another account"
                  : "දුරකථන අංකය වෙනත් ගිණුමක් භාවිතා කරයි",
                showPhoneVerification: false,
              });
            } else {
              this.setState({
                errorAlert: true,
                errorCode: err.response.data.code,
              });
            }

            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        this.setState({
          verificationError: true,
          loading: false,
        });
      });
  };

  // login when press the enter key
  handleRegisterKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-register-btn").click();
    }
  };

  handleClose = () => {
    this.setState({ errorAlert: false });
  };

  handleLoginBtn = () => {
    document.getElementById("drawer-login-dialog-btn").click();
  };

  render() {
    const filteredCountries = this.state.countries.filter(
      (filter) =>
        filter.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
        -1
    );

    return (
      <React.Fragment>
        {!this.state.showPhoneVerification ? (
          <div className="create-login-div" style={{ margin: 0 }}>
            {this.props.history.location.pathname === "/register" ? null : (
              <img
                src={Close}
                alt="close"
                className="close-icon"
                onClick={this.props.handleClose}
              />
            )}

            {this.props.history.location.pathname === "/register" &&
            this.state.showFirstView ? (
              <p
                className="back"
                onClick={() => this.props.history.goBack()}
                style={{
                  width: "fit-content",
                  marginBottom: "-8px",
                }}
              >
                {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
              </p>
            ) : null}

            {this.state.showCountryCodes ? null : (
              <React.Fragment>
                {this.props.history.location.pathname !== "/register" && (
                  <p
                    className="modal-heading"
                    style={{ textAlign: "center", border: 0, marginBottom: 50 }}
                  >
                    {this.props.lng ? (
                      "Create an Account"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-17">
                        ගිණුමක් තනන්න
                      </span>
                    )}
                  </p>
                )}

                {this.state.showFirstView && (
                  <p className="login-using">
                    {this.props.lng ? (
                      "Create an account using"
                    ) : (
                      <span className="sinhala-size-12">
                        දැන්වීම් ගිණුම සෑදීමට යොදා ගන්නේ
                      </span>
                    )}
                  </p>
                )}

                {this.state.showFirstView ? (
                  <React.Fragment>
                    <div
                      onClick={this.handleEmailMethod}
                      className="select-login-btn"
                    >
                      <i className="fas fa-envelope"></i>
                      {this.props.lng ? (
                        "Email"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          විද්‍යුත් තැපෑල
                        </span>
                      )}
                    </div>
                    <div
                      onClick={this.handlePhoneMethod}
                      className="select-login-btn"
                    >
                      <i className="fas fa-phone fa-rotate-90"></i>
                      {this.props.lng ? (
                        "Phone Number"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          දුරකතන අංකය
                        </span>
                      )}
                    </div>

                    {this.props.history.location.pathname !== "/register" ? (
                      <span
                        className="create-account-link"
                        onClick={this.handleLoginBtn}
                        style={{ cursor: "pointer", marginTop: "-5px" }}
                      >
                        {this.props.lng ? (
                          <span>
                            Already registered? <u>Login</u>
                          </span>
                        ) : (
                          <span className="sinhala-size-10">
                            ගිණුමක් තිබේද? පිවිසෙන්න
                          </span>
                        )}
                      </span>
                    ) : (
                      <Link
                        className="create-account-link"
                        to="/login"
                        style={{ cursor: "pointer", marginTop: "8px" }}
                      >
                        {this.props.lng ? (
                          <span>
                            Already registered? <u>Login</u>
                          </span>
                        ) : (
                          <span className="sinhala-size-10">
                            ගිණුමක් තිබේද? පිවිසෙන්න
                          </span>
                        )}
                      </Link>
                    )}

                    <CallUsToCreateAccount lng={this.props.lng} />
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}

            {this.state.authError ? (
              <p
                style={{
                  color: "#f44336",
                  fontSize: "0.75rem",
                  marginTop: 0,
                }}
              >
                {this.state.authError}
              </p>
            ) : null}

            {this.state.showFirstView || this.state.showCountryCodes ? null : (
              <React.Fragment>
                <p className="input-headings">
                  {this.props.lng ? (
                    "First Name"
                  ) : (
                    <span className="sinhala-size-12">මුල් නම</span>
                  )}
                </p>
                <input
                  type="text"
                  name="name"
                  id="your-name"
                  className={
                    this.state.nameError
                      ? "login-input red-border"
                      : "login-input"
                  }
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <div className="error-handler">{this.state.nameError}</div>
              </React.Fragment>
            )}

            {!this.state.showFirstView && this.state.isUsingPhone ? (
              <React.Fragment>
                {!this.state.showCountryCodes && (
                  <p className="input-headings">
                    {this.props.lng ? (
                      "Phone Number"
                    ) : (
                      <span className="sinhala-size-12">දුරකතන අංකය</span>
                    )}
                  </p>
                )}

                {this.state.showCountryCodes ? (
                  <React.Fragment>
                    <p
                      className="login-signup-back"
                      onClick={() => this.setState({ showCountryCodes: false })}
                    >
                      <i className="fas fa-chevron-left"></i>{" "}
                      {this.props.lng === true ? (
                        "BACK"
                      ) : (
                        <span className="sinhala-size-12">ආපසු යන්න</span>
                      )}
                    </p>
                    <p className="modal-heading" style={{ border: 0 }}>
                      {this.props.lng ? (
                        "Select Phone Dialing Code"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-17">
                          දුරකථන ඇමතුම් කේතය තෝරන්න
                        </span>
                      )}
                    </p>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        name="search"
                        className="search-county-code-input"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <i className="fas fa-search code-search-icon"></i>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <input
                      className="phone-input-country-code"
                      onClick={() => this.setState({ showCountryCodes: true })}
                      value={this.state.code}
                    />
                    <input
                      type="number"
                      name="phone"
                      className={
                        this.state.phoneError
                          ? "phone-input-custom red-border"
                          : "phone-input-custom"
                      }
                      value={this.state.phone}
                      onChange={this.handlePhone}
                      autoComplete="off"
                    />
                    <div className="error-handler">
                      {this.state.phoneError}{" "}
                      {this.state.phoneError ===
                      "Phone number is used by another account" ? (
                        <span
                          style={{
                            color: "#165ca7",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={this.handleLoginBtn}
                        >
                          (Login)
                        </span>
                      ) : null}
                    </div>
                  </React.Fragment>
                )}

                {this.state.showCountryCodes && (
                  <div className="country-code-div">
                    {filteredCountries.map((code, i) => (
                      <p
                        key={i}
                        onClick={() => this.handleCountryCode(code.dialCode)}
                      >
                        <span>{code.name}</span> <span>{code.dialCode}</span>
                      </p>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ) : this.state.showFirstView ? null : (
              <React.Fragment>
                <p className="input-headings">
                  {this.props.lng ? (
                    "Email"
                  ) : (
                    <span className="sinhala-size-12">විද්‍යුත් තැපෑල</span>
                  )}
                </p>
                <input
                  type="email"
                  name="regEmail"
                  className={
                    this.state.regEmailError
                      ? "login-input red-border"
                      : "login-input"
                  }
                  value={this.state.regEmail}
                  onChange={this.handleChange}
                />
                <div className="error-handler">
                  {this.state.regEmailError}{" "}
                  {this.state.regEmailError === "Email already exists" ? (
                    <span
                      style={{
                        color: "#165ca7",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={this.handleLoginBtn}
                    >
                      {" "}
                      ( Login )
                    </span>
                  ) : null}
                </div>
              </React.Fragment>
            )}

            {this.state.showFirstView && (
              <React.Fragment>
                {this.props.history.location.pathname !== "/register" && (
                  <p className="terms-and-policy" style={{ marginTop: "15vh" }}>
                    {this.props.lng
                      ? "By signing up you are agreeing to our "
                      : "ඉදිරියට යෑමෙන් ඔබ අපගේ "}
                    <Link to="/privacy">
                      {this.props.lng
                        ? "Privacy Policy"
                        : "රහස්‍යතා ප්‍රතිපත්තිය"}
                    </Link>{" "}
                    {this.props.lng ? "and" : "සහ"}{" "}
                    <Link to="/terms">
                      {this.props.lng ? "Terms of Use" : "සේවා කොන්දේසි "}
                    </Link>
                    {this.props.lng ? "" : "වලට එකග වේ"}.
                  </p>
                )}
              </React.Fragment>
            )}

            {this.state.showFirstView || this.state.showCountryCodes ? null : (
              <React.Fragment>
                <p className="input-headings">
                  {this.props.lng ? (
                    "Create Password"
                  ) : (
                    <span className="sinhala-size-12">මුරපදයක් තනන්න</span>
                  )}
                </p>
                <div style={{ position: "relative" }}>
                  <input
                    type={this.state.isShowPwd ? "text" : "password"}
                    name="password"
                    className={
                      this.state.passwordError
                        ? "login-input red-border"
                        : "login-input"
                    }
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  {this.state.isShowPwd ? (
                    <i
                      className="fas fa-eye-slash password-show-hide-btn"
                      onClick={() => this.setState({ isShowPwd: false })}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye password-show-hide-btn"
                      onClick={() => this.setState({ isShowPwd: true })}
                    ></i>
                  )}
                </div>
                <div className="error-handler">{this.state.passwordError}</div>

                <p className="input-headings">
                  {this.props.lng ? (
                    "Confirm Password"
                  ) : (
                    <span className="sinhala-size-12">මුරපදය තහවුරු කරන්න</span>
                  )}
                </p>
                <div style={{ position: "relative" }}>
                  <input
                    type={this.state.isShowConPwd ? "text" : "password"}
                    name="conPassword"
                    className={
                      this.state.conPasswordError
                        ? "login-input red-border"
                        : "login-input"
                    }
                    value={this.state.conPassword}
                    onChange={this.handleChange}
                    onKeyUp={this.handleRegisterKeyUp}
                  />
                  {this.state.isShowConPwd ? (
                    <i
                      className="fas fa-eye-slash password-show-hide-btn"
                      onClick={() => this.setState({ isShowConPwd: false })}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye password-show-hide-btn"
                      onClick={() => this.setState({ isShowConPwd: true })}
                    ></i>
                  )}
                </div>
                <div className="error-handler">
                  {this.state.conPasswordError}
                </div>

                <p className="input-headings">
                  {this.props.lng ? (
                    "Account created by"
                  ) : (
                    <span className="sinhala-size-12">ගිණුම සාදන්නේ</span>
                  )}
                </p>
                <select
                  className={
                    this.state.createdByError
                      ? "login-select red-border"
                      : "login-select"
                  }
                  name="createdBy"
                  value={this.state.createdBy}
                  onChange={this.handleChange}
                >
                  <option selected hidden value="-- Select --">
                    {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                  </option>

                  <option value="SELF">
                    {this.props.lng ? "Self" : "තමන් විසින්"}
                  </option>
                  <option value="FAMILY">
                    {this.props.lng ? "Parents/Family" : "දෙමවුපියන්/පවුලේ අය"}
                  </option>
                </select>
                <div className="error-handler">{this.state.createdByError}</div>
                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={this.handleRegister}
                  id="user-register-btn"
                >
                  {this.state.loading ? (
                    <React.Fragment>
                      <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                    </React.Fragment>
                  ) : this.state.isUsingPhone ? (
                    this.props.lng ? (
                      "Continue"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ඉදිරියට
                      </span>
                    )
                  ) : this.props.lng ? (
                    "Create Account"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      ගිණුම තනන්න
                    </span>
                  )}
                </Button>
                {this.state.toManyAttempts && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#f44336",
                      marginRight: 12,
                      marginBottom: 0,
                      textAlign: "center",
                    }}
                  >
                    {this.props.lng
                      ? this.state.timeCountDown !== 0
                        ? `* Too many attempts. Please try again in 
                            ${this.state.timeCountDown} seconds.`
                        : ""
                      : this.state.timeCountDown !== 0
                      ? `* කරුණාකර තත්පර ${this.state.timeCountDown} කින් නැවත උත්සාහ කරන්න.`
                      : ``}
                  </p>
                )}

                {this.props.history.location.pathname !== "/register" && (
                  <span
                    className="create-account-link"
                    onClick={this.handleLoginBtn}
                    style={{ cursor: "pointer", marginTop: "-5px" }}
                  >
                    {this.props.lng ? (
                      <span>
                        Already registered? <u>Login</u>
                      </span>
                    ) : (
                      <span className="sinhala-size-10">
                        ගිණුමක් තිබේද? පිවිසෙන්න
                      </span>
                    )}
                  </span>
                )}

                <p
                  className="back"
                  onClick={() => this.setState({ showFirstView: true })}
                >
                  {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
                </p>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div className="create-login-div" style={{ margin: 0 }}>
            <p
              className="modal-heading"
              style={{ textAlign: "center", border: 0, marginBottom: 25 }}
            >
              {this.props.lng ? (
                "Verify Phone Number"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  දුරකථන අංකය තහවුරු කරන්න
                </span>
              )}
            </p>

            <p className="your-phone">
              {this.props.lng ? "Your Phone Number" : "ඔබගේ දුරකථන අංකය"}
            </p>
            <p className="phone">
              {this.state.code} {this.state.phone}
            </p>
            <p
              className="change"
              onClick={() => this.setState({ showPhoneVerification: false })}
            >
              {this.props.lng ? "(Change)" : "වෙනස් කරන්න"}
            </p>
            <p className="enter-code">
              {this.props.lng ? "Enter Code" : "කේතය ඇතුලත් කරන්න"}{" "}
            </p>
            <ReactCodeInput
              fields={4}
              onChange={(val) => this.handleOPT(val)}
              autocomplete="one-time-code"
              values={(this.state.getAuotOtp) ? [1,2,3,4] : null}
            />
            <p className="opt-error" style={{ textAlign: "center" }}>
              {this.state.verificationError
                ? this.props.lng
                  ? "Verification code is invalid"
                  : "කේතය වලංගු නොවේ"
                : null}
            </p>
            <p className="opt">
              {this.props.lng ? "Didn't receive OTP?" : "OTP ලැබුණේ නැද්ද?"}
            </p>
            <p className="opt" style={{ marginTop: 2 }}>
              {this.state.showSendOptCode ? (
                <span onClick={this.sendToVerify} style={{ cursor: "pointer" }}>
                  {this.props.lng ? (
                    "Send again"
                  ) : (
                    <span className="sinhala-w-600">නැවත යවන්න</span>
                  )}
                </span>
              ) : (
                <span style={{ textDecoration: "none" }}>
                  {this.props.lng ? "Retry in" : "තත්පර"}{" "}
                  {this.state.timeCountDown}
                  {this.props.lng ? "s" : "කින් පසුව උත්සහ කරන්න"}
                </span>
              )}
              {/* {this.state.showSendOptCode ? (
                <React.Fragment>
                  &nbsp; | &nbsp;
                  <span
                    onClick={this.sendToVerifyByCall}
                    style={{ cursor: "pointer" }}
                  >
                    {this.props.lng ? (
                      "Get Code via Phone Call"
                    ) : (
                      <span className="sinhala-w-600">
                        දුරකථන ඇමතුමක් හරහා කේතය ලබා ගන්න
                      </span>
                    )}
                  </span>
                </React.Fragment>
              ) : null} */}
            </p>
            {this.state.sendAgainError && (
              <p
                style={{
                  fontSize: 12,
                  color: "#f44336",
                  marginRight: 12,
                  marginTop: 5,
                }}
              >
                * {this.state.sendAgainError}
              </p>
            )}

            <Button
              variant="contained"
              className="login-btn"
              onClick={this.verify}
            >
              {this.state.loading ? (
                <React.Fragment>
                  <CircularProgress size={16} style={{ color: "#000" }} />
                </React.Fragment>
              ) : (
                <span>
                  {this.props.lng ? (
                    "Create Account"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      ගිණුම තනන්න
                    </span>
                  )}
                </span>
              )}
            </Button>

            <p
              className="back"
              onClick={() => this.setState({ showPhoneVerification: false })}
            >
              {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
            </p>
          </div>
        )}
        <ComErrorAlert
          lng={this.props.lng}
          errorAlert={this.state.errorAlert}
          errorCode={this.state.errorCode}
          handleClose={this.handleCloseAlert}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterAcc);
