import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { isEmpty } from "lodash";
import ReviewComponent from "../common/ReviewComponent";
import "../.././styles/reviews.css";

const FilterBarReviews = (props) => {
  const [reviews, setReviews] = useState({});

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 1000,
    infinite: true,
    initialSlide: 0,
  };

  const getReviews = async () => {
    const pageNum = 0;
    const pageSize = 6;

    const BaseUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.get(
        `${BaseUrl}/v1/generic/reviews?pageNum=${pageNum}&pageSize=${pageSize}`
      );
      setReviews(response.data.results);
    } catch (e) {
      console.log("Error [userReviews]", e);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <>
      {!isEmpty(reviews) && (
        <div className="sidebar-review-container">
          <h1 className="review-heading">
            {props.lng
              ? "Reviews and Ratings"
              : "නිර්දේශ කිරීම් සහ ශ්‍රේණිගත කිරීම්"}
          </h1>
          <Slider {...settings}>
            {reviews.map((review) => (
              <ReviewComponent
                key={review.id}
                review={review}
                isRedirect={true}
                lang={props.lng}
              />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default FilterBarReviews;
