import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";
import Empty from "../../../images/empty-profile.png";

import { removePost } from "../../../actions/removePost";
import { updateSeen } from "../../../actions/updateSeen";
import { fetchIntPostWithoutSeen } from "../../../actions/fetchIntPostWithoutSeen";
import { fetchRestrictedUnmatch } from "../../../actions/fetchRestrictedUnmatch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function MatchCards(props) {
  const [avatar, setAvatar] = useState(Empty);
  const history = useHistory();

  useEffect(() => {
    if (!props.post.ignored && props.post.avatar) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
            props.post.id
          }/images/${props.post.avatar}?thumbnail=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);

          setAvatar(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.post.id]);

  useEffect(() => {
    if (props.post.id === props.intId) {
      props.updateSeen(props.intId);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem("postId")}/matches/${props.intId}/seen
        `,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.sideMenuMatches]);

  const handlePostWithoutSeen = () => {
    props.removePost();
    history.push(`/messages?id=${props.post.id}`);
  };

  const handleRestrict = () => {
    history.push(`/messages?id=${props.post.id}`);
  };

  return (
    <div
      className={`interest-post-container ${
        props.post.id === props.intId ? "active-interest" : ""
      }`}
      onClick={() => {
        const { post, intId, preLoading } = props;
        if (preLoading || post.id === intId) {
          return;
        }
        if (post.restricted || post.isDisabledProfile) {
          handleRestrict();
        } else {
          handlePostWithoutSeen();
        }
      }}
      id={props.post.post.id}
    >
      <img
        src={
          !props.post.ignored &&
          !props.post.isDisabledProfile &&
          props.post.avatar
            ? avatar
            : props.post.post.personalInfo.gender === "MALE"
            ? Male
            : Female
        }
        className="avatar"
        alt="profile pic"
      />
      <p className="name">{props.post.post.personalInfo.displayName}</p>
      {props.post.ignored || !props.post.latestChat ? (
        <p className="message" style={{ fontWeight: 300 }}>
          {props.post.post.personalInfo.age}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {props.post.post.personalInfo.residentCity}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {props.post.post.personalInfo.profession.name === "Other"
            ? props.post.post.personalInfo.professionOther
            : props.lng
            ? props.post.post.personalInfo.profession.name
            : props.post.post.personalInfo.profession.name_si}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {Math.floor(
            Math.round(props.post.post.personalInfo.height / 2.54) / 12
          )}
          ' {Math.round(props.post.post.personalInfo.height / 2.54) % 12}"
        </p>
      ) : (
        <p
          className="message"
          style={{
            fontWeight:
              props?.post?.latestChat?.sender === "SELF"
                ? 300
                : props?.post?.latestChat?.seen === false
                ? 500
                : 300,
          }}
        >
          {props.post.latestChat && props.post.latestChat.message === "" ? (
            <p className="message-del">message deleted</p>
          ) : (
            props.post.latestChat.message
          )}
        </p>
      )}

      <p className="msg-time">
        {moment
          .utc(
            props.post.timeline.filter((el) => el.eventType === "UNMATCHED")
              .length > 0
              ? props.post.timeline.filter(
                  (el) => el.eventType === "UNMATCHED"
                )[0].eventTime
              : props.post.latestChat
              ? props.post.latestChat.createdAt
              : props.post.matchSeenAt
          )
          .local()
          .fromNow()}
      </p>
      {!props.post.ignored &&
        props.post.latestChat &&
        props.post.id !== props.intId &&
        props.post.latestChat.sender === "OTHER_PARTY" &&
        !props.post.latestChat.seen && <i className="fas fa-circle"></i>}
    </div>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostWithoutSeen,
      fetchRestrictedUnmatch,
      removePost,
      updateSeen
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    sideMenuMatches: state.matches.sideMenuPosts.matched,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(MatchCards);
