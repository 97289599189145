export const extractAnalyticsCookies = () => {
  const output = {};
  const cookies = document.cookie;

  const separator = "; ";
  const analyticsCookieKeys = ["_fbp", "_ga", "_gid"];
  const segements = cookies.split(separator);

  for (const cookieSegment of segements) {
    const [key, value] = cookieSegment.split("=");
    if (analyticsCookieKeys.includes(key)) {
      output[key.replace("_", "")] = value;
    }
  }
  return output;
};

export const serializeCookie = (name, val) =>
  `${encodeURIComponent(name)}=${encodeURIComponent(val)}`;

export const extractUtmCookies = (cookieKeys) => {
  const output = {};

  if (!Array.isArray(cookieKeys)) {
    return output;
  }

  const cookies = document.cookie;

  const separator = "; ";
  const segements = cookies.split(separator);

  for (const cookieSegment of segements) {
    const [key, value] = cookieSegment.split("=");
    if (cookieKeys.includes(key)) {
      output[key] = decodeURIComponent(value);
    }
  }
  return output;
};

export const clearCookies = (cookieKeys) => {
  if (Array.isArray(cookieKeys)) {
    const cookies = document.cookie;

    const segments = cookies.split("; ");
    for (const cookieSegments of segments) {
      const [key, value] = cookieSegments.split("=");
      if (cookieKeys.includes(key)) {
        document.cookie =
          key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    }
  }
};
