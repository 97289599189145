import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";
import { useContext } from "react";
import AppContext from "../../../context";
import { useTranslation } from "react-i18next";

const DataSection = ({ heading, children }) => {
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className="data-section-wrapper">
      <Typography
        className={`typo-h3-${appLanguage} font-weight-500 data-section-title`}
      >
        {t(heading)}
      </Typography>
      {children}
    </Grid>
  );
};

export default DataSection;
