import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import close from "../../images/close.png";
import CreateAccountMethod from "../common/CreateAccountMethod";
import ReactCodeInput from "react-verification-code-input";
import { fetchAllInterest } from "../../actions/fetchAllInterest";
import axios from "axios";
import qs from "qs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { extractUtmCookies } from "../../utils/extractor";
import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";
import { initializeAuth } from "../../v2/features/application";

var timer;

class Main extends Component {
  state = {
    isShowPwd: false,
    isOTPLogin: this.props ? this.props.isOTPLogin : false,
    isOTPLogin: this.props ? this.props.isOTPLogin : false,
    phoneError: "",
    isOTPSecondView: false,
    timeCountDown: 30,
    timeCountDown: 30,
    phoneId: "",
    token: "",
    captchaSecret: "",
    isNewUser: false,
    createdBy: "",
    createdByError: "",
  };

  componentDidMount = () => {
    this.loadScripts();
    this.setState({ captchaSecret: process.env.REACT_APP_CAPTCHA_KEY });
  };

  loadScripts = () => {
    // captcha
    const captchaScript = document.createElement("script");
    captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`;
    captchaScript.async = true;
    document.body.appendChild(captchaScript);
  };

  handleCreators = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  // validate
  validate = () => {
    let phoneError = "";
    if (this.props.phone === "") {
      phoneError = this.props.lng
        ? "Phone number is required"
        : "දුරකථන අංකය අවශ්‍ය වේ";
    }

    if (phoneError) {
      this.setState({
        phoneError,
      });
      return false;
    }
    return true;
  };

  optTimer = () => {
    if (this.state.timeCountDown === 0) {
      clearInterval(timer);
      this.setState({ showSendOptCode: true });
    } else {
      this.setState({ timeCountDown: this.state.timeCountDown - 1 });
    }
  };

  handleRegister = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        toManyAttempts: false,
        verificationError: false,
        sendAgainError: "",
        timeCountDown: 0,
      });

      const data = qs.stringify({
        userId: this.state.code + this.state.phone,
        idType: "PHONE",
      });
      let ALPHABET =
        "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
      let bb = Math.round(new Date().getTime() / 1000);
      const tso = bb;
      let aa = "";
      while (bb > 0) {
        let tss = ALPHABET[bb % ALPHABET.length].toString();
        aa = aa + tss;
        bb = parseInt(bb / ALPHABET.length);
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/trigger_otp`,
          qs.stringify({
            number: this.props.code + this.props.phone,
            webOTP: true,
            captchaToken: this.state.token,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "x-timestamp": tso,
              "x-client-id": aa,
            },
          }
        )
        .then((res) => {
          this.setState({
            isOTPSecondView: true,
            phoneId: res.data.id,
            timeCountDown: res.data.retryIn,
            showSendOptCode: false,
            showPhoneVerification: true,
            loading: false,
          });
          timer = setInterval(this.optTimer, 1000);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.code === 1201) {
            this.setState({
              toManyAttempts: true,
              phoneError: this.props.lng
                ? "Too many attempts. Please try again later"
                : "කරුණාකර පසුව උත්සාහ කරන්න",
            });
          } else if (err.response.data.code === 1000) {
            this.setState({
              phoneError: this.props.lng
                ? "Could not send sms to this number"
                : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
            });
          } else if (err.response.data.code === 1203) {
            this.setState({
              phoneError: this.props.lng
                ? "Invalid phone number format"
                : "දුරකථන අංකය වැරදිය",
            });
          } else if (err.response.data.code === 1200) {
            this.setState({
              phoneError: this.props.lng
                ? "Number too short"
                : "දුරකථන අංකය වැරදිය",
            });
          }
          this.setState({
            showPhoneVerification: false,
            loading: false,
          });
        });
    }
  };

  handleOPT = (code) => {
    this.setState({ optCode: code, verificationError: false });
  };

  switchOPTLogin = () => {
    this.props.handleShowFirstView();
    this.setState({ isOTPLogin: true });
  };

  verify = () => {
    this.setState({ loading: true });

    const utmCookieKeys = ["utm_campaign", "utm_medium", "utm_source"];
    const utmTags = extractUtmCookies(utmCookieKeys);

    let ALPHABET =
      "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round(new Date().getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = ALPHABET[bb % ALPHABET.length].toString();
      aa = aa + tss;
      bb = parseInt(bb / ALPHABET.length);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/auth/login_otp`,
        qs.stringify({
          phoneId: this.state.phoneId,
          code: this.state.optCode,
          ...utmTags,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("userId", res.data.memberId);
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
            },
          })
          .then((res) => {
            localStorage.setItem(
              "user_name",
              res.data.name ? res.data.name : ""
            );
            localStorage.setItem("user_role", res.data.role);
            if (
              res.data.role === "OPERATOR" ||
              res.data.role === "OPERATOR_L2"
            ) {
              this.props.history.push("/manage-posts");
            } else {
              if (
                res.data.role === "OPERATOR" ||
                res.data.role === "OPERATOR_L2"
              ) {
                this.props.history.push("/manage-posts");
              } else {
                let url = "";
                if (
                  localStorage.getItem("user_role") === "OPERATOR" ||
                  localStorage.getItem("user_role") === "OPERATOR_L2"
                ) {
                  url = `operator/managed_posts/${localStorage.getItem(
                    "postId"
                  )}`;
                } else {
                  url = "account/posts";
                }

                axios
                  .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem(
                        "auth_token"
                      )}`,
                    },
                  })
                  .then(async (res) => {
                    if (
                      localStorage.getItem("user_role") === "OPERATOR" ||
                      localStorage.getItem("user_role") === "OPERATOR_L2"
                    ) {
                      res.data[0] = res.data;
                    }
                    if (res.data.length > 0) {
                      localStorage.setItem(
                        "post_details",
                        JSON.stringify(res.data[0])
                      );
                      localStorage.setItem("postId", res.data[0].id);
                      this.props.fetchAllInterest();
                      await this.props.initializeAuth();
                      if (
                        this.props.history.location.pathname === "/register" ||
                        this.props.history.location.pathname === "/auth"
                      ) {
                        this.props.history.push("/?page=1");
                      } else {
                        window.location.reload();
                      }
                      this.setState({ loadingLogin: false });
                    } else {
                      axios
                        .get(
                          `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "auth_token"
                              )}`,
                            },
                          }
                        )
                        .then(async (res) => {
                          localStorage.removeItem("personalInfo");
                          localStorage.removeItem("parentInfo");
                          localStorage.removeItem("privateInfo");

                          if (res.data !== "") {
                            if (res.data.postData.personalInfo) {
                              localStorage.setItem(
                                "personalInfo",
                                JSON.stringify(res.data.postData.personalInfo)
                              );
                            }

                            if (
                              !localStorage.getItem("user_name") &&
                              res.data.postData.personalInfo &&
                              res.data.postData.personalInfo.fname
                            ) {
                              localStorage.setItem(
                                "user_name",
                                res.data.postData.personalInfo.fname
                              );
                            }

                            if (res.data.postData.parentInfo) {
                              localStorage.setItem(
                                "parentInfo",
                                JSON.stringify(res.data.postData.parentInfo)
                              );
                            }

                            if (
                              res.data.postData.horoscopeMatching ||
                              res.data.postData.makePhonePublic
                            ) {
                              localStorage.setItem(
                                "privateInfo",
                                JSON.stringify({
                                  createdBy: res.data.postData.createdBy
                                    ? res.data.postData.createdBy
                                    : "SELF",
                                  phoneId: res.data.postData.phoneId,
                                  makePhonePublic:
                                    res.data.postData.makePhonePublic,
                                  horoscopeMatching:
                                    res.data.postData.horoscopeMatching,
                                  birthTime: res.data.postData.birthTime,
                                  birthCity: res.data.postData.birthCity,
                                  images: res.data.postData.images,
                                  phone: res.data.postData.phone,
                                  horoscopeDetail: res.data.postData
                                    .horoscopeDetail
                                    ? res.data.postData.horoscopeDetail
                                    : "",
                                  horoscopeImages: res.data.postData
                                    .horoscopeImages
                                    ? res.data.postData.horoscopeImages
                                    : [],
                                })
                              );
                            }
                          }
                          await this.props.initializeAuth();
                          this.props.history.push("/complete-ad-personal");
                        })
                        .catch((err) => console.log(err));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    this.setState({ loadingLogin: false });
                  });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        this.setState({
          verificationError: true,
          loading: false,
        });
      });
  };

  submitRecaptcha = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(this.state.captchaSecret, { action: "contact" })
        .then((token) => {
          this.setState({
            token: token,
          });
          this.handleRegister();
        });
    });
  };

  render() {
    return (
      <div
        className="create-login-div login-div-container"
        style={{ margin: 0, width: "300px" }}
      >
        {this.props.showCountryCodes ? (
          <React.Fragment>
            <p className="login-signup-back" onClick={this.props.handleBackBtn}>
              <i className="fas fa-chevron-left"></i>{" "}
              {this.props.lng ? (
                "BACK"
              ) : (
                <span className="sinhala-size-12">ආපසු යන්න</span>
              )}
            </p>
            <p className="modal-heading">
              {this.props.lng ? (
                "Select Phone Dialing Code"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  දුරකථන ඇමතුම් කේතය තෝරන්න
                </span>
              )}
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!this.props.showFirstView ? (
              <p
                className="login-signup-back"
                onClick={this.props.handleShowFirstView}
              >
                <i className="fas fa-chevron-left"></i>{" "}
                {this.props.lng ? (
                  "BACK"
                ) : (
                  <span className="sinhala-size-12">ආපසු යන්න</span>
                )}
              </p>
            ) : (
              <div>
                <picture>
                  <ProductLogo
                    variant={LogoVariant.black}
                    size={LogoSize.small}
                    className={"login-logo"}
                  />
                </picture>
                {this.props.isModalMode ? null : (
                  <p
                    className="login-signup-back"
                    onClick={() =>
                      this.props.isModalMode
                        ? this.props.handleShowFirstView
                        : this.props.history.push("/auth")
                    }
                  >
                    <i className="fas fa-chevron-left"></i>{" "}
                    {this.props.lng ? (
                      "BACK"
                    ) : (
                      <span className="sinhala-size-12">ආපසු යන්න</span>
                    )}
                  </p>
                )}
              </div>
            )}
            <p
              className="modal-heading"
              style={{
                textAlign: this.props.showFirstView ? "center" : "",
                borderBottom:
                  this.props.history.location.pathname === "/login" ? 0 : "",
              }}
            >
              {this.props.lng ? (
                "Login to Your Account"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  ඔබගේ ගිණුමට පිවිසෙන්න
                </span>
              )}
            </p>
          </React.Fragment>
        )}

        {this.props.history.location.pathname === "/login" ? null : (
          <img
            src={close}
            alt="close"
            className="close-icon"
            onClick={this.props.handleClose}
          />
        )}

        {!this.state.isOTPLogin && this.props.showFirstView ? (
          <React.Fragment>
            <p className="register-sec-heading">
              {this.props.lng
                ? "Choose the login method"
                : "පිවිසීමේ ක්‍රමය තෝරන්න"}
            </p>

            <div
              onClick={this.props.handlePhoneMethod}
              className="select-login-btn"
            >
              <i className="fas fa-phone fa-rotate-90"></i>
              {this.props.lng ? (
                "Phone Number"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දුරකතන අංකය
                </span>
              )}
            </div>
            <div
              onClick={this.props.handleEmailMethod}
              className="select-login-btn"
            >
              <i className="fas fa-envelope"></i>
              {this.props.lng ? (
                "Email"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  විද්‍යුත් තැපෑල
                </span>
              )}
            </div>
            <div
              onClick={this.props.handleUserIdMethod}
              className="select-login-btn"
            >
              <i className="fas fa-user-circle"></i>
              {this.props.lng ? (
                "Ad ID"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දැන්වීම් කේතය
                </span>
              )}
            </div>
          </React.Fragment>
        ) : null}
        {!this.state.isOTPSecondView && this.state.isOTPLogin ? (
          <React.Fragment>
            {!this.props.showCountryCodes && (
              <p className="input-headings">
                {this.props.lng ? (
                  "Phone Number"
                ) : (
                  <span className="sinhala-size-12">දුරකතන අංකය</span>
                )}
              </p>
            )}

            {this.props.showCountryCodes ? (
              <React.Fragment>
                <div style={{ position: "relative" }}>
                  <input
                    autoFocus
                    type="text"
                    name="search"
                    className="search-county-code-input"
                    placeholder="Search"
                    value={this.props.search}
                    onChange={this.props.handleChange}
                    autoComplete="off"
                  />
                  <i className="fas fa-search code-search-icon"></i>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input
                  className="phone-input-country-code"
                  onClick={this.props.handleShowCountryCodes}
                  value={this.props.code}
                />
                <input
                  type="number"
                  name="phone"
                  className={
                    this.state.phoneError
                      ? "phone-input-custom red-border"
                      : "phone-input-custom"
                  }
                  value={this.props.phone}
                  onChange={this.props.handlePhone}
                  autoComplete="off"
                />
                <div className="error-handler">{this.state.phoneError}</div>
              </React.Fragment>
            )}

            {this.props.showCountryCodes && (
              <div className="country-code-div">
                {this.props.filteredCountries.map((code, i) => (
                  <p
                    key={i}
                    onClick={() => this.props.handleCountryCode(code.dialCode)}
                  >
                    <span>{code.name}</span> <span>{code.dialCode}</span>
                  </p>
                ))}
              </div>
            )}
            <React.Fragment>
              <Button
                variant="contained"
                className="login-btn g-recaptcha"
                onClick={(e) => this.submitRecaptcha(e)}
                id="user-register-btn"
                data-sitekey={this.state.captchaSecret}
              >
                {this.state.loading ? (
                  <React.Fragment>
                    <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                  </React.Fragment>
                ) : this.props.lng ? (
                  "Continue"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">ඉදිරියට</span>
                )}
              </Button>
            </React.Fragment>
          </React.Fragment>
        ) : null}

        {this.state.isOTPSecondView && this.state.isOTPLogin ? (
          <div
            className="create-login-div auth-login-div"
            style={{ margin: 0, width: "auto" }}
          >
            <p
              className="modal-heading"
              style={{ textAlign: "center", border: 0, marginBottom: 15 }}
            >
              {this.props.lng ? (
                "Verify Phone Number"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  දුරකථන අංකය තහවුරු කරන්න
                </span>
              )}
            </p>
            <p className="your-phone">
              {this.props.lng ? "Your Phone Number" : "ඔබගේ දුරකථන අංකය"}
            </p>
            <p
              className="phone"
              style={{ marginBottom: "0", padding: "3px 0" }}
            >
              {this.props.code} {this.props.phone}
            </p>
            <p
              className="change"
              onClick={() => this.setState({ isOTPSecondView: false })}
            >
              {this.props.lng ? "(Change)" : "වෙනස් කරන්න"}
            </p>
            <p className="enter-code" style={{ marginTop: "15px" }}>
              {this.props.lng ? "Enter Code" : "කේතය ඇතුලත් කරන්න"}{" "}
            </p>
            <div style={{ marginLeft: "-30px" }}>
              <ReactCodeInput
                fields={6}
                onChange={(val) => this.handleOPT(val)}
                autocomplete="one-time-code"
                values={this.state.getAuotOtp ? [1, 2, 3, 4, 5, 6] : null}
              />
            </div>
            <p className="opt-error" style={{ textAlign: "center" }}>
              {this.state.verificationError
                ? this.props.lng
                  ? "Verification code is invalid"
                  : "කේතය වලංගු නොවේ"
                : null}
            </p>
            <p className="opt">
              {this.props.lng ? "Didn't receive OTP?" : "OTP ලැබුණේ නැද්ද?"}
            </p>
            <p className="opt" style={{ marginTop: 2 }}>
              {this.state.showSendOptCode ? (
                <span
                  onClick={this.handleRegister}
                  style={{ cursor: "pointer" }}
                >
                  {this.props.lng ? (
                    "Send again"
                  ) : (
                    <span className="sinhala-w-600">නැවත යවන්න</span>
                  )}
                </span>
              ) : (
                <span style={{ textDecoration: "none" }}>
                  {this.props.lng ? "Retry in" : "තත්පර"}{" "}
                  {this.state.timeCountDown}
                  {this.props.lng ? "s" : "කින් පසුව උත්සහ කරන්න"}
                </span>
              )}
            </p>
            {this.state.sendAgainError && (
              <p
                style={{
                  fontSize: 12,
                  color: "#f44336",
                  marginRight: 12,
                  marginTop: 5,
                }}
              >
                * {this.state.sendAgainError}
              </p>
            )}
            <Button
              variant="contained"
              className="login-btn"
              onClick={this.verify}
            >
              {this.state.loading ? (
                <React.Fragment>
                  <CircularProgress size={16} style={{ color: "#000" }} />
                </React.Fragment>
              ) : (
                <span>
                  {this.props.lng ? (
                    "Login"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      පිවිසෙන්න
                    </span>
                  )}
                </span>
              )}
            </Button>
            <p
              className="back"
              onClick={() => this.setState({ isOTPSecondView: false })}
            >
              {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
            </p>
          </div>
        ) : null}
        {this.props.authError ? (
          <p style={{ color: "#f44336", fontSize: "0.75rem", marginTop: 0 }}>
            {this.props.authError}
          </p>
        ) : null}

        {!this.props.showFirstView && this.props.isUsingPhone ? (
          <React.Fragment>
            {!this.props.showCountryCodes && (
              <p className="input-headings">
                {this.props.lng ? (
                  "Phone Number"
                ) : (
                  <span className="sinhala-size-12">දුරකතන අංකය</span>
                )}
              </p>
            )}

            {this.props.showCountryCodes ? (
              <React.Fragment>
                <div style={{ position: "relative" }}>
                  <input
                    autoFocus
                    type="text"
                    name="search"
                    className="search-county-code-input"
                    placeholder="Search"
                    value={this.props.search}
                    onChange={this.props.handleChange}
                    autoComplete="off"
                  />
                  <i className="fas fa-search code-search-icon"></i>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input
                  className="phone-input-country-code"
                  onClick={this.props.handleShowCountryCodes}
                  value={this.props.code}
                />
                <input
                  type="number"
                  name="phone"
                  className={
                    this.props.phoneError
                      ? "phone-input-custom red-border"
                      : "phone-input-custom"
                  }
                  value={this.props.phone}
                  onChange={this.props.handlePhone}
                  autoComplete="off"
                />
                <div className="error-handler">{this.props.phoneError}</div>
              </React.Fragment>
            )}

            {this.props.showCountryCodes && (
              <div className="country-code-div">
                {this.props.filteredCountries.map((code, i) => (
                  <p
                    key={i}
                    onClick={() => this.props.handleCountryCode(code.dialCode)}
                  >
                    <span>{code.name}</span> <span>{code.dialCode}</span>
                  </p>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : !this.props.showFirstView &&
          !this.props.isUsingPhone &&
          this.props.isUserId ? (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Ad ID"
              ) : (
                <span className="sinhala-size-12">දැන්වීම් කේතය</span>
              )}{" "}
              <Tooltip
                title={
                  this.props.lng
                    ? 'Example: 1000123456 (Previously named "Login ID")'
                    : 'උදාහරණය: 1000123456 (කලින් නම් කර ඇත්තේ "පිවිසුම් හැඳුනුම්පත" ලෙසය)'
                }
                arrow
                placement="top"
              >
                <span>
                  <i className="fas fa-info-circle"></i>
                </span>
              </Tooltip>
            </p>

            <input
              type="email"
              name="email"
              className={
                this.props.emailError ? "login-input red-border" : "login-input"
              }
              value={this.props.email}
              onChange={this.props.handleChange}
            />
            <div className="error-handler">{this.props.emailError}</div>
          </React.Fragment>
        ) : !this.props.showFirstView &&
          !this.props.isUsingPhone &&
          !this.props.isUserId ? (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Email"
              ) : (
                <span className="sinhala-size-12">විද්‍යුත් තැපෑල</span>
              )}
            </p>

            <input
              type="email"
              name="email"
              className={
                this.props.emailError ? "login-input red-border" : "login-input"
              }
              value={this.props.email}
              onChange={this.props.handleChange}
            />
            <div className="error-handler">{this.props.emailError}</div>
          </React.Fragment>
        ) : null}

        {this.props.sentSuccess ? (
          <p
            className="text-success text-center"
            style={{
              fontSize: 13,
              color: "green",
              textAlign: "center",
            }}
          >
            <i className="fas fa-check"> </i>{" "}
            {this.props.lng ? "Mail Sent." : "තැපැල් යවන ලදි."}
          </p>
        ) : null}

        {this.props.resendEmail ? (
          <Button
            variant="contained"
            className="resend-btn"
            onClick={this.props.handleResendEmail}
            style={{ minWidth: 90 }}
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : this.props.lng ? (
              "Resend Verification Email"
            ) : (
              "නැවත යවන්න"
            )}
          </Button>
        ) : null}

        {this.props.showFirstView || this.props.showCountryCodes ? null : (
          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Password"
              ) : (
                <span className="sinhala-size-12">මුරපදය</span>
              )}
            </p>
            <div style={{ position: "relative" }}>
              <input
                type={this.state.isShowPwd ? "text" : "password"}
                name="password"
                className={
                  this.props.passwordError
                    ? "login-input red-border"
                    : "login-input"
                }
                value={this.props.password}
                onChange={this.props.handleChange}
                onKeyUp={this.props.handleLoginKeyUp}
              />
              {this.state.isShowPwd ? (
                <i
                  className="fas fa-eye-slash password-show-hide-btn"
                  onClick={() => this.setState({ isShowPwd: false })}
                ></i>
              ) : (
                <i
                  className="fas fa-eye password-show-hide-btn"
                  onClick={() => this.setState({ isShowPwd: true })}
                ></i>
              )}
            </div>

            <div className="error-handler">{this.props.passwordError}</div>
            <Button
              variant="contained"
              className="login-btn"
              onClick={this.props.handleSaveProfile}
              id="user-login-btn"
            >
              {this.props.loadingLogin ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : this.props.lng ? (
                "Login"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  ඇතුල් වන්න
                </span>
              )}
            </Button>
          </React.Fragment>
        )}

        {this.props.showFirstView || !this.props.showCountryCodes ? (
          <React.Fragment>
            {this.state.isOTPLogin ? (
              <p
                className="forget-pwd-link"
                onClick={() => this.setState({ isOTPLogin: false })}
                style={{ width: "fit-content", margin: "auto" }}
              >
                {this.props.lng ? (
                  "Login using password?"
                ) : (
                  <span className="sinhala-size-10">
                    මුරපදය භාවිතයෙන් පිවිසෙන්න
                  </span>
                )}
              </p>
            ) : (
              <div>
                <p
                  className="forget-pwd-link"
                  onClick={this.props.showPwdResetModal}
                >
                  {this.props.lng ? (
                    "Forgot your password?"
                  ) : (
                    <span className="sinhala-size-10">මුරපදය අමතක වුනාද?</span>
                  )}
                </p>
                {this.props.isOTPLogin ? (
                  <p
                    style={{ width: "fit-content", margin: "auto" }}
                    className="forget-pwd-link"
                    onClick={this.switchOPTLogin}
                  >
                    {this.props.lng ? (
                      "Login using OTP?"
                    ) : (
                      <span className="sinhala-size-10">
                        OTP භාවිතයෙන් පිවිසෙන්න
                      </span>
                    )}
                  </p>
                ) : (
                  <p
                    className="forget-pwd-link"
                    onClick={() => this.props.history.push("/auth")}
                  >
                    {this.props.lng ? (
                      "Login using OTP?"
                    ) : (
                      <span className="sinhala-size-10">
                        OTP භාවිතයෙන් පිවිසෙන්න
                      </span>
                    )}
                  </p>
                )}
              </div>
            )}

            <hr style={{ margin: "30px 0", border: "1px solid #ebebeb" }} />

            <CreateAccountMethod lng={this.props.lng} />

            <p className="terms-and-policy">
              {this.props.lng
                ? "By signing up you are agreeing to our "
                : "ඉදිරියට යෑමෙන් ඔබ අපගේ "}
              <Link to="/privacy">
                {this.props.lng ? "Privacy Policy" : "රහස්‍යතා ප්‍රතිපත්තිය"}
              </Link>{" "}
              {this.props.lng ? "and" : "සහ"}{" "}
              <Link to="/terms">
                {this.props.lng ? "Terms of Use" : "සේවා කොන්දේසි "}
              </Link>
              {this.props.lng ? "" : "වලට එකග වේ"}.
            </p>

            {this.props.history.location.pathname === "/login" && (
              <p
                className="back"
                onClick={() => this.props.history.push("/")}
                style={{ width: "fit-content", margin: "auto", marginTop: 10 }}
              >
                {this.props.lng ? "< BACK TO HOME" : "< ආපසු යන්න"}
              </p>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAllInterest,
      initializeAuth,
    },
    dispatch
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Main));
