import React, { useEffect, useState } from "react";
import MuiSnackBar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { setAppSnackNotification } from "../../../../store/actions/application";
import { setEditAdPageNotification } from "../../../../store/actions/edit-ad-page"

const NotificationBar = ({ severity = "success", message }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    dispatch(setAppSnackNotification({}));
    dispatch(setEditAdPageNotification({}));
  };

  useEffect(() => {
    if (!isEmpty(message)) {
      setOpen(true);
    }
  }, [message]);

  return (
    !isEmpty(message) && (
      <MuiSnackBar
        open={open}
        autoHideDuration={2000}
        onClose={onClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MuiAlert severity={severity} variant="filled" onClose={onClose}>
          {message}
        </MuiAlert>
      </MuiSnackBar>
    )
  );
};

export default NotificationBar;
