import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

function CancelRequestModeal(props) {
  return (
    <Dialog
      open={props.withdrawModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0 }}
      >
        {props.lng === true ? (
          "Are you sure?"
        ) : (
          <span className="sinhala-w-600 sinhala-size-13">ඔබට විශ්වාසද?</span>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.lng === true ? (
            "Are you sure you want to cancel this request? You will not be able to show your interest to this user in future."
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              ඔබට මෙම ඉල්ලීම අවලංගු කිරීමට අවශ්‍ය යැයි විශ්වාසද? අනාගතයේදී මෙම
              පරිශීලකයාට ඔබේ කැමැත්ත පෙන්වීමට ඔබට නොහැකි වනු ඇත.
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleModalClose}>
          {props.lng === true ? (
            "No"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">නැත</span>
          )}
        </Button>
        <Button
          onClick={props.handleIgnoreAllInt}
          style={{ background: "#000", color: "#fff" }}
        >
          {props.loadingIgnore ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            <span>
              {props.lng === true ? (
                "Yes"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">ඔව්</span>
              )}
            </span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(CancelRequestModeal);
