import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Check as CheckIcon, Clear as ClearIcon } from "@material-ui/icons";

import "./style.css";

const FormInteractiveButton = ({
  onClickInteractiveSave,
  onClickInteractiveClear,
}) => {
  return (
    <Grid container direction="row" className="form-interactive-btn-paper">
      <IconButton onMouseDown={onClickInteractiveSave}>
        <CheckIcon />
      </IconButton>
      <IconButton onMouseDown={onClickInteractiveClear}>
        <ClearIcon />
      </IconButton>
    </Grid>
  );
};

export default FormInteractiveButton;
