import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Header from "./detail-page/Header";
import ExternalHeader from "./detail-page/ExternalHeader";

import TimeLine from "./detail-page/TimeLine";
import Connect from "./detail-page/Connect";
import Personal from "./detail-page/Personal";
import Parent from "./detail-page/Parent";

import { setPreLoading } from "../../actions/setPreLoading";
import { removePost } from "../../actions/removePost";
import { fetchInterestPost } from "../../actions/fetchInterestPost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getInnerHeight } from "./methods/getInnerHeight";

import "../../styles/received-int-profile.css";

function ReceivedInterest(props) {
  console.log(props)
  const [preLoading, setPreLoading] = useState(false);

  useEffect(() => {
    if (!props.post.extPostId) {
      setPreLoading(true);
      props.setPreLoading(true);
      setTimeout(() => {
        setPreLoading(false);
      }, 1000);

      if (props.post.restricted || props.post.isDisabledProfile) {
        props.setPreLoading(false);
      } else {
        props.fetchInterestPost(props.post.id, "interests");
      }

      getInnerHeight("received-profile-detail-div-unique", 70);
    }
  }, [props.post.id]);

  return (
    <div
      className={
        props.isEmptyObj
          ? "received-profile-detail-div"
          : "received-profile-detail-div show-view"
      }
      id="received-profile-detail-div-unique"
    >
      {!props.post.extPostId ?
        <div className="inner-div">
          <span
            className="dis-back-btn back-btn-msg"
            onClick={() => props.removePost()}
            style={{ margin: "15px 15px 15px 0" }}
          >
            <i className="fas fa-angle-left"></i>{" "}
            {props.lng ? "Back" : "ආපසු යන්න"}
          </span>
          <Header lng={props.lng} preLoading={preLoading} />

          {props.post.ignored ? null : props.post.timeline &&
            props.post.timeline.filter(
              (el) =>
                (el.eventType === "LIKED" && el.actionBy === "SELF") ||
                el.eventType === "LIKE_REFUSED"
            ).length > 0 ? null : (
            <Connect lng={props.lng} preLoading={preLoading} />
          )}

          <TimeLine lng={props.lng} preLoading={preLoading} />

          {props.post.post.personalInfo.civilStatus ? (
            <Personal lng={props.lng} preLoading={preLoading} />
          ) : null}

          {props.post.post.parentInfo ? (
            <Parent lng={props.lng} preLoading={preLoading} />
          ) : null}
        </div>
        :
        <div className="inner-div">
          <ExternalHeader lng={props.lng} preLoading={preLoading} />
        </div>
      }
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setPreLoading, removePost, fetchInterestPost },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    post: state.matches.post,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(ReceivedInterest));
