import React, { Component } from "react";

import { Dialog, CircularProgress, Button } from "@material-ui/core";
import ImageEditorRc from "react-cropper-image-editor";
import "cropperjs/dist/cropper.css";

export default class ImageCrop extends Component {
  render() {
    return (
      <Dialog
        open={this.props.uploadImageModal}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        className="login-modal"
        scroll="body"
      >
        <div className="create-login-div login-div-container image-crop-rotate-container">
          <button
            className="close-icon-img-crop"
            onClick={this.props.handleClose}
          >
            Cancel
          </button>

          <p
            className="modal-heading"
            style={{ paddingBottom: 15, marginBottom: 15 }}
          >
            {this.props.lng === true ? (
              "Upload Photos"
            ) : (
              <span className="sinhala-w-600 sinhala-size-17">
                ඡායාරූප උඩුගත කරන්න
              </span>
            )}{" "}
          </p>

          <ImageEditorRc
            ref="cropper"
            crossOrigin="false" // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
            src={this.props.selectedImage}
            className="image-crop-rotate"
            guides={true}
            rotatable={true}
            aspectRatio={2 / 2}
            saveImage={this.props.handleChangeFile} // it has to catch the returned data and do it whatever you want
            responseType="blob/base64"
          />

          <ImageCrop />

          {this.props.photoLoading ? (
            <Button
              variant="contained"
              className="upload-photo-btn-circle"
              onClick={this.handleSave}
              style={{ width: 160 }}
            >
              <CircularProgress size={25} style={{ color: "#fff" }} />
            </Button>
          ) : null}
        </div>
      </Dialog>
    );
  }
}
