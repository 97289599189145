import { FETCH_NEW_MATCHED_POSTS } from "./types";
import axios from "axios";

export const getNewMatches = () => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
        "postId"
      )}/interests/new_matches`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: FETCH_NEW_MATCHED_POSTS,
        payload: {
          newMatches: res.data,
        },
      });
    });
};
