import PostServiceApi from "../../services/api/post-service";

export const getAuthPostImages = async ({
  postId,
  imageId,
  thumbnail = false,
}) => {
  try {
    const response = await PostServiceApi.getPostImages({
      postId,
      imageId,
      thumbnail,
    });
    if (!response.success) {
      throw new Error("Unable to download image");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    throw e;
  }
};

export const getMatchImage = async ({
  postId,
  matchId,
  imageId,
  thumbnail = false,
}) => {
  try {
    const response = await PostServiceApi.getMatchImages({
      postId,
      matchId,
      imageId,
      thumbnail,
    });
    if (!response.success) {
      throw new Error("Unable to download image");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    throw e;
  }
};

export const getPostHoroscopeImage = async ({ postId, imageId }) => {
  try {
    const response = await PostServiceApi.getPostHoroscopeImage({
      postId,
      imageId,
    });
    if (!response.success) {
      throw new Error("Unable to download horoscope image");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    throw e;
  }
};

export const getMatchHoroscopeImg = async ({ postId, interestId, img }) => {
  try {
    const response = await PostServiceApi.getMatchHoroscopeImage({
      postId,
      interestId,
      img,
    });
    if (!response.success) {
      throw new Error("Unable to download horoscope images");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    throw e;
  }
};
