import isEmpty from "lodash/isEmpty";
import Config from "../../config";
const STORAGE_LANG_KEY = "lng";
export const V2_MIGRATION_CHECKER_KEY = "v2-migration-handled";
const AUTH_TOKEN_STORAGE_KEY = "auth_token";

const DeprecationHandler = {
  handleLanguage: () => {
    const currentValue =
      localStorage.getItem(STORAGE_LANG_KEY) === null ||
      JSON.parse(localStorage.getItem(STORAGE_LANG_KEY));
    localStorage.setItem(STORAGE_LANG_KEY, !currentValue);
  },
  getLanguage: () => {
    return (
      localStorage.getItem(STORAGE_LANG_KEY) === null ||
      JSON.parse(localStorage.getItem(STORAGE_LANG_KEY))
    );
  },
  getLanguageFromI18n: (i18nLang) => {
    if (isEmpty(i18nLang)) {
      return true;
    }
    const supportedLangs = Object.values(Config.APP_LANGS);
    if (!supportedLangs.includes(i18nLang)) {
      return true;
    }
    return i18nLang === Config.APP_LANGS.EN;
  },
  isAuthAuthorized: () => {
    return localStorage.getItem("auth_token") !== null;
  },
  handleV2ArchitectureChange: () => {
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    localStorage.clear();
    sessionStorage.clear();
    if (authToken !== null) {
      localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
    }
    localStorage.setItem(V2_MIGRATION_CHECKER_KEY, true);
    window.location.reload(true);
  },
};

export default DeprecationHandler;
