import { combineReducers } from "redux";
import intBtnReducer from "./intBtnReducer";
import globalReducer from "./globalReducer";
import globalNetwork from "./globalNetwork";
import genericDataReducer from "../v2/store/reducers/generic-data-reducer";
import applicationReducer from "../v2/store/reducers/application-reducer";
import homePageReducer from "../v2/store/reducers/home-page-reducer";
import editAdPageReducer from "../v2/store/reducers/edit-ad-page-reducer";

const rootReducer = combineReducers({
  matches: intBtnReducer,
  globalReducer,
  globalNetwork,
  genericData: genericDataReducer,
  application: applicationReducer,
  homePage: homePageReducer,
  editAdPage: editAdPageReducer,
});

export default rootReducer;
