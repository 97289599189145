import React from "react";
import {
  Button,
  Table,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";

function Invoice(props) {
  return (
    <div className="invoice">
      <Table className="checkout-table" aria-label="simple table">
        <tbody>
          {/* <TableRow>
            <TableCell component="td" scope="row">
              {!props.isRenew
                ? props.lng
                  ? "Advertising Fee"
                  : "දැන්වීම් ගාස්තුව"
                : props.lng
                ? "Renewal Fee"
                : "අලුත් කිරීමේ ගාස්තුව"}
            </TableCell>
            <TableCell align="right">
              {props.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : (
                `${props.currencySymbol} ${
                  props.discountPercentage > 0
                    ? props.amountBeforeDiscount.toFixed(2)
                    : props.price.toFixed(2)
                }`
              )}
            </TableCell>
          </TableRow> */}
          {props.promoCode ? null : props.discountPercentage > 0 ? (
            <TableRow>
              <TableCell component="td" scope="row">
                {props.lng ? "20% Renewal Discount" : "20% අලුත් කිරීමේ වට්ටම"}
              </TableCell>
              <TableCell align="right">
                {props.priceLoading ? (
                  <CircularProgress size={16} style={{ color: "#000" }} />
                ) : (
                  `- ${props.currencySymbol} ${(
                    props.amountBeforeDiscount - props.price
                  ).toFixed(2)}`
                )}
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell component="td" scope="row">
              <p
                onClick={props.handleShowPromo}
                style={{
                  display: props.showPromo ? "none" : "",
                  margin: 0,
                  fontSize: 11,
                  color: "#CCA326",
                  cursor: "pointer",
                  fontWeight: 500,
                }}
              >
                {props.lng ? "Add Promo Code" : "ප්‍රවර්ධන කේතය අතුලත් කරන්න"}{" "}
              </p>
              {props.showPromo
                ? props.lng
                  ? "Promo Code"
                  : "ප්‍රවර්ධන කේතය"
                : null}
            </TableCell>
            <TableCell align="right">
              {props.showPromo ? (
                props.isPromoApplied ? (
                  <>
                    <div style={{position: 'relative'}}>
                      <input
                        disabled
                        className="promo-input"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        name="promo-code"
                        value={props.appliedPromoCode}
                        style={{background: '#f2f2f2', border: 'none', paddingLeft: '16px', borderRadius: '6px'}}
                      />
                      <i
                        className="fa fa-times-circle password-show-hide-btn"
                        onClick={props.removePromo}
                      ></i>
                    </div>
                    <p
                      style={{
                        color: "#28a745",
                        fontWeight: 400,
                        fontSize: 14,
                        margin: 0,
                      }}
                    >
                      <i className="fas fa-check"></i> &nbsp;{" "}
                      {props.lng ? "Promo Applied" : "ප්‍රවර්ධනය සිදු කලා"}
                    </p>
                  </>
                ) : (
                  <React.Fragment>
                    <input
                      className="promo-input"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      name="promo"
                      value={props.promo}
                      onChange={props.handleChange}
                      error={props.promoError ? true : false}
                    />

                    <Button
                      variant="contained"
                      className="apply-btn"
                      onClick={props.handleApply}
                    >
                      {props.loading ? (
                        <CircularProgress size={16} style={{ color: "#fff" }} />
                      ) : props.lng ? (
                        "Apply"
                      ) : (
                        "ඇතුලත් කරන්න"
                      )}
                    </Button>
                  </React.Fragment>
                )
              ) : null}
              <p className="promo-error">{props.promoError}</p>
            </TableCell>
          </TableRow>
          {props.discount ? (
            <TableRow>
              <TableCell component="td" scope="row">
                {props.discountPercentage}% {props.lng ? "Promo Discount" : "ප්‍රවර්ධන වට්ටම්"}
              </TableCell>
              <TableCell align="right">
                {props.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : (
                `- ${props.currencySymbol} ${props.discount ? props.discount.toFixed(2) : 0}`
              )}
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell component="td" scope="row" style={{fontSize: '17px', fontWeight: '500'}}>
              {props.lng ? "Total Due" : "ගෙවිය යුතු මුළු මුදල"}
            </TableCell>
            <TableCell align="right" style={{fontSize: '17px', fontWeight: '500'}}>
              {props.priceLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : (
                `${props.currencySymbol}
                    ${props.price.toFixed(2)}`
              )}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </div>
  );
}

export default Invoice;
