import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const LightTextColumn = ({ label, value, containerProps }) => {
  const { i18n } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      className="light-text-column-container"
      {...containerProps}
    >
      <Typography
        className={`primary-text typo-body1-${i18n.language} font-weight-500`}
      >
        {label || ""}
      </Typography>
      <Typography className="secondary-text">{value || ""}</Typography>
    </Grid>
  );
};

export default LightTextColumn;
