import axios from "axios";

export const getBaseUrlBasedOnCountry = () => {
  if (sessionStorage.getItem("countryCode")) {
    if (sessionStorage.getItem("countryCode") === "LK") {
      return `${process.env.REACT_APP_API_URL}`
    } else {
      return `${process.env.REACT_APP_GLOBAL_API_URL}`
    }
  } else {
    axios
    .get(`${process.env.REACT_APP_API_URL}/v1/generic/ipinfo`)
    .then((res) => {
      if (res.data.country) {
        if (res.data.country.callingCode) {
          // set user dial code
          sessionStorage.setItem(
            "countryDialCode",
            `+${res.data.country.callingCode}`
          );
        }
        if (res.data.country.code) {
          // set user country code
          sessionStorage.setItem("countryCode", res.data.country.code);
          if (res.data.country.code === "LK") {
            return `${process.env.REACT_APP_API_URL}`
          } else {
            return `${process.env.REACT_APP_GLOBAL_API_URL}`
          }
        }
      }
    })
    .catch((err) => console.log(err));
  }
};
