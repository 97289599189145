import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  Paper,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Invoice from "./TopAdInvoice";
import Unauthorize from "../../pages/Unauthorize";
import Config from "../../v2/config";

import stripe from "../../images/stripe.png";

class Payment extends Component {
  state = {
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "",
    postId: localStorage.getItem("postId")
      ? localStorage.getItem("postId")
      : "",
    sessionId: localStorage.getItem("session_creation")
      ? JSON.parse(localStorage.getItem("session_creation")).sessionId
      : "",
    isAccessDenied: localStorage.getItem("auth_token") ? false : true,

    price: 0,
    currency: "",
    currencySymbol: "",
    countryCode: "LK",
    showCurrencyDropdown: false,
    currencyArr: ["LKR"],

    // errors
    chekoutError: false,
    errorCode: 0,

    agreeChecked: false,
    isNotAgree: false,

    // loading
    loading: false,
    priceLoading: false,
  };

  componentDidMount = () => {
    if (!this.state.isAccessDenied) {
      this.scrollToTop();

      // remove
      localStorage.removeItem("personalInfo");
      localStorage.removeItem("parentInfo");
      localStorage.removeItem("privateInfo");
      localStorage.removeItem("partnerInfo");
      localStorage.removeItem("statesOrDistricts");
      localStorage.removeItem("uploadPhotoFiles");
      localStorage.removeItem("promoCode");

      this.setState({ priceLoading: true });
      sessionStorage.setItem("force_lkr_featured", false);

      // get my post
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}`;
      } else {
        url = "account/posts";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            res.data[0] = res.data;
          }
          if (res.data[0].personalInfo.residentCountry.code !== "LK") {
            this.setState({
              currencyArr: [
                ...this.state.currencyArr,
                // res.data[0].personalInfo.residentCountry.currency,
                "USD",
              ],
              currency: "USD",
              countryCode: "USD",
            });

            // paymentmethods
            axios
              .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
              .then((res) => {
                const isLocalPayment = res.data.filter(
                  (el) => el.id === "local_cc"
                );
                if (isLocalPayment.length > 0) {
                  this.setState({ showCurrencyDropdown: true });
                }
              })
              .catch((err) => {
                console.log(err);
              });

              const data = qs.stringify({
                forceLKR: false,
              });
              
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/featured_posts/checkout`,
                  data,
                  {
                    headers: {
                      "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then((res) => {
                  localStorage.setItem("session_creation", JSON.stringify(res.data));
                  this.setState({
                    sessionId: res.data.sessionId,
                    price: res.data.amount,
                    currency: res.data.currency,
                    currencySymbol:
                      res.data.currency === "LKR" ? "Rs." : res.data.currency,
                    priceLoading: false,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({ priceLoading: false });
                  this.setState({
                    chekoutError: true,
                    errorCode: err.response.data.code
                  });
                });
          } else {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/featured_posts/checkout`,
                {},
                {
                  headers: {
                    "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res) => {
                localStorage.setItem("session_creation", JSON.stringify(res.data));
                this.setState({
                  sessionId: res.data.sessionId,
                  price: res.data.amount,
                  currency: res.data.currency,
                  currencySymbol:
                    res.data.currency === "LKR" ? "Rs." : res.data.currency,
                  priceLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({ priceLoading: false });
                this.setState({
                  chekoutError: true,
                  errorCode: err.response.data.code
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  // checkout
  handleApply = () => {
    if (this.validate()) {
      this.setState({ loading: true });
      let data = {};
      if (this.state.countryCode !== "LK") {
        data = qs.stringify({
          forceLKR: (this.state.currency === "LKR") ? true : false,
        });
      }

      // const data = qs.stringify({
      //   promoCode: this.state.promo,
      //   // paymentMethodId:
      //   //   this.state.currency === "LKR" ? "local_cc" : "foreign_cc",
      // });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/featured_posts/checkout`,
          data,
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("session_creation", JSON.stringify(res.data));
          this.setState({
            price: res.data.amount,
            sessionId: res.data.sessionId,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
          this.setState({
            chekoutError: true,
            errorCode: err.response.data.code
          });
        });
    }
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleContinue = () => {
    sessionStorage.setItem("paying_currency", this.state.currency);
    this.props.history.push("/top-ad-checkout");
    // if (this.state.agreeChecked) {
    //   this.props.history.push("/top-ad-checkout");
    // } else {
    //   this.setState({ isNotAgree: true });
    // }
  };

  handleCurrency = (e) => {
    this.setState({currency: e.target.value, priceLoading: true})
    const data = qs.stringify({
      paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
      forceLKR: (e.target.value === "LKR") ? true : false,
    });
    if (e.target.value === "LKR") {
      sessionStorage.setItem("force_lkr_featured", true);
    } else {
      sessionStorage.setItem("force_lkr_featured", false);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/featured_posts/checkout`,
        data,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));

        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ priceLoading: false });
      });
  };

  render() {
    return this.state.isAccessDenied ? (
        <Unauthorize title={`Settings | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <div className="remember-notice">
              {this.props.lng ? (
                `Ad ID : ${this.state.userId}`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දැන්වීම් කේතය : {this.state.userId}
                </span>
              )}
            </div>

            {this.state.chekoutError ? (
              <div
                className="invoice-terms pay-method-div"
                style={{ paddingBottom: 30 }}
              >
                {(this.state.errorCode === 1520) &&
                  <div
                    className="invoice"
                    style={{ padding: "25px 15px", textAlign: "center" }}
                  >
                    { this.props.lng ? "Your post is already a Top Ad" : "ඔබගේ දැන්වීම දැනටමත් Top Ad දැන්වීමකි"}
                  </div>
                }
                {(this.state.errorCode === 1506) &&
                  <div
                    className="invoice"
                    style={{ padding: "25px 15px", textAlign: "center" }}
                  >
                    { this.props.lng ? "Only live posts can be promoted as Top Ads" : "Top Ad දැන්වීම් ලෙස ප්‍රවර්ධනය කළ හැක්කේ සජීවී දැන්වීම පමණි"}
                  </div>
                }
                {((this.state.errorCode !== 1506) && (this.state.errorCode !== 1520) && (this.state.errorCode !== 0)) &&
                  <div
                    className="invoice"
                    style={{ padding: "25px 15px", textAlign: "center" }}
                  >
                    {this.props.lng ? "Please try again later" : "කරුණාකර පසුව නැවත උත්සාහ කරන්න"}
                  </div>
                }
              </div>
            ) : (
              <div className="invoice-terms" style={{ paddingBottom: 30 }}>
                {this.state.showCurrencyDropdown && (
                  <div className="choose-currency-dropdown-div">
                    <select
                      value={this.state.currency}
                      onChange={this.handleCurrency}
                      className="choose-currency-dropdown"
                    >
                      {this.state.currencyArr.map((el, i) => (
                        <option value={el} key={i}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <Invoice
                  lng={this.props.lng}
                  currencySymbol={this.state.currencySymbol}
                  price={this.state.price}
                  loading={this.state.loading}
                  priceLoading={this.state.priceLoading}
                  handleChange={this.handleChange}
                  handleApply={this.handleApply}
                />

                {/* <div
                  style={{
                    marginTop: 40,
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: 0,
                    }}
                  >
                    {this.props.lng ? (
                      "Terms of use"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        භාවිතා කිරීමේ කොන්දේසි
                      </span>
                    )}
                  </p>
                  <div
                    className="user-agreement-div"
                    style={{
                      fontSize: 14,
                      fontWeight: 300,
                      color: "#333333",
                    }}
                  >
                    <p
                      style={{
                        marginTop: 0,
                        fontSize: 14,
                        lineHeight: "20px",
                      }}
                    >
                      {this.props.lng
                        ? "By continuing, I understand that"
                        : null}
                    </p>

                    <p style={{ marginTop: 0 }}>
                      {this.props.lng ? (
                        "a) My ad will be a Top Ad on the website for 7 days and I am entitled to pay and make my ad as a Top Ad upon expiry."
                      ) : (
                        <span className="sinhala-size-12">
                          අ) මගේ දැන්වීම දින 7 ක් වෙබ් අඩවියේ Top Ad දැන්වීමක් වනු ඇති අතර
                          කල් ඉකුත් වූ පසු එම දැන්වීම ම මුදල් ගෙවා නැවතත් Top Ad දැන්වීමක්
                          කිරීමට මට අයිතියක් ඇත.
                        </span>
                      )}
                    </p>
                    <p style={{ marginTop: 0 }}>
                      {this.props.lng ? (
                        <span>
                          b) Other general terms and conditions as stated on
                          our <Link to="/terms">Terms & Conditions</Link> and
                          the <Link to="/privacy">Privacy Policy.</Link>.
                        </span>
                      ) : (
                        <span className="sinhala-size-12">
                          ආ) අපගේ <Link to="/terms">නියමයන් සහ කොන්දේසි</Link>{" "}
                          පිටුවේ සහ{" "}
                          <Link to="/privacy">රහස්‍යතා ප්‍රතිපත්තියේ</Link>{" "}
                          සඳහන් අනෙකුත් පොදු නියමයන් සහ කොන්දේසි අදාල වේ.
                        </span>
                      )}
                    </p>

                    <FormControlLabel
                      className="create-without-email"
                      style={
                        this.state.isNotAgree
                          ? { border: "1px solid red" }
                          : { color: "#000000de" }
                      }
                      control={
                        <Checkbox
                          checked={this.state.agreeChecked}
                          onChange={() =>
                            this.setState({
                              agreeChecked: !this.state.agreeChecked,
                              isNotAgree: false,
                            })
                          }
                          name="agreeChecked"
                          size="small"
                          disabled={this.state.email ? true : false}
                        />
                      }
                      label={
                        this.props.lng ? (
                          "I confirm that I have read and understood the terms & conditions mentioned above."
                        ) : (
                          <span className="sinhala-size-12">
                            ඉහත සඳහන් කොන්දේසි මම කියවා තේරුම් ගතිමි.
                          </span>
                        )
                      }
                    />
                  </div>
                </div> */}
              </div>
            )}
          </Paper>
        </Grid>

        <div style={{ paddingBottom: 15, marginTop: 20 }}>
          <Button
            variant="contained"
            className="back-btn"
            onClick={() => this.props.history.push("/?page=1")}
          >
            <KeyboardArrowLeftIcon />{" "}
            {this.props.lng ? "Pay Later" : "පසුව ගෙවන්න"}
          </Button>

          {!this.state.chekoutError && (
            <Button
              variant="contained"
              className="save-btn"
              onClick={this.handleContinue}
            >
              {this.props.lng ? "Continue to Payment" : "ඉදිරියට"}
            </Button>
          )}

          {this.state.errorRequired && (
            <p
              align="right"
              style={{
                fontSize: 12,
                color: "#f44336",
                marginRight: 12,
              }}
            >
              *{" "}
              {this.props.lng
                ? "Fill all required fields"
                : "අවශ්‍ය සියලුම යෙදවුම් පුරවන්න"}
            </p>
          )}
        </div>

        <p
          align="center"
          style={{
            fontSize: 12,
            color: "#737373",
            marginTop: 50,
            marginBottom: 5,
          }}
        >
          Online Payments are securely processed by
        </p>
        <img
          className="payment-methods-icons"
          src={stripe}
          alt="payment methods"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Payment);
