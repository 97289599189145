import React from "react";
// import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

function PhoneVisibilityModal(props) {
  return (
    <Dialog
      open={props.phoneVisibilityModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
        {props.lng ? "Share Phone Number " : "දුරකථන අංකය පෙන්වන්න"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.lng
            ? "Do you want to share your phone number with this user? You and your match will see each other's phone numbers when both of you have shared them."
            : "ඔබගේ දුරකථන අංකය මෙම පරිශීලකයා සමඟ බෙදා ගැනීමට ඔබට අවශ්‍යද? දෙදෙනාගේම අනුමැතිය මත පමනක් දුරකථන අංක එකිනෙකාට පෙන්වනු ලැබේ."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleModalClose}>
          {props.lng === true ? (
            "Cancel"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">අවලංගු කරන්න</span>
          )}
        </Button>
        <Button
          onClick={props.handleGivePhoneVisibility}
          style={{ background: "#000", color: "#fff" }}
        >
          {props.loading ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            <span>
              {props.lng ? (
                "Share"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">පෙන්වන්න</span>
              )}
            </span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PhoneVisibilityModal;
