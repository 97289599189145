import httpAdapter, {
    ApiVersion,
    ContentType,
    HttpMethod,
    ResponseType,
  } from "../../../utils/http-adapter/index";
  
  const UserApi = {
    postUserAnalyticsCookies: async ({ cookies }) => {
      return await httpAdapter.request({
        method: HttpMethod.PUT,
        apiVersion: ApiVersion.V1,
        path: "account/analytics_ids",
        authneticated: true,
        body: cookies,
        contentType: ContentType.FORM_URL_ENCODED,
        responseType: ResponseType.JSON,
      });
    },
    postUserReviews: async ({ payload }) => {
      return await httpAdapter.request({
        method: HttpMethod.POST,
        apiVersion: ApiVersion.V1,
        path: "account/reviews",
        authneticated: true,
        body: payload,
        contentType: ContentType.FORM_URL_ENCODED,
        responseType: ResponseType.JSON,
      });
    },
  };
  
  export default UserApi;
  