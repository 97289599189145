import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import Config from "../../../../config";
import { useTranslation } from "react-i18next";
import { ArrowBackIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import UserApi from "../../../../services/api/user";
import { createPaymentSessionForTopAd } from "../../../../features/payment";
import paymentMethodsImg from "../../../../../images/stripe.png";
import { scrollToTop } from "../../../../utils";

const {
  CURRENCY_META,
  SUPPORTED_CURRENCIES,
  BASE_CURRENCY,
  USER_ROLES,
  CURRENT_PLATFORM,
} = Config;

const TopAdPayment = () => {
  const [advertisement, setAdvertisement] = useState({});
  const [checkoutError, setCheckoutError] = useState("");
  const [currency, setCurrency] = useState(BASE_CURRENCY);
  const [payAmount, setPayAmount] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);

  const { authAccount, authProfile } = useSelector(
    (state) => state.application
  );
  const { t } = useTranslation();
  const history = useHistory();

  const supportedCurrencies = useMemo(() => {
    const output = CURRENCY_META.filter((current) =>
      SUPPORTED_CURRENCIES.includes(current.code)
    );
    return output;
  }, []);

  const createPayementSession = async (data = {}) => {
    const postId = authProfile.id;
    try {
      setIsFetchingData(true);
      const paymentSessionResponse = await createPaymentSessionForTopAd({
        postId,
        data,
      });

      if (paymentSessionResponse.statusCode === 200) {
        localStorage.setItem(
          "session_creation",
          JSON.stringify(paymentSessionResponse.body)
        );
        const currency = paymentSessionResponse.body?.currency ?? "";
        const amount = paymentSessionResponse.body?.amount ?? "";
        const payAmount = `${amount.toFixed(2)} ${currency}`;
        setPayAmount(payAmount);
      } else {
        switch (paymentSessionResponse?.body?.code) {
          case 1520:
            setCheckoutError(t("payment.yourAdIsAlreadyTopAd"));
            break;
          case 1506:
            setCheckoutError(t("payment.onlyLivePostsCanBeTopAd"));
            break;
          default:
            setCheckoutError(t("payment.tryAgainLater"));
        }
      }
    } catch (error) {
      console.log(" Error >>>", error);
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    if (isEmpty(authAccount)) {
      return;
    }
    const operatorRoles = [USER_ROLES.OPERATOR, USER_ROLES.OPERATOR_L2];

    if (operatorRoles.includes(authAccount.role)) {
      const postId = localStorage.getItem("postId");
      if (postId !== null) {
        UserApi.getOperatorManagedProfile({ postId }).then((response) => {
          if (!response.success) {
            return; // TODO: Error Handling
          }
          setAdvertisement(response.body[0] ?? {});
        });
      }
    } else {
      const residentCountry = advertisement.personalInfo?.residentCountry?.code;
      if (residentCountry !== CURRENT_PLATFORM) {
        setCurrency("USD");
      }
      setAdvertisement(authProfile);
    }
  }, [authAccount, authProfile, advertisement]);

  useEffect(() => {
    if (isEmpty(advertisement)) {
      return;
    }
    if (currency === "USD") {
      sessionStorage.setItem("force_lkr_featured", false);
    } else {
      sessionStorage.setItem("force_lkr_featured", true);
    }
    const residentCountry = advertisement.personalInfo?.residentCountry?.code;
    if (residentCountry !== CURRENT_PLATFORM) {
      let data = {
        paymentMethodId: "foreign_cc",
        ...(currency === "USD" ? { forceLKR: false } : { forceLKR: true }),
      };
      createPayementSession(data);
    } else {
      createPayementSession();
    }
  }, [advertisement, currency]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const currencySelectVisibility = useMemo(() => {
    let output = false;
    const residentCountry = advertisement.personalInfo?.residentCountry?.code;
    if (residentCountry !== CURRENT_PLATFORM) {
      output = true;
    }
    return output;
  }, [advertisement]);

  const handlePaymentContinue = () => {
    sessionStorage.setItem("paying_currency", currency);
    history.push("/top-ad-checkout");
  };

  return (
    <>
      <Grid
        container
        direction="column"
        className="top-ad-page-content"
        alignItems="center"
      >
        {!isEmpty(advertisement) && (
          <Typography className="top-ad-payment-banner">
            {t("common.computedAdIdLabel", {
              id: advertisement.memberId ?? "",
            })}
          </Typography>
        )}
        {checkoutError ? (
          <Grid style={{ padding: 60 }}>
            <Typography>{checkoutError}</Typography>
          </Grid>
        ) : isEmpty(payAmount) ? (
          <Grid style={{ padding: 60 }}>
            <CircularProgress size={25} color="#fff" />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="invoice-section"
          >
            {currencySelectVisibility && (
              <Select
                labelId="currency-select-label"
                variant="outlined"
                className="currency-select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                {supportedCurrencies.map((currency) => (
                  <MenuItem key={currency.code} value={currency.code}>
                    <Typography className="selected-txt">{currency.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}

            <Typography className="invoice-txt">
              {t("payment.invoice")}
            </Typography>

            <Grid container direction="row" justifyContent="space-between">
              <Typography className="payment-price-txt">
                {t("payment.topAdFee")}
              </Typography>
              {isEmpty(payAmount) || isFetchingData ? (
                <CircularProgress size={16} color="#fff" />
              ) : (
                <Typography className="payment-price-txt">
                  {payAmount}
                </Typography>
              )}
            </Grid>

            <Grid container direction="row" justifyContent="space-between">
              <Typography className="payment-price-txt">
                {t("payment.totalDue")}
              </Typography>
              {isEmpty(payAmount) || isFetchingData ? (
                <CircularProgress size={16} color="#fff" />
              ) : (
                <Typography className="payment-price-txt">
                  {payAmount}
                </Typography>
              )}
            </Grid>
            <Divider style={{ width: "100%" }} />
          </Grid>
        )}
      </Grid>

      {!isEmpty(payAmount) && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="footer-navigation-section"
        >
          <Button
            variant="text"
            className="pay-later-btn"
            startIcon={<ArrowBackIos />}
            onClick={() => history.push("/")}
          >
            {t("payment.payLater")}
          </Button>

          <Button
            variant="contained"
            className="continue-payment-btn"
            disabled={isFetchingData || isEmpty(payAmount)}
            onClick={() => handlePaymentContinue()}
          >
            {t("payment.continueToPayment")}
          </Button>
        </Grid>
      )}

      <Grid
        container
        direction="column"
        alignItems="center"
        className="top-ad-payment-methods"
      >
        <Typography className="payment-methods-notice">
          Online Payments are securely processed by
        </Typography>
        <img
          src={paymentMethodsImg}
          alt="payment methods"
          className="payement-methods-img"
        />
      </Grid>
    </>
  );
};

export default TopAdPayment;
