import React from "react";
import { Link } from "react-router-dom";
// import Magazine from "../../magazines/may-2022.jpg";

function FilterBarMagazine(props) {
  const Magazine = "https://static.poruwa.lk/magazines/nov-2024.jpg";
  return (
    <Link
      to="/magazine"
      className="maganize-card"
      style={{ width: "100%", margin: '0' }}
      id="magasize-heading"
    >
      <div className="filter-maga-div" id="filter-maga-div">
        <h1 className="magasize-heading" style={{ lineHeight: "15px" }}>
          {props.lng ? (
            "Monthly Magazine"
          ) : (
            <span style={{ fontSize: 18 }}>මාසික සඟරාව</span>
          )}
        </h1>
        <img src={Magazine} alt="November 2024 magazine" className="maganize-img" />
        <span>
          {props.lng ? (
            "November 2024"
          ) : (
            <span style={{ fontSize: 12, lineHeight: "12px" }}>
              නොවැම්බර් 2024
            </span>
          )}
        </span>
      </div>
    </Link>
  );
}

export default FilterBarMagazine;
