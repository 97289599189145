import React from "react";
import Alert from "@material-ui/lab/Alert";

import Envelop from "../../images/address-envelop.png";

const Address = (props) => {
  return (
    <div className="row">
      <p className="sec-heading" style={{ marginBottom: 5, fontSize: 14 }}>
        {props.lng ? (
          "Mailing Address"
        ) : (
          <span className="sinhala-w-600 sinhala-size-14">ඔබගේ ලිපිනය</span>
        )}
      </p>
      <Alert severity="info" style={{ marginBottom: 25, padding: "0 10px" }}>
        {props.lng ? (
          "This address will be used to deliver the printed magazine and any written interests received. Your address will not be shared with anyone. All the interests will be received by Poruwa.lk and then re-posted to the provided address."
        ) : (
          <span className="sinhala-w-600 sinhala-size-12">
            ඔබ ඇතුලත් කරන ලිපිනය භාවිතා කරන්නේ ඔබගේ දැන්වීම සඳහා ලිපි මගින්
            ලැබෙන පිලිතුරු සහ මාසික දැන්වීම් සඟරාව ඔබ වෙත එවීමට පමණක් වන අතර
            වෙබ් අඩවියේ හෝ අනෙකුත් දැන්වීම් පලකරන අයට හෝ ඔබගේ ලිපිනය පෙන්වනු
            නොලැබේ.
          </span>
        )}
      </Alert>

      <div className="col">
        <div className="field-row">
          <div className="field-col">
            <p>{props.lng ? "Name of recipient" : "ලබන්නාගේ නම"}</p>
          </div>
          <div className="field-col">
            <input
              type="text"
              className={props.recipNameError ? "red-border" : ""}
              name="recipName"
              value={props.recipName}
              onChange={props.handleChange}
            />
            <p className="fields-error">{props.recipNameError}</p>
          </div>
        </div>
        <div className="field-row">
          <div className="field-col">
            <p>{props.lng ? "Address Line 1" : "ලිපිනය (පලමු පේලිය)"}</p>
          </div>
          <div className="field-col">
            <input
              type="text"
              className={props.line1Error ? "red-border" : ""}
              name="line1"
              value={props.line1}
              onChange={props.handleChange}
            />
            <p className="fields-error">{props.line1Error}</p>
          </div>
        </div>

        <div className="field-row">
          <div className="field-col">
            <p>
              {props.lng
                ? "Address Line 2 (Optional)"
                : "උප නගරය (අත්‍යවශ්‍ය නොවේ)"}
            </p>
          </div>
          <div className="field-col">
            <input
              type="text"
              name="line2"
              value={props.line2}
              onChange={props.handleChange}
            />
            <p className="fields-error"></p>
          </div>
        </div>

        <div className="field-row">
          <div className="field-col">
            <p>{props.lng ? "City" : "නගරය"}</p>
          </div>
          <div className="field-col">
            <input
              type="text"
              className={props.cityError ? "red-border" : ""}
              name="city"
              value={props.city}
              onChange={props.handleChange}
            />
            <p className="fields-error">{props.cityError}</p>
          </div>
        </div>
        <div className="field-row">
          <div className="field-col">
            <p>{props.lng ? "Country" : "රට"}</p>
          </div>
          <div className="field-col">
            <input
              type="text"
              name="city"
              value={props.lng ? "Sri Lanka" : "ශ්‍රි ලංකාව"}
              disabled={true}
            />
            <p className="fields-error"></p>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="address-prev-envelop">
          <img src={Envelop} alt="envelop" />
          <div className="address">
            <p>{props.recipName}</p>
            <p>{props.line1}</p>
            <p>{props.line2}</p>
            <p>{props.city}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
