import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { accordianSubTab } from "../../actions/accordianSubTab";
import { getArchived } from "../../actions/getArchived";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import IntButtonGroup from "./interest-com/IntButtonGroup";
import UnseenMatches from "./interest-com/UnseenMatches";
import MatchCards from "./interest-com/MatchCards";
import InterestCards from "./interest-com/InterestCards";

import { getInnerHeight } from "./methods/getInnerHeight";

import Search from "./interest-com/Search";

function InterestView(props) {
  const [search, setSearch] = useState("");
  const [matchArchivedCount, setMatchArchivedCount] = useState(0);
  const [receivedArchivedCount, setReceivedArchivedCount] = useState(0);
  const [externalRequests, setExternalRequests] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem(
          "postId"
        )}/interests/archived_count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setMatchArchivedCount(
          res.data.incomingMatches + res.data.outgoingMatches
        );
        setReceivedArchivedCount(res.data.incomingInterests);
      })
      .catch((err) => {
        console.log(err);
      });

    getInnerHeight("interest-view-div-unique", 70);

    if (props.history.pathname === "/interests-matched") {
      props.handleIntBtns("match");
    }

    const intId = document.location.href.split("=")[1];

    setTimeout(() => {
      if (intId) {
        let el = document.getElementById(intId);

        if (el) {
          el.click();
        }
      }
    }, 2000);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem(
          "postId"
        )}/ext_interests`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        const likedArray = res.data.map(v => ({...v, likedAt: v.createdAt}))
        setExternalRequests(likedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.isReceivedActive]);

  return (
    <div
      className={
        props.isEmptyPost ? "interest-view-div" : "interest-view-div view-hide"
      }
      id="interest-view-div-unique"
    >
      {props.history.location.pathname === "/interests-matched" ? null : (
        <IntButtonGroup lng={props.lng} />
      )}

      <Search lng={props.lng} search={props.search} setSearch={setSearch} />

      {props.posts.filter((el) => {
        if (!el.ignored) {
          if (el.latestChat) {
            return !el.latestChat;
          } else {
            if (!el.matchSeen) {
              return !el.matchSeen;
            }
          }
        }
      }).length > 0 &&
        props.isMatchActive && (
          <p className="heading">
            {props.lng ? (
              "New Matches"
            ) : (
              <span className="sinhala-w-600">නව ගැලපිම්</span>
            )}
          </p>
        )}
      {props.isMatchActive &&
        props.posts
          .filter((el) => {
            if (!el.ignored) {
              if (el.latestChat) {
                return !el.latestChat;
              } else {
                if (!el.matchSeen) {
                  return !el.matchSeen;
                }
              }
            }
          })
          .filter(
            (el) =>
              el.post.personalInfo.displayName
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
          )
          .sort((a, b) => {
            let time1 =
              a.timeline.length > 0
                ? a.timeline.filter((el) => el.eventType === "MATCHED").length >
                  0
                  ? a.timeline.filter((el) => el.eventType === "MATCHED")[0]
                      .eventTime
                  : ""
                : "";

            let time2 =
              b.timeline.length > 0
                ? b.timeline.filter((el) => el.eventType === "MATCHED").length >
                  0
                  ? b.timeline.filter((el) => el.eventType === "MATCHED")[0]
                      .eventTime
                  : ""
                : "";

            return time2.localeCompare(time1);
          })
          .map((el, i) => <UnseenMatches post={el} key={i} />)}
      {props.posts.filter(
        (el) => el.ignored === false && (el.matchSeen || el.latestChat)
      ).length > 0 &&
        props.isMatchActive && (
          <p className="heading">
            {props.lng ? (
              "Messages"
            ) : (
              <span className="sinhala-w-600">පණිවිඩ</span>
            )}
          </p>
        )}
      {props.isMatchActive &&
        props.posts
          .filter(
            (el) => el.ignored === false && (el.matchSeen || el.latestChat)
          )
          .filter(
            (el) =>
              el.post.personalInfo.displayName
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
          )
          .sort((a, b) => {
            let time1 = a.latestChat ? a.latestChat.createdAt : a.matchSeenAt;
            let time2 = b.latestChat ? b.latestChat.createdAt : b.matchSeenAt;

            return time2.localeCompare(time1);
          })
          .map((el, i) => <MatchCards post={el} lng={props.lng} key={i} />)}

      {props.isReceivedActive && (
        // <InterestCards
        //   posts={props.posts
        //     .filter((el) => el.ignored === false)
        //     .sort((a, b) => b.likedAt.localeCompare(a.likedAt))
        //     .filter(
        //       (el) =>
        //         el.post.personalInfo.displayName
        //           .toLowerCase()
        //           .indexOf(search.toLowerCase()) !== -1
        //     )}
        //   lng={props.lng}
        // />
        <InterestCards
          posts={(props.posts
            .filter((el) => el.ignored === false)
            .sort((a, b) => b.likedAt.localeCompare(a.likedAt))
            .filter(
              (el) =>
                el.post.personalInfo.displayName
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1
            )).concat(externalRequests).sort((a, b) => b.likedAt.localeCompare(a.likedAt))}
          lng={props.lng}
        />
      )}
      {props.isMatchActive ? (
        props.posts.filter((el) => el.ignored).length > 0 ||
        matchArchivedCount > 0 ? (
          <div
            className={props.isOpenAccodian ? "accordion active" : "accordion"}
            onClick={() => props.accordianSubTab(props.isOpenAccodian)}
          >
            {props.lng ? (
              "Unmatched"
            ) : (
              <span className="sinhala-w-600">ගැලපීමෙන් ඉවත් කල ගැලපීම </span>
            )}
          </div>
        ) : null
      ) : null}

      {props.isReceivedActive ? (
        props.posts.filter((el) => el.ignored).length > 0 ||
        receivedArchivedCount > 0 ? (
          <div
            className={props.isOpenAccodian ? "accordion active" : "accordion"}
            onClick={() => props.accordianSubTab(props.isOpenAccodian)}
          >
            {props.lng ? (
              "Withdrawn / Ignored"
            ) : (
              <span className="sinhala-w-600">
                කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම
              </span>
            )}
          </div>
        ) : null
      ) : null}

      {props.isOpenAccodian ? (
        <div className="panel">
          {props.isMatchActive ? (
            <>
              {props.posts
                .filter((el) => el.ignored === true)
                .sort((a, b) =>
                  b.timeline
                    .filter((el) => el.eventType === "UNMATCHED")[0]
                    .eventTime.localeCompare(
                      a.timeline.filter((el) => el.eventType === "UNMATCHED")[0]
                        .eventTime
                    )
                )
                .map((el, i) => (
                  <MatchCards post={el} lng={props.lng} key={i} />
                ))}
              {props.isArchived
                ? null
                : matchArchivedCount > 0 && (
                    <p
                      className="see-more-btn"
                      onClick={() => props.getArchived()}
                    >
                      See more...
                    </p>
                  )}
            </>
          ) : (
            <>
              <InterestCards
                posts={props.posts
                  .filter((el) => el.ignored)
                  .sort((a, b) => {
                    const typeA =
                      a.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                        .length > 0
                        ? a.timeline.filter(
                            (el) => el.eventType === "LIKE_REFUSED"
                          )
                        : a.timeline.filter((el) => el.eventType === "UNLIKED");
                    const typeB =
                      b.timeline.filter((el) => el.eventType === "LIKE_REFUSED")
                        .length > 0
                        ? b.timeline.filter(
                            (el) => el.eventType === "LIKE_REFUSED"
                          )
                        : b.timeline.filter((el) => el.eventType === "UNLIKED");

                    return typeB[0].eventTime.localeCompare(typeA[0].eventTime);
                  })}
                lng={props.lng}
              />
              {props.isArchived
                ? null
                : receivedArchivedCount > 0 && (
                    <p
                      className="see-more-btn"
                      onClick={() => props.getArchived()}
                    >
                      See more...
                    </p>
                  )}
            </>
          )}
        </div>
      ) : null}
      {/* {(externalRequests.length > 0 && props.isReceivedActive) &&
        <div>
          <p className="heading">
            {props.lng ? (
              "External Requests"
            ) : (
              <span className="sinhala-w-600">පණිවිඩ</span>
            )}
          </p>
          <InterestCards
            posts={externalRequests}
            isExternal={true}
            lng={props.lng}
          />
        </div>
      } */}
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ accordianSubTab, getArchived }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    isOpenAccodian: state.matches.isOpenAccodian,
    isArchived: state.matches.allInterests.isArchived,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(InterestView));
