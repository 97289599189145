import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";

import "./style.css";
import AppContext from "../../../context";

const FormSection = ({ heading, subHeading, children }) => {
  const { appLanguage } = useContext(AppContext);

  return (
    <Grid
      container
      direction="column"
      className="form-section-wrapper"
      style={{ rowGap: 16 }}
    >
      <Grid item container direction="column">
        <Typography
          className={`form-section-heading form-section-heading-${appLanguage}`}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            className={`form-section-subheading form-section-subheading-${appLanguage}`}
          >
            {subHeading}
          </Typography>
        )}
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default FormSection;
