import React, { Component } from "react";
import axios from "axios";
import { Grid, Typography } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import CheckoutMethod from "../components/payment/CheckoutMethod";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import ProgressBar from "../components/common/ProgressBar";
import Unauthorize from "../pages/Unauthorize";
import { getPayemntStatus } from "../methods/getPayemntStatus";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Checkout extends Component {
  state = {
    isRenew: sessionStorage.getItem("isRenew")
      ? sessionStorage.getItem("isRenew") === "true"
        ? true
        : false
      : false,
    isAccessDenied: false,
  };

  componentDidMount = () => {
    this.setState({
      isAccessDenied:
        localStorage.getItem("postId") &&
        localStorage.getItem("auth_token") &&
        getPayemntStatus()
          ? false
          : true,
    });

    // get my post
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        const isRenew =
          res.data[0].status === "PAYMENT_PENDING"
            ? false
            : res.data[0].status === "EXPIRED"
            ? true
            : res.data[0].status === "LIVE"
            ? true
            : false;
        this.setState({
          isRenew,
        });

        sessionStorage.setItem("isRenew", isRenew);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`Checkout | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <PageHead title={`Checkout | ${Config.DOMAIN_DISPLAY_NAME}`} />

        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container complete-ad-container"
          >
            {this.state.isRenew ? (
              <Typography
                variant="h3"
                align="center"
                className="create-new-ad"
                style={{
                  paddingBottom: this.state.isRenew ? 30 : 0,
                }}
              >
                {this.props.lng === true ? (
                  "Renew Ad"
                ) : (
                  <span>දැන්වීම අලුත් කරන්න</span>
                )}
              </Typography>
            ) : (
              <React.Fragment>
                <Typography
                  variant="h3"
                  align="center"
                  className="create-new-ad"
                >
                  {this.props.lng === true ? (
                    "New Ad"
                  ) : (
                    <span>නව දැන්වීමක් නිර්මාණය කිරීම</span>
                  )}
                </Typography>
                <ProgressBar lng={this.props.lng} />
              </React.Fragment>
            )}
            <CheckoutMethod align="center" lng={this.props.lng} />
          </Grid>
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.props.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.props.lng} />
        </div>
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Checkout);
