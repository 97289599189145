import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

function UnmatchModal(props) {
  return (
    <Dialog
      open={props.unmatchModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0 }}
      >
        {props.lng ? (
          "Are you sure?"
        ) : (
          <span className="sinhala-w-600 sinhala-size-13">ඔබට විශ්වාසද?</span>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.lng ? (
            "Are you sure you want to unmatch this user? You will not be able to match with them again."
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              ඔබට මෙම පරිශීලකයා නොගැලපීමට අවශ්‍ය බව ඔබට විශ්වාසද? ඔබට ඔවුන් සමඟ
              නැවත ගැලපීමට නොහැකි වනු ඇත.
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleModalClose}>
          {props.lng ? (
            "No"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">නැත</span>
          )}
        </Button>
        <Button
          onClick={() => props.handleIgnoreAllInt("unmatch")}
          style={{ background: "#000", color: "#fff" }}
        >
          {props.loadingIgnore ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            <span>
              {props.lng ? (
                "Yes"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">ඔව්</span>
              )}
            </span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnmatchModal;
