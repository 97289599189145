import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button } from "@material-ui/core";

import BannerClose from "../../images/banner-close.png";

import "../../styles/new-feature-banner.css";

const NewFeatureBanner = (props) => {
  const [banner, setBanner] = useState({});
  const [isEnable, setIsEnable] = useState(false);
  const [isAddFea, setIsAddFea] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);

  const getBanner = (data) => {
    if (data.feature_id === 1) {
      setIsAddFea(true);
      getAddress();
    }

    setTimeout(() => {
      if (
        localStorage.getItem(`newFeaRemindLater${data.feature_id}`) &&
        moment().format() <
          moment
            .utc(
              moment.utc(
                localStorage.getItem(`newFeaRemindLater${data.feature_id}`)
              )
            )
            .local()
            .format()
      ) {
      } else {
        if (
          parseInt(
            localStorage.getItem(`newFeaCloseCount${data.feature_id}`)
          ) === 2
        ) {
          setIsEnable(false);
        } else {
          if (
            localStorage.getItem(`newFeaCloseTime${data.feature_id}`) &&
            moment().format() <
              moment
                .utc(
                  moment.utc(
                    localStorage.getItem(`newFeaCloseTime${data.feature_id}`)
                  )
                )
                .local()
                .format()
          ) {
          } else {
            if (data.requireLogin) {
              if (localStorage.getItem("auth_token")) {
                setIsEnable(data.enabled);
                setBanner(data);
              } else {
                setIsEnable(false);
              }
            } else {
              setIsEnable(data.enabled);
              setBanner(data);
            }
          }
        }
      }
    }, 1000);
  };

  const getAddress = () => {
    if (localStorage.getItem("auth_token")) {
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}/owner`;
      } else {
        url = "account";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          setHasAddress(
            res.data.address ? (res.data.address.line1 ? true : false) : false
          );
        })
        .catch((err) => console.log(err));
    }
  };

  const handleBanner = (feature_id) => {
    setIsEnable(false);

    if (localStorage.getItem(`newFeaCloseCount${feature_id}`)) {
      if (
        parseInt(localStorage.getItem(`newFeaCloseCount${feature_id}`)) === 1
      ) {
        localStorage.setItem(`newFeaCloseCount${feature_id}`, 2);
        localStorage.removeItem(`newFeaCloseTime${feature_id}`);
      }
    } else {
      localStorage.setItem(`newFeaCloseCount${feature_id}`, 1);
      localStorage.setItem(
        `newFeaCloseTime${feature_id}`,
        moment().add(3, "days").local().format()
      );
    }
  };

  const remindLater = (feature_id) => {
    setIsEnable(false);

    localStorage.setItem(
      `newFeaRemindLater${feature_id}`,
      moment().add(3, "days").local().format()
    );
  };

  return (
    <React.Fragment>
      {isEnable ? (
        isAddFea ? (
          hasAddress ? null : (
            <Banner
              lng={props.lng}
              banner={banner}
              remindLater={remindLater}
              handleBanner={handleBanner}
            />
          )
        ) : (
          <Banner
            lng={props.lng}
            banner={banner}
            remindLater={remindLater}
            handleBanner={handleBanner}
          />
        )
      ) : null}
    </React.Fragment>
  );
};

function Banner(props) {
  return (
    <div
      className="banner-container new-feature-container"
      style={{
        background: props.banner.background_color
          ? props.banner.background_color
          : "transparent",
        color: props.banner.text_color ? props.banner.text_color : "#333333",
      }}
    >
      <div className="all-ads-container" style={{ background: "transparent" }}>
        {props.banner.title && (
          <p
            className="banner-heading"
            style={{ fontWeight: props.lng ? "" : 600 }}
          >
            {props.lng ? props.banner.title : props.banner.title_si}
          </p>
        )}
        {props.banner.message && (
          <p className="banner-sub-heading">
            {props.lng ? props.banner.message : props.banner.message_si}
          </p>
        )}
        {props.banner.link && (
          <a
            href={props.banner.link}
            style={{
              textDecoration: "none",
              color: props.banner.text_color
                ? props.banner.text_color
                : "#333333",
            }}
          >
            <Button className="banner-action-btn">
              {props.lng
                ? props.banner.button_text
                : props.banner.button_text_si}
            </Button>
          </a>
        )}

        <span
          className="remind-me-later"
          onClick={() => props.remindLater(props.banner.feature_id)}
        >
          {props.lng ? "Remind Me Later" : "මට පසුව මතක් කරන්න"}
        </span>
      </div>

      <img
        className="banner-close-icon"
        src={BannerClose}
        alt="banner close"
        onClick={() => props.handleBanner(props.banner.feature_id)}
      />
    </div>
  );
}

export default withRouter(NewFeatureBanner);
