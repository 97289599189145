import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";

import Actions from "./Actions";
import PhoneVisibilityModal from "../my-matches/detail-page/PhoneVisibilityModal";
import ShowIntMsgSection from "../interestModals/ShowIntMsgSection";
import AcceptIntMsgSection from "../interestModals/AcceptIntMsgSection";

import Envelop from "../../images/envelop.jpg";
import Envelop_si from "../../images/envelop_si.jpg";
import Config from "../../v2/config";

class Connect extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          {this.props.intMessageModal ?
            <ShowIntMsgSection
              lng={this.props.lng}
              handleModalClose={this.props.handleModalClose}
              loading={this.props.loading}
              fname={this.props.fName}
              lnameMasked={this.props.lnameMasked}
              gender={this.props.gender}
              messageModal={this.props.intMessageModal}
              handleShowInterest={this.props.handleShowInterest}
              sendingPostId={this.props.postId}
              sensitiveDataError={this.props.sensitiveDataError}
              displayName={this.props.displayName}
              preferenceMismatchMessage={this.props.preferenceMismatchMessage}
              preferenceMismatchMessageSinhala={this.props.preferenceMismatchMessageSinhala}
            />
            :
            this.props.messageModal ?
              <AcceptIntMsgSection
                messageModal={this.props.messageModal}
                sendingPostId={this.props.sendingPostId}
                commingMsg={this.props.matchMessage}
                sensitiveDataError={this.props.sensitiveDataError}
                fname={this.props.fName}
                lnameMasked={this.props.lnameMasked}
                gender={this.props.gender}
                loading={this.props.loading}
                handleModalClose={this.props.handleModalClose}
                handleShowInterest={this.props.handleShowInterest}
                lng={this.props.lng}
                displayName={this.props.displayName}
                likeMessage={this.props.likeMessage}
                showPhotos={this.props.showPhotos}
                receivedInterestImages= {this.props.receivedInterestImages}
                intId={this.props.intId}
                height={this.props.height}
                age={this.props.age}
                profession={this.props.profession}
                profession_si={this.props.profession_si}
                residentCity={this.props.residentCity}
              />
              :
              <Paper
                elevation={0}
                style={{ minHeight: "8vh", position: "relative" }}
                className="complete-ad-div settings-container post-details-con"
              >
              {this.props.preLoading ? (
                <div className="loading-div">
                  <CircularProgress className="loading-circle" />
                </div>
              ) : (
                <React.Fragment>
                  <Typography
                    variant="h6"
                    className="heading"
                    style={{ marginBottom: 20 }}
                  >
                    {this.props.lng ? (
                      "Respond"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-16">
                        ප්‍රතිචාර දක්වන්න
                      </span>
                    )}
                  </Typography>
                  {!this.props.isMyProfile && (
                    <div>
                      {this.props.cancelAcceptTime ||
                      this.props.cancelSentTime ||
                      this.props.unmatchedTime ? null : (
                        <Actions
                          lng={this.props.lng}
                          applyInterest={this.props.applyInterest}
                          applyNormalPost={this.props.applyNormalPost}
                          isInterestInfo={this.props.isInterestInfo}
                          isExpired={this.props.isExpired}
                          alertModal={this.props.alertModal}
                          alertHeading={this.props.alertHeading}
                          alertMessage={this.props.alertMessage}
                          showInterestOnMismatchedProfile={this.props.showInterestOnMismatchedProfile}
                          preferenceMismatchModal={
                            this.props.preferenceMismatchModal
                          }
                          preferenceMismatchMessage={
                            this.props.preferenceMismatchMessage
                          }
                          handlePreferenceMismatchModalClose={
                            this.props.handlePreferenceMismatchModalClose
                          }
                          gender={this.props.gender}
                          handleMessageModal={this.props.handleMessageModal}
                          handleModalClose={this.props.handleModalClose}
                          postId={this.props.postId}
                          messageModal={this.props.messageModal}
                          intMessageModal={this.props.intMessageModal}
                          handleShowInterest={this.props.handleShowInterest}
                          showAccept={this.props.showAccept}
                          isIgnored={this.props.isIgnored}
                          handleIgnoreAllInt={this.props.handleIgnoreAllInt}
                          handleIgnoreModal={this.props.handleIgnoreModal}
                          ignoreModal={this.props.ignoreModal}
                          ignoreReason={this.props.ignoreReason}
                          handleIgnoreReason={this.props.handleIgnoreReason}
                          loadingIgnore={this.props.loadingIgnore}
                          handleAcceptMessageModal={
                            this.props.handleAcceptMessageModal
                          }
                          sendingPostId={this.props.sendingPostId}
                          commingMsg={this.props.matchMessage}
                          sensitiveDataError={this.props.sensitiveDataError}
                          fName={this.props.fName}
                          lnameMasked={this.props.lnameMasked}
                          loading={this.props.loading}
                          showIntTooltip={this.props.showIntTooltip}
                          displayName={this.props.displayName}
                          btnLoading={this.props.btnLoading}
                        />
                      )}
                    </div>
                  )}

                  {!this.props.isMyProfile && this.props.applyMatch ? (
                    <table className="respond-ways">
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: "middle" }}>
                            <i className="fas fa-paper-plane"></i>
                          </td>
                          <td>
                            <Button
                              variant="contained"
                              className="message-btn"
                              onClick={() =>
                                this.props.history.push(
                                  `/messages?id=${this.props.postId}`
                                )
                              }
                            >
                              {this.props.lng ? (
                                "Message"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-12">
                                  පණිවිඩ
                                </span>
                              )}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}

                  <table style={{ marginTop: 4 }}>
                    <tbody>
                      <tr>
                        <td width="50px"></td>
                        <td>
                          {this.props.isExpired && (
                            <p
                              style={{
                                color: "#f44336",
                                fontSize: 14,
                                marginTop: 4,
                                marginBottom: 0,
                              }}
                            >
                              *{" "}
                              {this.props.lng ? (
                                "This ad has expired. You may not receive a response."
                              ) : (
                                <span className="sinhala-size-11">
                                  මෙම දැන්වීම කල් ඉකුත් වී ඇත. ඇතැම්විට ඔබට
                                  ප්‍රතිචාරයක් නොලැබෙනු ඇත.
                                </span>
                              )}
                            </p>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {this.props.applyMatch ? (
                    this.props.showPhone ? (
                      this.props.phone ? (
                        <Show
                          lng={this.props.lng}
                          icon="fas fa-phone fa-rotate-90"
                          message={this.props.phone}
                        />
                      ) : (
                        <Hidden
                          lng={this.props.lng}
                          icon="fas fa-phone fa-rotate-90"
                          message={
                            this.props.lng
                              ? "This user has not shared their phone number with you yet. Your phone number will not be shared with them until they share theirs."
                              : "මෙම පරිශීලකයා තවමත් ඔවුන්ගේ දුරකථන අංකය ඔබ සමඟ බෙදාගෙන නොමැත. එම නිසා ඔබගේ දුරකථන අංකයද ඔවුන්ට නොපෙනේ."
                          }
                        />
                      )
                    ) : (
                      <Hidden
                        lng={this.props.lng}
                        icon="fas fa-phone fa-rotate-90"
                        message={
                          this.props.lng
                            ? "You have not shared your phone number with this user yet. You will not be able to see their number until you share yours."
                            : "ඔබ තවමත් ඔබගේ දුරකථන අංකය මෙම පරිශීලකයාගෙන් සඟවා ඇත. ඔබ ඔබේ දුරකථන අංකය පෙන්වන තුරු ඔබට ඔවුන්ගේ දුරකථන අංකය නොපෙනේ."
                        }
                        share={
                          this.props.lng
                            ? "Share my phone number."
                            : "දුරකථන අංකය පෙන්වන්න."
                        }
                        handlePhoneVisibilityModal={
                          this.props.handlePhoneVisibilityModal
                        }
                      />
                    )
                  ) : !this.props.profileType ? (
                    this.props.phone ? (
                      <Show
                        lng={this.props.lng}
                        icon="fas fa-phone fa-rotate-90"
                        message={this.props.phone}
                      />
                    ) : null
                  ) : null}

                  {this.props.profileType === "full" ? (
                    <>
                      <Show
                        lng={this.props.lng}
                        icon="fas fa-phone fa-rotate-90"
                        message={this.props.phone}
                      />
                      {this.props.email ? (
                        <Show
                          lng={this.props.lng}
                          icon="fas fa-at"
                          message={this.props.email}
                        />
                      ) : null}
                    </>
                  ) : null}

                  {this.props.applyMatch ? (
                    this.props.email ? (
                      <Show
                        lng={this.props.lng}
                        icon="fas fa-at"
                        message={this.props.email}
                      />
                    ) : null
                  ) : null}

                  {this.props.profileType === "full" ||
                  localStorage.getItem("auth_token") ||
                  Config.CURRENT_PLATFORM === Config.PLATFORMS.IN ? null : this
                      .props.offlineId ? (
                    <Show
                      lng={this.props.lng}
                      icon="fas fa-envelope"
                      message={` Ref Code : ${this.props.offlineId}`}
                      offlineId={this.props.offlineId}
                    />
                  ) : (
                    <Hidden
                      lng={this.props.lng}
                      icon="fas fa-envelope"
                      message={
                        this.props.lng
                          ? "This advertisement does not accept responses via post."
                          : "මෙම දැන්වීම තැපැල් මගින් ප්‍රතිචාර බාර ගන්නේ නැත."
                      }
                    />
                  )}
                </React.Fragment>
              )}

              <PhoneVisibilityModal
                lng={this.props.lng}
                phoneVisibilityModal={this.props.phoneVisibilityModal}
                handleGivePhoneVisibility={this.props.handleGivePhoneVisibility}
                handleModalClose={this.props.phoneVisibilityModalClose}
              />
            </Paper>
          }
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(Connect);

function Hidden(props) {
  return (
    <table className="respond-ways-hidden">
      <tbody>
        <tr>
          <td>
            <i className={props.icon}></i>
          </td>
          <td>
            {props.message}{" "}
            {props.share && (
              <span
                style={{ color: "#2196F3", cursor: "pointer" }}
                onClick={props.handlePhoneVisibilityModal}
              >
                {props.share}
              </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Show(props) {
  return (
    <table className="respond-ways">
      <tbody>
        <tr>
          <td>
            <i className={props.icon}></i>
          </td>
          <td>
            {props.offlineId && (
              <p style={{ marginTop: 0 }}>
                {props.lng ? (
                  "Respond to this ad via Post."
                ) : (
                  <span className="sinhala-size-14">
                    මෙම දැන්වීමට තැපැල් මගින් ප්‍රතිචාර දක්වන්න.
                  </span>
                )}
              </p>
            )}

            <span style={{ fontWeight: 500 }}>{props.message}</span>

            {props.offlineId && (
              <Alert
                severity="info"
                style={{ marginTop: 15, padding: "0 10px" }}
              >
                {props.lng ? (
                  `You can respond to this advertisement via post by sending a letter to the following address with the Ref Code “${props.offlineId}” clearly written on the top left corner of the envelope.`
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    මෙම දැන්වීම සඳහා තැපැල් මගින් ප්‍රතිචාර යවන්නේනම් ඔබගේ ලිපිය
                    පහත ලිපිනයට යොමු කරන්න. ලියුම් කවරයේ උඩ වම් කෙලවරේ{" "}
                    {props.offlineId} ලෙස සඳහන් කරන්න.
                  </span>
                )}

                <p className="address" style={{ marginTop: 15 }}>
                  {props.lng ? (
                    Config.DOMAIN_DISPLAY_NAME
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      Config.DOMAIN_DISPLAY_NAME
                    </span>
                  )}
                </p>
                <p className="address">
                  {props.lng ? (
                    "P.O. Box 5,"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      තැ. පෙට්ටිය 5,
                    </span>
                  )}
                </p>
                <p className="address" style={{ marginBottom: 15 }}>
                  {props.lng ? (
                    "Nugegoda."
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      නුගේගොඩ.
                    </span>
                  )}
                </p>

                <PhotoProvider>
                  <PhotoConsumer src={Envelop} intro={Envelop}>
                    {props.lng ? (
                      <span className="sample-envelop">Sample Envelope</span>
                    ) : null}
                  </PhotoConsumer>

                  <PhotoConsumer src={Envelop_si} intro={Envelop_si}>
                    {props.lng ? null : (
                      <span className="sample-envelop sinhala-size-12">
                        ආදර්ශ ලියුම් කවරයක්
                      </span>
                    )}
                  </PhotoConsumer>
                </PhotoProvider>
              </Alert>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
