import React from "react";

import SearchIcon from "@material-ui/icons/Search";

function Search(props) {
  return (
    <div className="input-container">
      <SearchIcon className="send-icon" id="send-message-icon" />
      <input
        className="search-field"
        type="text"
        placeholder={props.lng ? "Search" : "සොයන්න"}
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
        autoComplete="off"
      />
    </div>
  );
}

export default Search;
