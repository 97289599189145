import { Grid, Typography } from "@material-ui/core";
import React from "react";

const ListItem = ({ listItem, value, subValue, link, linkType }) => {
  return (
    <Grid container direction="row">
      <Typography style={{ fontSize: 14, color: "#494949", width: "32%" }}>
        {listItem}
      </Typography>
      {value && (
        <Typography style={{ fontSize: 14, color: "#494949" }}>
          <>
            {`: ${value}`}
            <br />
            {subValue}
          </>
        </Typography>
      )}
      {link && (
        <Typography
          className="icon-box-txt"
          dangerouslySetInnerHTML={{ __html: `: ${link}` }}
        />
      )}
    </Grid>
  );
};

export default ListItem;
