import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import Footer from "../components/common/Footer";
import Config from "../v2/config";

import Info from "../images/service-unavailable.png";

import "../styles/disable-profile.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Outage extends Component {
  state = {};

  handleTry = () => {
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Service Unavailable | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="disable-div">
            <p className="message">
              {this.props.lng ? "Service Unavailable" : "සේවාව ලබා ගත නොහැක"}
            </p>
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 100, opacity: 0.7 }} />
            </p>
            <p align="center" className="desc">
              {this.props.lng ? (
                "Service is unavailable at the moment. Please try again later."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  මේ මොහොතේ සේවාව ලබා ගත නොහැක. කරුණාකර පසුව නැවත උත්සාහ කරන්න.
                </span>
              )}
            </p>

            <Button
              variant="contained"
              onClick={this.handleTry}
              className="login-btn"
              style={{ width: 150 }}
            >
              {this.props.lng ? (
                "Try Again"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  නැවත උත්සාහ කරන්න
                </span>
              )}
            </Button>
          </div>
        </Grid>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(Outage));
