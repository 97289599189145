import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import InterestListCards from "./InterestListItemCards";

const InterestSideViewWithdrawn = (props) => {
  const {
    sideMenuInterests,
    lng,
    sideMenuArchivedInterests,
    intId,
    archivedClick,
    hideMoreBtn,
  } = props;

  const withdrawnInterests = useMemo(() => {
    if (!Array.isArray(sideMenuInterests)) {
      return [];
    }
    const filteredList = sideMenuInterests.filter((element) => {
      return element.ignored;
    });
    return filteredList;
  }, [sideMenuInterests]);

  return (
    <div>
      <Typography className="message-header-text">
        {lng
          ? "Withdrawn / Ignored"
          : "කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම"}
      </Typography>
      {withdrawnInterests.length > 0 ? (
        <InterestListCards intId={intId} posts={withdrawnInterests} lng={lng} />
      ) : (
        <Typography
          align="center"
          style={{ marginTop: 50, padding: 20 }}
          className="availability-messsage"
        >
          {lng
            ? "No ignored/withdrawn profiles found"
            : "කැමැත්ත ඉවත් කල හෝ නොසලකා හරින ලද ගැලපීම් නොමැත."}
        </Typography>
      )}
      {sideMenuArchivedInterests && (
        <InterestListCards
          intId={intId}
          posts={sideMenuArchivedInterests}
          lng={lng}
        />
      )}
      {!hideMoreBtn && (
        <p className="see-more-btn" onClick={archivedClick}>
          See more...
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenuReceivedAllFetched: state.matches.sideMenuReceivedAllFetched,
    sideMenuInterests: state.matches.sideMenuInterests.interests,
    sideMenuArchivedInterests: state.matches.sideMenuArchivedInterests.archived,
  };
};

export default connect(mapStateToProps)(withRouter(InterestSideViewWithdrawn));
