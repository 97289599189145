import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Alert from "@material-ui/lab/Alert";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import ProgressBar from "../components/common/ProgressBar";
import Config from "../v2/config";
import qs from "qs";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class BankTransfer extends Component {
  state = {
    isRenew: sessionStorage.getItem("isRenew")
      ? sessionStorage.getItem("isRenew") === "true"
        ? true
        : false
      : false,

    bank: [],
    countryCode: "",
    postId: localStorage.getItem("postId")
      ? localStorage.getItem("postId")
      : "",
    adId: localStorage.getItem("userId") ? localStorage.getItem("userId") : "",

    // loading
    loading: false,
  };

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const postId = localStorage.getItem("postId");
    this.setState({ loading: true });
    const data = {
      paymentMethodId: code === "LK" ? "local_cc" : "foreign_cc",
      isRenew: false,
      pricingTierId: sessionStorage.getItem("selected_package")
        ? sessionStorage.getItem("selected_package")
        : "2m",
      simulate: false,
    };
    if (localStorage.getItem("promoCode")) {
      data.promoCode = localStorage.getItem("promoCode");
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/bank_accounts`)
      .then((res) => {
        const bank = res.data.filter((el) => el.countryCode === code);
        this.setState({
          bank: bank[0],
          countryCode: code,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    // get my post
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        const isRenew =
          res.data[0].status === "PAYMENT_PENDING"
            ? false
            : res.data[0].status === "EXPIRED"
            ? true
            : res.data[0].status === "LIVE"
            ? true
            : false;
        this.setState({
          isRenew,
        });

        sessionStorage.setItem("isRenew", isRenew);
      })
      .catch((err) => {
        console.log(err);
      });

    this.scrollToTop();
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  ConfirmBankTransfer = () => {
    this.setState({ loading: true });

    if (
      JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED" ||
      JSON.parse(localStorage.getItem("post_details")).status === "LIVE"
    ) {
      this.setState({ loading: false });
      this.props.history.push("/?page=1");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/confirm_paylater`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          this.props.history.push("/?page=1");

          localStorage.removeItem("personalInfo");
          localStorage.removeItem("parentInfo");
          localStorage.removeItem("privateInfo");
          localStorage.removeItem("statesOrDistricts");
          localStorage.removeItem("uploadPhotoFiles");
          localStorage.removeItem("session_creation");
        })
        .catch((err) => {
          this.setState({ loading: false, fail: true });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Confirm Bank Transfer | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container complete-ad-container"
          >
            {this.state.isRenew ? (
              <Typography
                variant="h3"
                align="center"
                className="create-new-ad"
                style={{
                  paddingBottom: this.state.isRenew ? 30 : 0,
                }}
              >
                {this.props.lng === true ? (
                  "Renew Ad"
                ) : (
                  <span>දැන්වීම අලුත් කරන්න</span>
                )}
              </Typography>
            ) : (
              <React.Fragment>
                <Typography
                  variant="h3"
                  align="center"
                  className="create-new-ad"
                >
                  {this.props.lng === true ? (
                    "New Ad"
                  ) : (
                    <span>නව දැන්වීමක් නිර්මාණය කිරීම</span>
                  )}
                </Typography>
                <ProgressBar lng={this.props.lng} />
              </React.Fragment>
            )}
            <Grid item xs={12}>
              <Paper elevation={0}>
                <div style={{ paddingBottom: 5 }}>
                  <Alert
                    severity="info"
                    style={{
                      borderTop: "5px solid #5fa0bd",
                      marginBottom: 20,
                    }}
                  >
                    {this.props.lng === true ? (
                      <div>
                        <div>
                          Please deposit/transfer the due amount to the bank
                          account mentioned below and send the receipt to{" "}
                          <a
                            href={`${Config.WHATSAPP_URL}`}
                            target="_blank"
                            className="payment-banner-link"
                            rel="noopener noreferrer"
                          >
                            {Config.PHONE_NUMBER}
                          </a>{" "}
                          (WhatsApp)/ pay@poruwa.lk.
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <b>
                            *Mention your Ad ID as the message/Email subject.
                          </b>
                        </div>
                      </div>
                    ) : (
                      <div className="sinhala-w-600 sinhala-size-12">
                        <div>
                          කරුණාකර නියමිත මුදල පහත සඳහන් බැංකු ගිණුමට තැන්පත් කර
                          / මාරු කර රිසිට්පත{" "}
                          <a
                            href={`${Config.WHATSAPP_URL}`}
                            target="_blank"
                            className="payment-banner-link"
                            rel="noopener noreferrer"
                          >
                            {Config.PHONE_NUMBER}
                          </a>{" "}
                          (WhatsApp) හෝ pay@poruwa.lk වෙත එවන්න.
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <b>
                            *ඔබගේ දැන්වීම් කේතය WhatsApp පණිවිඩයේ/ විද්‍යුත්
                            තැපැල් විෂය (subject) ලෙස සඳහන් කරන්න.
                          </b>
                        </div>
                      </div>
                    )}
                  </Alert>
                  <h4 align="center" style={{ color: "#d8af0d" }}>
                    {this.props.lng === true
                      ? "Ad ID"
                      : "දැන්වීම් හැඳුනුම්පත් අංකය"}{" "}
                    : {this.state.adId}
                  </h4>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ marginTop: 45, marginBottom: 55 }}
                  >
                    {this.props.lng === true ? (
                      "Amount Due"
                    ) : (
                      <span className="sinhala-w-600">ගෙවිය යුතු මුදල</span>
                    )}{" "}
                    :{" "}
                    {this.props.loading ? (
                      <CircularProgress size={16} />
                    ) : (
                      <b>
                        {" "}
                        {JSON.parse(localStorage.getItem("session_creation"))
                          .currency === "LKR"
                          ? "Rs"
                          : JSON.parse(localStorage.getItem("session_creation"))
                              .currency}{" "}
                        {JSON.parse(
                          localStorage.getItem("session_creation")
                        ).amount.toFixed(2)}
                      </b>
                    )}
                  </Typography>

                  <h3 align="center" style={{ marginBottom: 10 }}>
                    {this.props.lng === true ? "Bank Account" : "බැංකු ගිණුම"}
                  </h3>
                  <p className="bank-info">
                    <b>
                      {this.props.lng === true ? "Acc Name" : "ගිණුමේ නම"} :
                    </b>{" "}
                    {this.state.bank && this.state.bank.accName}
                  </p>
                  <p className="bank-info">
                    <b>
                      {this.props.lng === true ? "Acc No" : "ගිණුම් අංකය"} :
                    </b>{" "}
                    {this.state.bank && this.state.bank.accNumber}
                  </p>

                  <p className="bank-info">
                    <b>{this.props.lng === true ? "Bank" : "බැංකුව"} :</b>{" "}
                    {this.state.bank && this.state.bank.bankName}
                  </p>

                  {this.state.bank.branchName && (
                    <p className="bank-info">
                      <b>{this.props.lng === true ? "Branch" : "ශාඛාව"} :</b>{" "}
                      {this.state.bank && this.state.bank.branchName} (
                      {this.state.bank && this.state.bank.branchCode})
                    </p>
                  )}
                  {this.state.bank.swiftCode &&
                    this.state.countryCode !== "LK" && (
                      <p className="bank-info">
                        <b>
                          {this.props.lng === true
                            ? "Swift Code"
                            : "Swift Code"}{" "}
                          :
                        </b>{" "}
                        {this.state.bank.swiftCode}
                      </p>
                    )}
                  {this.state.bank.bsb && (
                    <p className="bank-info">
                      <b>{this.props.lng === true ? "BSB" : "BSB"} :</b>{" "}
                      {this.state.bank.bsb}
                    </p>
                  )}
                  {this.state.bank.sortCode && (
                    <p className="bank-info">
                      <b>
                        {this.props.lng === true ? "Sort Code" : "Sort Code"} :
                      </b>{" "}
                      {this.state.bank.sortCode}
                    </p>
                  )}
                  {this.state.bank.routingNumber && (
                    <p className="bank-info">
                      <b>
                        {this.props.lng === true
                          ? "Routing Number"
                          : "Routing Number"}{" "}
                        :
                      </b>{" "}
                      {this.state.bank.routingNumber}
                    </p>
                  )}

                  <Button
                    variant="contained"
                    className="confirm-bank-transfer"
                    onClick={this.ConfirmBankTransfer}
                  >
                    {this.state.loading ? (
                      <React.Fragment>
                        <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                        <span>
                          {" "}
                          &nbsp;{" "}
                          {this.props.lng === true
                            ? "Confirming..."
                            : "තහවුරු කිරීම..."}{" "}
                        </span>
                      </React.Fragment>
                    ) : this.props.lng === true ? (
                      "CONFIRM PAYMENT METHOD"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        තහවුරු කරන්න
                      </span>
                    )}
                  </Button>
                </div>
              </Paper>
              <div style={{ paddingBottom: 15, marginTop: 20 }}>
                <Button
                  variant="contained"
                  className="back-btn"
                  onClick={() => this.props.history.goBack()}
                >
                  <KeyboardArrowLeftIcon />{" "}
                  {this.props.lng ? "Back" : "ආපසු යන්න"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.props.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.props.lng} />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(BankTransfer));
