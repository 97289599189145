import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Close from "../../images/close.png";

function BlockedUserModal(props) {
  return (
    <Dialog
      open={props.showBlockedUserModal}
      onClose={props.blockedUserModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0 }}
      >
        {props.lng ? (
          "Phone Number Error"
        ) : (
          <span className="sinhala-w-600 sinhala-size-13">
            දුරකථන අංකයේ දෝෂයකි
          </span>
        )}
        <img
          src={Close}
          alt="close"
          className="close-modal"
          onClick={props.blockedUserModalClose}
        />
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          {props.lng ? (
            "This phone number cannot be used to create an account. Please contact support for more information."
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              මෙම දුරකථන අංකය දැන්වීමක් පලකිරීම සඳහා භාවිතා කළ නොහැක. වැඩිදුර තොරතුරු සඳහා කරුණාකර සහාය කණ්ඩායම අමතන්න.
            </span>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default BlockedUserModal;
