import React from "react";
import { Link } from 'react-router-dom';

import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import moment from "moment";

import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";
import heart from "../../../images/heart.png";
import googlePlay from "../../../images/google-play-new.png";
import appStore from "../../../images/app-store-new.png";
import hytch from "../../../images/hytch.jpg";

const Header = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              {props.post && (
                <div className="post-details-col ">
                  <img src={props.post.gender === "MALE" ? groom : bride} className="applicant-photo" alt="groom" />
                  <Typography variant="h6" className="name">
                    <span style={{ textTransform: "capitalize" }}>
                      {props.post.extName}
                    </span>
                  </Typography>
                  <p className="location">
                    {props.post.extBirthYear}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.extCity}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.extProfession}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  </p>
                </div>
              )}
              {props.post && (
                <div className="post-date-col">
                  <p align="right" className="date-of-post">
                    {props.post.countryCode ===
                    "" ? (
                      <i
                        className="fas fa-globe-americas"
                        data-tip="Other Country"
                      ></i>
                    ) : (
                      <ReactCountryFlag
                        countryCode={
                          props.post.countryCode
                        }
                        svg
                        style={{
                          fontSize: "1.25em",
                        }}
                      />
                    )}
                  </p>
                </div>
              )}
              <p className="likes-you">
                <img className="heart-icon" src={heart} alt="heart" />
                &nbsp; 
                {props.lng
                  ? " Liked you on "
                  : " කැමැත්ත ප්‍රකශ කරන ලද්දේ "}
                  {moment
                  .utc(
                    props.post.createdAt
                  )
                  .local()
                  .format("MMM D")}
              </p>
            </React.Fragment>
          )}
        </Paper>


        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              <img id="hytch-logo" src={hytch} alt="hytch logo" style={{width: "80px"}}/>
              <div style={{color: '#333333', fontSize: '14px'}}>
                {props.lng
                  ? <p>This profile is from an App called <strong>Hytch</strong> which is developed and operated by the Poruwa.lk team. Hytch is a privacy focused dating app designed for Sri Lankans and all Poruwa users are eligible to join it free of charge for 3 months.</p>
                  : <p>මෙය Poruwa.lk කණ්ඩායම විසින් මෙහෙයවනු ලබන <strong>Hytch</strong> නම් App එකයි. Hytch යනු ශ්‍රී ලාංකිකයන් සඳහා නිර්මාණය කර ඇති රහස්‍යතා කේන්ද්‍ර කරගත් App එකක් වන අතර සියලුම Poruwa භාවිතා කරන්නන් හට මාස 3ක් සඳහා නොමිලේ එයට සම්බන්ධ විය හැක.</p>}
              </div>
              <div style={{ color: "#333333", fontSize: "14px" }}>
                {props.lng ? (
                  <p>You can only reply to Hytch users by installing the app.</p>
                ) : (
                  <p>
                    ඔබට Hytch පරිශීලකයින්ට පිළිතුරු දිය හැක්කේ යෙදුම ස්ථාපනය කිරීමෙන් පමණි.
                  </p>
                )}
              </div>
              <p style={{fontSize: '16px', fontWeight:"600", margin:"50px 0px 0px 0px"}}>Download Hytch</p>
              <div className="app-store-div">
                <a
                  href="https://play.google.com/store/apps/details?id=com.serendib.hytch&hl=en&gl=US"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ marginRight: '20px'}}
                >
                  <img
                    src={googlePlay}
                    alt="google play"
                    style={{width: '200px'}}
                  />
                </a>
                <a
                  id="app-store-logo"
                  href="https://www.facebook.com/poruwaSL"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{marginRight: '20px', pointerEvents: 'none'}}
                >
                  <img
                    src={appStore}
                    alt="app store"
                    style={{width: '200px', opacity: '0.3'}}
                  />
                </a>
              </div>
              <div style={{ color: "#333333", fontSize: "14px" }}>
                {props.lng ? (
                  <>
                    <p style={{ fontWeight: "600", fontSize: "15px" }}>
                      Prevent interest requests from Hytch
                    </p>
                    <p>
                      You can disable interests requests from Hytch from the{" "}
                      <Link to="/settings">
                        <b>Settings</b>
                      </Link>{" "}
                      section
                    </p>
                  </>
                ) : (
                  <>
                    <p style={{ fontWeight: "600", fontSize: "15px" }}>
                      Hytch වෙතින් කැමැත්ත පළකිරීම් වළක්වන්න
                    </p>
                    <p>
                      ඔබට{" "}
                      <Link to="/settings">
                        <b>Settings</b>
                      </Link>{" "}
                      පිටුව තුළින් Hytch ඔස්සේ කැමැත්ත පළකිරීම් අක්‍රිය කළ හැක.
                    </p>
                  </>
                )}
              </div>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};
                
const mapStateToProps = (state) => {
  return {
    id: state.matches.post.id,
    // post: state.matches.post,
    images: state.matches.post
      ? state.matches.post.post
        ? state.matches.post.post.images
          ? state.matches.post.post.images
          : []
        : []
      : [],
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    isSentActive: state.matches.activeBtnType.sent,
  };
};

export default connect(mapStateToProps, null)(Header);
