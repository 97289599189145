import axios from "axios";

export const logErrors = (err) => {
  if (err.config.url == `${process.env.REACT_APP_API_URL}/v1/events/errors`) {
    console.log("error_log");
  } else {
    const data = {
      platform: "WEB",
      responseCode: err.status,
      errorCode: err.data ? err.data.code : "",
      errorMessage: err.data ? err.data.message : "",
      additionalData: {
        member_id: localStorage.getItem("userId")
          ? localStorage.getItem("userId")
          : null,
        post_id: localStorage.getItem("postId")
          ? localStorage.getItem("postId")
          : null,
        user_agent: navigator.userAgent ? navigator.userAgent : null,
        vendor: navigator.vendor ? navigator.vendor : null,
        network: navigator.connection ? navigator.connection.effectiveType : null,
      },
      uri: err.config.url,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/events/errors`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }
};
