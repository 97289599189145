export const getPayemntStatus = () => {
  if (localStorage.getItem("post_details")) {
    if (
      JSON.parse(localStorage.getItem("post_details")).status ===
        "PAYMENT_PENDING" ||
      JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED" ||
      JSON.parse(localStorage.getItem("post_details")).status === "LIVE"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
