import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import Invoice from "../payment/Invoice";

function PayhereFailReminder(props) {
  const [postId, setPostId] = useState(
    localStorage.getItem("postId") ? localStorage.getItem("postId") : ""
  );
  const [showStripe, setShowStripe] = useState(false);
  const [state, setState] = useState({
    amountBeforeDiscount: 0,
    price: 0,
    dueAmount: 0,
    discountPercentage: 0,
    discount: 0,
    currency: "",
    currencySymbol: "",
    promo: "",
    isRenew: true,
    countryCode: "",

    isPromoApplied: false,
    showPromo: false,

    // errors
    promoError: "",

    agreeChecked: false,
    isNotAgree: false,

    // loading
    loading: false,
    priceLoading: false,
    loadingStripe: false,
  });

  useEffect(() => {
    setState({
      ...state,
      priceLoading: true,
    });

    const data = `forceLKR=${(sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") ? true : false}&pricingTierId=${(sessionStorage.getItem("selected_package")) ? sessionStorage.getItem("selected_package") : "2m"}`;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));

        setState({
          ...state,
          sessionId: res.data.sessionId,
          price: res.data.amount,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          priceLoading: false,
        });
      });

    loadScripts();
  }, []);

  const loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  const handleShowPromo = () => {
    setState({
      ...state,
      showPromo: true,
    });
  };

  // validate
  const validate = () => {
    let promoError = "";

    if (state.promo === "") {
      promoError = props.lng
        ? "Promo code is required"
        : "ප්‍රවර්ධනය කේතය අවශ්‍ය වේ";
    }

    if (promoError) {
      setState({ ...state, promoError });
      return false;
    }

    return true;
  };

  // checkout
  const handleApply = () => {
    if (validate()) {
      setState({ ...state, loading: true });

      const data = `promoCode=${state.promo}&forceLKR=${(sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") ? true : false}&pricingTierId=${(sessionStorage.getItem("selected_package")) ? sessionStorage.getItem("selected_package") : "2m"}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("session_creation", JSON.stringify(res.data));
          localStorage.setItem("promoCode", state.promo);

          setState({
            ...state,
            discount: state.price - res.data.amount,
            dueAmount: res.data.amount,
            isPromoApplied: true,
            sessionId: res.data.sessionId,
            loading: false,
            promo: "",
            promoError: "",
          });
        })
        .catch((err) => {
          console.log(err);

          setState({ ...state, loading: false });

          if (err.response.data.code === 1400) {
            setState({
              ...state,
              promoError: props.lng
                ? "Invalid promo code"
                : "ප්‍රවර්ධනය කේතය වලංගු නොවේ",
            });
          } else if (err.response.data.code === 1401) {
            setState({
              ...state,
              promoError: props.lng
                ? "Promo code expired"
                : "ප්‍රවර්ධනය කේතය කල් ඉකුත් වී ඇත",
            });
          } else if (err.response.data.code === 1311) {
            setState({
              ...state,
              promoError: props.lng
                ? "Promo code valid only for new accounts"
                : "ප්‍රවර්ධනය කේතය නව ගිණුම් සඳහා පමණක් වලංගු වේ",
            });
          } else if (err.response.data.code === 1312) {
            setState({
              ...state,
              promoError: props.lng
                ? "Promo code invalid for the selected package"
                : "ප්‍රවර්ධනය කේතය තෝරාගත් පැකේජය සඳහා වලංගු නොවේ",
            });
          }
        });
    }
  };

  const redirect = () => {
    localStorage.removeItem("payment_payhere_reminder");
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);

    setState({ ...state, loadingStripe: false });

    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: state.sessionId,
      })
      .then(function (result) {
        setState({ ...state, loadingStripe: false });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
      });
  };

  return (
    <Dialog
      open={props.isShowFailPayhereModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#000", paddingBottom: 0 }}
      >
        {!showStripe ? (
          props.lng ? (
            "Payment Unsuccessful?"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">
              ගෙවීම අසාර්ථකද?
            </span>
          )
        ) : props.lng ? (
          "Complete Payment"
        ) : (
          <span className="sinhala-w-600 sinhala-size-13">ඔබට විශ්වාසද?</span>
        )}
      </DialogTitle>
      {!showStripe ? (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.lng ? (
                "Did you face any issues while completing the payment? Would you like to try again?"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  ගෙවීම සම්පූර්ණ කිරීමේදී යම් අපහසුතාවකට මුහුණ දීමට සිදු වූවා ද?
                  නැවත උත්සාහ කිරීමට කැමතිද?
                </span>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleFailPayhereModalClose}>
              {props.lng ? (
                "Cancel"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">ඉවත් වන්න</span>
              )}
            </Button>
            <Button
              onClick={() => setShowStripe(true)}
              style={{ background: "#000", color: "#fff" }}
            >
              {props.lng ? (
                "Try Again"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  නැවත උත්සාහ කරන්න
                </span>
              )}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <p style={{ width: 500 }}></p>
            <Invoice
              lng={props.lng}
              isRenew={state.isRenew}
              currencySymbol={state.currencySymbol}
              discountPercentage={state.discountPercentage}
              amountBeforeDiscount={state.amountBeforeDiscount}
              price={state.price}
              showPromo={state.showPromo}
              isPromoApplied={state.isPromoApplied}
              discount={state.discount}
              promoError={state.promoError}
              priceLoading={state.priceLoading}
              handleChange={handleChange}
              handleApply={handleApply}
              handleShowPromo={handleShowPromo}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleFailPayhereModalClose}>
              {props.lng ? (
                "Cancel"
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">නැත</span>
              )}
            </Button>
            <Button
              onClick={redirect}
              style={{ background: "#000", color: "#fff" }}
            >
              {state.loadingStripe ? (
                <CircularProgress size={16} style={{ color: "#fff" }} />
              ) : (
                <span>
                  {props.lng ? (
                    "Checkout"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">ඔව්</span>
                  )}
                </span>
              )}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default PayhereFailReminder;
