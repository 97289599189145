import { Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import "./style.css";

const FormCheckbox = ({
  fieldName,
  value,
  handleChange,
  label,
  lang,
  visibility = true,
  containerProps = {},
  labelProps = {},
}) => {
  if (!visibility) {
    return false;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox onChange={handleChange} checked={value} name={fieldName} />
      }
      label={
        <Typography
          className={`checkbox-label checkbox-label-${lang}`}
          style={labelProps}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      }
      style={containerProps}
    />
  );
};

export default FormCheckbox;
