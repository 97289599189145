import React, { Component } from 'react';
import "../../styles/google-ads.css";

class GoogleDisplayAds extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

    render() {
      return (
        <ins
          className="adsbygoogle display-ad-header"
          style={{ display: 'block', textAlign: 'center'}}
          data-ad-client= 'ca-pub-2370761850448977'
          data-ad-slot={this.props.slot}
          // data-ad-format= 'auto'
          data-full-width-responsive= "true"
          // data-adtest="on"
        >
        </ins>
      );
    }
}

export default GoogleDisplayAds;