import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Typography
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";

import Invoice from "../payment/Invoice";

function ProfileStats(props) {
  const [postId, setPostId] = useState(
    localStorage.getItem("postId") ? localStorage.getItem("postId") : ""
  );
  const [showStripe, setShowStripe] = useState(false);
  const [state, setState] = useState({
    range: "7-days",
    from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    appeared: 0,
    detailedView: 0,
    mismatched: 0,
    like: 0,
    youLiked: 0,
    matches: 0,
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem("postId")}/stats?from=${state.from}&to=${state.to}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          }
        }
      )
      .then((res) => {
        setState({
          ...state,
          appeared: res.data.impressions,
          detailedView: res.data.views,
          mismatched: res.data.prefRejections,
          like: res.data.incomingInterests,
          youLiked: res.data.outgoingInterests,
          matches: res.data.matches
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.from]); 

  const handleDateRange = (e) => {
    let from = null;
    switch (e.target.value) {
      case "7-days":
        from = moment().subtract(7, "days").format("YYYY-MM-DD");
        break;
      case "4-weeks":
        from = moment().subtract(28, "days").format("YYYY-MM-DD");
        break;
      case "3-months":
        from = moment().subtract(3, "months").format("YYYY-MM-DD");
        break;
      case "12-months":
        from = moment().subtract(12, "months").format("YYYY-MM-DD");
        break;
    }
    setState({...state, from: from, range: e.target.value});
  }

  return (
    <div className="stats-page-div">
      <div>
        <div className="panel" style={{margin:'auto'}}>
          <div className="period-drop-down" style={{ paddingBottom: 15, width: '200px' }}>
            <select
              className="age-input period-drop-down-input"
              name="minAge"
              value={state.range}
              onChange={handleDateRange}
            >
              <option key="1" value="7-days">{props.lng ? 'Last 7 days' : 'පසුගිය දින 7'}</option>
              <option key="2" value="4-weeks">{props.lng ? 'Last 4 weeks' : 'පසුගිය සති 4'}</option>
              <option key="3" value="3-months">{props.lng ? 'Last 3 months' : 'පසුගිය මාස 3'}</option>
              <option key="4" value="12-months">{props.lng ? 'Last 12 months' : 'පසුගිය මාස 12'}</option>
            </select>
          </div>

          <div className="stats-main-div profile-div">
            <div style={{ padding: '5px'}}>
              <p className="stat-ttile-p">{props.lng ? 'Appeared on Search' : 'සෙවීමේදී පෙනී සිටි'}</p>
              <p className="stat-count-p">{state.appeared}</p>
            </div>
            <div className="stat-box-div">
              <p className="stat-ttile-p">{props.lng ? 'Detailed View' : 'විස්තර දැකීම්'}</p>
              <p className="stat-count-p">{state.detailedView}</p>
            </div>
            <div className="stat-box-div">
              <p title={props.lng ? "Blocked due to preference mismatches" : "නිර්ණායක නොගැලපීම් හේතුවෙන් අවහිර කරන ලදී"} className="stat-ttile-p">{props.lng ? 'Preference Mismatches' : 'නිර්ණායක නොගැලපීම්'}</p>
              <p className="stat-count-p">{state.mismatched}</p>
            </div>
            <div className="stat-box-div">
              <p className="stat-ttile-p">{props.lng ? 'Interests Received' : 'කැමැත්ත එවූ අය'}</p>
              <p className="stat-count-p">{state.like}</p>
            </div>
            <div className="stat-box-div">
              <p className="stat-ttile-p">{props.lng ? 'Interests Sent' : 'යවන ලද කැමැත්තන්'}</p>
              <p className="stat-count-p">{state.youLiked}</p>
            </div>
            <div className="stat-box-div">
              <p className="stat-ttile-p">{props.lng ? 'Matches' : 'ගැලපිම්'}</p>
              <p className="stat-count-p">{state.matches}</p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default ProfileStats;
