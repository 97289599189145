import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Config from "../../v2/config";

const CreateAccountMethod = (props) => {
  const [showPhone, setShowPhone] = useState(false);
  const history = useHistory();

  return (
    <>
      <p className="register-sec-heading">
        {props.lng ? "Don't have an account yet?" : "තවම ගිණුමක් නැද්ද?"}
      </p>
      <div className="create-account-method-div">
        <div
          onClick={() => history.push("/auth")}
          className="create-account-method"
        >
          <i className="fas fa-user-edit"></i>
          {props.lng ? (
            "Create an account"
          ) : (
            <span className="sinhala-w-600 sinhala-size-12">ගිණුමක් තනන්න</span>
          )}
        </div>
        {
          (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) ?
          <div
            onClick={() => setShowPhone(!showPhone)}
            className="create-account-method"
          >
            <div className="mobile">
              <a href={`tel:${Config.PHONE_NUMBER}`}>
                <i className="fas fa-headset"></i>
                {showPhone ? (
                  <span className="phone">{Config.PHONE_NUMBER}</span>
                ) : props.lng ? (
                  "Call us to create your account"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-12">
                    ඔබගේ ගිණුම සෑදීමට අපව අමතන්න
                  </span>
                )}
              </a>
            </div>
            <div className="non-mobile">
              <i className="fas fa-headset"></i>
              {showPhone ? (
                <span className="phone">{Config.PHONE_NUMBER}</span>
              ) : props.lng ? (
                "Call us to create your account"
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  ඔබගේ ගිණුම සෑදීමට අපව අමතන්න
                </span>
              )}
            </div>
          </div> : null
        }
      </div>
    </>
  );
};

export default CreateAccountMethod;
