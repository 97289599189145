
import { MARK_INTEREST_SEEN } from "./types";

export const markInterestSeen = (id) => (dispatch) => {
  if (id) {
    dispatch({
      type: MARK_INTEREST_SEEN,
      payload: {
        id: id,
      },
    });
  }
};