import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context";
const AlertDialogBox = ({ showModal, onClose, title, children }) => {
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);

  return (
    <Dialog open={showModal} onClose={onClose}>
      <DialogTitle>
        <Typography
          align="center"
          className={`image-notice-heading-${appLanguage}`}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Typography
          align="center"
          className={`image-modal-action image-modal-action-${appLanguage}`}
          onClick={() => onClose()}
        >
          {t("common.close")}
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogBox;

export const ActionDialogBox = ({
  showModal,
  onClose,
  title,
  secondaryBtnText,
  primaryBtnText,
  secondaryBtnAction,
  primaryBtnAction,
  children,
}) => {
  return (
    <Dialog open={showModal} onClose={onClose}>
      <DialogTitle style={{ padding: "10px 0px 0px 20px" }}>
        <Typography style={{ fontSize: 20 }}>{title}</Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={secondaryBtnAction}>{secondaryBtnText}</Button>
        <Button onClick={primaryBtnAction} style={{ background: "#000", color: "#fff" }}>
          {primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
