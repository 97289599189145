import React, { useState, useEffect } from "react";
import ReceivedInterest from "../components/my-matches/ReceivedInterestProfile";
import InterestSideView from "../components/my-matches/InterestSideView";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import { getSentInterests } from "../actions/getSentInterests";
import { handleIntBtns } from "../actions/handleIntBtns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Config from "../v2/config";

import "../styles/my-matches.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

const InterestsSent = (props) => {
  const { lng } = props;
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    setSelectedId(id);
  });

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    props.handleIntBtns("sent");
  }, []);

  return isAccessDenied ? (
    <Unauthorize title={`Sent Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Sent Interests | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div">
          <InterestSideView lng={lng} intId={selectedId} />
          {selectedId && (
            <ReceivedInterest lng={lng} intId={selectedId} isSent={true} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSentInterests, handleIntBtns: handleIntBtns },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    // post: state.matches.post,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withLanguageMigrator(InterestsSent));
