import { SET_IS_OUTAGE } from "../actions/globalActions";

const initialState = {
    isOutage : false
}

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_OUTAGE:
            return {...state, isOutage: action.payload};
        default:
            return state;
    }
}

export default globalReducer;