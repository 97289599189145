import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import FormSection from "../../../../../components/molecules/form-section";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import AppContext from "../../../../../context";

const Habbits = ({ formMeta }) => {
  const { t } = useTranslation();
  const { values, errors, handleChange, submitCount } = useFormikContext();
  const showFieldErrors = submitCount > 0;
  const { appLanguage } = useContext(AppContext);
  const { drinkingPreferences, smokingPrefernces, foodPreferences } =
    useSelector((state) => state.genericData);

  return (
    <FormSection heading={t("common.habits")}>
      <Grid container direction="row" className="row-gap-16">
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          className="row-gap-16"
        >
          <FormDropdown
            fieldName={formMeta.drinking.fieldName}
            value={values.drinking}
            onChange={handleChange}
            options={drinkingPreferences}
            keyExtractor={formMeta.drinking.keyExtractor}
            labelExtractor={formMeta.drinking.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.drinking.label)}
            errorText={showFieldErrors && errors.drinking}
          />
          <FormDropdown
            fieldName={formMeta.smoking.fieldName}
            value={values.smoking}
            onChange={handleChange}
            options={smokingPrefernces}
            keyExtractor={formMeta.smoking.keyExtractor}
            labelExtractor={formMeta.smoking.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.smoking.label)}
            errorText={showFieldErrors && errors.smoking}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <FormDropdown
            fieldName={formMeta.foodPreference.fieldName}
            value={values.foodPreferenceId}
            onChange={handleChange}
            options={foodPreferences}
            keyExtractor={formMeta.foodPreference.keyExtractor}
            labelExtractor={formMeta.foodPreference.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.foodPreference.label)}
            errorText={showFieldErrors && errors.foodPreferenceId}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default Habbits;
