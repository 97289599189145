import React, { useContext, useEffect } from "react";
import FormSection from "../../../../../components/molecules/form-section";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import FormDropdown, {
  LABEL_POSITION,
} from "../../../../../components/molecules/form-dropdown";
import AppContext from "../../../../../context";
import { DIFFERENTLY_ABLED_OPTIONS } from "../../../../../static-data";
import Notice from "../../../../../components/molecules/notice";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import { useFormikContext } from "formik";
import { checkSensitiveData } from "../../../../../features/generic-data";
import { trimFieldValue } from "../../../../../utils";

const AdditionalInfomation = ({ formMeta }) => {
  const {
    values,
    errors,
    handleChange,
    submitCount,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (submitCount === 0) {
      return;
    }
    if (!Boolean(values.description)) {
      setFieldError("description", "");
      return;
    }
    checkSensitiveData({ content: values.description })
      .then((validity) => {
        const errorMsg = validity
          ? ""
          : t("createflow.descriptionSensitiveDataError");
        setFieldError("description", errorMsg);
      })
      .catch((e) => {
        console.error("Error:[validateDescriptionField] ", e);
      });
  }, [setFieldError, submitCount, t, values]);

  return (
    <FormSection heading={t("createflow.adDescriptionTitle")}>
      <Grid container direction="row" className="row-gap-16">
        <Grid item xs={12} md={6}>
          <FormDropdown
            fieldName={formMeta.differentlyAbled.fieldName}
            value={values.differentlyAbled}
            onChange={handleChange}
            options={DIFFERENTLY_ABLED_OPTIONS}
            label={t(formMeta.differentlyAbled.label)}
            keyExtractor={formMeta.differentlyAbled.keyExtractor}
            labelExtractor={formMeta.differentlyAbled.labelExtractor}
            labelPosition={`${LABEL_POSITION.top}`}
            lang={appLanguage}
            errorText={errors.differentlyAbled}
          />
        </Grid>
        <Grid item xs={12} container direction="column" className="row-gap-16">
          <Notice
            label={t("createflow.adDescriptionNotice")}
            lang={appLanguage}
          />
          {values.differentlyAbled && JSON.parse(values.differentlyAbled) && (
            <Notice
              label={t("createflow.specialNeedAdvertistmentNotice")}
              lang={appLanguage}
            />
          )}
        </Grid>
        <FormTextInput
          fieldName={formMeta.additionalInfo.fieldName}
          value={values.additionalInfo}
          onChange={handleChange}
          label={t(formMeta.additionalInfo.label)}
          labelPosition={LABEL_POSITION.top}
          placeholder={t(formMeta.additionalInfo.placeholder)}
          lang={appLanguage}
          multiline
          errorText={submitCount > 0 && errors.additionalInfo}
          onBlur={() => {
            trimFieldValue({
              fieldName: formMeta.additionalInfo.fieldName,
              value: values[formMeta.additionalInfo.fieldName],
              setFieldValue,
            });
          }}
          max={formMeta.additionalInfo.max}
          maxCharactorCount={formMeta.additionalInfo.max}
        />
      </Grid>
    </FormSection>
  );
};

export default AdditionalInfomation;
