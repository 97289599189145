import { FETCH_NOTIFICATION_COUNT } from "./types";
import axios from "axios";

export const fetchNotificationCount = () => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
        "postId"
      )}/interests/new_count`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: FETCH_NOTIFICATION_COUNT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
