export default {
  NODE_ENV: process.env["NODE_ENV"],
  API_URL: process.env["REACT_APP_API_URL"],
  WEB_SOCKET_URL: process.env["REACT_APP_WEB_SOCKET_URL"],
  CAPTCHA_KEY: process.env["REACT_APP_CAPTCHA_KEY"],
  CAPTCHA_KEY_V2: process.env["REACT_APP_CAPTCHA_KEY_V2"],
  GLOBAL_API_URL: process.env["REACT_APP_GLOBAL_API_URL"],
  PAYHERE_URL: process.env["REACT_APP_PAYHERE_URL"],
  SENTRY_ID: process.env["REACT_APP_SENTRY_ID"],
  STRIPE_KEY: process.env["REACT_APP_STRIPE_KEY"],
  APP_VARIANT: process.env["REACT_APP_APP_VARIANT"],
  PLATFORM: process.env["REACT_APP_PLATFORM"],
  TARGET_ENVIRONMENT: process.env["REACT_APP_TARGET_ENVIRONMENT"],
  DOMAIN_DISPLAY_NAME: process.env["REACT_APP_DOMAIN_DISPLAY_NAME"],
  DOMAIN_DISPLAY_NAME_SI: process.env["REACT_APP_DOMAIN_DISPLAY_NAME_SI"],
  DOMAIN_NAME: process.env["REACT_APP_DOMAIN_NAME"]
};
