import React from "react";
import "./style.css";

const PrivacyInfoSection = ({ title, description, listItems = [] }) => {
  const HtmlRenderer = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  return (
    <>
      <h4 className="privacy-content-title-text">{title}</h4>

      <p
        style={{
          margin: "1rem 0 0 1rem",
          lineHeight: 1.8,
        }}
        className="privacy-content-primary-text"
      >
        <HtmlRenderer htmlString={description} />
      </p>
      <ol type="a" style={{ margin: 0, padding: 0 }}>
        {listItems.map((item, index) => (
          <li
            key={index}
            className="privacy-content-primary-text"
            style={{
              margin: "1rem 0 1rem 2rem",
              lineHeight: 1.4,
            }}
          >
            {item}
          </li>
        ))}
      </ol>
    </>
  );
};

export default PrivacyInfoSection;
