import React from "react";
import { Paper, Grid } from "@material-ui/core";
import RegisterAcc from "./RegisterAcc";

function RegisterNewCom(props) {
  return (
    <Grid item xs={12}>
      <Paper elevation={0} style={{ position: "relative" }}>
        <RegisterAcc lng={props.lng} />
      </Paper>
    </Grid>
  );
}

export default RegisterNewCom;
