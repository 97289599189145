import UserApi from "../../services/api/user";

export const createPayementSession = async ({ postId, data }) => {
  try {
    const response = await UserApi.createPaymentSession({
      postId,
      data,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createPaymentSessionForTopAd = async ({ postId, data }) => {
  try {
    const response = await UserApi.createPaymentSessionForTopAd({
      postId,
      data,
    });
    if (response.statusCode !== 400 && response.statusCode !== 200) {
      throw new Error("unable to create payment session");
    }
    return response;
  } catch (error) {
    console.log("Error >>>", error);
  }
};
