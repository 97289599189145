import React, { Component } from "react";

import { Dialog, Button, CircularProgress } from "@material-ui/core";

import close from "../../images/close.png";
import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";

export default class MailResentModal extends Component {
  handleKeyUpEmailSent = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("email-reset-btn").click();
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.mailResentModal}
        onClose={this.handleCloseResetPwd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        className="login-modal"
      >
        <div className="create-login-div login-div-container">
          <picture>
            <ProductLogo variant={LogoVariant.black} size={LogoSize.xsmall} className={"login-logo"}/>
          </picture>
          <p className="modal-heading" style={{ textAlign: "center" }}>
            {this.props.lng ? (
              "Resend Activation Link"
            ) : (
              <span className="sinhala-w-600 sinhala-size-17">
                සක්‍රිය කිරීමේ සබැඳිය නැවත යවන්න
              </span>
            )}
          </p>
          <img
            src={close}
            alt="close"
            className="close-icon"
            onClick={this.props.handleClose}
          />

          {this.props.showResendSuccess ? (
            <p
              style={{
                color: "#28a745",
                fontSize: "0.75rem",
                marginTop: 25,
                textAlign: "center",
              }}
            >
              <i className="fas fa-check"> </i>{" "}
              {this.props.lng ? (
                "Email activation link sent. Please check your indox."
              ) : (
                <span className="sinhala-w-600 sinhala-size-13">
                  විද්‍යුත් තැපැල් සක්‍රීය කිරීමේ සබැඳිය යවන ලදි. කරුණාකර ඔබගේ
                  එන ලිපි පරීක්ෂා කරන්න.
                </span>
              )}
            </p>
          ) : null}

          {this.props.showResendFail ? (
            <p
              style={{
                color: "#f44336",
                fontSize: "0.75rem",
                marginTop: 0,
                textAlign: "center",
              }}
            >
              {this.props.lng
                ? "Opps! Something Went Wrong"
                : "Opps! මොකක්හරි වැරැද්දක් වෙලා"}
            </p>
          ) : null}

          <React.Fragment>
            <p className="input-headings">
              {this.props.lng ? (
                "Email"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  විද්යුත් තැපෑල
                </span>
              )}{" "}
            </p>
            <input
              type="text"
              className={
                this.props.resendEmailError
                  ? "login-input red-border"
                  : "login-input"
              }
              name="resendEmail"
              value={this.props.resendEmail}
              onChange={this.props.handleChange}
              onKeyUp={this.handleKeyUpEmailSent}
            />
            <div className="error-handler">{this.props.resendEmailError}</div>

            <Button
              variant="contained"
              className="login-btn"
              onClick={this.props.handleResendEmail}
              id="email-reset-btn"
            >
              {this.props.resetLoading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : this.props.lng ? (
                "Send"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">යවන්න</span>
              )}
            </Button>
          </React.Fragment>
        </div>
      </Dialog>
    );
  }
}
