import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";

import { connect } from "react-redux";

import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";
import heart from "../../../images/heart.png";
import { setPreLoading } from "../../../actions/setPreLoading";
import PhotoGrid from "../../PhotoGrid";

const Header = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [postDetails, setPostDetails] = useState([]);
  const [cancelledPost, setCancelledPost] = useState({});
  const [unmatchedPost, setUnmatchedPost] = useState({});
  const [withdrawnPost, setWithdrawnPost] = useState({});


  useEffect(() => {
    if (props.isInactiveProfile) {
      setPostDetails(props.post);
      if (props.post.timeline) {
        setCancelledPost(props.post.timeline.filter((filter) => filter.eventType === "UNLIKED"));
        setUnmatchedPost(props.post.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
        setWithdrawnPost(props.post.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
      }
    } else {
      getProfile();
    }
  }, [props.postDetails]);

  const setImages = (post) => {
    setImageData([]);
    if (post.images && post.images.length > 0) {
      for (let i = 0; i < post.images.length; i++) {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
              props.intId
            }/images/${post.images[i]}?thumbnail=false`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    } else {
      setImageData([]);
    }
  }

  const getProfile = () => {
    setLoading(true);
    if (props.isInterestActive) {
      if (props && Object.keys(props.postDetails).length > 0) {
        setPostDetails(props.postDetails);
        if (props.postDetails.timeline) {
          setCancelledPost(props.postDetails.timeline.filter((filter) => filter.eventType === "UNLIKED"));
          setUnmatchedPost(props.postDetails.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
          setWithdrawnPost(props.postDetails.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
        }
        setImages(props.postDetails.post);
        setLoading(false);
      }
      /*axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.intId
        }
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setPostDetails(res.data);
        if (res.data.timeline) {
          setCancelledPost(res.data.timeline.filter((filter) => filter.eventType === "UNLIKED"));
          setUnmatchedPost(res.data.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
          setWithdrawnPost(res.data.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
        }
        setImages(res.data.post);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });*/
    } else {
      if (props && Object.keys(props.postDetails).length > 0) {
        setPostDetails(props.postDetails);
        if (props.postDetails.timeline) {
          setCancelledPost(props.postDetails.timeline.filter((filter) => filter.eventType === "UNLIKED"));
          setUnmatchedPost(props.postDetails.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
          setWithdrawnPost(props.postDetails.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
        }
        setImages(props.postDetails.post);
        setLoading(false);
      }


      // axios
      // .get(
      //   `${
      //     process.env.REACT_APP_API_URL
      //   }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
      //     props.intId
      //   }
      //   `,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      //     },
      //   }
      // )
      // .then((res) => {
      //   setPostDetails(res.data);
      //   if (res.data.timeline) {
      //     setCancelledPost(res.data.timeline.filter((filter) => filter.eventType === "UNLIKED"));
      //     setUnmatchedPost(res.data.timeline.filter((filter) => filter.eventType === "UNMATCHED"));
      //     setWithdrawnPost(res.data.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED"));
      //   }
      //   setImages(res.data.post);
      //   setLoading(false);
      // })
      // .catch((err) => {
      //   console.log(err);
      //   setLoading(false);
      // });
    }
  };

  const getLiked = () => {
    if (cancelledPost.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getMatched = () => {
    if (unmatchedPost.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getSent = () => {
    if (withdrawnPost.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {loading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              {postDetails.post && (
                <div className="post-details-col ">
                  {postDetails.post.personalInfo.gender === "MALE" ? (
                    <img src={groom} className="applicant-photo" alt="groom" />
                  ) : (
                    <img src={bride} className="applicant-photo" alt="bride" />
                  )}

                  <Typography variant="h6" className="name">
                    <span data-testid="name" style={{ textTransform: "capitalize" }}>
                      {postDetails.post.personalInfo && postDetails.post.personalInfo.fname && getMatched() ?
                        postDetails.post.personalInfo.fname+" "+postDetails.post.personalInfo.lname
                        :
                        postDetails.post.personalInfo.displayName
                      }
                    </span>
                  </Typography>
                  {Object.keys(postDetails).length > 0 &&
                    <p className="location">
                      {postDetails.post.personalInfo.age}{" "}
                      <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                      {postDetails.post.personalInfo.residentCity}{" "}
                      <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                      {postDetails.post.personalInfo.profession.name === "Other"
                        ? postDetails.post.personalInfo.professionOther
                        : props.lng
                        ? postDetails.post.personalInfo.profession.name
                        : postDetails.post.personalInfo.profession.name_si}{" "}
                      <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                      {Math.floor(
                        Math.round(postDetails.post.personalInfo.height / 2.54) /
                          12
                      )}
                      '{" "}
                      {Math.round(postDetails.post.personalInfo.height / 2.54) %
                        12}
                      "
                    </p>
                  }
                </div>
              )}
              {Object.keys(postDetails).length > 0 && (
                <div className="post-date-col">
                  <p align="right" className="date-of-post">
                    {postDetails.post.personalInfo.residentCountry.code ===
                    "" ? (
                      <i
                        className="fas fa-globe-americas"
                        data-tip="Other Country"
                      ></i>
                    ) : (
                      <ReactCountryFlag
                        countryCode={
                          postDetails.post.personalInfo.residentCountry.code
                        }
                        svg
                        style={{
                          fontSize: "1.25em",
                        }}
                        aria-label={
                          props.lng
                            ? postDetails.post.personalInfo.residentCountry.name
                            : postDetails.post.personalInfo.residentCountry
                                .name_si
                        }
                        data-tip={
                          props.lng
                            ? postDetails.post.personalInfo.residentCountry.name
                            : postDetails.post.personalInfo.residentCountry
                                .name_si
                        }
                      />
                    )}
                  </p>
                </div>
              )}
              {(Object.keys(postDetails).length > 0 && postDetails.post.createdBy) ? (
                <label className="createdby-label">
                  {postDetails.post.createdBy === "SELF"
                    ? props.lng
                      ? "Account created by: Self"
                      : "ගිණුම සාදන ලද්දේ : තමන් විසින්"
                    : props.lng
                    ? "Account created by : Parents / Family"
                    : "ගිණුම සාදන ලද්දේ: දෙමවුපියන් / පවුලේ අය"}
                </label>
              ) : null}

              {props.isSent &&
                (getSent() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; <span data-testid="match-label">Interest Sent</span>
                  </p>
                ) : null)}

              {props.isMatchActive &&
                (getMatched() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; <span data-testid="match-label">Matched</span>
                  </p>
                ) : null)}

              {(!props.isSent && props.isInterestActive) &&
                (getLiked() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; <span data-testid="match-label">Interest Received</span>
                  </p>
                ) : null)}

              {(!localStorage.getItem("post_details") || postDetails.isDisabledProfile) ? null : props.isMatchActive ? (
                !postDetails.ignored ? (
                  JSON.parse(localStorage.getItem("post_details")).images
                    .length > 0 ? (
                    imageData.length > 0 ? (
                      <PhotoGrid  images={imageData} />
                    ) : (
                      <p className="invisible-photos">
                        <i className="fas fa-eye-slash"></i>{" "}
                        {props.lng
                          ? "This user has not uploaded pictures to their profile. Your pictures will not be shared with them."
                          : "මෙම පරිශීලකයා ඔවුන්ගේ දැන්වීමට පින්තූර ඇතුලත් කර නොමැත. එම නිසා ඔබේ පින්තූර ඔවුන්ට පෙන්වන්නේ ද නොමැත."}
                      </p>
                    )
                  ) : (
                    <p className="invisible-photos" style={{ marginBottom: 0 }}>
                      <i className="fas fa-eye-slash"></i>{" "}
                      {props.lng
                        ? "Upload pictures to your profile to view pictures of your matches"
                        : "ඔබගේ ගැලපීම් වල පින්තූර බැලීමට ඔබගේ දැන්වීම සඳහා ද පින්තූර ඇතුලත් විය යුතුය."}{" "}
                      <a
                        href="/edit-my-ad?scroll=toPhotos"
                        style={{ color: "#2196F3" }}
                      >
                        {props.lng
                          ? "Upload your photos"
                          : "ඔබගේ පින්තූර ඇතුලත් කරන්න"}
                      </a>
                    </p>
                  )
                ) : postDetails.restricted ? (
                  <p className="invisible-photos" style={{ marginBottom: 0 }}>
                    <i className="fas fa-eye-slash"></i>{" "}
                    {props.lng ? (
                      "You cannot view details of this profile once unmatched."
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ගැලපුමක් ඉවත් කල පසු එහි විස්තර නොපෙන්වයි.
                      </span>
                    )}
                  </p>
                ) : null
              ) : null}

              {postDetails.isDisabledProfile || postDetails.restricted
                ? null
                : (postDetails.post && postDetails.post.personalInfo.additionalInfo) && (
                    <p
                      className="desc"
                      style={{ marginBottom: 0, marginTop: 20 }}
                    >
                      {postDetails.post.personalInfo.additionalInfo}
                    </p>
                  )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // id: state.matches.post.id,
    // post: state.matches.post,
    // images: state.matches.post
    //   ? state.matches.post.post
    //     ? state.matches.post.post.images
    //       ? state.matches.post.post.images
    //       : []
    //     : []
    //   : [],
    // isMatchActive: state.matches.activeBtnType.match,
    // isReceivedActive: state.matches.activeBtnType.received,
    // isSentActive: state.matches.activeBtnType.sent,
  };
};

// export default connect(mapStateToProps, null)(Header);
export default Header;
