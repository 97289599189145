import React, { useState, useEffect } from "react";

// import Navbar from "../components/common/Navbar";
import SentSidebar from "../components/my-matches/SentSidebar";
import ReceivedInterest from "../components/my-matches/ReceivedInterest";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

import { fetchAllInterest } from "../actions/fetchAllInterest";
import { handleIntBtns } from "../actions/handleIntBtns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../styles/my-matches.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

const InterestsSent = (props) => {
  const lng = props.lng;
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    props.handleIntBtns("sent");
  }, []);

  useEffect(() => {
    props.fetchAllInterest();
  }, []);

  const isEmptyObj =
    Object.keys(props.post).length === 0 && props.post.constructor === Object;

  return isAccessDenied ? (
    <Unauthorize title={`Interest sent | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Interest sent | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div">
          <SentSidebar posts={props.sentPosts} lng={lng} />

          {isEmptyObj ? null : props.post.code ? null : (
            <ReceivedInterest lng={lng} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchAllInterest: fetchAllInterest, handleIntBtns: handleIntBtns },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    post: state.matches.post,
    sentPosts: state.matches.allInterests.sent
      ? state.matches.allInterests.sent
      : [],
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withLanguageMigrator(InterestsSent));
