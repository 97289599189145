import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import "./style.css";

export const Varaint = Object.freeze({
  primary: "primary",
});

const Notice = ({
  varaint,
  label,
  lang,
  noticeList = [],
  isRightAligned = false,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const computedVaraint = Object.keys(Varaint).includes(varaint)
    ? varaint
    : Varaint.primary;

  return (
    <Grid
      container
      direction="column"
      className={`root-container ${computedVaraint} ${
        isRightAligned &&
        `root-container-${isMobileScreen && "xs"} root-container-${
          !isMobileScreen && "md"
        }`
      } `}
    >
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className={`root-container ${computedVaraint}`}
      >
        <InfoIcon />
        <Grid item xs>
          <Typography className={`notice-label-${lang}`}>{label}</Typography>
        </Grid>
      </Grid>

      {Array.isArray(noticeList) && noticeList.length !== 0 && (
        <Grid container style={{ justifyContent: "right"}}>
          <Grid className="notice-label-list">
            <ul className="list-wrapper">
              {noticeList.map((list) => (
                <li className={`notice-list-item${lang === "si" ? "-si" : ""}`}>
                  {list}
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Notice;
