import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  Snackbar,
  FormControlLabel,
  RadioGroup,
  Radio,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import "../../styles/settings.css";
import "../../styles/common.css"

import Config from "../../v2/config";

export default class RemoveAd extends Component {
  state = {
    postId: "",
    status: false,
    disableModal: false,
    reasons: [],
    whyDissable: "",

    // btn
    isDissable: true,

    // error
    error: false,

    // alerts
    loading: false,
  };

  componentDidMount = () => {
    this.initialStates();
  };

  initialStates = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        this.setState({
          postId: res.data[0].id,
          status: res.data[0].status === "DISABLED" ? true : false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/disable_reasons`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          reasons: res.data,
        });
      });
  };

  handleDissableOptions = (e) => {
    this.setState({ whyDissable: e.target.value, isDissable: false });
  };

  // unpublish
  handleUnplublish = () => {
    if (this.state.whyDissable !== "") {
      this.setState({
        loading: true,
      });

      const reasonId = this.state.reasons.filter(
        (filter) => filter.reason === this.state.whyDissable
      );

      const data = `reasonCode=${reasonId[0].id}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/disable`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    } else {
      this.setState({ error: true });
    }
  };

  handleClose = () => {
    this.setState({
      disableModal: false,
      successAlert: false,
      failAlert: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={
              !this.state.status
                ? { minHeight: "10vh", position: "relative" }
                : {
                    minHeight: "10vh",
                    position: "relative",
                    background: "#ff9f9f",
                  }
            }
            className="complete-ad-div"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                {!this.state.status ? (
                  <div className="row">
                    <p className="sec-heading" style={{ marginBottom: 5 }}>
                      {this.props.lng ? (
                        "Remove Ad"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දැන්වීම ඉවත් කරන්න
                        </span>
                      )}
                    </p>
                    <p className="heading-desc">
                      {this.props.lng ? (
                        "Your ad will be unpublished from the website. This change can not be undone."
                      ) : (
                        <span className="sinhala-size-11">
                          ඔබේ දැන්වීම ${Config.DOMAIN_DISPLAY_NAME} වෙබ් අඩවියෙන් ඉවත් කෙරෙනු ඇත.
                          මෙම වෙනස් කිරීම ඔබට නැවත හැරවිය නොහැක
                        </span>
                      )}
                    </p>

                    <div style={{ paddingBottom: 70 }}>
                      <Button
                        variant="contained"
                        className="common-remove-btn"
                        onClick={() => this.setState({ disableModal: true })}
                        style={{float: "right"}}
                      >
                        {this.state.loading ? (
                          <CircularProgress
                            size={25}
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          <span>
                            {this.props.lng === true ? (
                              "Remove"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-10">
                                ඉවත් කරන්න
                              </span>
                            )}
                          </span>
                        )}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <p className="sec-heading" style={{ marginBottom: 5 }}>
                      {this.props.lng ? (
                        "Ad Removed"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දැන්වීම ඉවත් කර ඇත
                        </span>
                      )}
                    </p>
                    <p className="heading-desc" style={{ marginBottom: 15 }}>
                      {this.props.lng ? (
                        `Your ad has been removed from the website. Please contact ${Config.SUPPORT_EMAIL} if you need to publish it again.`
                      ) : (
                        <span className="sinhala-size-11">
                          ඔබගේ දැන්වීම සම්පූර්ණයෙන්ම ඉවත් කර ඇත. නැවත පල කිරීමට
                          අවශ්‍යනම් {Config.SUPPORT_EMAIL} හරහා දැනුම් දෙන්න
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
              key={this.state.vertical + this.state.horizontal}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Your ad has been unpublished successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    ඔබේ දැන්වීම සාර්ථකව ඉවත් කරන ලදී.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
              key={this.state.vertical + this.state.horizontal}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    ඔබේ දැන්වීම ඉවත් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Dialog
              open={this.state.disableModal}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
                {this.props.lng === true ? (
                  `Remove my ad from the ${Config.DOMAIN_DISPLAY_NAME} website`
                ) : (
                  <span className="sinhala-size-14 sinhala-w-600">
                    මගේ දැන්වීම සම්පූර්ණයෙන්ම ඉවත් කරන්න
                  </span>
                )}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: 15 }}
                >
                  {this.props.lng === true ? (
                    <span>
                      Are you sure you want to{" "}
                      <b>unpublish your ad? This action cannot be undone.</b>{" "}
                      You could consider removing your ad from public search
                      instead.
                    </span>
                  ) : (
                    <span className="sinhala-size-12">
                      ඔබගේ ගිණුම ස්ථිරවම ඉවත් කිරීමට ඔබට අවශ්‍යද? මෙම ක්‍රියාව
                      ආපසු හැරවිය නොහැක. ඒ වෙනුවට ඔබගේ ගිණුම පොදු සෙවුමෙන් ඉවත්
                      කිරීම ගැන සලකා බැලිය හැකිය.
                    </span>
                  )}
                </DialogContentText>

                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: 15 }}
                >
                  {this.props.lng === true ? (
                    <span>
                      Let us know why you want to unpublish your ad. This
                      information will only be used to improve the user
                      experience in future.
                    </span>
                  ) : (
                    <span className="sinhala-size-12">
                      ඔබගේ ගිණුම ඉවත් කිරීමට ඔබට අවශ්‍ය ඇයිදැයි අපට දන්වන්න. මෙම
                      තොරතුරු භාවිතා කරනුයේ අනාගතයේ පරිශීලක අත්දැකීම් වැඩිදියුණු
                      කිරීමට පමණි.
                    </span>
                  )}
                </DialogContentText>

                <List component="nav" aria-label="secondary mailbox folders">
                  <RadioGroup
                    className="filter-radio why-dissable-radio-group"
                    aria-label="reasons"
                    value={this.state.whyDissable}
                    onChange={this.handleDissableOptions}
                  >
                    {this.state.reasons.map((reason, i) => (
                      <FormControlLabel
                        key={reason.id}
                        value={reason.reason}
                        control={<Radio size="small" />}
                        label={
                          this.props.lng === true
                            ? reason.reason
                            : reason.reason_si
                        }
                        className={
                          this.props.lng !== true
                            ? "sinhala-w-600  sinhala-size-12"
                            : ""
                        }
                      />
                    ))}
                  </RadioGroup>
                  {this.state.error ? (
                    <p
                      style={{
                        color: "#cc2a2a",
                        fontSize: 14,
                        marginTop: 3,
                        marginLeft: 45,
                      }}
                    >
                      {this.props.lng === true ? (
                        "Please select one of above."
                      ) : (
                        <span className="sinhala-size-14">
                          කරුණාකර ඉහත එකක් තෝරන්න.
                        </span>
                      )}
                    </p>
                  ) : null}
                </List>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ textTransform: "capitalize" }}
                >
                  {this.props.lng === true ? "Cancel" : "අවලංගු කරන්න"}
                </Button>
                {this.state.isDissable ? (
                  <Button
                    style={{
                      background: "#e4e4e4",
                      color: "#5f5f5f",
                      textTransform: "capitalize",
                    }}
                    disabled
                  >
                    {this.props.lng === true ? (
                      "Remove My Ad"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        දැන්වීම් ඉවත් කරන්න
                      </span>
                    )}
                  </Button>
                ) : (
                  <Button
                    onClick={this.handleUnplublish}
                    style={{
                      background: "#bd2727",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                    autoFocus
                  >
                    {this.state.loading ? (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    ) : this.props.lng === true ? (
                      "Remove My Ad"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        දැන්වීම් ඉවත් කරන්න
                      </span>
                    )}
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
