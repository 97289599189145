import { FETCH_MATCHED_POSTS, PAGE_VIEW_INITIALIZED } from "./types";
import axios from "axios";

const PAGE_SIZE = 25;

export const getMatchedPosts = (page=0) => (dispatch) => { 
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
        "postId"
      )}/interests/matches?pageNum=${page}&pageSize=${PAGE_SIZE}&type=matched`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      const allMatches = res.data.results;
      const page = res.data.pageNum;
      
      dispatch({
        type: FETCH_MATCHED_POSTS,
        payload: {
          matched: allMatches,
          page: page,
          pageSize: PAGE_SIZE,
        },
      });
    });
};

export const setInitializedView = (value) => (dispatch) => {
  dispatch({
    type: PAGE_VIEW_INITIALIZED,
    payload: value
  })
}
