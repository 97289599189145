import React, { Component } from "react";
import axios from "axios";
// import { CircularProgress } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import moment from "moment";
import Config from "../v2/config";
import "../styles/manage-posts.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Settings extends Component {
  state = {
    isAccessDenied: false,
    userId: "",
    name: "",
    postStatus: "",
    isOffline: false,
    idVerified: false,
    isFeatured: false,
    isPhotosUploaded: false,
    isSiblingsData: false,
    isSetInterestPreferences: false,
    posts: [],
    selectedPostId: "",
    memberId: "",
    operatorId: "",
  };

  componentDidMount = () => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getOperatorAccount();
    this.setState({
      isAccessDenied: localStorage.getItem("auth_token") ? false : true,
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : "",
      name: localStorage.getItem("post_details")
        ? JSON.parse(localStorage.getItem("post_details")).personalInfo.fname +
          " " +
          JSON.parse(localStorage.getItem("post_details")).personalInfo.lname
        : "",
      postStatus: localStorage.getItem("post_details")
        ? JSON.parse(localStorage.getItem("post_details")).status
        : "INCOMPLETE",
      selectedPostId: localStorage.getItem("postId")
        ? localStorage.getItem("postId")
        : "",
    });
    if (
      localStorage.getItem("auth_token") &&
      (localStorage.getItem("user_role") === "OPERATOR" ||
        localStorage.getItem("user_role") === "OPERATOR_L2")
    ) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/operator/managed_posts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          this.setState({ posts: res.data });
          if (res.data.length > 0 && !this.state.selectedPostId) {
            localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            localStorage.setItem("postId", res.data[0].id);
            this.setState({ selectedPostId: res.data[0].id });
            this.getOwner(res.data[0].id);
          }
        })
        .catch((err) => console.log(err));
    } else {
      this.props.history.push("/");
    }
  };

  getOperatorAccount = () => {
    if (localStorage.getItem("auth_token")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          this.setState({ operatorId: res.data.memberId });
          localStorage.setItem("operator_id", res.data.memberId);
        })
        .catch((err) => console.log(err));
    }
  };

  getOwner = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator/managed_posts/${id}/owner`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("user_name", res.data.name);
        localStorage.setItem("userId", res.data.memberId);
        this.setState({ userId: res.data.memberId });
        localStorage.setItem(
          "user_email",
          res.data.email ? res.data.email : ""
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  switchProfile = (post) => {
    this.getOwner(post.id);
    localStorage.setItem("post_details", JSON.stringify(post));
    localStorage.setItem("postId", post.id);
    this.setState({ selectedPostId: post.id });
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : (
      <React.Fragment>
        <PageHead title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} navBarId={this.state.userId} operatorId={this.state.operatorId}/> */}
        <div
          className="ad-form-sec"
          style={{
            maxWidth: "fit-content",
            minHeight: "60vh",
            margin: "auto",
            marginTop: "5vh",
          }}
        >
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0 manage-posts-table"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr className="table-header">
                  <th width="150" className="border-0">
                    Member ID
                  </th>
                  <th width="100" className="border-0">
                    Post ID
                  </th>
                  <th width="125" className="border-0">
                    Name
                  </th>
                  <th width="125" className="border-0">
                    Gender
                  </th>
                  <th width="150" className="border-0">
                    Phone
                  </th>
                  <th width="200" className="border-0">
                    Published
                  </th>
                  <th width="100" className="border-0">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.posts.length < 10 ? (
                  this.state.posts.map((el, i) => (
                    <tr
                      key={i}
                      onClick={() => this.switchProfile(el)}
                      style={{ cursor: "pointer" }}
                      className={
                        el.id === this.state.selectedPostId
                          ? "selected"
                          : "not-selected"
                      }
                    >
                      <td width="150">{el.memberId}</td>
                      <td width="100">{el.id}</td>
                      <td width="125">
                        {el.personalInfo.fname} {el.personalInfo.lname}
                      </td>
                      <td width="125">{el.personalInfo.gender}</td>
                      <td width="150">{el.phone ? el.phone : "-"}</td>
                      <td width="200">
                        {moment(el.publishedAt).format("DD-MM-YYYY")}
                      </td>
                      <td width="100">{el.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Settings);
