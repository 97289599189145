import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";
import User from "../../../images/user.png";

import { removePost } from "../../../actions/removePost";
import { fetchRestrictedUnmatch } from "../../../actions/fetchRestrictedUnmatch";
import { fetchIntPostData } from "../../../actions/fetchIntPostData";
import { fetchAllInterest } from "../../../actions/fetchAllInterest";
import { getNewMatches } from "../../../actions/getNewMatches";
import { getMatchedPosts } from "../../../actions/getMatchedPosts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const UnseenMatches = (props) => {
  const [avatar, setAvatar] = useState(User);
  const history = useHistory();

  useEffect(() => {
    if (props.post.avatar) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
            props.post.id
          }/images/${props.post.avatar}?thumbnail=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);
          setAvatar(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }


  }, []);

  const markAsSeen = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem("postId")}/matches/${props.post.id}/seen
      `,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        props.getNewMatches();
        props.getMatchedPosts();
        history.push(`/messages?id=${props.post.id}`)
      })
      .catch((err) => {
        console.log(err);
        props.getNewMatches();
        props.getMatchedPosts();
        history.push(`/messages?id=${props.post.id}`)
      });
  }

  return (
    <div
      data-testid="counterA"
      className="new-matches-notifi"
      onClick={markAsSeen}
    >
      <img
        src={
          props.post?.avatar
            ? avatar
            : props.post?.post?.personalInfo?.gender === "MALE"
            ? Male
            : Female
        }
        alt="profile pic"
      />
      <p>{props.post?.post?.personalInfo?.displayName}</p>
    </div>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostData,
      fetchAllInterest,
      removePost,
      fetchRestrictedUnmatch,
      getNewMatches,
      getMatchedPosts,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    intId: state.matches.post.id,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(UnseenMatches);
