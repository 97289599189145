import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";

import Footer from "../../../components/common/Footer";

// import "../styles/post-details.css";
// import "../styles/ad-details.css";
import "../../../styles/post-details.css";
import "../../../styles/ad-details.css";
import FooterMobile from "../../../components/common/FooterMobile";

import PageNotFound from "../../../pages/PageNotFound";
import UnauthorizedUser from "../../../pages/IncorrectToken";
import RestrictedAccount from "../../../pages/RestrictedProfile";
import Outage from "../../../pages/Outage";
import NotLoggedBanner from "../../../components/banners/NotLoggedBanner";
import GoogleAds from "../../../components/common/GoogleAds";
import GoogleDisplayAds from "../../../components/common/GoogleDisplayAds";
// import Navbar from "../../../components/common/Navbar";
import PageHead from "../../../components/common/PageHead";
import CancelRequestModal from "../../../components/modals/CancelRequestModal";
import NeedToCreateAnAccount from "../../../components/modals/NeedToCreateAnAccount";
// import Alerts from "../../../components/post-details/Alerts";
import Connect from "../../../components/post-details/Connect";
// import Header from "../../../components/post-details/Header";
// import Horoscope from "../../../components/post-details/Horoscope";
// import Parent from "../../../components/post-details/Parent";
// import Personal from "../../../components/post-details/Personal";
import TimeLine from "../../../components/post-details/TimeLine";
import Unavailable from "../../../pages/DisabledAcc.jsx";
import { getBaseUrlBasedOnCountry } from "../../../methods/getBaseUrlBasedOnCountry.js";
import PersonalInformation from "../../templates/profile-view/information-section/personal-information/index.jsx";
import FamilyInformation from "../../templates/profile-view/information-section/family-information/index.jsx";
import HoroscopeInformation from "../../templates/profile-view/information-section/horoscope-information/index.jsx";
import HeaderSection from "../../templates/profile-view/header-section/index.jsx";
import StatusSection from "../../templates/profile-view/status-section/index.jsx";
import Config from "../../../v2/config";
import withLanguageMigrator from "../../hoc/language-migrator/index.jsx";

class Post extends Component {
  state = {
    postDetails: {},
    interestData: {},
    offlineId: "",
    // pages
    applyMatch: false,
    applyInterest: false,
    applyNormalPost: false,

    isInterestInfo: false,
    interestInfo: {},

    // personal
    postId: "",
    isOffline: true,
    publishedAt: "",
    isExpired: false,
    gender: "",
    fName: "",
    lname: "",
    lnameMasked: "",
    age: "",
    residentCity: "",
    height: "",
    profession: "",
    profession_si: "",
    displayName: "",
    ignorePreferences: false,

    // private
    phone: "",
    showPhone: false,
    phoneVisibilityModal: false,
    email: "",
    showPhotos: false,
    receivedInterestImages: [],
    isIgnored: false,
    autoIgnored: false,
    receivedTime: "",
    receivedTo: "",
    acceptTime: "",
    acceptBy: "",
    unmatchedTime: "",
    unmatchedBy: "",

    likeMessage: "",
    matchMessage: "",
    sentTime: "",
    cancelSentTime: "",
    cancelAcceptTime: "",
    isHideConnect: false,

    undoAutoRejectTime: "",
    undoAutoRejectedBy: "",

    // page loading
    preLoading: true,
    btnLoading: false,

    // alert modal
    alertModal: false,
    alertHeading: "",
    alertMessage: "",
    cancelRequest: false,
    ignoreModal: false,
    ignoreReason: "",
    ignoredBy: "",

    // show interest msg modal
    intMessageModal: false,
    messageModal: false,
    sendingPostId: "",
    sensitiveDataError: "",

    // preference mismatch modal
    preferenceMismatchModal: false,
    preferenceMismatchMessage: "",
    preferenceMismatchMessageSinhala: "",

    // need to create an account
    needToCreateAnAccount: false,

    // report modal
    matchPostId: "",
    reportedPosts: [],
    reasons: [],
    reportModal: false,
    isDissable: true,
    error: false,
    whyReport: "",
    reasonDesc: "",

    // redirects
    RedirectToMatches: false,
    redirectToUnavailabele: false,
    redirectToPageNotFound: false,
    redirectToUnauthorizedUser: false,
    redirectToRestrictedAccount: false,
    outagePage: false,

    // loading
    loadingIgnore: false,
    loading: false,

    intId: "",

    // showIntTooltip
    showIntTooltip: true,
  };

  componentDidMount = () => {
    // lng
    this.setState({
      lng: localStorage.getItem("lng")
        ? localStorage.getItem("lng") === "true"
          ? true
          : false
        : true,
    });

    this.getPostInfo();

    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getPostInfo = () => {
    if (
      localStorage.getItem("postId") &&
      localStorage.getItem("post_details")
    ) {
      var header = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      };
    } else {
      var header = {};
    }

    const id = document.location.href.split("=");

    axios
      .get(
        `${
          getBaseUrlBasedOnCountry()
            ? getBaseUrlBasedOnCountry()
            : process.env.REACT_APP_API_URL
        }/v1/posts/${id[1]}${
          localStorage.getItem("postId")
            ? localStorage.getItem("post_details")
              ? `?context=${localStorage.getItem("postId")}`
              : ""
            : ""
        }
        `,
        header
      )
      .then((res) => {
        this.setState({
          offlineId: res.data.offlineId ? res.data.offlineId : "",
        });

        this.setState({ isInterestInfo: res.data.interestInfo ? true : false });

        if (res.data.interestInfo) {
          this.setState({
            interestInfo: res.data.interestInfo,
          });

          if (res.data.interestInfo.matched) {
            this.setState({
              applyMatch: true,
              applyInterest: false,
              applyNormalPost: false,
            });
            this.getMatchedData(res);
          } else {
            this.setState({
              applyInterest: true,
              applyMatch: false,
              applyNormalPost: false,
            });
            this.getInterestedData(res);
          }
        } else {
          this.setState({
            applyNormalPost: true,
            applyMatch: false,
            applyInterest: false,
          });
          this.getNonInterestedData(res);
        }
      })
      .catch((err) => {
        console.log(err);
        // if (err.message == "Network Error" || err.response.status === 501 || err.response.status === 502 || err.response.status === 503 || err.response.status === 520) {
        if (err.message && err.message === "Network Error") {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 501
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 502
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 503
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 520
        ) {
          this.setState({ outagePage: true });
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.code === 1001
        ) {
          // this.props.history.push("/404");
          this.setState({ redirectToPageNotFound: true });
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.code === 1006
        ) {
          // this.props.history.push("/unavailable-profile");
          this.setState({ redirectToUnavailabele: true });
        }
      });
  };

  getInterestedData = (res) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          res.data.interestInfo.interestId
        }
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          intId: res.data.id,
          postId: res.data.post.id,
          isOffline: res.data.isOffline,
          publishedAt: res.data.likedAt
            ? res.data.likedAt
            : res.data.post.publishedAt,
          gender: res.data.post.personalInfo.gender,

          fName: res.data.post.personalInfo.fname,
          displayName: res.data.post.personalInfo.displayName,
          lnameMasked: res.data.post.personalInfo.lnameMasked,
          residentCity: res.data.post.personalInfo.residentCity,

          age: res.data.post.personalInfo.age,

          profession:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name,
          profession_si:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name_si,

          height: res.data.post.personalInfo.height,

          likeMessage: res.data.likeSeen === false ? "" : res.data.likeMessage,
          matchMessage:
            res.data.likeSeen === false ? res.data.matchMessage : "",

          showInt: res.data.likeSeen !== undefined ? false : true,
          showAccept: res.data.likeSeen !== undefined ? true : false,
          refuseReason: res.data.refuseReason ? res.data.refuseReason : "",
          phone: res.data.post.phone,
          isIgnored: res.data.ignored,
          autoIgnored: res.data.autoIgnored,
          likedAt: res.data.likedAt,
          isHideConnect:
            res.data.timeline &&
            res.data.timeline.filter(
              (el) =>
                (el.eventType === "LIKED" && el.actionBy === "SELF") ||
                el.eventType === "LIKE_REFUSED"
            ).length > 0
              ? true
              : false,
          preLoading: false,
          showPhotos: res.data.showPhotos,
          receivedInterestImages:
            res.data.showPhotos && res.data.images ? res.data.images : [],
          postDetails: res.data?.post,
          interestData: res.data,
        });

        if (res.data.timeline.length > 0) {
          const sentTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKED"
          );

          if (sentTime.length > 0) {
            this.setState({ sentTime: sentTime[0].eventTime });
          }

          const cancelSentTime = res.data.timeline.filter(
            (filter) => filter.eventType === "UNLIKED"
          );

          if (cancelSentTime.length > 0) {
            this.setState({
              cancelSentTime: cancelSentTime[0].eventTime,
            });
          }

          const receivedTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKED"
          );

          if (receivedTime.length > 0) {
            if (receivedTime[0].actionBy === "OTHER_PARTY") {
              this.setState({
                receivedTime: receivedTime[0].eventTime,
                receivedTo: receivedTime[0].actionBy,
              });
            }
          }

          const cancelAcceptTime = res.data.timeline.filter(
            (filter) => filter.eventType === "LIKE_REFUSED"
          );

          if (cancelAcceptTime.length > 0) {
            this.setState({
              cancelAcceptTime: cancelAcceptTime[0].eventTime,
              ignoredBy: cancelAcceptTime[0].actionBy,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1006) {
          this.setState({ redirectToUnavailabele: true });
        } else if (err.response.data.code === 1004) {
          // this.props.history.push("/unauthorized-user");
          this.setState({ redirectToUnauthorizedUser: true });
        }
      });
  };

  getNonInterestedData = (res) => {
    // const father = res.data.parentInfo.filter(
    //   (filter) => filter.type === "FATHER"
    // );

    // const mother = res.data.parentInfo.filter(
    //   (filter) => filter.type === "MOTHER"
    // );

    this.setState({
      postId: res.data.id,
      isOffline: res.data.isOffline,
      publishedAt: res.data.publishedAt,
      isExpired: res.data.isExpired,
      gender: res.data.personalInfo.gender,

      fName: res.data.personalInfo.fname,
      displayName: res.data.personalInfo.displayName,
      lnameMasked: res.data.personalInfo.lnameMasked,
      age: res.data.personalInfo.age,
      residentCity: res.data.personalInfo.residentCity,
      height: res.data.personalInfo.height,

      profession:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name,
      profession_si:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name_si,

      liked: res.data.liked ? res.data.liked : "",
      matched: res.data.matched ? res.data.matched : "",
      phone: res.data.phone,
      preLoading: false,
      postDetails: res.data,
    });
  };

  getMatchedData = (result) => {
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        if (res.data.length > 0) {
          this.setState({
            reportedPosts: res.data[0].reportedPosts
              ? res.data[0].reportedPosts
              : [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // report reasons
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/report_reasons`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          reasons: res.data,
        });
      });

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/matches/${
          result.data.interestInfo.interestId
        }
      `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          intId: res.data.id,
          postId: res.data.id,
          isOffline: res.data.isOffline,
          isExpired: res.data.post.isExpired,
          matchPostId: res.data.post.id,
          matchedAt: res.data.matchedAt,
          gender: res.data.post.personalInfo.gender,
          fName: res.data.post.personalInfo.fname,
          displayName: res.data.post.personalInfo.displayName,
          lnameMasked: res.data.post.personalInfo.lnameMasked,
          lname: res.data.post.personalInfo.lname,
          residentCity: res.data.post.personalInfo.residentCity,
          age: res.data.post.personalInfo.age,
          profession:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name,
          profession_si:
            res.data.post.personalInfo.profession.name === "Other"
              ? res.data.post.personalInfo.professionOther
              : res.data.post.personalInfo.profession.name_si,

          height: res.data.post.personalInfo.height,

          likeMessage: res.data.likeMessage,
          matchMessage: res.data.matchMessage,
          phone: res.data.post.phone,
          showPhone: res.data.showPhone,
          email: res.data.post.email,

          showInt: res.data.timeline
            ? res.data.timeline.filter((filter) => filter.eventType === "LIKED")
                .length > 0
              ? res.data.timeline.filter(
                  (filter) => filter.eventType === "LIKED"
                )[0].actionBy === "SELF"
                ? true
                : false
              : false
            : false,
          isIgnored: res.data.ignored,
          postDetails: res.data?.post,
          interestData: res.data,
        });

        this.setState({
          preLoading: false,
        });

        // if (res.data.timeline.length > 0) {
        const sentTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (sentTime.length > 0) {
          if (sentTime[0].actionBy === "SELF") {
            this.setState({ sentTime: sentTime[0].eventTime });
          }
        }

        const receivedTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (receivedTime.length > 0) {
          if (receivedTime[0].actionBy === "OTHER_PARTY") {
            this.setState({
              receivedTime: receivedTime[0].eventTime,
              receivedTo: receivedTime[0].actionBy,
            });
          }
        }

        const acceptTime = res.data.timeline.filter(
          (filter) => filter.eventType === "MATCHED"
        );

        if (acceptTime.length > 0) {
          this.setState({
            acceptTime: acceptTime[0].eventTime,
            acceptBy: acceptTime[0].actionBy,
          });
        } else {
          this.setState({
            acceptTime: res.data.matchedAt ? res.data.matchedAt : "",
          });
        }

        const unmatchedTime = res.data.timeline.filter(
          (filter) => filter.eventType === "UNMATCHED"
        );

        if (unmatchedTime.length > 0) {
          this.setState({
            unmatchedTime: unmatchedTime[0].eventTime,
            unmatchedBy: unmatchedTime[0].actionBy,
          });
        }
        // }

        const cancelAcceptTime = res.data.timeline.filter(
          (filter) => filter.eventType === "LIKE_REFUSED"
        );

        if (cancelAcceptTime.length > 0) {
          this.setState({
            cancelAcceptTime: cancelAcceptTime[0].eventTime,
            ignoredBy: cancelAcceptTime[0].actionBy,
          });
        }

        const undoAutoRejectTime = res.data.timeline.filter(
          (filter) => filter.eventType === "UNDO_AUTO_REJECT"
        );

        if (undoAutoRejectTime.length > 0) {
          this.setState({
            undoAutoRejectTime: undoAutoRejectTime[0].eventTime,
            undoAutoRejectedBy: undoAutoRejectTime[0].actionBy,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.message === "Network Error" ||
          err.response.status === 501 ||
          err.response.status === 502 ||
          err.response.status === 503 ||
          err.response.status === 520
        ) {
          this.setState({ outagePage: true });
        } else if (err.response.data.code === 1006) {
          this.setState({ redirectToUnavailabele: true });
        } else if (err.response.data.code === 1004) {
          // this.props.history.push("/unauthorized-user");
          this.setState({ redirectToUnauthorizedUser: true });
        } else if (err.response.data.code === 1514) {
          // this.props.history.push("/restricted");
          this.setState({ redirectToRestrictedAccount: true });
        }
      });
  };

  handleMessageModal = (gender) => {
    this.setState({ showIntTooltip: false, btnLoading: true });
    if (localStorage.getItem("auth_token")) {
      if (localStorage.getItem("post_details")) {
        if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "IN_REVIEW"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Ad Under Review"
              : "සමාලෝචනය යටතේ දැන්වීම පවති",
            alertMessage: this.props.lng
              ? "Our team is reviewing your ad at the moment. You will be able to show interest as soon as the ad goes live."
              : "අපගේ කණ්ඩායම මේ මොහොතේ ඔබගේ දැන්වීම සමාලෝචනය කරමින් සිටී. දැන්වීම සජීවීව ගිය විගස ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "PAYMENT_PENDING"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Complete Your Ad"
              : "ඔබේ දැන්වීම සම්පූර්ණ කරන්න",
            alertMessage: this.props.lng
              ? "Please make the payment to complete your ad. You will be able to show interest as soon as the ad goes live."
              : "කරුණාකර ඔබේ දැන්වීම සම්පුර්ණ කිරීම සඳහා ගෙවීමක් කරන්න. දැන්වීම සජීවීව ගිය වහාම ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Ad Expired"
              : "දැන්වීම කල් ඉකුත් වි ඇත",
            alertMessage: this.props.lng
              ? "Your ad is expired. Please renew your add to respond."
              : "දැන්වීම කල් ඉකුත් වී ඇත. ප්‍රතිචාර දැක්වීම සඳහා දැන්වීම අලුත් කරන්න.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "DISABLED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng ? "Ad Disabled" : "දැන්වීම අක්‍රීයයි",
            alertMessage: this.props.lng
              ? "You have disabled your ad. Please contact support to get your ad account re-activated."
              : "ඔබ ඔබේ දැන්වීම අක්‍රීය කර ඇත. ඔබේ දැන්වීම නැවත සක්‍රිය කිරීමට කරුණාකර අපව අමතන්න.",
          });
        } else {
          if (
            JSON.parse(localStorage.getItem("post_details")).personalInfo
              .gender === gender
          ) {
            this.setState({
              alertModal: true,
              alertHeading: this.props.lng
                ? "Response not accepted"
                : "ප්‍රතිචාරය බාර නොගනී",
              alertMessage: this.props.lng
                ? `This profile only accepts responses from ${
                    gender === "MALE" ? "bride" : "groom"
                  } profiles.`
                : `මෙම දැන්වීම පිළිගන්නේ ${
                    gender === "MALE" ? "මනාලියන්" : "මනාලයන්"
                  }ගේ ගිණුම් වලින් ලැබෙන ප්‍රතිචාර පමණි.`,
            });
          } else {
            this.interestValidate();
          }
        }
      } else {
        this.setState({
          alertModal: true,
          alertHeading: this.props.lng
            ? "Complete your ad"
            : "දැන්වීම සම්පූර්ණ කරන්න",
          alertMessage: this.props.lng
            ? "Please complete your ad to send/receive interests."
            : "ප්‍රතිචාර දැක්වීම සදහා ඔබගේ දැන්වීම සම්පූර්ණ කල යුතුය.",
        });
      }
    } else {
      this.setState({ needToCreateAnAccount: true });
    }
    // this.setState({btnLoading: false})
  };

  interestValidate = () => {
    const data = `likedPostId=${this.state.postId}`;

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/validate_interest`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ intMessageModal: true });
      })
      .catch((err) => {
        // this.setState({
        //   preferenceMismatchModal: true,
        // });

        if (err.response.data.code === 1800) {
          this.setState({
            preferenceMismatchMessage:
              "Your age does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              " ඔබේ වයස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (err.response.data.code === 1805) {
          this.setState({
            preferenceMismatchMessage:
              "Your country of residence does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ පදිංචි රට මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (
          err.response.data.code === 1802 ||
          err.response.data.code === 1803 ||
          err.response.data.code === 1804
        ) {
          this.setState({
            preferenceMismatchMessage:
              "Your civil status, ethnicity, religion or height does not match the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ සිවිල් තත්වය, ජාතිය, ආගම හෝ උස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        }
        this.showInterestOnMismatchedProfile();
      });
  };

  handleShowInterest = (id, message, showPhone, showPhotos = null) => {
    if (localStorage.getItem("auth_token")) {
      const data = `likedPostId=${id}&message=${message}&showPhone=${showPhone}&showPhoto=${showPhotos}&ignorePreferences=${this.state.ignorePreferences}`;
      this.setState({ loading: true });

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/show_interest`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            showAlert: true,
            messageModal: false,
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.code === 1801) {
            this.setState({
              sensitiveDataError: this.props.lng
                ? "Your message contains sensitive personal information such as email addresses, phone numbers or web URLs. Please remove them and retry."
                : "ඔබගේ පණිවිඩයේ විද්‍යුත් ලිපින, දුරකථන අංක හෝ වෙබ් URL වැනි සංවේදී පුද්ගලික තොරතුරු අඩංගු වේ. කරුණාකර ඒවා ඉවත් කර නැවත උත්සාහ කරන්න.",
            });
          }
          this.setState({ loading: false });
        });
    }
  };

  handleAcceptMessageModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.props.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.props.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({
        messageModal: true,
        sendingPostId: this.state.postId,
      });
    }
  };

  handleCancelReqModal = () => {
    this.setState({ cancelRequest: true });
  };

  handleIgnoreReason = (e) => {
    this.setState({ ignoreReason: e.target.value });
  };

  handleIgnoreAllInt = (type) => {
    this.setState({ loadingIgnore: true });
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }?${type === "unmatch" ? "unmatchReasonId" : "rejectReason"}=${
          this.state.ignoreReason
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loadingIgnore: false,
          cancelRequest: false,
          ignoreModal: false,
        });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingIgnore: false });
      });
  };

  handleIgnoreModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.props.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.props.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({ ignoreModal: true });
    }
  };

  handleUnmatchModal = () => {
    this.setState({ unmatchModal: true });
  };

  handleModalClose = () => {
    this.setState({
      alertModal: false,
      messageModal: false,
      intMessageModal: false,
      cancelRequest: false,
      ignoreModal: false,
      unmatchModal: false,
      btnLoading: false,
    });
  };

  handleReport = () => {
    this.setState({ reportModal: true });
  };

  handleReportOptions = (e) => {
    this.setState({ whyReport: e.target.value, isDissable: false });
  };

  handleChange = (e) => {
    this.setState({ reasonDesc: e.target.value });
  };

  // report Ad
  handleReportAd = () => {
    if (this.state.whyReport !== "") {
      this.setState({
        loading: true,
      });

      const reasonId = this.state.reasons.filter(
        (filter) => filter.reason === this.state.whyReport
      );

      const data = `reportedInterest=${this.state.postId}&reasonId=${reasonId[0].id}&reasonOther=${this.state.reasonDesc}`;

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/report_post`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: false,
            isDissable: true,
            reportModal: false,
            successAlert: true,
            whyReport: "",
            reasonDesc: "",
          });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    } else {
      this.setState({ error: true });
    }
  };

  handleReportClose = () => {
    this.setState({
      reportModal: false,
      isDissable: true,
      failAlert: false,
      successAlert: false,
      whyReport: "",
      reasonDesc: "",
    });
  };

  freeRenew = () => {
    this.setState({ loading: true });
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/free_renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((res) => {
            if (
              localStorage.getItem("user_role") === "OPERATOR" ||
              localStorage.getItem("user_role") === "OPERATOR_L2"
            ) {
              res.data[0] = res.data;
            }
            if (res.data.length > 0) {
              localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            }

            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handlePhoneVisibilityModal = () => {
    this.setState({ phoneVisibilityModal: true });
  };

  handleGivePhoneVisibility = (e) => {
    e.preventDefault();

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }/phone_visibility`,
        "showPhone=true",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ phoneVisibilityModal: false });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  phoneVisibilityModalClose = () => {
    this.setState({ phoneVisibilityModal: false });
  };

  handlePreferenceMismatchModalClose = () => {
    this.setState({ preferenceMismatchModal: false, btnLoading: false });
  };

  needToCreateAnAccountClose = () => {
    this.setState({ needToCreateAnAccount: false, btnLoading: false });
  };

  showInterestOnMismatchedProfile = () => {
    this.setState({
      preferenceMismatchModal: false,
      btnLoading: false,
      intMessageModal: true,
      ignorePreferences: true,
    });
  };

  render() {
    if (this.state.redirectToUnavailabele) {
      return (
        <Unavailable title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
      );
    } else if (this.state.redirectToPageNotFound) {
      return (
        <PageNotFound title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
      );
    } else if (this.state.redirectToUnauthorizedUser) {
      return (
        <UnauthorizedUser
          title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
      );
    } else if (this.state.redirectToRestrictedAccount) {
      return (
        <RestrictedAccount
          title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
      );
    } else if (this.state.outagePage) {
      return <Outage title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else {
      return (
        <React.Fragment>
          <PageHead
            title={this.state.displayName}
            name={` | ${Config.DOMAIN_DISPLAY_NAME}`}
          />
          {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
          <div style={{ background: "#dfdfdf" }} className="post-det-div">
            <div style={{ maxWidth: "820px", margin: "auto" }}>
              <GoogleDisplayAds slot="9743846933" />
            </div>
            <Grid
              container
              xs={12}
              sm={10}
              md={8}
              className="cus-inner-page cus-container-post"
            >
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Button
                  variant="contained"
                  className="back-btn-in-detail-pages "
                  onClick={() => this.props.history.goBack()}
                >
                  <i className="fas fa-angle-left"></i> &nbsp;
                  {this.props.lng ? "Back" : "ආපසු යන්න"}
                </Button>
              )}
              <NotLoggedBanner lng={this.props.lng} />

              {/* Refactored sections */}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Grid
                  container
                  direction="column"
                  style={{ rowGap: 20, marginBottom: 20 }}
                >
                  <HeaderSection
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                  <StatusSection
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                </Grid>
              )}

              {!this.state.isHideConnect && (
                <Connect
                  applyMatch={this.state.applyMatch}
                  lng={this.props.lng}
                  preLoading={this.state.preLoading}
                  phone={this.state.phone}
                  isExpired={this.state.isExpired}
                  showPhone={this.state.showPhone}
                  offlineId={this.state.offlineId}
                  email={this.state.email}
                  phoneVisibilityModal={this.state.phoneVisibilityModal}
                  handlePhoneVisibilityModal={this.handlePhoneVisibilityModal}
                  handleGivePhoneVisibility={this.handleGivePhoneVisibility}
                  phoneVisibilityModalClose={this.phoneVisibilityModalClose}
                  isMyProfile={false}
                  fName={this.state.fName}
                  lnameMasked={this.state.lnameMasked}
                  gender={this.state.gender}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  cancelSentTime={this.state.cancelSentTime}
                  unmatchedTime={this.state.unmatchedTime}
                  applyInterest={this.state.applyInterest}
                  applyNormalPost={this.state.applyNormalPost}
                  isInterestInfo={this.state.isInterestInfo}
                  alertModal={this.state.alertModal}
                  alertHeading={this.state.alertHeading}
                  alertMessage={this.state.alertMessage}
                  preferenceMismatchModal={this.state.preferenceMismatchModal}
                  showInterestOnMismatchedProfile={
                    this.showInterestOnMismatchedProfile
                  }
                  preferenceMismatchMessage={
                    this.state.preferenceMismatchMessage
                  }
                  handlePreferenceMismatchModalClose={
                    this.handlePreferenceMismatchModalClose
                  }
                  handleMessageModal={this.handleMessageModal}
                  handleModalClose={this.handleModalClose}
                  postId={this.state.postId}
                  matchPostId={this.state.matchPostId}
                  messageModal={this.state.messageModal}
                  intMessageModal={this.state.intMessageModal}
                  handleShowInterest={this.handleShowInterest}
                  showAccept={this.state.showAccept}
                  isIgnored={this.state.isIgnored}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  handleIgnoreModal={this.handleIgnoreModal}
                  ignoreModal={this.state.ignoreModal}
                  ignoreReason={this.state.ignoreReason}
                  handleIgnoreReason={this.handleIgnoreReason}
                  loadingIgnore={this.state.loadingIgnore}
                  handleAcceptMessageModal={this.handleAcceptMessageModal}
                  sendingPostId={this.state.sendingPostId}
                  commingMsg={this.state.matchMessage}
                  sensitiveDataError={this.state.sensitiveDataError}
                  loading={this.state.loading}
                  showIntTooltip={this.state.showIntTooltip}
                  displayName={this.state.displayName}
                  btnLoading={this.state.btnLoading}
                  likeMessage={this.state.likeMessage}
                  showPhotos={this.state.showPhotos}
                  receivedInterestImages={this.state.receivedInterestImages}
                  intId={this.state.intId}
                  height={this.state.height}
                  age={this.state.age}
                  profession={this.state.profession}
                  profession_si={this.state.profession_si}
                  residentCity={this.state.residentCity}
                  preferenceMismatchMessageSinhala={
                    this.state.preferenceMismatchMessageSinhala
                  }
                />
              )}

              {this.state.isInterestInfo && !this.state.messageModal && (
                <TimeLine
                  lng={this.props.lng}
                  fName={this.state.fName}
                  preLoading={this.state.preLoading}
                  likeMessage={this.state.likeMessage}
                  matchMessage={this.state.matchMessage}
                  showInt={this.state.showInt}
                  isIgnored={this.state.isIgnored}
                  autoIgnored={this.state.autoIgnored}
                  refuseReason={this.state.refuseReason}
                  likedAt={this.state.likedAt}
                  cancelSentTime={this.state.cancelSentTime}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  ignoredBy={this.state.ignoredBy}
                  unmatchedTime={this.state.unmatchedTime}
                  unmatchedBy={this.state.unmatchedBy}
                  acceptTime={this.state.acceptTime}
                  matchedAt={this.state.matchedAt}
                  acceptBy={this.state.acceptBy}
                  receivedTo={this.state.receivedTo}
                  sentTime={this.state.sentTime}
                  receivedTime={this.state.receivedTime}
                  applyMatch={this.state.applyMatch}
                  applyInterest={this.state.applyInterest}
                  handleCancelReqModal={this.handleCancelReqModal}
                  unmatchModal={this.state.unmatchModal}
                  handleUnmatchModal={this.handleUnmatchModal}
                  handleModalClose={this.handleModalClose}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  loadingIgnore={this.state.loadingIgnore}
                  isMyProfile={false}
                  matchPostId={this.state.matchPostId}
                  reportedPosts={this.state.reportedPosts}
                  reasons={this.state.reasons}
                  handleChange={this.handleChange}
                  handleReport={this.handleReport}
                  reportModal={this.state.reportModal}
                  whyReport={this.state.whyReport}
                  handleReportAd={this.handleReportAd}
                  handleReportOptions={this.handleReportOptions}
                  handleReportClose={this.handleReportClose}
                  error={this.state.error}
                  undoAutoRejectTime={this.state.undoAutoRejectTime}
                  undoAutoRejectedBy={this.state.undoAutoRejectedBy}
                  displayName={this.state.displayName}
                />
              )}

              {/* <div style={{width: '100%', marginBottom: '16px'}}><GoogleInfeedAds slot="7432721829"/></div> */}

              {/* Refactored sections */}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Grid container direction="column" style={{ rowGap: 20 }}>
                  <PersonalInformation data={this.state.postDetails} />
                  <FamilyInformation data={this.state.postDetails} />
                  <HoroscopeInformation
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                </Grid>
              )}
            </Grid>
            <GoogleAds slot="6370303041" />
          </div>
          <div className="create-floor-footer-desktop">
            <Footer lng={this.props.lng} />
          </div>
          <div className="create-floor-footer-mobile">
            <FooterMobile lng={this.props.lng} />
          </div>

          <CancelRequestModal
            lng={this.props.lng}
            cancelRequest={this.state.cancelRequest}
            handleModalClose={this.handleModalClose}
            handleIgnoreAllInt={this.handleIgnoreAllInt}
            loadingIgnore={this.state.loadingIgnore}
          />

          <NeedToCreateAnAccount
            lng={this.props.lng}
            needToCreateAnAccount={this.state.needToCreateAnAccount}
            needToCreateAnAccountClose={this.needToCreateAnAccountClose}
          />
        </React.Fragment>
      );
    }
  }
}

export default withRouter(withLanguageMigrator(Post));
