import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import CreateFlowPage from "../templates/create-flow-page";
import ReviewPersonalInfo from "./templates/review-personal-info";
import ReviewPrivateInfo from "./templates/review-personal-info/review-private-info";
import AppContext from "../../../context";
import ReviewParentInfo from "./templates/review-personal-info/review-parent-info";
import ReviewOfflineResponses from "./templates/review-personal-info/offline-responses";
import { useDispatch, useSelector } from "react-redux";
import {
  createAuthProfile,
  getAuthUserProfile,
} from "../../../features/application";
import { setAppSnackNotification } from "../../../store/actions/application";
import Config from "../../../config";
import "./style.css";
import {
  deleteApiIdempotencyKeyFromStorage,
  generateApiIdempotencyKey,
} from "../../../utils/data-generator";

const ReviewAndPay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { appLanguage } = useContext(AppContext);
  const { authTempProfile } = useSelector((state) => state.application);
  const [submitBtnDisability, setSubmitBtnDisability] = useState(false);

  useEffect(() => {
    generateApiIdempotencyKey({
      storageKey: Config.STORAGE_KEY_POST_CREATE_IDEMPOTENCY,
    });
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitBtnDisability(true);
      const response = await createAuthProfile({
        tempProfile: authTempProfile.postData,
      });
      if (response.success) {
        deleteApiIdempotencyKeyFromStorage({
          storageKey: Config.STORAGE_KEY_POST_CREATE_IDEMPOTENCY,
        });
      }
      await dispatch(getAuthUserProfile());
      setTimeout(() => {
        history.push("pay-for-ad");
      }, 500);
    } catch (error) {
      console.error("[Error] unable to create profile", error);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unable to create advertisement",
        })
      );
    } finally {
      setSubmitBtnDisability(false);
    }
  };

  const hanldeBack = () => {
    history.push("complete-ad-private");
  };

  return (
    <CreateFlowPage>
      <ReviewPersonalInfo />
      <ReviewParentInfo />
      <ReviewPrivateInfo />
      {Config.OFFLINE_RESPONSES_VISIBILITY && <ReviewOfflineResponses />}
      <Grid container direction="row" justifyContent="space-between">
        <Button
          variant="text"
          startIcon={<ArrowBackIos />}
          onClick={hanldeBack}
        >
          {t("common.backBtnText")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`button-${appLanguage}`}
          onClick={handleSubmit}
          disabled={submitBtnDisability}
        >
          {t("createflow.confirmDetails")}
        </Button>
      </Grid>
    </CreateFlowPage>
  );
};

export default ReviewAndPay;
