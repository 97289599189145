import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Switch from "react-input-switch";

import close from "../../images/close.png";
import groom from "../../images/groom.png";
import bride from "../../images/bride.png";

class ShowIntMsgModal extends Component {
  state = {
    message: "",
    showHidePhone: "true",
  };

  componentDidMount = () => {
    if (localStorage.getItem("auth_token")) {
      let url = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        url = `operator/managed_posts/${localStorage.getItem("postId")}`;
      } else {
        url = "account/posts";
      }
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            res.data[0] = res.data;
          }
          this.setState({
            gender: res.data[0].personalInfo.gender,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handle show hide
  handleShowHide = (value) => {
    this.setState({
      showHidePhone: value,
    });
  };

  render() {
    console.log(this.state.showHidePhone);
    return (
      <Dialog
        open={this.props.messageModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="message-modal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.handleClose}
        >
          {this.props.gender === "MALE" ? (
            <img src={groom} alt="groom" className="messager" />
          ) : (
            <img src={bride} alt="bride" className="messager" />
          )}
          <span className="receivers-name">
            {/* {this.props.fname} {this.props.lnameMasked} */}
            {this.props.displayName}
          </span>
        </DialogTitle>
        <DialogContent dividers></DialogContent>
        <DialogContent>
          <img
            src={close}
            alt="close"
            className="close-message-modal"
            onClick={this.props.handleModalClose}
          />

          <Alert severity="info" style={{ padding: "0 10px" }}>
            {this.props.lng ? (
              `This message will be displayed to ${
                this.props.gender === "MALE" ? "him" : "her"
              } along with your interest request.`
            ) : (
              <span className="sinhala-w-600 sinhala-size-12">
                ඔබගේ ඉල්ලීම සමඟ මෙම පණිවිඩය
                {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} දර්ශනය වේ.
              </span>
            )}
          </Alert>

          <textarea
            className={
              this.props.sensitiveDataError
                ? "message-textarea red-border"
                : "message-textarea"
            }
            rows="5"
            name="message"
            value={this.state.message}
            onChange={this.handleChange}
            placeholder={
              this.props.lng === true
                ? "Message (Optional)"
                : "පණිවිඩය (අත්‍යවශ්‍ය නොවේ)"
            }
            maxLength="1000"
          />
          <p className="cus-modal-error">
            {this.props.lng ? (
              this.props.sensitiveDataError
            ) : (
              <span className="sinhala-size-11">
                {this.props.sensitiveDataError}
              </span>
            )}
          </p>

          <div
            className="share-phone-number-div"
            style={{ marginBottom: 0, paddingBottom: 0, position: "relative" }}
          >
            <Typography variant="h6" className="show-phone-on-off">
              {this.props.lng ? (
                <span className="on-off-label">
                  Share my phone number when
                  {this.props.gender === "MALE" ? " he" : " she"} accepts my
                  request.
                </span>
              ) : (
                <span className="sinhala-w-600 sinhala-size-12 on-off-label">
                  මගේ ඉල්ලීම අනුමත කල විට මගේ දුරකථන අංකය
                  {this.props.gender === "MALE" ? " ඔහුට" : " ඇයට"} පෙන්වන්න.
                </span>
              )}
              <Switch
                on="true"
                off="false"
                value={this.state.showHidePhone}
                onChange={this.handleShowHide}
                style={{
                  float: "right",
                }}
                className="interest-on-off-btn"
              />
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="int-btn"
            onClick={() =>
              this.props.handleShowInterest(
                this.props.sendingPostId,
                this.state.message,
                this.state.showHidePhone
              )
            }
            color="primary"
          >
            {this.props.loading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <span>
                {this.props.lng === true ? (
                  "Show Interest"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-9">
                    කැමැත්ත පෙන්වන්න
                  </span>
                )}
              </span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(ShowIntMsgModal);
