import { Grid, Link, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import FormSection from "../../../../../components/molecules/form-section";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import ImageRestrictionNotice from "../image-restriction-notice";
import AlertDialogBox from "../../../../../components/molecules/dialog-box";
import AppContext from "../../../../../context";
import FormImageSelector from "../../../../../components/molecules/form-image-selector";
import ImageCrop from "../../../../../../components/image-crop/ImageCrop";
import {
  Base64ToBlob,
  FileToBase64,
  resizeImageToUpload,
} from "../../../../../utils";
import { getAuthTempProfileImage } from "../../../../../features/application";
import Config from "../../../../../config";
import "./style.css";

const PROFILE_IMAGES_SLOTS = Config.POST_IMAGE_SLOTS;

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    flexWrap: "wrap",
    rowGap: 8,
    columnGap: 8,
    width: "100%",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "48%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "32%",
    },
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%",
    },
  },
}));

const PictureInformation = () => {
  // global states
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { setFieldValue, values } = useFormikContext();
  const { authTempProfile } = useSelector((state) => state.application);

  // component states
  const [showDialog, setShowDialog] = useState(false);
  const [profilePictures, setProfilePictures] = useState(
    Array.from(Array(PROFILE_IMAGES_SLOTS).fill(undefined))
  );
  const [imageMap, setImageMap] = useState({});
  const [selectedImage, setSelectedImage] = useState(undefined);
  const styleClasses = useStyles();

  const hasProfileImages = useMemo(() => {
    return profilePictures.some((element) => element !== undefined);
  }, [profilePictures]);

  // event handlers
  const onSelectImage = async (file, index) => {
    try {
      const base64 = await FileToBase64(file);
      setSelectedImage({ slotId: index, source: base64 });
    } catch (e) {
      console.error(">>>> [Error!] ", e);
    }
  };

  const onDeleteImage = async (idx) => {
    try {
      const file = profilePictures[idx];
      if (typeof file === "string") {
        const entry = Object.entries(imageMap).find(([_, url]) => {
          return url === file;
        });
        if (entry !== undefined) {
          const deletedFile = entry[0];
          const filteredImages = values.images.filter(
            (file) => file !== deletedFile
          );
          setFieldValue("images", filteredImages);
        }
      }
      let temp = [...profilePictures];
      temp[idx] = undefined;
      temp = temp.filter((image) => image !== undefined);
      const emptySlots = PROFILE_IMAGES_SLOTS - temp.length;

      if (emptySlots > 0) {
        const slots = Array.from(Array(emptySlots).fill(undefined));
        temp = [...temp, ...slots];
        setProfilePictures(temp);
      }
    } catch (e) {
      console.error("Error! onDeleteImage ", e);
    }
  };

  const onCloseCropDialog = () => {
    setSelectedImage(undefined);
  };

  const handleChangeFile = async (e) => {
    const blob = await Base64ToBlob(e);
    resizeImageToUpload({
      blob,
      onResize: (outputBlob) => {
        if (selectedImage !== undefined) {
          let tempArray = values.images;
          let imagesFieldArray = [...values.images];
          if (!Array.isArray(tempArray)) {
            return;
          }
          tempArray = [...profilePictures];
          const noOfEmptySlots = PROFILE_IMAGES_SLOTS - imagesFieldArray.length;
          if (noOfEmptySlots > 0) {
            const emptySlots = Array.from(
              Array(noOfEmptySlots).fill(undefined)
            );
            imagesFieldArray.push(...emptySlots);
          }

          imagesFieldArray[selectedImage.slotId] = outputBlob;
          tempArray[selectedImage.slotId] = outputBlob;
          setFieldValue("images", imagesFieldArray);
          setProfilePictures(tempArray);
          setSelectedImage(undefined);
        }
      },
    });
  };

  const handleDownloadProfileImages = useCallback(async () => {
    if (isEmpty(authTempProfile.postData?.images)) {
      return;
    }
    let profileImages = [];
    const imageMap = {};
    for (const image of authTempProfile.postData.images) {
      if (typeof image === "string") {
        const imageURL = await getAuthTempProfileImage({ id: image });
        imageMap[image] = imageURL;
        profileImages.push(imageURL);
      }
    }
    const noOfEmptySlots = PROFILE_IMAGES_SLOTS - profileImages.length;
    if (noOfEmptySlots > 0) {
      const emptySlots = Array.from(Array(noOfEmptySlots).fill(undefined));
      profileImages.push(...emptySlots);
    }
    setImageMap(imageMap);
    setProfilePictures(profileImages);
  }, [authTempProfile]);

  useEffect(() => {
    handleDownloadProfileImages();
  }, [handleDownloadProfileImages]);

  return (
    <FormSection heading={t("common.pictures")}>
      <Grid container direction="column" style={{ rowGap: 16 }}>
        <Notice
          varaint={Varaint.primary}
          label={t("createflow.pictureNotice")}
          lang={appLanguage}
        />
        <Link
          onClick={() => setShowDialog(true)}
          className={`image-notice-link image-notice-link-${appLanguage}`}
        >
          {t("createflow.pictureRestrictionNotice")}
        </Link>
        <Grid container direction="row" className={styleClasses.outerContainer}>
          {profilePictures.map((image, idx) => (
            <Grid
              key={`profile-image-slot-${idx}`}
              item
              className={styleClasses.root}
            >
              <FormImageSelector
                image={image}
                onSelect={(file) => onSelectImage(file, idx)}
                onDelete={() => onDeleteImage(idx)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          direction="row"
          className="picture-info-notice column-gap-8"
          alignItems="center"
        >
          {!hasProfileImages && (
            <Grid item xs container direction="row" alignItems="center">
              <Typography className={`notice-text notice-text-${appLanguage}`}>
                <WarningIcon style={{ fontSize: 15 }} />
                {t("createflow.pictureWarning")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <AlertDialogBox
        showModal={showDialog}
        onClose={() => setShowDialog(false)}
        title={t("createflow.pictureModalNotice")}
      >
        <ImageRestrictionNotice />
      </AlertDialogBox>
      <ImageCrop
        uploadImageModal={selectedImage !== undefined}
        selectedImage={selectedImage?.source}
        handleClose={onCloseCropDialog}
        handleChangeFile={handleChangeFile}
        photoLoading={false}
        handleSave={() => {}}
        lng={true}
      />
    </FormSection>
  );
};

export default PictureInformation;
