import React, { Component } from "react";
// import axios from "axios";
import { Grid, Typography } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import CheckoutMethod from "../components/payment/TopAdCheckoutMethod";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import FeaturedAdd from "./FeaturedAdd";
import { getPayemntStatus } from "../methods/getPayemntStatus";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Checkout extends Component {
  state = {
    isAccessDenied: false,
    isFeatured: false,
  };

  componentDidMount = () => {
    this.setState({
      isAccessDenied:
        localStorage.getItem("postId") &&
        localStorage.getItem("auth_token") &&
        getPayemntStatus()
          ? false
          : true,
      isFeatured: JSON.parse(localStorage.getItem("post_details"))?.isFeatured,
    });
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize title={`Checkout | ${Config.DOMAIN_DISPLAY_NAME}`} />
    ) : this.state.isFeatured ? (
      <FeaturedAdd title="Already Featured!" />
    ) : (
      <React.Fragment>
        <PageHead title={`Checkout | ${Config.DOMAIN_DISPLAY_NAME}`} />

        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container complete-ad-container"
          >
            <Typography
              variant="h3"
              align="center"
              className="create-new-ad"
              style={{
                paddingBottom: 0,
              }}
            >
              {this.props.lng === true ? "Top Ad" : <span>Top Ad</span>}
            </Typography>
            <CheckoutMethod align="center" lng={this.props.lng} />
          </Grid>
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.props.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.props.lng} />
        </div>
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Checkout);
