import React, { useState } from "react";
import Config from "../../v2/config";

const CallUsToCreateAccount = ({ lng }) => {
  const [showPhone, setShowPhone] = useState(false);

  return (
    <>
      <div
        className="call-us-to-create-your-account mobile"
        onClick={() => setShowPhone(!showPhone)}
      >
        <a href={`tel:${Config.PHONE_NUMBER}`}>
          <i className="fas fa-headset"></i>
          {showPhone ? (
            `${Config.PHONE_NUMBER}`
          ) : lng ? (
            "Call us to create your account"
          ) : (
            <span className="sinhala-w-600 sinhala-size-12">
              ඔබගේ ගිණුම සෑදීමට අපව අමතන්න
            </span>
          )}
        </a>
      </div>
      <div
        className="call-us-to-create-your-account non-mobile"
        onClick={() => setShowPhone(!showPhone)}
      >
        <i className="fas fa-headset"></i>
        {showPhone ? (
            `${Config.PHONE_NUMBER}`
        ) : lng ? (
          "Call us to create your account"
        ) : (
          <span className="sinhala-w-600 sinhala-size-12">
            ඔබගේ ගිණුම සෑදීමට අපව අමතන්න
          </span>
        )}
      </div>
    </>
  );
};

export default CallUsToCreateAccount;
