import React, { Component } from "react";
import { Grid } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import { withRouter } from "react-router-dom";
import Config from "../v2/config";

import Info from "../images/info.png";

import "../styles/disable-profile.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class RestrictedProfile extends Component {
  handleBack = () => {
    this.props.history.push("/messages");
  };

  render() {
    return (
      <React.Fragment>
        <PageHead
          title={`Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <span className="dis-back-btn" onClick={this.handleBack}>
            <i className="fas fa-angle-left"></i>{" "}
            {this.props.lng ? "Back" : "ආපසු යන්න"}
          </span>
          <div className="disable-div">
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 65, opacity: 0.7 }} />
            </p>
            <p className="message">
              {this.props.lng ? "Unmatched Profile" : "ඉවත් කරන ලද ගැලපුමකි"}
            </p>
            <p align="center" className="desc">
              {this.props.lng ? (
                "You cannot view details of this profile once unmatched."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  ගැලපුමක් ඉවත් කල පසු එහි විස්තර නොපෙන්වයි.
                </span>
              )}
            </p>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withRouter(withLanguageMigrator(RestrictedProfile));
