import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MatchItemCards from "./MatchItemCards";
import { Typography } from "@material-ui/core";

const IntersetSideViewUnmatches = (props) => {
  return (
    <div className="panel">
      {props.sideMenuUnmatchesAllFetched && (
        <>
          {props.sideMenuUnmatches.length > 0 ? (
            <div>
              <Typography>
                {props.lng ? (
                  <span className="message-header-text">Unmatched</span>
                ) : (
                  <span className="message-header-text">
                    ගැලපීමෙන් ඉවත් කල ගැලපීම
                  </span>
                )}
              </Typography>

              {props.sideMenuUnmatches.map((el, i) => (
                <MatchItemCards
                  post={el}
                  lng={props.lng}
                  key={i}
                  intId={props.intId}
                />
              ))}
            </div>
          ) : (
            <Typography align="center" style={{marginTop: "50px" , padding:"20px 20px"}}>
              {props.lng ? (
                <span className="availability-messsage">
                  No unmatched profiles found
                </span>
              ) : (
                <span>ඉවත් කල ගැලපීම් කිසිවක් නොමැත.</span>
              )}
            </Typography>
          )}
          {props.sideMenuArchivedMatches
            .filter((el) => el.post)
            .map((el, i) => (
              <MatchItemCards
                post={el}
                lng={props.lng}
                key={i}
                intId={props.intId}
              />
            ))}
          {!props.hideMoreBtn && (
            <p className="see-more-btn" onClick={props.archivedClick}>
              See more...
            </p>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenuUnmatchesAllFetched: state.matches.sideMenuUnmatchesAllFetched,
    sideMenuUnmatches: state.matches.sideMenuUnmatched.unmatched,
    sideMenuArchivedMatches:
      state.matches.sideMenuArchivedMatched.archivedMatched,
  };
};

export default connect(mapStateToProps)(withRouter(IntersetSideViewUnmatches));
