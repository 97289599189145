import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
// import Navbar from "../components/common/Navbar";s
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import messenger from "../images/messenger.png";
import whatsapp from "../images/whatsapp.png";
import Config from "../v2/config";

import "../styles/help.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

export const FAQ_CATEGORIES = Object.freeze({
  ACCOUNT_CREATION: "ACCOUNT_CREATION",
  MATCHING_WITH_OTHER_ADS: "MATCHING_WITH_OTHER_ADS",
  SETTINGS: "SETTINGS",
});

class Help extends Component {
  state = {};

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Help | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7", padding: "50px 15px 30px 15px" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container"
            style={{ background: "#ffffff", marginTop: "-10px" }}
          >
            <Typography
              variant="h5"
              align="center"
              className="help-page-heading"
              style={{ marginTop: 20 }}
            >
              {this.props.lng === true ? (
                "Help Center"
              ) : (
                <span className="sinhala-w-500 sinhala-size-18">
                  උපදෙස් සහ සහාය
                </span>
              )}
            </Typography>
            <div className="help-div">
              <div className="help-col">
                <Link
                  to={{
                    pathname: "/faq",
                    state: {
                      category: FAQ_CATEGORIES.ACCOUNT_CREATION,
                    },
                  }}
                  className="help-btn-link"
                >
                  <Button className="help-btn">
                    {this.props.lng === true ? (
                      "Account Creation"
                    ) : (
                      <span className="sinhala-w-500 sinhala-size-14">
                        ගිණුමක් සෑදීම
                      </span>
                    )}
                    <i className="fas fa-angle-right help-drawer-icon"></i>
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/faq",
                    state: {
                      category: FAQ_CATEGORIES.MATCHING_WITH_OTHER_ADS,
                    },
                  }}
                  className="help-btn-link"
                >
                  <Button className="help-btn">
                    {this.props.lng === true ? (
                      "Matching with other ads"
                    ) : (
                      <span className="sinhala-w-500 sinhala-size-14">
                        අනෙකුත් දැන්වීම් සමග සම්බන්ද වීම
                      </span>
                    )}
                    <i className="fas fa-angle-right help-drawer-icon"></i>
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/faq",
                    state: {
                      category: FAQ_CATEGORIES.SETTINGS,
                    },
                  }}
                  className="help-btn-link"
                >
                  <Button className="help-btn">
                    {this.props.lng === true ? (
                      "Settings"
                    ) : (
                      <span className="sinhala-w-500 sinhala-size-14">
                        සැකසුම්
                      </span>
                    )}
                    <i className="fas fa-angle-right help-drawer-icon"></i>
                  </Button>
                </Link>
              </div>
            </div>
            <Typography
              variant="h5"
              align="center"
              className="help-page-heading"
              style={{ marginTop: "4rem", marginBottom: "0.5rem" }}
            >
              {this.props.lng === true ? (
                "Support"
              ) : (
                <span className="sinhala-w-500 sinhala-size-18">සහාය</span>
              )}
            </Typography>
            <div className="help-div">
              <div className="help-col">
                <Grid container spacing={4} columns={12}>
                  <Grid item xs={6} style={{ paddingBottom: "0px" }}>
                    <p className="non-link">
                      <a
                        href={`${Config.WHATSAPP_URL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="help-link"
                      >
                        <img
                          src={whatsapp}
                          alt="messsenger"
                          width="44"
                          className="help-link-img"
                        />
                        <span>Whatsapp Live Chat</span>
                      </a>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ paddingBottom: "0px" }}>
                    <p className="non-link">
                      <a
                        href={`${Config.MESSENGER_URL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="help-link"
                      >
                        <img
                          src={messenger}
                          alt="messsenger"
                          width="44"
                          className="help-link-img"
                        />
                        <span>Messenger Live Chat</span>
                      </a>
                    </p>
                  </Grid>
                </Grid>
                {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (
                  <Grid container spacing={4} columns={12}>
                    <Grid item xs={12}>
                      <p className="non-link">
                        <a
                          href={`${Config.PHONE_NUMBER}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="help-link"
                        >
                          <i className="fas fa-headset fa-2x"></i>
                          <div style={{ marginTop: "10px" }}>
                            <div>Hotline</div>
                            <div style={{ marginTop: "2px" }}>
                              {Config.PHONE_NUMBER}
                            </div>
                            <div style={{ marginTop: "2px" }}>
                              {Config.PHONE_NUMBER_MOBILE}
                            </div>
                          </div>
                          <p>(8.30 am to 8.30 pm GMT+5.30)</p>
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            </div>
          </Grid>
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.props.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.props.lng} />
        </div>
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Help);
