import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import DataColumn from "../../../../../../components/molecules/data-column";
import ReviewSection from "../../../../../../components/molecules/review-section";
import AppContext from "../../../../../../context";
import Constants from "../../../../../../config/constant";
import { ParentDisplayModel } from "../../../../../../models/profile";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../../../features/createflow";
import { fieldVisibility } from "../../../../../../utils";
import parentFormMeta from "../../../../parents-create-account/schema/meta.json";
import reviewAndPayMeta from "../../../schema/formMeta.json";

const { PARENT_TYPE } = Constants;
const formMeta = { ...parentFormMeta, ...reviewAndPayMeta };

const initialValues = {
  [PARENT_TYPE.FATHER]: ParentDisplayModel(PARENT_TYPE.FATHER),
  [PARENT_TYPE.MOTHER]: ParentDisplayModel(PARENT_TYPE.MOTHER),
};

const ReviewParentInfo = () => {
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { initialized } = useSelector((state) => state.genericData);
  const { authTempProfile } = useSelector((state) => state.application);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [displayValues, setDisplayValues] = useState(initialValues);

  useEffect(() => {
    if (initialized && !isEmpty(authTempProfile)) {
      generateDisplayValues({
        section: DataDisplaySection.parentInformation,
        lang: appLanguage,
      }).then((value) => setDisplayValues(value));
    }
  }, [appLanguage, initialized, authTempProfile]);

  const fatherForm = {
    formMeta: formMeta,
    displayValues: displayValues[PARENT_TYPE.FATHER],
  };
  const motherForm = {
    formMeta: formMeta,
    displayValues: displayValues[PARENT_TYPE.MOTHER],
  };
  const parentInfos = [fatherForm, motherForm];

  return (
    <ReviewSection
      heading={t("common.parentsInfo")}
      navigate={"complete-ad-parent"}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className="review-info-wrapper"
      >
        {parentInfos.map((formData) => (
          <Grid
            container
            direction="column"
            className="data-single-column-container"
            style={{ width: isMobileScreen ? "100%" : "48%" }}
          >
            <Typography className="review-parent-section-title margin-bottom-8">
              {formData.displayValues.parentType}
            </Typography>
            <DataColumn
              label={t(formMeta.ethnicityId.label)}
              value={formData.displayValues.ethnicity}
              visibility={fieldVisibility(formMeta.ethnicityId)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.religionId.label)}
              value={formData.displayValues.religion}
              visibility={fieldVisibility(formMeta.religionId)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.caste.label)}
              value={formData.displayValues.caste}
              visibility={fieldVisibility(formMeta.caste)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.communityId.label)}
              value={formData.displayValues.community}
              visibility={fieldVisibility(formMeta.communityId)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.subCommunity.label)}
              value={formData.displayValues.subCommunity}
              visibility={fieldVisibility(formMeta.subCommunity)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.professionId.label)}
              value={formData.displayValues.profession}
              visibility={fieldVisibility(formMeta.professionId)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.residentCountryCode.label)}
              value={formData.displayValues.residentCountry}
              visibility={fieldVisibility(formMeta.residentCountryCode)}
              className="parent-data-column"
            />
            <DataColumn
              label={t(formMeta.additionalInfo.label)}
              value={formData.displayValues.additionalInfo}
              visibility={fieldVisibility(formMeta.additionalInfo)}
              className="parent-data-column"
            />
          </Grid>
        ))}
      </Grid>
    </ReviewSection>
  );
};

export default ReviewParentInfo;
