import React from "react";
import { Grid } from "@material-ui/core";
import { SectionHeading } from "../../custom-typography";
import FormDropdown, {
  LABEL_POSITION,
} from "../../../../../../components/molecules/form-dropdown";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { fieldVisibility } from "../../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";

const DifferentlyAbledInformation = () => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    initialValues,
    submitCount,
    handleChange,
    setFieldError,
    setFieldValue,
  } = useFormikContext();
  const { t, i18n } = useTranslation();
  const showFieldErrors = submitCount > 0;
  const { differentlyAbledOptions } = useSelector((state) => state.genericData);

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionHeading labelKey="common.differentlyAbled" />
      <Grid container direction="row">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormValue({
              meta: FormMeta.differentlyAbled,
              initialValues,
              values,
            })}
            onClickInteractiveSave={() => {
              dispatch(
                updateAdField(
                  FormMeta.differentlyAbled,
                  values,
                  t,
                  setFieldError
                )
              );
            }}
            onClickInteractiveClear={() => {
              dispatch(
                resetChangedField(
                  FormMeta.differentlyAbled,
                  initialValues,
                  setFieldValue
                )
              );
            }}
            fieldName={FormMeta.differentlyAbled.fieldName}
            value={values[FormMeta.differentlyAbled.fieldName]}
            onChange={handleChange}
            options={differentlyAbledOptions}
            labelExtractor={FormMeta.differentlyAbled.labelExtractor}
            keyExtractor={FormMeta.differentlyAbled.keyExtractor}
            label={t(FormMeta.differentlyAbled.label)}
            labelPosition={LABEL_POSITION.top}
            lang={i18n.language}
            errorText={
              showFieldErrors && errors[FormMeta.differentlyAbled.fieldName]
            }
            visibility={fieldVisibility(FormMeta.differentlyAbled)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DifferentlyAbledInformation;
