import * as Yup from "yup";
import { isFunction } from "lodash";
import Config from "../../../../config";
import {
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
} from "../../../../utils";
import FormMeta from "./meta.json";

const CommonSchema = (t) => {
  return Yup.object().shape({
    religionId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.religion") })
    ),
    caste: Yup.string().max(
      FormMeta.caste.max,
      t("errors.formFieldTooLong", { fieldName: t("common.caste") })
    ),
    professionId: Yup.string(),
    otherProfession: Yup.string().when(
      "professionId",
      ([_professionId], schema) => {
        let professionId = _professionId;
        if (Boolean(professionId) && typeof professionId === "string") {
          professionId = parseInt(professionId);
        }

        if (
          Boolean(professionId) &&
          professionId === Config.OTHER_PROFESSION_ID
        )
          return schema
            .required(
              t("errors.formRequiredField", {
                fieldName: t("common.typeProfession"),
              })
            )
            .max(
              FormMeta.otherProfession.max,
              t("errors.formFieldTooLong", {
                fieldName: t("common.typeProfession"),
              })
            )
            .test({
              test: (value) => {
                return !(
                  isStringContainsEmoji(value) ||
                  isStringContainsNumbers(value) ||
                  isStringContainsSpecialCharacters(value)
                );
              },
              message: t("errors.invalidFieldValue", {
                fieldName: t("common.typeProfession"),
              }),
            });
      }
    ),
    residentCountryCode: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.countryOfResidence"),
      })
    ),
  });
};

const PlatformSpecificSchema = (t) => {
  const { CURRENT_PLATFORM, PLATFORMS } = Config;
  switch (CURRENT_PLATFORM) {
    case PLATFORMS.IN:
      return Yup.object().shape({
        communityId: Yup.string().required(
          t("errors.formRequiredField", {
            fieldName: t("createflow.communityField"),
          })
        ),
      });
    default:
      return Yup.object().shape({
        ethnicityId: Yup.string().required(
          t("errors.formRequiredField", { fieldName: t("common.ethnicity") })
        ),
      });
  }
};

const FormValidationSchema = (t) => {
  if (!isFunction(t)) {
    throw new Error("t arguement should be a function");
  }
  const formScheam = CommonSchema(t);
  const platformSchema = PlatformSpecificSchema(t);
  return formScheam.concat(platformSchema);
};

export default FormValidationSchema;
