import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

export default class CancelRequestModal extends Component {
  render() {
    return (
      <Dialog
        open={this.props.cancelRequest}
        onClose={this.props.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "#000", paddingBottom: 0 }}
        >
          {this.props.lng === true ? (
            "Are you sure?"
          ) : (
            <span className="sinhala-w-600 sinhala-size-13">ඔබට විශ්වාසද?</span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.lng === true ? (
              "Are you sure you want to cancel this request? You will not be able to show your interest to this user in future."
            ) : (
              <span className="sinhala-w-600 sinhala-size-13">
                ඔබට මෙම ඉල්ලීම අවලංගු කිරීමට අවශ්‍ය යැයි විශ්වාසද? අනාගතයේදී මෙම
                පරිශීලකයාට ඔබේ කැමැත්ත පෙන්වීමට ඔබට නොහැකි වනු ඇත.
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleModalClose}>
            {this.props.lng === true ? (
              "No"
            ) : (
              <span className="sinhala-w-600 sinhala-size-11">නැත</span>
            )}
          </Button>
          <Button
            onClick={() => this.props.handleIgnoreAllInt("withdraw")}
            style={{ background: "#000", color: "#fff" }}
          >
            {this.props.loadingIgnore ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <span>
                {this.props.lng === true ? (
                  "Yes"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-11">ඔව්</span>
                )}
              </span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
