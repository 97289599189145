// Package Imports
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Grid, Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { isEqual } from "lodash";

// Repository Imports
import CreateFlowPage from "../templates/create-flow-page";
import PictureInformation from "./templates/picture-information";
import HoroscopeInformation from "./templates/horoscope-information";
import ContactInformation from "./templates/contact-information";
import OfflineResponses from "./templates/offline-responses";
import AppContext from "../../../context";
import FormSchema from "./schema";
import { useDispatch, useSelector } from "react-redux";
import { bindProfileDataToFormData, saveTempProfile } from "./extension";
import { setAppSnackNotification } from "../../../store/actions/application";
import { scrollOnError } from "../../../utils";
import { buildModelByFormMeta } from "../../../utils";
import formMeta from "./schema/meta.json";
import Config from "../../../config";

const initailValues = buildModelByFormMeta(formMeta);

// Form Proxy Component
const Form = ({ children }) => {
  const { authAccount, authTempProfile, initialized } = useSelector(
    (state) => state.application
  );
  const { values, dirty, resetForm } = useFormikContext();
  const [oldShadow, setOldShadow] = useState({});

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const model = bindProfileDataToFormData({ authAccount, authTempProfile });
    resetForm({ values: model });
    setOldShadow(model);
  }, [authAccount, authTempProfile, initialized, resetForm]);

  useEffect(() => {
    if (!(dirty && !isEqual(values, oldShadow))) {
      return;
    }
  }, [dirty, oldShadow, values]);

  return <>{children}</>;
};

// Main Component
const PrivateCreateAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const { authTempProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const [submitBtnDisability, setSubmitBtnDisability] = useState(false);

  const onSubmitForm = useCallback(
    async (formValues, { setFieldError }) => {
      try {
        saveTempProfile({
          dispatch,
          formValues,
          currentProfile: authTempProfile.postData,
          authAccount: authAccount,
          history,
          setFieldError,
          t,
          setSubmitBtnDisability
        });
      } catch (error) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unknown Error Occurred, Try Again",
          })
        );
      }
    },
    [authAccount, authTempProfile, dispatch, history, t]
  );

  const validationSchema = useMemo(() => {
    if (!Boolean(appLanguage) || !Boolean(t)) {
      return {};
    }
    const schema = FormSchema(t);
    return schema;
  }, [appLanguage, t]);

  return (
    <CreateFlowPage>
      <Formik
        initialValues={initailValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnBlur={true}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <PictureInformation />
            <HoroscopeInformation />
            <ContactInformation />
            {Config.OFFLINE_RESPONSES_VISIBILITY && <OfflineResponses />}
            <Grid container direction="row" justifyContent="space-between">
              <Button
                variant="text"
                startIcon={<ArrowBackIos />}
                onClick={() => {
                  history.push("/complete-ad-parent");
                }}
              >
                {t("common.backBtnText")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={`button-${appLanguage}`}
                onClick={() => {
                  handleSubmit();
                  setTimeout(() => {
                    scrollOnError();
                  }, 500);
                }}
                disabled={isSubmitting || submitBtnDisability}
              >
                {t("common.saveAndContinueBtn")}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </CreateFlowPage>
  );
};

export default PrivateCreateAccount;
