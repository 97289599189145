import React, { Component } from 'react';

class GoogleAds extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

    render() {
      return (
        <ins
          className='adsbygoogle'
          style={{ display: 'block', textAlign: 'center', minWidth: '10px' }}
          data-ad-client= 'ca-pub-2370761850448977'
          data-ad-slot={this.props.slot}
          data-ad-format= 'fluid'
          data-ad-layout= "in-article"
          // data-adtest="on"
        >
        </ins>
      );
    }
}

export default GoogleAds;