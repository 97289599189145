import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: "#222",
  },
  tooltip: {
    backgroundColor: "#222",
    padding: "8px 20px 8px 20px",
    fontSize: 13,
  },
}));

export const BootstrapTooltip = (props) => {
  const classes = useStylesBootstrap();

  return (
    <Tooltip 
      arrow 
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
      {...props} 
    />
  );
};