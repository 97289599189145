import React from "react";
import PropTypes from "prop-types";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "./style.css";

const PhotoGrid = ({ images }) => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleVisibleChange = (visible) => {
    if (visible) {
      document.addEventListener("contextmenu", handleContextMenu);
    } else {
      document.removeEventListener("contextmenu", handleContextMenu);
    }
  };

  return (
    <PhotoProvider onVisibleChange={(visible) => handleVisibleChange(visible)}>
      {images.map((item, index) => (
        <PhotoConsumer key={index} src={item} intro={item}>
          <img
            src={item}
            alt=""
            className="photo-grid"
            onContextMenu={handleContextMenu}
          />
        </PhotoConsumer>
      ))}
    </PhotoProvider>
  );
};

PhotoGrid.propTypes = {
  images: PropTypes.array.isRequired,
};

export default PhotoGrid;
