import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Rating from "react-rating";

import BannerClose from "../../images/banner-close-white.png";

import "../../styles/google-review-banner.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const GOOGLE_REVIEW_HREF =
  "https://g.page/r/CZE2EBzRkvukEBM/review";

const GoogleReviewBanner = (props) => {
  let history = useHistory();
  const [rating, setRating] = useState(0);
  const [isEnable, setIsEnable] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/show_review_request`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.value) {
            if (localStorage.getItem("googleReviewCount")) {
              if (parseInt(localStorage.getItem("googleReviewCount")) === 2)
                setIsEnable(false);
              else setIsEnable(true);
            } else {
              setIsEnable(true);
            }
          } else setIsEnable(false);
        })
        .catch((err) => {
          console.log(err);
          setIsEnable(false);
        });
    }
  }, []);

  const handleRating = (value) => {
    setRating(value);
  };

  const onClickRating = (value) => {
    value > 3
      ? window.open(GOOGLE_REVIEW_HREF, "_blank")
      : history.push("/review-comments");
  };

  const handleBannerClose = () => {
    setIsEnable(false);

    if (localStorage.getItem("googleReviewCount")) {
      if (parseInt(localStorage.getItem("googleReviewCount")) === 1) {
        localStorage.setItem("googleReviewCount", 2);
      }
    } else {
      localStorage.setItem("googleReviewCount", 1);
    }
  };

  return (
    isEnable && (
      <div
        className="banner-container new-feature-container"
        style={{
          background: "#424242",
          paddingBottom: "15px",
        }}
      >
        <div
          className="all-ads-container"
          style={{ background: "transparent" }}
        >
          <p className="review-heading">
            {props.lng ? (
              "How is your experience?"
            ) : (
              <span className="sinhala-size-13">ඔබේ අත්දැකීම කොහොමද?</span>
            )}
          </p>

          <Rating
            initialRating={rating}
            onChange={(rate) => handleRating(rate)}
            className="rate-starts-input"
            emptySymbol="far fa-star"
            fullSymbol="fas fa-star"
            onClick={(e) => onClickRating(e)}
          />
        </div>

        <img
          className="banner-close-icon"
          src={BannerClose}
          alt="banner close"
          onClick={handleBannerClose}
        />
      </div>
    )
  );
};

export default withRouter(GoogleReviewBanner);
