import axios from "axios";
import { FETCH_MATCH_POST } from "./types";

export const fetchInterestPost = (id, type) => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/account/posts/${localStorage.getItem(
        "postId"
      )}/${type === "interests" ? "interests" : "matches"}/${id}
    `,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: FETCH_MATCH_POST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_MATCH_POST,
        payload: err.response.data,
      });
    });
};
