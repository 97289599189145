import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Config from "../../v2/config";

function Header(props) {
  const [search, setSearch] = useState("");

  const handleAd = () => {
    if (search) {
      props.history.push(`/post?id=${search}`);
    }
  };

  // search when press the enter key
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("magazine-ad-search-btn").click();
    }
  };

  return (
    <div className="magazine-search-banner">
      <Link to="/" className="go-to-home">
        Go to all ads
      </Link>
      <h1 className="magasize-heading">
        {props.lng ? (
          "MAGAZINE"
        ) : (
          <span style={{ fontSize: 35 }}>මාසික සඟරාව</span>
        )}
      </h1>
      <p className="magasize-sub-heading">
        {props.lng ? (
          "Search by ad URL"
        ) : (
          <span style={{ fontSize: 15 }}>දැන්වීම් කේතය මගින් සොයන්න</span>
        )}
      </p>
      <div className="search-wrap">
        <div className="search-box">
          <div
            className="btn btn-common"
            onClick={handleAd}
            id="magazine-ad-search-btn"
          >
            <i className="fas fa-search"></i>
          </div>
          <input
            type="text"
            className="input"
            placeholder="ABC1234"
            value={search}
            onChange={(e) => setSearch(e.target.value.trim())}
            onKeyUp={handleKeyUp}
          />
          <div className="fix-text">
            <p>{Config.DOMAIN_NAME}/post?id=</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
