import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import "./style.css";

const FaqItem = ({ question, answers = [] }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <List>
        <ListItem
          button
          onClick={() => setOpen(!open)}
          className="faq-item-container"
        >
          <ListItemText>
            <Typography className="faq-item-question">{question}</Typography>
          </ListItemText>
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {answers.map((answer, index) => (
              <Fragment key={index}>
                <ListItem>
                  <ListItemText>
                    <Typography
                      className="faq-item-answer-txt"
                      dangerouslySetInnerHTML={{ __html: answer.answerText }}
                    />
                  </ListItemText>
                </ListItem>
                {!isEmpty(answer.BulletPoints) &&
                  answer.BulletPoints.map((bullet, index) => (
                    <ListItem
                      key={index}
                      style={{
                        listStyle: "disc",
                        display: "list-item",
                        margin: "0 0 0 3rem",
                        padding: 0,
                      }}
                    >
                      <ListItemText>
                        <Typography
                          className="faq-item-answer-txt"
                          dangerouslySetInnerHTML={{ __html: bullet }}
                        />
                      </ListItemText>
                    </ListItem>
                  ))}
              </Fragment>
            ))}
          </List>
        </Collapse>
      </List>
    </>
  );
};

export default FaqItem;
