import Constants from "../../../config/constant";
import {
  SET_APP_MONTHLY_MAGAZINE_VISIBILITY,
  SET_APP_LANGUAGES,
  SET_APP_LANGUAGE,
  SET_APP_LNG,
  SET_APP_AUTHENTICATED,
  SET_APP_INITIALIZED,
  SET_APP_AUTH_ACCOUNT,
  SET_APP_AUTH_PROFILE,
  SET_APP_AUTH_TEMP_PROFILE,
  SET_APP_PREV_LOCATION,
  SET_APP_CONFIG,
  SET_APP_SNACK_NOTIFICATION,
} from "../../actions/application";

const initialState = {
  initialized: false,
  appConfig: {},
  snackNotification: {},
  authenticated: false,
  authAccount: {},
  authProfile: {},
  authTempProfile: {},
  monthlyMagazineVisibility: false,
  languages: Constants.APP_LANG,
  appLanguage: Constants.APP_LANG.EN,
  lng: true,
  previousLocation: undefined,
};

const applicationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_APP_MONTHLY_MAGAZINE_VISIBILITY:
      return { ...state, monthlyMagazineVisibility: payload };
    case SET_APP_LANGUAGES:
      return { ...state, languages: payload };
    case SET_APP_LANGUAGE:
      return { ...state, appLanguage: payload };
    case SET_APP_LNG:
      return { ...state, lng: payload };
    case SET_APP_AUTHENTICATED:
      return { ...state, authenticated: payload };
    case SET_APP_INITIALIZED:
      return { ...state, initialized: payload };
    case SET_APP_AUTH_ACCOUNT:
      return { ...state, authAccount: payload };
    case SET_APP_AUTH_PROFILE:
      return { ...state, authProfile: payload };
    case SET_APP_AUTH_TEMP_PROFILE:
      return { ...state, authTempProfile: payload };
    case SET_APP_PREV_LOCATION:
      return { ...state, previousLocation: payload };
    case SET_APP_CONFIG:
      return { ...state, appConfig: payload };
    case SET_APP_SNACK_NOTIFICATION:
      return { ...state, snackNotification: payload };
    default:
      return state;
  }
};

export default applicationReducer;
