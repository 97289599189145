import React, { useState, useEffect } from "react";
import axios from "axios";

// import Navbar from "../components/common/Navbar";
import InterestView from "../components/my-matches/InterestView";
import MessageView from "../components/my-matches/MessageView";
import PersonalProfile from "../components/my-matches/PersonalProfile";
import ReceivedInterest from "../components/my-matches/ReceivedInterest";
import UnautorizedProfile from "../components/my-matches/interest-com/UnautorizedProfile";
import IncorrectToken from "../components/my-matches/interest-com/IncorrectToken";
import Unauthorize from "../pages/Unauthorize";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";

import { fetchAllInterest } from "../actions/fetchAllInterest";
import { handleIntBtns } from "../actions/handleIntBtns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../styles/my-matches.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

const MyMatches = (props) => {
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const lng = props.lng;

  useEffect(() => {
    setIsAccessDenied(
      localStorage.getItem("auth_token") && localStorage.getItem("postId")
        ? false
        : true
    );

    if (window.ws) {
      window.ws.close();
    }

    // chat_token
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("chat_token", res.data.token);
        sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

        openWS(res.data.token);
      })
      .catch((err) => console.log(err));
  }, []);

  const openWS = (token) => {
    // web socket
    window.ws = new WebSocket(
      `wss://${Config.WEB_SOCKET_URL}/v1/ws/chat_subscribe?token=${token}`
    );

    setInterval(() => {
      window.ws.send("ping");
    }, 30000);

    if (!props.post.post) {
      window.ws.onmessage = (event) => {
        let msgData = event.data
          ? event.data === "pong"
            ? event.data
            : JSON.parse(event.data)
          : {};
        console.log(msgData);
        if (msgData !== "pong") {
          props.fetchAllInterest();
        }
      };
    }

    window.ws.onclose = function (event) {
      console.log("close");
    };
  };

  useEffect(() => {
    props.fetchAllInterest();
  }, []);

  const isEmptyObj =
    Object.keys(props.post).length === 0 && props.post.constructor === Object;

  return isAccessDenied ? (
    <Unauthorize title={`Matches | ${Config.DOMAIN_DISPLAY_NAME}`} />
  ) : (
    <React.Fragment>
      <PageHead title={`Matches | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <div
        style={{
          background: "#dfdfdf",
        }}
      >
        <div className="my-matches-div kk">
          <InterestView
            posts={
              props.isMatchActive ? props.matchedPosts : props.receivedPosts
            }
            lng={lng}
          />

          {props.isMatchActive ? (
            isEmptyObj ? null : props.post.code ? (
              props.post.code === 1006 ? (
                <IncorrectToken lng={lng} />
              ) : props.post.code === 1004 ? (
                <UnautorizedProfile lng={lng} />
              ) : (
                <>
                  <MessageView lng={lng} />
                  <PersonalProfile lng={lng} />
                </>
              )
            ) : (
              <>
                <MessageView lng={lng} />
                <PersonalProfile lng={lng} />
              </>
            )
          ) : isEmptyObj ? null : props.post.code ? null : (
            <ReceivedInterest lng={lng} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAllInterest, handleIntBtns }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    post: state.matches.post,
    matchedPosts: state.matches.allInterests.matches
      ? state.matches.allInterests.matches
      : [],
    receivedPosts: state.matches.allInterests.received
      ? state.matches.allInterests.received
      : [],
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withLanguageMigrator(MyMatches));
