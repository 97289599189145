import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import withLanguageMigrator from "../../v2/hoc/language-migrator";

const AccountCreation = ({ lng }) => {
  const [oneMonth, setOneMonth] = useState(0);
  const [twoMonth, setTwoMonth] = useState(0);
  const [threeMonth, setThreeMonth] = useState(0);

  const [selected, setSelected] = useState(1);
  const [expandArr, setExpandArr] = useState([]);

  const QNA = [
    {
      id: 1,
      quee: "What is the Poruwa.lk website?",
      ques: "පෝරුව.lk වෙබ් අඩවිය යනු කුමක්ද ?",
      answe:
        "Poruwa.lk is the most trusted matrimony website made by Sri Lankans for Sri Lankans living in Sri Lanka and overseas. Our focus on prioritizing privacy while delivering a smooth matching experience for our customers makes us unique from other websites. Your identity-revealing information will only be shared on Ads that you accept.",
      answs:
        "පෝරුව.lk යනු දැනට ශ්‍රී ලංකාවේ ඇති ප්‍රමුඛ හා වඩාත් විශ්වාසදායි මංගල දැන්වීම් වෙබ් අඩවියයි.  Poruwa.lk  හි විශේෂත්වය වනුයේ ඔබගේ පෞද්ගලිකත්වයට මුල් තැන දීමයි. එනම් ඔබගේ අනන්‍යතාවය පෙන්වන විස්තර පෙන්වනු ලබන්නේ ඔබ විසින් අනුමත කරන දැන්වීම් සඳහා පමණයි. අප හා ලියාපදිංචි වන පාරිබෝගිකයින් හට දැනට පුවත් පත් වලින් පරිශීලනය කරනවාට වඩා කාර්යක්ෂමව  තමන්ට ගැලපෙන  සහකරුවකු/ සහකාරියකු සොයා ගත හැකියි.",
    },
    {
      id: 2,
      quee: "Are you a government-registered institution?",
      ques: "ඔබ රජයේ ලියාපදිංචි ආයතනයක්ද ?",
      answe:
        "Yes. We are a registered software company operating in both Sri Lanka and Australia. See www.poruwa.lk/terms for registration numbers and other details.",
      answs:
        "ඔව්. අප ශ්‍රී ලංකාවේ හා ඕස්ට්‍රේලියාවේ ලියාපදිංචි මෘදුකාංග නිපදවන ආයතනයකි. ලියාපදිංචි අංක හා අනෙකුත් විස්තර සඳහා www.poruwa.lk/terms පිටුව බලන්න.",
    },
    {
      id: 3,
      quee: "Who can post an Ad on Poruwa.lk?",
      ques: "පෝරුව.lk වෙබ් අඩවියෙහි දැන්වීම් පල කල හැක්කේ කාටද ?",
      answe: (
        <>
          Sri Lankans between the ages of 18 and 60 who wish to get married, can
          create an Ad on Poruwa.lk
        </>
      ),
      answs:
        "ශ්‍රී ලාංකික හෝ ශ්‍රී ලාංකික පසුබිමක් ඇති වයස අවුරුදු 18 ත් 60 ත් අතර විවාහ වීමට අපේක්ෂා කරන ඕනෑම අයකුට අපගේ වෙබ් අඩවිය තුල දැන්වීමක් පල කල හැකියි.",
    },
    {
      id: 4,
      quee: "How to create an Ad on Poruwa.lk website?",
      ques: "පෝරුව.lk වෙබ් අඩවියෙහි දැන්වීමක් සාදන්නේ කෙසේද ?",
      answe:
        "You can create an Ad by going to 'Create/Login' on the top right corner of the website. After confirming your phone number, you can fill in the details mentioned on the website and create your Ad",
      answs:
        "වෙබ් අඩවියෙහි දකුණු පස  කෙළවරෙහි  ඇති “ගිණුමක් තනන්න හෝ පිවිසෙන්න’’ වෙත ගොස් ඔබගේ දුරකථන අංකය යොදා එය තහවුරු කිරීමෙන් පසු වෙබ් අඩවියෙහි අසා ඇති විස්තර පුරවා දැන්වීමක්  සෑදිය හැක",
    },
    {
      id: 5,
      quee: "How much does it cost to post an Ad?",
      ques: "දැන්වීමක් පල කිරීමට කොපමණ මුදලක් ගෙවිය යුතුද ?",
      answe: (
        <>
          If the advertisement is for a resident of Sri Lanka, the prices of the
          plans are as follows,{" "}
          <ul>
            <li>Rs. {oneMonth}/- (1 Month)</li>
            <li>Rs. {twoMonth}/- (2 Months)</li>
            <li>Rs. {threeMonth}/- (3 Months)</li>
          </ul>
          and if the advertisement is for a Sri Lankan residing abroad, the
          payments will be charged as shown on this page,{" "}
          <a href="/pricing" target="_blank">
            www.poruwa.lk/pricing
          </a>
        </>
      ),
      answs: (
        <>
          ඔබ ශ්‍රී ලංකාවේ පදිංචි අයෙක් වෙනුවෙන් දැන්වීම පල කරන්නේනම්,{" "}
          <ul>
            <li>Rs. {oneMonth}/- (මාසයක් සදහා)</li>
            <li>Rs. {twoMonth}/- (මාස 2ක් සදහා)</li>
            <li>Rs. {threeMonth}/- (මාස 3ක් සදහා)</li>
          </ul>
          ක්ද, විදේශ රටක පදිංචි අයෙක් වෙනුවෙන් දැන්වීම පල කරන්නේනම්,{" "}
          <a href="www.poruwa.lk/pricing" target="_blank">
            www.poruwa.lk/pricing
          </a>{" "}
          පිටුවේ දැක්වෙන ආකාරයට අය කිරීම් කරනු ලැබේ.
        </>
      ),
    },
    {
      id: 6,
      quee: "How long is my Ad valid for?",
      ques: "මාගේ දැන්වීම කොපමණ කාලයක් වලංගු වේද?",
      answe:
        "The advertisement will be valid from the date of publication on the website for the relevant period according to the package you choose.",
      answs:
        "දැන්වීම වෙබ් අඩවියේ පල කරන දින සිට ඔබ තෝරාගන්නා පැකේජයට අනුව අදාළ කාලසීමාව තුල වලංගු වේ",
    },
    {
      id: 7,
      quee: "In what languages ​​can this website be used?",
      ques: "මෙම  වෙබ් අඩවිය පරිශීලනය කල හැක්කේ කුමන භාෂා වලින්ද ?",
      answe:
        "You can access the website in both English and Sinhala languages. You can select the preferred language by clicking on the ‘Menu’ button in the top right corner.",
      answs:
        "ඔබට ඉංග්‍රීසි හා සිංහල යන භාෂා දෙකෙන්ම වෙබ් අඩවිය පරිශීලනය කල හැකි අතර දකුණු පස උඩ කෙලවරේ ඇති මෙනුව වෙත ගොස් ඔබට අවශ්‍ය විටෙක එය වෙනස් කල හැක.",
    },
    {
      id: 8,
      quee: "Can I create an Ad without a phone number?",
      ques: "දුරකථන අංකයක් නොමැතිව දැන්වීමක් සෑදිය හැකිද?",
      answe:
        "A phone number is required to create an Ad. We always verify phone numbers with an OTP code and add them to the Ad, and no one can create an Ad using your phone number without your knowledge.",
      answs:
        "දැන්වීමක් සෑදීම සදහා දුරකථන අංකය අත්‍යවශ්‍ය වේ. සෑම විටම අප දුරකථන අංක OTP කේතයක් මගින් තහවුරු කර දැන්වීමට එකතු කරගන්නා අතර ඔබගේ දැනුවත් වීමකින් තොරව කිසිවෙකුට ඔබගේ දුරකථන අංකය ඔස්සේ දැන්වීමක් සෑදිය නොහැක.",
    },
    {
      id: 9,
      quee: "Can I create an Ad without an email address?",
      ques: "ඊමේල් ලිපිනයක් නොමැතිව දැන්වීමක් සාදා ගත හැකි ද?",
      answe:
        "Yes. Entering an email address is not mandatory. But we recommend that you enter your email address to receive notifications that we send to your Ad and to receive the monthly magazine and know about special offers.",
      answs:
        "ඊමේල් ලිපිනයක් ඇතුලත් කිරීම අත්‍යවශ්‍ය නැත. නමුත් ඔබගේ දැන්වීම වෙත අප එවන දැනුම්දීම් දැක ගැනීම සදහා සහ මාසික සගරාව සහ විශේෂ දීමනා පිලිබදව දැනුවත් වීමට ඊමේල් ලිපිනය ඇතුලත් කරන ලෙස අප නිර්දේශ කරන්නෙමු.",
    },
    {
      id: 10,
      quee: "Can parents post an Ad on behalf of their child?",
      ques: "දෙමව්පියන්ට තම දරුවා වෙනුවෙන් දැන්වීමක් පල කල හැකිද ?",
      answe:
        "Yes, an Ad can be posted on behalf of a person by a family member. Contact details of the person that should be contacted regarding the Ad can be included when creating the Ad",
      answs:
        "ඔව්. දරුවෙකු වෙනුවෙන් හෝ පවුලේ අයෙකු වෙනුවෙන් දන්වීමක් පලකර ගත හැකි වන අතර එම දැන්වීම වෙනුවෙන් සම්බන්ධ කර ගත යුතු වන්නේ ඔබව නම් ඔබගේ දුරකථන විස්තර යොදා ගිණුම සාදන්න.",
    },
    {
      id: 11,
      quee: "Can I find a Sri Lankan partner living abroad through Poruwa.lk?",
      ques: "පෝරුව.lk වෙබ් අඩවිය හරහා විදේශ රටක ජිවත් වන ශ්‍රී ලාංකික සහකරුවෙකු/ සහකාරියක් සොයා ගත හැකිද ?",
      answe:
        "Yes. If you are a Sri Lankan, regardless of the country where you live, you can contact anyone who has posted an ad on our website",
      answs:
        "ඔව්. ඔබ  ජිවත් වන රට කුමක් වුවත් ශ්‍රී ලාංකික හෝ ශ්‍රී ලාංකික පසුබිමක් ඇති අයෙක් නම් අපගේ වෙබ් අඩවිය තුල දැන්වීම් පල කර ඇති ඕනෑම කෙනෙකු හා ඔබට සම්බන්ද විය හැකියි.",
    },
    {
      id: 12,
      quee: "Is my information secure on this site?",
      ques: "මාගේ තොරතුරු මෙම වෙබ් අඩවියෙහි ආරක්ෂිත ද ?",
      answe:
        "Yes. We always prioritize your privacy and store your data in accordance with international standards. Your identity-revealing information (last name, pictures, horoscope, date of birth, phone number) will only be shared with the profiles that you accept.",
      answs:
        "ඔව්. අප සෑම විටම ඔබගේ පුද්ගලිකත්වය සදහා ප්‍රමුඛත්වය ලබා දෙන අතර අන්තර්ජාතික ප්‍රමිතීන්ට අනුව ඔබගේ දත්ත ගබඩා කරනු ලැබේ. ඔබගේ අනන්‍යතාව පෙන්වන විස්තර (සම්පූර්ණ නම, පින්තුර, කේන්දර විස්තර, උපන්දිනය, දුරකථන අංකය) පෙන්වනු ලබන්නේ ඔබ විසින් අනුමත කරන අයට පමණි.",
    },
    {
      id: 13,
      quee: "Is it mandatory to enter my full name?",
      ques: "මාගේ සම්පුර්ණ නම ඇතුලත් කිරීම අනිවාර්යද?",
      answe: (
        <>
          No, your full name is not required. We recommend that you enter your
          first and last name correctly to keep the authenticity of the Ads and
          ensure that the other party has more trust in your Ad. Your last name
          will only be shown to people you accept. You can always change the
          displaying format of your name as per your preference.{" "}
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            Ex – You can display only your first name in the Ad.
          </div>
        </>
      ),
      answs: (
        <>
          ඔබගේ සම්පුර්ණ නම අවශ්‍ය නොවේ. සම්බන්ධ වූ පසු අනෙක් පාර්ශවයේ විශ්වාසය
          තබා ගැනීමට ඔබගේ මුල් නම හා අවසාන නම නිවැරදිව ඇතුලත් කිරීම අපි නිර්දේශ
          කරමු. ඔබගේ නම අනෙක් පාර්ශවයන්ට පෙන්වන ආකෘතිය ඔබට අවශ්‍ය ලෙස වෙනස් කල
          හැක.{" "}
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            උදා - දෙවන නම සගවා පළමු නම පමණක් දැන්වීමෙහි පෙන්විය හැක.
          </div>
        </>
      ),
    },
    {
      id: 14,
      quee: "Can photos that are uploaded when creating an Ad be seen by the public?",
      ques: "දැන්වීම සාදන අවස්ථාවේදී ඇතුලත් කරන ලද පින්තූර පොදුවේ අනෙක් පාර්ශවයන් හට දැක ගත හැකිද?",
      answe:
        "No. You can only view photos when you match with another profile via ‘SHOW INTEREST’. Both parties must have included pictures in their ads for the pictures to be visible. If at least one party has not uploaded photos, both ads’ pictures will not be shown to each other.",
      answs:
        "නැත. ඔබ ඇතුලත් කරන ලද පින්තුර පෙන්වනු ලබන්නේ ඔබ සමග ගැලපෙන පාර්ශවයන් හට පමණක් වන අතර අනෙක් පාර්ශවයේ පින්තුර වැනි පුද්ගලික විස්තර දැක ගැනීමට දෙදෙනාම තම දැන්වීම් වලට පින්තුර ඇතුලත් කර තිබීම අනිවාර්ය වේ. එක අයකු හෝ පින්තුර ඇතුලත් කර නොමැති විට දෙදෙනාටම අනෙක් පාර්ශවයේ පින්තුර නොපෙන්වයි.",
    },
    {
      id: 15,
      quee: "Is it mandatory to enter the date of birth when creating an Ad?",
      ques: "දැන්වීම පල කිරීමේදී උපන් දිනය ඇතුලත් කල යුතුද?",
      answe:
        "Yes. It is mandatory to enter the date of birth, but only your age will be shown in the public Ad. Birthdays are shown only to the profiles that you match.",
      answs:
        "ඔව්. උපන් දිනය ඇතුලත් කිරීම අනිවාර්ය වන අතර ඔබගේ වයස පමණක් පොදු දැන්වීම සමග පෙන්වනු ලැබේ. උපන්දිනය ඔබ හා සම්බන්ධ වන අයට පමණක් පෙන්වනු ලැබේ.",
    },
    {
      id: 16,
      quee: "How can I add a profession not listed in the section when creating an Ad?",
      ques: "රැකියාව ඇතුලත් කරන කොටසෙහි මාගේ රැකියාව දක්වා නොමැති විට එය දැන්වීමට ඇතුලත් කල හැක්කේ කෙසේද?",
      answe:
        "When the profession list does not include the profession that you are currently employed in, you can enter your specific job by selecting the 'Other' option at the bottom of that list.",
      answs:
        "රැකියා කොටසෙහි ඔබ නියැලෙන නිශ්චිත රැකියාව දක්වා නොමැති විට එම  කොටසෙහි පහලම ඇති ‘වෙනත්’ යන්න තෝරා ඔබගේ නිශ්චිත රැකියාව ඇතුලත් කල හැක.",
    },
    {
      id: 17,
      quee: "What is meant by a ‘Differently abled’ person?",
      ques: "විශේෂ අවශ්‍යතා ඇති පුද්ගලයකු යනුවෙන් අදහස් කරන්නේ කවුරුන්ද ?",
      answe:
        "If a person is suffering from any disability from birth or later, an acknowledgement can be made by pressing ‘Yes’ in that section to let the other parties know about his/her disability.",
      answs:
        "යම් කිසි පුද්ගලයකු උපතින් හෝ පසු කලෙක සිට යම්කිසි ආබාධ තත්වයකින් පෙලෙන අයෙක් නම් ඔහුගේ/ඇයගේ ආබාධ තත්ත්වය අනෙක් පාර්ශවයන්ට දැන ගැනීම සදහා එම කොටසෙහි “ඔව්” යන්න ඔබා දැන්වීම ඉදිරියට සෑදිය හැක.",
    },
    {
      id: 18,
      quee: "Is it mandatory to add parent information?",
      ques: "දෙමව්පියන්ගේ විස්තර එක් කිරීම අනිවාර්ය වේද ?",
      answe:
        "It is mandatory to include Ethnicity, Religion, and country of residence under the details of the parents. Other details are optional.",
      answs:
        "දෙමව්පියන්ගේ විස්තර තුල ජාතිය, ආගම සහ පදිංචි රට ඇතුලත් කිරීම අනිවාර්ය වේ. අනෙකුත් විස්තර ඔබට කැමති පරිදි ඇතුලත් කල හැක. ",
    },
    {
      id: 19,
      quee: "Can a person who does not have or does not believe in horoscopes avoid entering horoscope details?",
      ques: "කේන්දර විස්තර නොමැති හෝ ඒ පිලිබදව විශ්වාසයක් නොමැති අයකුට කේන්දර විස්තර ඇතුලත් කිරීමෙන් වැලකී සිටිය හැකිද?",
      answe:
        "Yes. You may proceed by selecting 'Horoscope details not required'.",
      answs:
        "ඔව්. ඔබට කේන්දර විස්තර ඇතුලත් නොකර සිටිය හැකියි. ‘කේන්දර ගැලපීම් අවශ්‍ය නොවේ’ යන්න තෝරා ඉදිරියට යා හැක.",
    },
    {
      id: 20,
      quee: "What is meant by 'Accept written interests via post'?",
      ques: "ලිපි මගින් පිළිතුරු ලබා ගැනීම යනු කුමක්ද ?",
      answe:
        "When you enable this, a person who is not registered with us will be able to see your Ad in the monthly magazine or the website and respond to your Ad via post. This provides your Ad with more visibility. Your address will not be shown publicly. All the emails will be sent to Poruwa.lk, and we’ll forward the emails received for your Ad to your address. There is no extra charge for this service.",
      answs:
        "ඔබ මෙය සක්‍රීය කල විට, අප හා ලියාපදිංචි වී නොමැති කෙනෙකු හට මාසික සගරාව තුලින් හෝ වෙබ් අඩවිය තුලින් ඔබගේ දැන්වීම බලා ලිපියක් මගින් පිළිතුරු දීමේ හැකියාව ඇත. අප ඔවුන් සමග ඔබගේ ලිපිනය බෙදා නොගන්නා අතර දන්වීම වෙනුවෙන් අපිට ලැබෙන ලිපි එකතු කර අප විසින් ඔබ වෙත එවනු ලැබේ. මේ සඳහා අමතර ගාස්තුවක් අය නොකෙරේ.",
    },
    {
      id: 21,
      quee: "Can I add more details in addition to the details filled out when creating the Ad?",
      ques: "මෙහි ඇති විස්තර වලට අමතරව තවත් විස්තර දන්වීම සමග ඇතුලත් කල හැකි ද?",
      answe:
        "You may add more information about yourself in the 'Additional Details' section of the page when creating the Ad.",
      answs:
        "ඔබගේ විස්තර ඇතුලත් කරන පිටුවෙහි ඇති ‘අමතර විස්තර’ කොටසෙහි ඔබ පිලිබදව වැඩි දුර විස්තරයක් ඇතුලත් කල හැක.",
    },
    {
      id: 22,
      quee: "Why does an error code appear when entering the Ad information and proceeding?",
      ques: "දැන්වීමෙහි තොරතුරු ඇතුලත් කර ඉදිරියට යාමේදී දෝෂ කේතයක් දිස්වන්නේ ඇයි?",
      answe:
        "Double-check if all the required information fields have been filled in. If the error code or message still appears after entering all the details, you may call our customer service number (+94 112 824 814) and provide the error code for assistance.",
      answs:
        "අත්‍යවශ්‍ය තොරතුරු පිරවීමට ඇති කොටස් සියල්ල පිරවුවාදැයි නැවත පරික්ෂා කරන්න. සියලු විස්තර ඇතුලත් කල පසුවත් දෝෂ කේතය හෝ පණිවිඩය පෙන්නුම් කරන්නේ නම් අපගේ පාරිභෝගික සේවා අංකය අමතා දෝෂ කේතය ලබා දී සහය ලබා ගන්න.",
    },
    {
      id: 23,
      quee: "How do I pay for an Ad?",
      ques: "දැන්වීමක් සඳහා මුදල් ගෙවන්නේ කෙසේද?",
      answe: (
        <React.Fragment>
          If you are making the payment online, you can choose one of the
          following methods,
          <ul>
            <li>Credit or Debit cards</li>
            <li>eZ Cash</li>
            <li>FriMi</li>
            <li>PayPal</li>
            <li>Sampath Vishwa</li>
            <li>Dialog Genie</li>
          </ul>
          Also, payments can be credited to any of the bank accounts that we
          have in several countries such as Sri Lanka, Australia, The Un, United
          Kingdom, and after such payment, a photo or copy of the receipt should
          be sent to <a href="mailto:pay@poruwa.lk">pay@poruwa.lk</a>
        </React.Fragment>
      ),
      answs: (
        <React.Fragment>
          ඔබ අන්තර්ජාලය හරහා ගෙවීම් සිදුකරන්නේ නම් පහත දැක්වෙන ආකාර වලින් එකක්
          තෝරාගත හැක,
          <ul>
            <li>Credit හෝ Debit කාඩ් පත්</li>
            <li>eZ Cash</li>
            <li>FriMi</li>
            <li>PayPal</li>
            <li>Sampath Vishwa</li>
            <li>Dialog Genie</li>
          </ul>
          තවද, ශ්‍රී ලංකාව, ඕස්ට්‍රේලියාව, එක්සත් ජනපදය, එක්සත් රාජාධානිය වැනි
          රටවල් කිහිපයක ඇති අපගේ ඕනෑම බැංකු ගිණුමකට මුදල් බැර කල හැකි අතර එසේ
          මුදල් බැර කිරීමෙන් පසු එම රිසිට් පතේ පින්තුරයක් හෝ පිටපතක්{" "}
          <a href="mailto:pay@poruwa.lk">pay@poruwa.lk</a> යන ඊමේල් ලිපිනය ඔස්සේ
          හෝ අපගේ WhatsApp අංකය වෙත යොමු කල යුතු වේ.
        </React.Fragment>
      ),
    },
    {
      id: 24,
      quee: "Is my payment method secure on this site?",
      ques: "මෙම වෙබ් අඩවිය තුල මාගේ  මුදල් ගෙවන ක්‍රමය ආරක්‍ෂිත ද?",
      answe:
        "Yes, we use the safest and most reliable payment methods in Sri Lanka and internationally such as Pay Here, Stripe, and PayPal to ensure that your payments are always secure.",
      answs:
        "අප ශ්‍රී ලංකාවේ හා ජාත්‍යන්තරව පිළිගත් PayHere, Stripe, PayPal වැනි ආරක්ෂාකාරී හා වඩාත් විශ්වාසදායී මුදල් ගෙවීමේ ක්‍රම භාවිතා කරන අතර ඔබගේ මුදල් ගෙවීම් සෑම විටම ආරක්ෂාකාරී වේ. මෙහිදී ඔබගේ කාඩ් පත් විස්තර කිසිවක් අප ගබඩා කර නොගනී.",
    },
    {
      id: 25,
      quee: "How long does it take for my Ad to get published on the site?",
      ques: " මාගේ දැන්වීම වෙබ් අඩවිය තුල පල වීමට කොපමණ කාලයක් ගත වේද ?",
      answe:
        "Your advertisement will be published on the website within 12 hours from the time you make the payment by card or when you make the payment to a bank account and send us the receipt.",
      answs:
        "ඔබ කාඩ්පත් මගින් ගෙවීම සිදු කරන අවස්ථාවේ සිට හෝ බැංකු ගිණුමකට ගෙවීමක් සිදු කර රිසිට් පත අප වෙත ලබා දුන් අවස්ථාවේ සිට පැය 12ක් ඇතුලත ඔබගේ දැන්වීම වෙබ් අඩවිය තුල පල වේ.",
    },
    {
      id: 26,
      quee: "What is meant by ‘Attract more responses’?",
      ques: "‘දැන්වීම ආකර්ශනීය කරන්න’ යනු කුමක්ද?",
      answe:
        "You can improve the attractiveness of your Ad by submitting details enabling features such as identity verification, Top Ad, photos, offline responses, interest preferences, and details about siblings.",
      answs:
        "අනන්‍යතාවය තහවුරු කිරීම, Top Ad, ජායාරුප, ලිපි මගින් පිළිතුරු ලබා ගැනීම, කැමැත්ත පිලි ගැනීමේ නිර්ණායක, සහෝදර සහෝදරියන් පිළිබද විස්තර යන විස්තර දැන්වීමට ඇතුලත් කිරීම තුලින්  ඔබගේ දැන්වීමට තිබෙන ආකර්ශනීය බාවය වැඩි දියුණු කර ගත හැක.",
    },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/pricing_tiers`)
      .then((res) => {
        setOneMonth(res.data[0].amountLKR);
        setTwoMonth(res.data[1].amountLKR);
        setThreeMonth(res.data[2].amountLKR);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="new-faq-mobile">
        {QNA.map((qna) => (
          <div className="new-faq-div">
            <div className="new-faq-col">
              <div
                onClick={() => {
                  if (!expandArr.includes(qna.id)) {
                    setExpandArr((current) => [...current, qna.id]);
                  } else {
                    setExpandArr(
                      expandArr.filter((current) => current !== qna.id)
                    );
                  }
                }}
                className="new-faq-ques"
              >
                <div className="new-faq-ques-wrapper">
                  {lng === true ? <>{qna.quee}</> : <span>{qna.ques}</span>}
                </div>
                {expandArr.includes(qna.id) ? (
                  <i class="fas fa-chevron-down faq-drawer-icon"></i>
                ) : (
                  <i class="fas fa-chevron-right faq-drawer-icon"></i>
                )}
              </div>
              <p
                className={
                  expandArr.includes(qna.id)
                    ? "new-faq-ans-expand"
                    : "new-faq-ans"
                }
              >
                {lng === true ? (
                  <React.Fragment>{qna.answe}</React.Fragment>
                ) : (
                  <span>{qna.answs}</span>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withLanguageMigrator(AccountCreation);
