import { Chip, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../../../../context";
import "./style.css";
import { useTranslation } from "react-i18next";
import FormImageSelector from "../../../../../../components/molecules/form-image-selector";
import { getAuthTempProfileImage } from "../../../../../../features/application";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    flexWrap: "wrap",
    rowGap: 8,
    columnGap: 8,
    width: "100%",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "48%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "32%",
    },
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%",
    },
  },
}));

const ReviewImageSection = ({ heading, isPrivate, pictures = [] }) => {
  const { appLanguage } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const styleClasses = useStyles();
  const [downloadedImages, setDownloadedImages] = useState([]);

  const downloadProfileImages = useCallback(async () => {
    const temp = [];
    for (const file of pictures) {
      try {
        const image = await getAuthTempProfileImage({ id: file });
        temp.push(image);
      } catch (e) {
        console.log("Error downloadProfileImages ", e);
      }
    }
    setDownloadedImages(temp);
  }, [pictures]);

  useEffect(() => {
    if (!Array.isArray(pictures)) {
      return;
    }
    downloadProfileImages();
  }, [pictures, downloadProfileImages]);

  return (
    <Grid container md={12} xs={12} direction="column" style={{ rowGap: 16 }}>
      <Grid
        direction="row"
        container
        wrap="nowrap"
        alignItems="center"
        style={{ columnGap: 8 }}
      >
        <Typography
          className={`review-image-section-title typo-h4-${i18n.language} capitalized-text`}
        >
          {heading}
        </Typography>
        {isPrivate && (
          <Chip
            size="small"
            label="Private"
            color="primary"
            className="data-section-chip"
          />
        )}
      </Grid>
      <Grid container direction="column" style={{ rowGap: 4 }}>
        {!isEmpty(downloadedImages) ? (
          <Grid
            container
            direction="row"
            className={`column-gap-8 ${styleClasses.outerContainer}`}
          >
            {downloadedImages.map((image, idx) => (
              <Grid
                key={`profile-image-${idx}`}
                item
                className={styleClasses.root}
              >
                <FormImageSelector image={image} onSelect={() => {}} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid>
            <Typography className="image-empty-text">No Pictures</Typography>
            <Grid className="image-notice-wrapper">
              <Typography
                className={`image-notice image-notice-${appLanguage}`}
              >
                <Grid
                  className="fas fa-exclamation-triangle notice-symbol"
                  style={{ paddingRight: 8 }}
                />
                {t("createflow.pictureWarning")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewImageSection;
