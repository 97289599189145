import React, { useState } from "react";
import { MoreVert } from "@material-ui/icons";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";

const MessageMoreInfoButton = ({ menuItems, isSecondryItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (isSecondryItems) {
    return false;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="interest-menu-items"
        aria-haspopup="true"
        onClick={handleMenuClick}
        style={{ color: "#cca326", padding: 0 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="interest-menu-items"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            onClick={() => {
              menuItem.onClick();
              setAnchorEl(null);
            }}
            className="menu-list-text"
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MessageMoreInfoButton.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default MessageMoreInfoButton;
