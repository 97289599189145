import { Grid, Select, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import "./style.css";
import moment from "moment";
import { range } from "lodash";
import FormInteractiveButton from "../form-interactive-button";
import FormItemLabel from "../../atoms/form-item-label";

export const DATE_PATTERN = "YYYY-MM-DD";
const INNER_DATE_PATTERN = "YYYY-MMM-DD";

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

const CRITERIA = Object.freeze({
  year: "year",
  month: "month",
  day: "day",
});

const PLACEHOLDER = Object.freeze({
  [CRITERIA.year]: "YYYY",
  [CRITERIA.month]: "MMM",
  [CRITERIA.day]: "DD",
});

const MONTH_OPTIONS = moment.monthsShort();

const FormDatePicker = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  defaultDate,
  value,
  onChange,
  startDate,
  endDate,
  label,
  labelStyles,
  lang,
  errorText = "",
  disabled = false,
  visibility,
}) => {
  const startDateMoment = moment(startDate, DATE_PATTERN);
  const endDateMoment = moment(endDate, DATE_PATTERN);
  // const valueMoment = value ? moment(value, DATE_PATTERN) : undefined;

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);

  const generateOptions = (criteria) => {
    let output = [];
    if (!Object.keys(CRITERIA).includes(criteria)) {
      return output;
    }
    switch (criteria) {
      case CRITERIA.year:
        output = range(startDateMoment.year(), endDateMoment.year() + 1).map(
          (i) => i.toString()
        );
        break;
      case CRITERIA.month:
        output = MONTH_OPTIONS;
        if (year === startDateMoment.year().toString()) {
          const fromIndex = MONTH_OPTIONS.indexOf(
            startDateMoment.format("MMM")
          );
          output = MONTH_OPTIONS.slice(fromIndex);
        }
        if (year === endDateMoment.year().toString()) {
          const endIndex =
            MONTH_OPTIONS.indexOf(endDateMoment.format("MMM")) + 1;
          output = MONTH_OPTIONS.slice(0, endIndex);
        }
        break;
      case CRITERIA.day:
        const daysInMonth = moment(
          `${year}-${month}`,
          "YYYY-MMM"
        ).daysInMonth();
        output = range(1, daysInMonth + 1).map((item) =>
          item.toString().padStart(2, 0)
        );
        if (
          year === startDateMoment.year().toString() &&
          month === startDateMoment.format("MMM")
        ) {
          const fromIndex = output.indexOf(startDateMoment.format("DD"));
          output = output.slice(fromIndex);
        }

        if (
          year === endDateMoment.year().toString() &&
          month === endDateMoment.format("MMM")
        ) {
          const endIndex = output.indexOf(endDateMoment.format("DD"));
          output = output.slice(0, endIndex + 1);
        }
        break;
      default:
        break;
    }

    return output;
  };

  const resetPicker = () => {
    setMonth("");
    setDay("");
  };

  const setDate = (date) => {
    if (typeof date === "string" && date.length > 0) {
      const valueMoment = moment(date, DATE_PATTERN);
      setYear(valueMoment.year().toString());
      setMonth(valueMoment.format("MMM"));
      setDay(valueMoment.format("DD"));
    }
  };

  useEffect(() => {
    setDate(value);
  }, [value]);

  useEffect(() => {
    setYearOptions(generateOptions(CRITERIA.year));
  }, []);

  useEffect(() => {
    if (year.length === 0) {
      return;
    }
    setMonthOptions(generateOptions(CRITERIA.month));
  }, [year]);

  useEffect(() => {
    if (Boolean(year) && Boolean(month)) {
      setDayOptions(generateOptions(CRITERIA.day));
    }
  }, [year, month]);

  useEffect(() => {
    if (month.length === 0 || day.length === 0) {
      return;
    }
    const valueMoment = moment(`${year}-${month}-${day}`, INNER_DATE_PATTERN);

    if (valueMoment.isBefore(startDateMoment)) {
      resetPicker();
      return;
    }

    if (valueMoment.isAfter(endDateMoment)) {
      resetPicker();
      return;
    }
    if (onChange !== undefined) {
      onChange(valueMoment.format(DATE_PATTERN));
    }
  }, [year, month, day]);

  if (!visibility) {
    return false;
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className="form-date-picker"
    >
      <FormItemLabel
        text={label}
        lang={lang}
        // labelPosition={labelPosition}
        styleOverrides={labelStyles}
      />
      <Grid item xs>
        <Grid
          container
          direction="row"
          style={{ justifyContent: "space-between" }}
        >
          <Grid item className="generic-dropdown-container">
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              variant="outlined"
              native
              fullWidth
              error={Boolean(errorText)}
              disabled={disabled}
            >
              <option selected hidden>
                {PLACEHOLDER.year}
              </option>
              {yearOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item className="generic-dropdown-container">
            <Select
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              variant="outlined"
              native
              fullWidth
              disabled={disabled || year.length === 0}
            >
              <option selected hidden>
                {PLACEHOLDER.month}
              </option>
              {monthOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item className="generic-dropdown-container">
            <Select
              value={day}
              onChange={(e) => setDay(e.target.value)}
              variant="outlined"
              native
              fullWidth
              disabled={disabled || month.length === 0}
            >
              <option selected hidden>
                {PLACEHOLDER.day}
              </option>
              {dayOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </Grid>
          {errorText && (
            <Grid item xs={12}>
              <Typography
                variant="caption"
                className={`form-error-text form-error-text-${lang}`}
              >
                {errorText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {mode === Mode.interactive && showInteractiveActions && (
        <FormInteractiveButton
          onClickInteractiveClear={() => defaultDate && setDate(defaultDate)}
          onClickInteractiveSave={onClickInteractiveSave}
        />
      )}
    </Grid>
  );
};

export default FormDatePicker;
