import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import OTPLoginCom from "../components/register/OTPLoginCom";
import PageHead from "../components/common/PageHead";
import PageMetaDescription from "../components/common/PageMetaDescription";
// import Navbar from "../components/common/Navbar";
import Header from "../components/register/new/Header";
import ButtonNav from "../components/register/new/ButtonNav";
import Feature from "../components/register/new/Feature";
import "../styles/register-new.css";
import "../styles/reviews.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewComponent from "../components/common/ReviewComponent";
import axios from "axios";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class RegisterNew extends Component {
  state = {
    value: 0,
    reviews: [],
    metaContent: 
    "Discover private matrimony at Poruwa.lk | Sri Lankan Matrimony , Sri Lanka's top site. Genuine profiles, secure environment. Find your soulmate discreetly with us. Join now for meaningful connections!"

  };

  updateDimensions = () => {
    if (window.innerWidth > 768) {
      this.setState({ value: 0 });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.getReviews(0, 6);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleButtonNavs = (btn) => {
    this.setState({ value: btn });
  };

  getReviews = (pageNum, pageSize) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/reviews?pageNum=${pageNum}&pageSize=${pageSize}`)
      .then((res) => {
        if (res.data) {
          this.setState({ reviews: res.data.results });
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      arrows: false,
      speed: 1000,
      infinite: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <React.Fragment>
        <PageHead title={`Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`} />
        <PageMetaDescription name="auth" content={this.state.metaContent}/>
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div
          className="register-new"
          style={{ background: "#f7f7f7", minHeight: "100vh" }}
        >
          <Header />

          <ButtonNav
            handleButtonNavs={this.handleButtonNavs}
            value={this.state.value}
          />

          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container cus-container-register cus-new-register-container"
          >
            <div className="reg-container">
              {this.state.value === 0 && (
                <div className="reg-col">
                  <OTPLoginCom lng={this.props.lng} />
                </div>
              )}
              {this.state.value !== 0 && (
                <div className="reg-col">
                  <Feature lng={this.props.lng} />
                </div>
              )}

              <div className="reg-col feature-col-cus">
                <Feature lng={this.props.lng} />
              </div>
            </div>
          </Grid>

          <p className="terms-n-policy">
            {this.props.lng
              ? "By signing up you are agreeing to our "
              : "ඉදිරියට යෑමෙන් ඔබ අපගේ "}
            <Link to="/privacy">
              {this.props.lng ? "Privacy Policy" : "රහස්‍යතා ප්‍රතිපත්තිය"}
            </Link>{" "}
            {this.props.lng ? "and" : "සහ"}{" "}
            <Link to="/terms">
              {this.props.lng ? "Terms of Use" : "සේවා කොන්දේසි "}
            </Link>
            {this.props.lng ? "" : "වලට එකග වේ"}.
          </p>
          <div>
          {Config.REVIEWS_AND_RATINGS_VISIBILITY &&
              this.state.reviews.length > 0 && (
              <div className="all-ads-main-container" style={{background: 'transparent'}}>
                <Grid container xs={12} sm={10} md={8} className="all-ads-container reviews-slider-grid">
                  <Grid item className="reviews-slider-sub-grid">
                    <Slider {...settings}>
                      {(this.state.reviews).map((review, i) => (
                        <ReviewComponent review={review} lang={this.props.lng} isRedirect={true}/>
                      ))}
                    </Slider>
                    <p
                      className="ignore-int-text-btn"
                      style={{textAlign: 'end'}}
                      onClick={() => this.props.history.push("/reviews")}
                    >
                      <span>
                        {this.props.lng ? (
                          "Load all reviews"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-13">
                            සියලු නිර්දේශ කිරීම් බලන්න
                          </span>
                        )}
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(RegisterNew));
