import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Pay from "../components/payment-link/Pay";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PaymentLink extends Component {
  state = {
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`} />

        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#f7f7f7" }}>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            direction="column"
            className="cus-container complete-ad-container"
          >
            <Typography
              variant="h3"
              align="center"
              className="create-new-ad"
              style={{
                paddingBottom: 25,
              }}
            >
              {this.props.lng === true ? (
                "Pay for Ad"
              ) : (
                <span>දැන්වීම අලුත් කරන්න</span>
              )}
            </Typography>

            <Pay align="center" lng={this.props.lng} />
          </Grid>
        </div>
        <div className="create-floor-footer-desktop">
          <Footer lng={this.props.lng} />
        </div>
        <div className="create-floor-footer-mobile">
          <FooterMobile lng={this.props.lng} />
        </div>
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(PaymentLink);
