import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FormSection from "../../../../../components/molecules/form-section";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import FormDropdown from "../../../../../components/molecules/form-dropdown";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import { useDispatch, useSelector } from "react-redux";
import { isFunction, useFormikContext } from "formik";
import AppContext from "../../../../../context";
import { getCommunitiesByReligionId } from "../../../../../features/generic-data";
import formMeta from "../../schema/meta.json";
import { fieldVisibility, trimFieldValue } from "../../../../../utils";
import Config from "../../../../../config";
import { setAppSnackNotification } from "../../../../../store/actions/application";
import { ADDITIONAL_COMMUNITY_OPTIONS } from "../../../../../static-data";

export const ParentType = Object.freeze({
  FATHER: "FATHER",
  MOTHER: "MOTHER",
});

const excludedFatherProfessionIds = [Config.HOUSE_WIFE_PROFESSION_ID];

const ParentInformation = ({
  parentType,
  setFormValues,
  setFomValidity,
  submitCount,
  additionalInfoError,
  setAdditionalInfoError,
}) => {
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    dirty,
    validateForm,
    setErrors,
  } = useFormikContext();
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { ethnicities, religions, countries, professions } = useSelector(
    (state) => state.genericData
  );
  const [oldShadow, setOldShadow] = useState({});
  const [otherProfessionVisibility, setOtherProfessionVisibility] =
    useState(false);
  const [communityOptions, setCommunityOptions] = useState([]);

  const labelStyles = {
    ...(isMobileScreen ? {} : { width: "20%" }),
  };

  const parentProfessions = useMemo(() => {
    return parentType === ParentType.FATHER
      ? professions.filter(
          (element) => !excludedFatherProfessionIds.includes(element.id)
        )
      : professions;
  }, [parentType, professions]);

  const getHeading = () => {
    switch (parentType) {
      case ParentType.FATHER:
        return t("common.father");
      case ParentType.MOTHER:
        return t("common.mother");
      default:
        return "Invalid";
    }
  };

  const fetchCommunities = useCallback(
    async ({ religionId }) => {
      try {
        const response = await getCommunitiesByReligionId({ religionId });
        if (!response.success) {
          throw new Error("unable to fetch communities");
        }
        setCommunityOptions([
          ...response.body,
          ...ADDITIONAL_COMMUNITY_OPTIONS,
        ]);
      } catch (error) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "Unable to fetch communities",
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (isEqual(oldShadow, values)) {
      return;
    }

    // Community field handling
    if (fieldVisibility(formMeta.communityId)) {
      if (!dirty && !isEmpty(values[formMeta.religionId.fieldName])) {
        fetchCommunities({ religionId: values[formMeta.religionId.fieldName] });
      } else if (
        oldShadow[formMeta.religionId.fieldName] !==
        values[formMeta.religionId.fieldName]
      ) {
        fetchCommunities({ religionId: values[formMeta.religionId.fieldName] });
        setFieldValue(formMeta.communityId.fieldName, "");
      }
    }
    // profession field handling
    if (fieldVisibility(formMeta.professionId)) {
      if (
        !dirty &&
        values[formMeta.professionId.fieldName] === Config.OTHER_PROFESSION_ID
      ) {
        setOtherProfessionVisibility(true);
      } else if (
        oldShadow[formMeta.professionId.fieldName] !==
        values[formMeta.professionId.fieldName]
      ) {
        const professionId =
          typeof values[formMeta.professionId.fieldName] === "string"
            ? parseInt(values[formMeta.professionId.fieldName])
            : values[formMeta.professionId.fieldName];
        if (professionId === Config.OTHER_PROFESSION_ID) {
          setOtherProfessionVisibility(true);
        } else {
          setOtherProfessionVisibility(false);
          setFieldValue(formMeta.otherProfession.fieldName, "");
        }
      }
    }

    setOldShadow(values);
    if (isFunction(setFormValues)) {
      setFormValues(values);
    }

    if (isFunction(setFomValidity)) {
      validateForm().then((errors) => {
        setErrors(errors);
        setFomValidity(Object.keys(errors).length === 0);
      });
    }
  }, [
    values,
    oldShadow,
    setFormValues,
    setFieldValue,
    fetchCommunities,
    dirty,
    setFomValidity,
    validateForm,
    setErrors,
  ]);

  return (
    <FormSection heading={getHeading()}>
      <Grid container direction="row" style={{ rowGap: 20 }}>
        {fieldVisibility(formMeta.ethnicityId) && (
          <Grid className="form-element-container">
            <FormDropdown
              fieldName={formMeta.ethnicityId.fieldName}
              value={values.ethnicityId}
              onChange={handleChange}
              options={ethnicities}
              keyExtractor={formMeta.ethnicityId.keyExtractor}
              labelExtractor={formMeta.ethnicityId.labelExtractor}
              label={t(formMeta.ethnicityId.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              errorText={submitCount > 0 && errors.ethnicityId}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.religionId) && (
          <Grid className="form-element-container">
            <FormDropdown
              fieldName={formMeta.religionId.fieldName}
              value={values.religionId}
              onChange={handleChange}
              options={religions}
              keyExtractor={formMeta.religionId.keyExtractor}
              labelExtractor={formMeta.religionId.labelExtractor}
              label={t(formMeta.religionId.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              errorText={submitCount > 0 && errors.religionId}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.caste) && (
          <Grid className="form-element-container">
            <FormTextInput
              fieldName={formMeta.caste.fieldName}
              value={values.caste}
              onChange={handleChange}
              label={t(formMeta.caste.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              placeholder={t(formMeta.caste.placeholder)}
              errorText={submitCount > 0 && errors.caste}
              onBlur={() => {
                trimFieldValue({
                  fieldName: formMeta.caste.fieldName,
                  value: values[formMeta.caste.fieldName],
                  setFieldValue,
                });
              }}
              max={formMeta.caste.max}
              maxCharactorCount={formMeta.caste.max}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.communityId) && (
          <Grid className="form-element-container">
            <FormDropdown
              fieldName={formMeta.communityId.fieldName}
              value={values.communityId}
              onChange={handleChange}
              options={communityOptions}
              keyExtractor={formMeta.communityId.keyExtractor}
              labelExtractor={formMeta.communityId.labelExtractor}
              label={t(formMeta.communityId.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              errorText={submitCount > 0 && errors.communityId}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.subCommunity) && (
          <Grid className="form-element-container">
            <FormTextInput
              fieldName={formMeta.subCommunity.fieldName}
              value={values.subCommunity}
              onChange={handleChange}
              label={t(formMeta.subCommunity.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              placeholder={t(formMeta.subCommunity.placeholder)}
              errorText={submitCount > 0 && errors.subCommunity}
              onBlur={() => {
                trimFieldValue({
                  fieldName: formMeta.subCommunity.fieldName,
                  value: values[formMeta.subCommunity.fieldName],
                  setFieldValue,
                });
              }}
              maxCharactorCount={formMeta.subCommunity.max}
              max={formMeta.subCommunity.max}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.residentCountryCode) && (
          <Grid className="form-element-container">
            <FormDropdown
              fieldName={formMeta.residentCountryCode.fieldName}
              value={values.residentCountryCode}
              onChange={handleChange}
              options={countries}
              keyExtractor={formMeta.residentCountryCode.keyExtractor}
              labelExtractor={formMeta.residentCountryCode.labelExtractor}
              label={t(formMeta.residentCountryCode.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              errorText={submitCount > 0 && errors.residentCountryCode}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.professionId) && (
          <Grid className="form-element-container">
            <FormDropdown
              fieldName={formMeta.professionId.fieldName}
              value={values.professionId}
              onChange={handleChange}
              options={parentProfessions}
              keyExtractor={formMeta.professionId.keyExtractor}
              labelExtractor={formMeta.professionId.labelExtractor}
              label={t(formMeta.professionId.label)}
              lang={appLanguage}
              labelStyles={labelStyles}
              isOptional={true}
              errorText={submitCount > 0 && errors.professionId}
            />
          </Grid>
        )}

        {fieldVisibility(formMeta.otherProfession) &&
          otherProfessionVisibility && (
            <Grid className="form-element-container">
              <FormTextInput
                fieldName={formMeta.otherProfession.fieldName}
                value={values.otherProfession}
                onChange={handleChange}
                label={t(formMeta.otherProfession.label)}
                lang={appLanguage}
                labelStyles={labelStyles}
                errorText={submitCount > 0 && errors.otherProfession}
                visibility={
                  fieldVisibility(formMeta.otherProfession) &&
                  otherProfessionVisibility
                }
                onBlur={() => {
                  trimFieldValue({
                    fieldName: formMeta.otherProfession.fieldName,
                    value: values[formMeta.otherProfession.fieldName],
                    setFieldValue,
                  });
                }}
                max={formMeta.otherProfession.max}
                maxCharactorCount={formMeta.otherProfession.max}
              />
            </Grid>
          )}

        {fieldVisibility(formMeta.additionalInfo) && (
          <Grid item xs={12} direction="row">
            <FormTextInput
              fieldName={formMeta.additionalInfo.fieldName}
              value={values.additionalInfo}
              onChange={(e) => {
                handleChange(e);
                setAdditionalInfoError("");
              }}
              multiline={true}
              label={t(formMeta.additionalInfo.label)}
              labelStyles={isMobileScreen ? {} : { width: "10%" }}
              lang={appLanguage}
              placeholder={t(formMeta.additionalInfo.placeholder)}
              errorText={submitCount > 0 && additionalInfoError}
              onBlur={() => {
                trimFieldValue({
                  fieldName: formMeta.additionalInfo.fieldName,
                  value: values[formMeta.additionalInfo.fieldName],
                  setFieldValue,
                });
              }}
              max={formMeta.additionalInfo.max}
              maxCharactorCount={formMeta.additionalInfo.max}
            />
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
};

export default ParentInformation;
