import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";
import FormItemLabel from "../../atoms/form-item-label";

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

const FormTextView = ({
  label,
  text,
  lang,
  visibility = true,
  labelStyles = {},
  labelPosition = LABEL_POSITION.left,
}) => {
  return (
    visibility && (
      <Grid
        container
        direction="row"
        className="form-text-input"
        alignItems="center"
      >
        <FormItemLabel
          text={label}
          lang={lang}
          labelPosition={labelPosition}
          styleOverrides={labelStyles}
        />
        <Grid item xs className="text-view-container">
          <Typography variant="body2">{text}</Typography>
        </Grid>
      </Grid>
    )
  );
};

export default FormTextView;
