import React from "react";
import { Button } from "@material-ui/core";

import { removePost } from "../../../actions/removePost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Info from "../../../images/info.png";

const IncorrectToken = (props) => {
  const clickToLogin = () => {
    let login = document.getElementById("drawer-login-btn");
    login.click();
  };
  return (
    <div
      className={
        props.isEmptyObj
          ? "received-profile-detail-div"
          : "received-profile-detail-div show-view"
      }
    >
      <span
        className="dis-back-btn back-btn-msg"
        onClick={() => props.removePost()}
      >
        <i className="fas fa-angle-left"></i> {props.lng ? "Back" : "ආපසු යන්න"}
      </span>
      <div className="inner-div" style={{ background: "#fff" }}>
        <div className="disable-div">
          <p align="center" style={{ marginBottom: 0 }}>
            <img src={Info} alt="info" style={{ width: 65, opacity: 0.7 }} />
          </p>
          <p className="message">
            {props.lng ? "Access Denied" : "භාවිතයට අවසර නැත"}
          </p>
          <p align="center" className="desc">
            {props.lng ? (
              "Content cannot be accessed by the logged-in user. Please log in to the correct ad account."
            ) : (
              <span className="sinhala-w-600 sinhala-size-14">
                පිවිසී ඇති පරිශීලකයාට අන්තර්ගතය නැරඹීමට නොහැක. කරුණාකර නිවැරදි
                දැන්වීම් ගිණුමට පිවිසෙන්න.
              </span>
            )}
          </p>

          <Button
            variant="contained"
            onClick={clickToLogin}
            className="login-btn"
            style={{ width: 120 }}
          >
            {props.lng ? (
              "Login"
            ) : (
              <span className="sinhala-w-600 sinhala-size-14">
                ප්‍රවේශ වන්න
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ removePost: removePost }, dispatch);
};

export default connect(null, matchDispatchToProps)(IncorrectToken);
