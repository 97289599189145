import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Close from "../../../images/close.png";

function DisabledAlertModal(props) {
  return (
    <Dialog
      open={props.disableAlertModal}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
        {props.lng ? "Ad Disabled" : "දැන්වීම අක්‍රීයයි"}
        <img
          src={Close}
          alt="close"
          className="close-modal"
          onClick={props.handleModalClose}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.lng
            ? "You have disabled your ad. Please contact support to get your ad account re-activated."
            : "ඔබ ඔබේ දැන්වීම අක්‍රීය කර ඇත. ඔබේ දැන්වීම නැවත සක්‍රිය කිරීමට කරුණාකර අපව අමතන්න."}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default DisabledAlertModal;
