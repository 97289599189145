import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

export default class ComErrorAlert extends Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.props.errorAlert}
        onClose={this.props.handleClose}
      >
        <Alert onClose={this.shouldComponentUpdatehandleClose} severity="error">
          {this.props.errorCode}{" "}
          {this.props.lng ? (
            ": Unexpected server error"
          ) : (
            <span className="sinhala-w-600 sinhala-size-10">
              : අනපේක්ෂිත දෝෂයකි
            </span>
          )}
        </Alert>
      </Snackbar>
    );
  }
}
