import React from "react";
import { Link } from "react-router-dom";
import Config from "../../../v2/config";

import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../../v2/templates/branding/product-logo";

function Header() {
  return (
    <div className="header-sec">
      <Link to="/" style={{ display: "block", margin: "auto", width: "138px" }}>
        <picture>
          <ProductLogo
            variant={LogoVariant.white}
            size={LogoSize.medium}
            className={"poruwa-logo"}
          />
        </picture>
      </Link>
      <p className="heading">Welcome to {Config.DOMAIN_DISPLAY_NAME}</p>
      <p className="heading-sub">
        {` The only privacy focused matrimony website for ${
          Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
            ? "Sri Lankans"
            : "Indians"
        }`}
      </p>
    </div>
  );
}

export default Header;
